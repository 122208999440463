import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import {
  closeMassEditDialog,
  disableUsers,
  enableUsers,
} from 'redux/actions/massEdit';
import DisableUsers from './DisableUsers';
import ChangeGroup from './ChangeGroup';
import MassRefund from './MassRefund';
import TabPanel from './TabPanel';
import TabBar from './TabBar';

const MassEditDialog = ({ fullDataUsers, showFullData }) => {
  const dispatch = useDispatch();
  const { visible, selected } = useSelector((state) => state.mass);
  const paginatedUsers = useSelector((state) => state.paginatedUsers.users);

  let users = [];
  if (showFullData) {
    users = fullDataUsers;
  } else {
    users = paginatedUsers;
  }
  const [value, setValue] = useState(0);
  const selectedUsers = selected && selected.map((x) => users[x.dataIndex]);

  const handleChange = (_, value) => {
    setValue(value);
  };

  const handleClose = () => {
    dispatch(closeMassEditDialog());
  };

  const sendDisableRequest = () => {
    const userIds = [];
    selectedUsers.map((user) => {
      if (user && user._id && typeof user._id === 'string') {
        userIds.push(user._id);
      } else {
        console.log('No user id found for user', user);
      }
      return null;
    });
    dispatch(disableUsers(userIds));
  };

  const sendEnableRequest = () => {
    const userIds = [];
    selectedUsers.map((user) => {
      if (user && user._id && typeof user._id === 'string') {
        userIds.push(user._id);
      } else {
        console.log('No user id found for user', user);
      }
      return null;
    });
    dispatch(enableUsers(userIds));
  };

  return (
    <Fragment>
      {visible && (
        <Dialog open={visible} fullScreen>
          <DialogContent>
            <TabBar value={value} handleChange={handleChange} />
            <TabPanel value={value} index={0}>
              <DisableUsers
                disableUsers={sendDisableRequest}
                enableUsers={sendEnableRequest}
                users={selectedUsers}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ChangeGroup users={selectedUsers} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <MassRefund users={selectedUsers} />
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default MassEditDialog;
