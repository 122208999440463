import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactFileReader from 'react-file-reader';
import Papa from 'papaparse';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { makeStyles } from '@material-ui/core/styles';

import {
  setCSVDialogVisibleFalse,
  updateCsvJSON,
  saveTicketsToUsers,
} from 'redux/actions/ferry';

import { getCSVDialogFromState } from 'redux/selectors/ferry';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  importButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  csvImportedDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },

  csvImportedIcon: {
    marginTop: theme.spacing(2),
    color: green[500],
  },
}));

export const ImportCSVDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { visible, csvJSON, ferryId } = useSelector((state) =>
    getCSVDialogFromState(state.ferry)
  );

  const handleFiles = (files) => {
    Papa.parse(files[0], {
      header: true,
      complete: function (results) {
        dispatch(updateCsvJSON(results.data));
      },
    });
  };

  return (
    <Fragment>
      {visible && (
        <Dialog
          open={visible}
          onClose={() => dispatch(setCSVDialogVisibleFalse())}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Import QR-code Tickets
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Import a CSV file of QR tickets. QR ticket is attached to
              customers outbound ferry or return ferry depending on customers
              groups ferry choices.
            </DialogContentText>
            <div className={classes.importButtonContainer}>
              <ReactFileReader handleFiles={handleFiles} fileTypes=".csv">
                <Button
                  variant="contained"
                  className={classes.button}
                  size="small"
                  style={{ color: grey[50], backgroundColor: green[500] }}
                  startIcon={<InsertDriveFileIcon />}
                >
                  Import CSV
                </Button>
              </ReactFileReader>
              {csvJSON && (
                <div className={classes.csvImportedDiv}>
                  <CheckCircleOutlineIcon className={classes.csvImportedIcon} />
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            {csvJSON && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(saveTicketsToUsers(csvJSON, ferryId));
                }}
              >
                Upload tickets to server
              </Button>
            )}
            <Button
              onClick={() => dispatch(setCSVDialogVisibleFalse())}
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default ImportCSVDialog;
