import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import CustomerAlert from 'components/layouts/CustomerAlert';
import DiscountCode from '../discountCodes/discountCode';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditIcon from '@material-ui/icons/Edit';

import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

import { DatePicker } from '@material-ui/pickers';

import {
  saveUserInfo,
  toggleEditUserInfo,
  toggleShowPassword,
  updateInfoFirstName,
  updateInfoLastName,
  updateInfoAddressCity,
  updateInfoAddressPostCode,
  updateInfoAddressStreet,
  updateInfoBirthday,
  updateInfoEmail,
  toggleInfoMarketing,
  updateInfoPhone,
  updateInfoPassword,
  updateInfoGender,
  updateInfoCountry,
} from 'redux/actions/customer';
import { fetchCustomerOrganizationById } from 'redux/actions/organization';

import {
  getUserInfoFormState,
  getUserEventFromState,
} from 'redux/selectors/customer';
import CountrySelector from 'components/layouts/CountrySelector';
import { usePhoneValidator } from 'hooks/usePhoneValidator';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  infoContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginLeft: '8%',
    marginRight: '6%',
  },
  infoHeader: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '80px',
    lineHeight: '98px',
    zIndex: 1000,
    color: '#FFFFFF',
  },
  infoText: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '120%',
    zIndex: 1000,
    color: '#FFFFFF',
  },
  ownInfoHeader: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '39px',
    lineHeight: '50px',
    zIndex: 1000,
    color: '#FFFFFF',
    marginTop: theme.spacing(4),
  },
  ownInfoText: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    zIndex: 1000,
    color: '#FFFFFF',
  },

  infoHeaderMobile: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '48px',
    lineHeight: '102%',
    zIndex: 1000,
    color: '#FFFFFF',
  },
  infoTextMobile: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '110%',
    zIndex: 1000,
    color: '#FFFFFF',
  },
  ownInfoHeaderMobile: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '102%',
    zIndex: 1000,
    color: '#FFFFFF',
    marginTop: theme.spacing(4),
  },
  ownInfoTextMobile: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '115%',
    zIndex: 1000,
    color: '#FFFFFF',
  },

  ownInfoTextWithMarginTopMobile: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '22px',
    zIndex: 1000,
    color: '#FFFFFF',
    marginTop: theme.spacing(2),
  },

  ownInfoTextWithMarginTop: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    zIndex: 1000,
    color: '#FFFFFF',
    marginTop: theme.spacing(2),
  },
  ownInfoChangeMarketing: {
    cursor: 'pointer',
    color: '#FFFFFF',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',

    zIndex: 1000,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControlGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  formInput: {
    background: 'rgba(255, 255, 255, 0.1)',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '119%',
    /* or 74px */
    color: '#FFFFFF',
    opacity: 0.5,
  },
  inputText: {
    color: '#FFFFFF',
    opacity: 0.95,
  },
  inputLabel: {
    color: '#FFFFFF',
    opacity: 0.95,
    '&.focused': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
    '&.shrink': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
  },
  alertBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  editButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    position: 'absolute',
    width: '2,71%',
    height: '4,81%',
    right: 0,
    /* White */
    background: '#FFFFFF',
    borderRadius: '69px',
  },
  saveButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    margin: '8px 0px',
  },
  iconSpan: {
    position: 'absolute',
    right: '3%',
    top: '-50%',
  },
  editButtonMobile: {
    '&:hover': {
      background: '#AD00FF',
    },
    position: 'absolute',
    width: '2,71%',
    height: '4,81%',
    right: '10%',
    top: '-400%',
    /* White */
    background: '#FFFFFF',
    borderRadius: '69px',
  },
  ownInfoExpandIcon: {
    color: '#FFFFFF',
    fontSize: '24px',
    lineHeight: '102%',
    /* or 18px */
  },
}));

export const UserInformation = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const mobileScreen = useMediaQuery('(max-width:990px)');

  const [ownInfoVisible, setOwnInfoVisible] = useState(true);

  useEffect(() => {
    if (mobileScreen) {
      setOwnInfoVisible(false);
    }
  }, [mobileScreen]);

  const { showPassword, info, editUserInfo } = useSelector((state) =>
    getUserInfoFormState(state.customer)
  );
  const { event } = useSelector((state) =>
    getUserEventFromState(state.customer)
  );

  const { group } = useSelector((state) => state.customer);

  useEffect(() => {
    if (event.organization) {
      dispatch(fetchCustomerOrganizationById(event.organization));
    }
  }, [dispatch, event.organization]);

  const { AlertComponent, setIsPhoneValid, validatePhoneNumber } =
    usePhoneValidator();

  return (
    <div
      className={
        mobileScreen ? classes.infoContainerMobile : classes.infoContainer
      }
    >
      {info && (
        <Fragment>
          <div key={info._id}>
            <Typography
              className={
                mobileScreen ? classes.infoHeaderMobile : classes.infoHeader
              }
            >
              Terve {info.firstName}!
            </Typography>
            <Typography
              className={
                mobileScreen ? classes.infoTextMobile : classes.infoText
              }
            >
              Tarkastele omia tietoja, lippuja ja muita matkan yksityiskohtia.
            </Typography>

            <Typography
              className={
                mobileScreen
                  ? classes.ownInfoHeaderMobile
                  : classes.ownInfoHeader
              }
            >
              Ryhmä
            </Typography>
            <Typography
              className={
                mobileScreen ? classes.ownInfoTextMobile : classes.ownInfoText
              }
            >
              {group?.name}
            </Typography>
            <Typography
              className={
                mobileScreen
                  ? classes.ownInfoHeaderMobile
                  : classes.ownInfoHeader
              }
            >
              Omat tiedot
              {mobileScreen && (
                <span>
                  <IconButton
                    className={classes.iconSpan}
                    onClick={() => setOwnInfoVisible(!ownInfoVisible)}
                  >
                    {ownInfoVisible ? (
                      <ExpandLessIcon className={classes.ownInfoExpandIcon} />
                    ) : (
                      <ExpandMoreIcon className={classes.ownInfoExpandIcon} />
                    )}
                  </IconButton>
                </span>
              )}
            </Typography>
            {ownInfoVisible && (
              <Fragment>
                {!editUserInfo ? (
                  <Fragment>
                    <Typography
                      className={
                        mobileScreen
                          ? classes.ownInfoTextMobile
                          : classes.ownInfoText
                      }
                    >
                      {info.firstName + ' ' + info.lastName}
                    </Typography>
                    <Typography
                      className={
                        mobileScreen
                          ? classes.ownInfoTextMobile
                          : classes.ownInfoText
                      }
                    >
                      {moment(info.birthday).format('DD.MM.YYYY')}
                    </Typography>
                    <Typography
                      className={
                        mobileScreen
                          ? classes.ownInfoTextMobile
                          : classes.ownInfoText
                      }
                    >
                      {info.gender === 'F' ? 'Nainen' : 'Mies'}
                    </Typography>

                    <Typography
                      className={
                        mobileScreen
                          ? classes.ownInfoTextWithMarginTopMobile
                          : classes.ownInfoTextWithMarginTop
                      }
                    >
                      {info.address.street}
                    </Typography>
                    <Typography
                      className={
                        mobileScreen
                          ? classes.ownInfoTextMobile
                          : classes.ownInfoText
                      }
                    >
                      {info.address.postcode}
                    </Typography>
                    <Typography
                      className={
                        mobileScreen
                          ? classes.ownInfoTextMobile
                          : classes.ownInfoText
                      }
                    >
                      {info.address.city}
                    </Typography>

                    <Typography className={classes.ownInfoTextWithMarginTop}>
                      {info.email}
                    </Typography>
                    <Typography
                      className={
                        mobileScreen
                          ? classes.ownInfoTextMobile
                          : classes.ownInfoText
                      }
                    >
                      {info.phone}
                      {event && event.allowUsersEditInfo && (
                        <IconButton
                          className={
                            mobileScreen
                              ? classes.editButtonMobile
                              : classes.editButton
                          }
                          onClick={() => {
                            dispatch(toggleEditUserInfo());
                          }}
                        >
                          <EditIcon style={{ color: '#20D2B7' }} />
                        </IconButton>
                      )}
                    </Typography>
                    <DiscountCode userId={info._id} />
                  </Fragment>
                ) : (
                  <form className={classes.form} noValidate>
                    <div className={classes.alertBox}>
                      <CustomerAlert />
                    </div>
                    <div className={classes.formControlGroup}>
                      <TextField
                        className={classes.formInput}
                        margin="dense"
                        required
                        fullWidth
                        id="first name"
                        name="first name"
                        label="Etunimi"
                        value={info.firstName}
                        onChange={(e) =>
                          dispatch(updateInfoFirstName(e.target.value))
                        }
                        InputProps={{
                          className: classes.inputText,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                            shrink: 'shrink',
                          },
                        }}
                      />
                    </div>
                    <div className={classes.formControlGroup}>
                      <TextField
                        className={classes.formInput}
                        margin="dense"
                        required
                        fullWidth
                        id="last name"
                        name="last name"
                        label="Sukunimi"
                        value={info.lastName}
                        onChange={(e) =>
                          dispatch(updateInfoLastName(e.target.value))
                        }
                        InputProps={{
                          className: classes.inputText,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                            shrink: 'shrink',
                          },
                        }}
                      />
                    </div>
                    <div className={classes.formControlGroup}>
                      <DatePicker
                        disableFuture
                        className={classes.formInput}
                        fullWidth
                        required
                        openTo="year"
                        format="DD/MM/yyyy"
                        label="Syntymäaika"
                        views={['year', 'month', 'date']}
                        id="birthday"
                        variant="inline"
                        value={info.birthday}
                        InputProps={{
                          className: classes.inputText,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                            shrink: 'shrink',
                          },
                        }}
                        onChange={(date) => {
                          dispatch(updateInfoBirthday(date));
                        }}
                      />
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="dense"
                          required
                          fullWidth
                          className={classes.formInput}
                          id="gender"
                          name="gender"
                          label="Sukupuoli"
                          select
                          value={info.gender}
                          onChange={(e) => {
                            dispatch(updateInfoGender(e.target.value));
                          }}
                          InputProps={{
                            className: classes.inputText,
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              focused: 'focused',
                              shrink: 'shrink',
                            },
                          }}
                          autoComplete="gender"
                        >
                          <MenuItem key={'F'} value={'F'}>
                            Nainen
                          </MenuItem>
                          <MenuItem key={'M'} value={'M'}>
                            Mies
                          </MenuItem>
                        </TextField>
                        <FormHelperText id="gender-text">
                          Meidän pitää kysyä tätä tietoa matkustussäädösten
                          takia. Kerrothan siis passissa / virallisessa
                          henkilöllisyystodistuksessa näkyvän sukupuolen.
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <TextField
                        className={classes.formInput}
                        margin="dense"
                        required
                        fullWidth
                        id="street"
                        name="street"
                        label="Katuosoite"
                        value={info.address.street}
                        onChange={(e) =>
                          dispatch(updateInfoAddressStreet(e.target.value))
                        }
                        InputProps={{
                          className: classes.inputText,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                            shrink: 'shrink',
                          },
                        }}
                      />
                    </div>
                    <div className={classes.formControlGroup}>
                      <TextField
                        className={classes.formInput}
                        margin="dense"
                        required
                        fullWidth
                        id="postCode"
                        name="postCode"
                        label="Postinumero"
                        value={info.address.postcode}
                        onChange={(e) =>
                          dispatch(updateInfoAddressPostCode(e.target.value))
                        }
                        InputProps={{
                          className: classes.inputText,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                            shrink: 'shrink',
                          },
                        }}
                      />
                    </div>
                    <div className={classes.formControlGroup}>
                      <TextField
                        className={classes.formInput}
                        margin="dense"
                        required
                        fullWidth
                        id="city"
                        name="city"
                        label="Kaupunki"
                        value={info.address.city}
                        onChange={(e) =>
                          dispatch(updateInfoAddressCity(e.target.value))
                        }
                        InputProps={{
                          className: classes.inputText,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                            shrink: 'shrink',
                          },
                        }}
                      />
                    </div>
                    <div className={classes.formControlGroup}>
                      <TextField
                        className={classes.formInput}
                        margin="dense"
                        required
                        fullWidth
                        id="email"
                        name="email"
                        label="Sähköposti"
                        value={info.email}
                        onChange={(e) =>
                          dispatch(updateInfoEmail(e.target.value))
                        }
                        InputProps={{
                          className: classes.inputText,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                            shrink: 'shrink',
                          },
                        }}
                      />
                    </div>
                    <div className={classes.formControlGroup}>
                      <TextField
                        className={classes.formInput}
                        margin="dense"
                        required
                        fullWidth
                        placeholder="+3581234567890"
                        id="phone"
                        name="phone"
                        label="Puhelin"
                        value={info.phone}
                        onChange={(e) => {
                          setIsPhoneValid(true);
                          dispatch(updateInfoPhone(e.target.value));
                        }}
                        InputProps={{
                          className: classes.inputText,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                            shrink: 'shrink',
                          },
                        }}
                      />
                    </div>
                    <div className={classes.formControlGroup}>
                      <CountrySelector
                        className={classes.formInput}
                        setCountry={updateInfoCountry}
                        currentValue={info.country}
                        fullWidth={true}
                        label={'Kansallisuus'}
                        inputLabelProps={classes.inputLabel}
                        inputProps={classes.inputText}
                      ></CountrySelector>
                    </div>
                    {editUserInfo && (
                      <div className={classes.formControlGroup}>
                        <TextField
                          className={classes.formInput}
                          margin="dense"
                          required
                          fullWidth
                          name="password"
                          label="Salasana"
                          type={showPassword ? 'text' : 'password'}
                          id="password"
                          value={info.password}
                          onChange={(e) => {
                            dispatch(updateInfoPassword(e.target.value));
                          }}
                          autoComplete="current-password"
                          InputProps={{
                            className: classes.inputText,
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={(e) =>
                                    dispatch(toggleShowPassword())
                                  }
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              focused: 'focused',
                              shrink: 'shrink',
                            },
                          }}
                        />
                      </div>
                    )}

                    <Typography
                      className={
                        mobileScreen
                          ? classes.ownInfoTextMobile
                          : classes.ownInfoText
                      }
                    >
                      {info.marketing
                        ? 'Minulle saa lähettää kiinnostavia viestejä tapahtumista, matkoista ja tarjouksista.'
                        : 'Minulle EI saa lähettää kiinnostavia viestejä tapahtumista, matkoista ja tarjouksista.'}
                    </Typography>
                    <Typography
                      className={classes.ownInfoChangeMarketing}
                      variant={'subtitle2'}
                      onClick={() => dispatch(toggleInfoMarketing())}
                    >
                      Muuta markkinointiasetuksia
                    </Typography>

                    <div className={classes.formControlGroup}>
                      <div className={classes.buttonContainer}>
                        <Button
                          className={classes.saveButton}
                          onClick={() => {
                            const isPhoneValid = validatePhoneNumber(
                              info.phone
                            );
                            if (!isPhoneValid) {
                              return;
                            }
                            dispatch(saveUserInfo(info));
                          }}
                        >
                          Tallenna
                        </Button>
                      </div>
                    </div>
                    <AlertComponent />
                  </form>
                )}
              </Fragment>
            )}
            <div></div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default UserInformation;
