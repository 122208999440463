import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { openMassEditDialog } from 'redux/actions/massEdit';

const useStyles = makeStyles(() => ({
  iconButton: {
    marginRight: '24px',
    top: '50%',
    display: 'inline-block',
    position: 'relative',
  },
}));

export const CustomToolbarSelect = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = () => {
    dispatch(openMassEditDialog(props.selectedRows));
  };

  return (
    <div>
      <Tooltip title={'Mass edit'}>
        <IconButton className={classes.iconButton} onClick={handleClick}>
          <AssessmentIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CustomToolbarSelect;
