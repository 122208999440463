import {
  SAVE_ADMIN_REQUESTED,
  SAVE_ADMIN_RESPONSE,
  SAVE_ADMIN_ERROR,
  UPDATE_ADMIN_REQUESTED,
  UPDATE_ADMIN_RESPONSE,
  UPDATE_ADMIN_ERROR,
  UPDATE_ADMIN_NAME,
  UPDATE_ADMIN_EMAIL,
  UPDATE_ADMIN_PASSWORD,
  UPDATE_ADMIN_ORGANIZATION,
  UPDATE_ADMIN_ROLE,
  TOGGLE_ADMIN_PASSWORD,
  GET_ADMINS_ERROR,
  GET_ADMINS_REQUESTED,
  GET_ADMINS_RESPONSE,
  SELECT_EXISTING_ADMIN,
  SELECT_NEW_ADMIN,
  DELETE_ADMIN_REQUESTED,
  DELETE_ADMIN_RESPONSE,
  DELETE_ADMIN_ERROR,
} from 'redux/actions/admin';

const initialState = {
  admins: [],
  admin: {
    name: null,
    email: null,
    password: null,
    organization: null,
    role: null,
    _id: '',
  },
  showPassword: false,
  error: null,
};

const updateAdminsArray = (admins, updated) => {
  const filtered = admins.filter((admin) => admin._id !== updated._id);
  filtered.push(updated);
  return filtered;
};

const addToAdminsArray = (admins, updated) => {
  const filtered = admins.filter((admin) => admin.email !== updated.email);
  filtered.push(updated);
  return filtered;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ADMIN_RESPONSE:
      return {
        ...state,
        admins: addToAdminsArray(state.admins, action.payload),
      };

    case UPDATE_ADMIN_RESPONSE:
      return {
        ...state,
        admins: updateAdminsArray(state.admins, action.payload),
      };

    case DELETE_ADMIN_RESPONSE:
      return {
        ...state,
        admins: state.admins.filter(
          (admin) => admin._id !== action.payload._id
        ),
        admin: initialState.admin,
      };

    case SAVE_ADMIN_ERROR:
      return { ...state, error: action.payload };

    case UPDATE_ADMIN_ERROR:
      return { ...state, error: action.payload };

    case DELETE_ADMIN_ERROR:
      return { ...state, error: action.payload };

    case SELECT_EXISTING_ADMIN:
      return { ...state, admin: action.payload };

    case SELECT_NEW_ADMIN:
      return {
        ...state,
        admin: { ...initialState.admin, organization: action.payload },
      };

    case TOGGLE_ADMIN_PASSWORD:
      return { ...state, showPassword: !state.showPassword };

    case GET_ADMINS_RESPONSE:
      return { ...state, admins: action.payload };

    case GET_ADMINS_ERROR:
      return { ...state, error: action.payload };

    case UPDATE_ADMIN_NAME:
      return {
        ...state,
        admin: { ...state.admin, name: action.payload },
      };

    case UPDATE_ADMIN_EMAIL:
      return {
        ...state,
        admin: { ...state.admin, email: action.payload },
      };

    case UPDATE_ADMIN_PASSWORD:
      return {
        ...state,
        admin: { ...state.admin, password: action.payload },
      };

    case UPDATE_ADMIN_ORGANIZATION:
      return {
        ...state,
        admin: { ...state.admin, organization: action.payload },
      };

    case UPDATE_ADMIN_ROLE:
      return {
        ...state,
        admin: { ...state.admin, role: action.payload },
      };

    case SAVE_ADMIN_REQUESTED:
    case GET_ADMINS_REQUESTED:
    case UPDATE_ADMIN_REQUESTED:
    case DELETE_ADMIN_REQUESTED:
    default:
      return state;
  }
}
