import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';

import { logout } from 'redux/actions/auth';

import { getUserEventFromState } from 'redux/selectors/customer';

const useStyles = makeStyles({
  naviContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5%',
    marginLeft: '8%',
    marginRight: '6%',
    marginBottom: '5%',
    justifyContent: 'space-between',
  },

  eventName: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '102%',
    zIndex: 1000,
    color: '#FFFFFF',
  },

  menuOption: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    marginLeft: '10%',
    fontSize: '24px',
    lineHeight: '102%',
    zIndex: 1000,
    color: '#20D2B7',
    '&:hover': {
      background: '#20D2B7',
      color: '#FFFFFF',
    },
  },
  divider: {
    // Theme Color, or use css color in quote
    background: '#20D2B7',
  },

  fullList: {
    width: 'auto',
  },
});

export const MobileNavigation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [navOpen, setNavOpen] = useState(false);

  const toggleNavigation = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setNavOpen(open);
  };

  const { event } = useSelector((state) =>
    getUserEventFromState(state.customer)
  );

  return (
    <div>
      <Fragment>
        <div className={classes.naviContainer}>
          {event && (
            <Typography className={classes.eventName}>{event.name}</Typography>
          )}
          <IconButton onClick={toggleNavigation(true)}>
            <MenuIcon fontSize="large" style={{ color: '#FFFFFF' }} />
          </IconButton>
        </div>
        <Drawer anchor="top" open={navOpen} onClose={toggleNavigation(false)}>
          <div
            className={classes.fullList}
            role="presentation"
            onClick={toggleNavigation(false)}
            onKeyDown={toggleNavigation(false)}
          >
            <List>
              <ListItem
                component={NavHashLink}
                to="/user-dashboard#payment-section"
              >
                <ListItemText className={classes.menuOption}>
                  Maksut
                </ListItemText>
              </ListItem>
              <Divider classes={{ root: classes.divider }} />
              <ListItem component={NavHashLink} to="#ferry-section">
                <ListItemText className={classes.menuOption}>
                  Laivamatka
                </ListItemText>
              </ListItem>
              <Divider classes={{ root: classes.divider }} />
              <ListItem component={NavHashLink} to="#accomodation-section">
                <ListItemText className={classes.menuOption}>
                  Yöpyminen
                </ListItemText>
              </ListItem>
              <Divider classes={{ root: classes.divider }} />
              <ListItem component={NavHashLink} to="#info-section">
                <ListItemText className={classes.menuOption}>
                  Infopaketit
                </ListItemText>
              </ListItem>
              <Divider classes={{ root: classes.divider }} />
              <ListItem
                component={NavLink}
                to="/"
                onClick={() => dispatch(logout())}
              >
                <ListItemText className={classes.menuOption}>
                  Kirjaudu ulos
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </Drawer>
      </Fragment>
    </div>
  );
};

export default MobileNavigation;
