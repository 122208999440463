export const combineReqData = (
  next,
  prev,
  limit,
  orgId,
  lastId,
  filters,
  searchBarValue
) => {
  if (filters) {
    filters = getFilters(filters);
  }

  let reqData = {
    orgId,
    limit,
    next,
    prev,
    lastId,
    filters,
    searchBarValue,
  };
  return reqData;
};

export const combineCsvDownloadData = (
  orgId,
  filters,
  columns,
  searchBarValue
) => {
  if (filters) {
    filters = getFilters(filters);
  }

  // Here we check if the column is marked to be downloaded or not
  const csvColumns = columns
    .filter((column) => column.download === true)
    .map((column) => {
      return { label: column.label, value: column.name };
    });

  let reqData = {
    orgId,
    filters,
    fields: csvColumns,
    searchBarValue,
  };
  return reqData;
};

const getFilters = (filters) => {
  filters = [].concat(...filters);
  let groupFilters = filters.filter((v) => v.type === 'group');
  let eventFilters = filters.filter((v) => v.type === 'event');
  let accomodationFilters = filters.filter((v) => v.type === 'accomodations');
  let ferryFilters = filters.filter((v) => v.type === 'ferry');
  let discountFilters = filters.filter((v) => v.type === 'discountCodes');
  let roomFilters = filters.filter((v) => v.type === 'room');
  let productFilters = filters.filter((v) => v.type === 'product');
  let roomPartnerChosenFilters = filters.filter(
    (v) => v.type === 'roomPartnerChosen'
  );
  let disabledFilters = filters.filter((v) => v.type === 'disabled');
  let firstNameFilters = filters.filter((v) => v.type === 'firstName');
  let lastNameFilters = filters.filter((v) => v.type === 'lastName');

  filters = {
    groupFilters,
    eventFilters,
    ferryFilters,
    accomodationFilters,
    discountFilters,
    roomFilters,
    productFilters,
    roomPartnerChosenFilters,
    firstNameFilters,
    lastNameFilters,
    disabledFilters,
  };
  return filters;
};
