export const getOrganizationEvents = (state) => {
  const { filterEventsOrg, events } = state;
  if (filterEventsOrg !== 'all' && filterEventsOrg !== '-') {
    const eventsToreturn = events.filter(
      (event) => event.organization === filterEventsOrg
    );
    return eventsToreturn;
  } else if (filterEventsOrg === '-') {
    return [];
  } else {
    return events;
  }
};

export const getOrganizationEventsForGroups = (state) => {
  const { filterEventsOrgForGroups, events } = state;
  if (filterEventsOrgForGroups !== 'all') {
    const eventsToreturn = events.filter(
      (event) => event.organization === filterEventsOrgForGroups
    );
    return { events: eventsToreturn };
  } else {
    return { events: events };
  }
};
