import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

export const FETCH_FERRYS_REQUESTED = 'FETCH_FERRYS_REQUESTED';
export const FETCH_FERRYS_RESPONSE = 'FETCH_FERRYS_RESPONSE';
export const FETCH_FERRYS_ERROR = 'FETCH_FERRYS_ERROR';

export const SEND_QR_TICKETS_REQUESTED = 'SEND_QR_TICKETS_REQUESTED';
export const SEND_QR_TICKETS_RESPONSE = 'SEND_QR_TICKETS_RESPONSE';
export const SEND_QR_TICKETS_ERROR = 'SEND_QR_TICKETS_ERROR';

export const UPDATE_FERRY_REQUESTED = 'UPDATE_FERRY_REQUESTED';
export const UPDATE_FERRY_RESPONSE = 'UPDATE_FERRY_RESPONSE';
export const UPDATE_FERRY_ERROR = 'UPDATE_FERRY_ERROR';

export const SAVE_FERRY_REQUESTED = 'SAVE_FERRY_REQUESTED';
export const SAVE_FERRY_RESPONSE = 'SAVE_FERRY_RESPONSE';
export const SAVE_FERRY_ERROR = 'SAVE_FERRY_ERROR';

export const DELETE_FERRY_ERROR = 'DELETE_FERRY_ERROR';
export const DELETE_FERRY_REQUESTED = 'DELETE_FERRY_REQUESTED';
export const DELETE_FERRY_RESPONSE = 'DELETE_FERRY_RESPONSE';

export const UPDATE_FERRY_NAME = 'UPDATE_FERRY_NAME';
export const UPDATE_FERRY_DEPARTURE_CITY = 'UPDATE_FERRY_DEPARTURE_CITY';
export const UPDATE_FERRY_DEPARTURE_TERMINAL =
  'UPDATE_FERRY_DEPARTURE_TERMINAL';
export const UPDATE_FERRY_DEPARTURE_DATE_TIME =
  'UPDATE_FERRY_DEPARTURE_DATE_TIME';
export const UPDATE_FERRY_DESTINATION_CITY = 'UPDATE_FERRY_DESTINATION_CITY';
export const UPDATE_FERRY_DESTINATION_TERMINAL =
  'UPDATE_FERRY_DESTINATION_TERMINAL';
export const UPDATE_FERRY_DESTINATION_DATE_TIME =
  'UPDATE_FERRY_DESTINATION_DATE_TIME';
export const UPDATE_FERRY_DESCRIPTION = 'UPDATE_FERRY_DESCRIPTION';
export const UPDATE_FERRY_ORGANIZATION = 'UPDATE_FERRY_ORGANIZATION';
export const UPDATE_FERRY_INFO = 'UPDATE_FERRY_INFO';

export const UPDATE_FERRY_PRODUCTS = 'UPDATE_FERRY_PRODUCTS';
export const UPDATE_FERRY_PRODUCT_COLLECT_PAYMENT =
  'UPDATE_FERRY_PRODUCT_COLLECT_PAYMENT';

export const SELECT_EXISTING_FERRY = 'SELECT_EXISTING_FERRY';
export const SELECT_NEW_FERRY = 'SELECT_NEW_FERRY';
export const SET_FERRY_FILTER_ORG = 'SET_FERRY_FILTER_ORG';
export const SET_FERRY_FILTER_GROUP_FORM_ORG =
  'SET_FERRY_FILTER_GROUP_FORM_ORG';

export const SET_CSV_DIALOG_VISIBLE_TRUE = 'SET_CSV_DIALOG_VISIBLE_TRUE';
export const SET_CSV_DIALOG_VISIBLE_FALSE = 'SET_CSV_DIALOG_VISIBLE_FALSE';
export const UPDATE_CSV_JSON = 'UPDATE_CSV_JSON';

export const fetchFerrys = () => async (dispatch) => {
  dispatch({ type: FETCH_FERRYS_REQUESTED });
  try {
    const res = await API.get('/api/admin/ferrys');
    dispatch({
      type: FETCH_FERRYS_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FERRYS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const saveTicketsToUsers = (csvJSON, ferryId) => async (dispatch) => {
  dispatch({ type: SEND_QR_TICKETS_REQUESTED });
  try {
    await API.post(`/api/admin/ferrys/tickets/${ferryId}`, {
      csvJSON,
    });
    dispatch(setAlert('Succesfully imported QR tickets', 'success'));

    dispatch({
      type: SEND_QR_TICKETS_RESPONSE,
    });
    dispatch({ type: SET_CSV_DIALOG_VISIBLE_FALSE });
  } catch (error) {
    dispatch(setAlert('Error while importing QR tickets', 'errors'));
    dispatch({
      type: SEND_QR_TICKETS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const saveFerry = (ferry) => async (dispatch) => {
  dispatch({ type: SAVE_FERRY_REQUESTED });

  try {
    const res = await API.post('/api/admin/ferrys', ferry);

    dispatch(setAlert('Ferry saved succesfully', 'success'));

    dispatch({ type: SAVE_FERRY_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: SAVE_FERRY_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateFerry = (ferry) => async (dispatch) => {
  dispatch({ type: UPDATE_FERRY_REQUESTED });
  try {
    const res = await API.put(`/api/admin/ferrys/${ferry._id}`, ferry);
    dispatch(setAlert('Ferry updated successfully', 'success'));
    dispatch({ type: UPDATE_FERRY_RESPONSE, payload: res.data });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to update ferry', 'error'));
    }
    dispatch({
      type: UPDATE_FERRY_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteFerry = (ferry) => async (dispatch) => {
  dispatch({ type: DELETE_FERRY_REQUESTED });
  try {
    const res = await API.delete(`/api/admin/ferrys/${ferry._id}`);
    dispatch(setAlert('Ferry successfully deleted', 'success'));
    dispatch({ type: DELETE_FERRY_RESPONSE, payload: res.data });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to delete ferry', 'error'));
    }
    dispatch({
      type: DELETE_FERRY_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const setCSVDialogVisibleTrue = (ferryId) => async (dispatch) => {
  dispatch({ type: SET_CSV_DIALOG_VISIBLE_TRUE, payload: ferryId });
};

export const setCSVDialogVisibleFalse = () => async (dispatch) => {
  dispatch({ type: SET_CSV_DIALOG_VISIBLE_FALSE });
};

export const selectOrgToFilter = (orgId) => async (dispatch) => {
  dispatch({
    type: SET_FERRY_FILTER_ORG,
    payload: orgId,
  });
};

export const selectOrgToFilterFerrysGroupForm = (orgId) => async (dispatch) => {
  dispatch({
    type: SET_FERRY_FILTER_GROUP_FORM_ORG,
    payload: orgId,
  });
};

export const selectExistingFerry = (ferry) => async (dispatch) => {
  dispatch({
    type: SELECT_EXISTING_FERRY,
    payload: ferry,
  });
};

export const selectNewFerry = () => async (dispatch) => {
  dispatch({
    type: SELECT_NEW_FERRY,
  });
};

export const updateCsvJSON = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_CSV_JSON, payload });
};

export const updateFerryName = (payload) => ({
  type: UPDATE_FERRY_NAME,
  payload,
});

export const updateFerryDescription = (payload) => ({
  type: UPDATE_FERRY_DESCRIPTION,
  payload,
});

export const updateFerryInfo = (payload) => ({
  type: UPDATE_FERRY_INFO,
  payload,
});

export const updateFerryDepartureCity = (payload) => ({
  type: UPDATE_FERRY_DEPARTURE_CITY,
  payload,
});

export const updateFerryDepartureTerminal = (payload) => ({
  type: UPDATE_FERRY_DEPARTURE_TERMINAL,
  payload,
});

export const updateFerryDepartureDateTime = (payload) => ({
  type: UPDATE_FERRY_DEPARTURE_DATE_TIME,
  payload,
});

export const updateFerryDestinationCity = (payload) => ({
  type: UPDATE_FERRY_DESTINATION_CITY,
  payload,
});

export const updateFerryDestinationTerminal = (payload) => ({
  type: UPDATE_FERRY_DESTINATION_TERMINAL,
  payload,
});

export const updateFerryDestinationDateTime = (payload) => ({
  type: UPDATE_FERRY_DESTINATION_DATE_TIME,
  payload,
});

export const updateFerryOrganization = (payload) => ({
  type: UPDATE_FERRY_ORGANIZATION,
  payload,
});

export const updateFerryProducts = (payload) => ({
  type: UPDATE_FERRY_PRODUCTS,
  payload,
});

export const toggleCollectPaymentForFerryProduct = (payload) => ({
  type: UPDATE_FERRY_PRODUCT_COLLECT_PAYMENT,
  payload,
});
