import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paymentHeader: {
    marginTop: '3%',
    marginLeft: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '46px',
    marginBottom: theme.spacing(2),
    color: '#20D2B7',
  },
  paymentHeaderMobile: {
    marginTop: '10%',
    marginLeft: '10%',
    marginRigth: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '102%',
    marginBottom: theme.spacing(2),
    color: '#20D2B7',
  },
}));
export const Title = ({ additional, mobileScreen }) => {
  const classes = useStyles();
  return (
    <Typography
      className={
        mobileScreen ? classes.paymentHeaderMobile : classes.paymentHeader
      }
    >
      {additional ? 'Lisätuotteet' : 'Maksut'}
    </Typography>
  );
};
