import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  makeStyles,
  TextField,
} from '@material-ui/core';
import AlertMessage from 'components/layouts/AlertMessage';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refundPayments } from 'redux/actions/massEdit';
import CsvDownloaderFailed from './CsvDownloaderFailed';
import CsvDownloaderSucceed from './CsvDownloaderSucceed';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  submitButton: {
    margin: '2em',
  },
  label: {
    padding: '1em',
  },
  formItem: {
    minWidth: '20em',
  },
}));

const MassRefund = ({ users }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { done, loading, success, failed } = useSelector(
    (state) => state.mass.refund
  );
  const [product, setProduct] = useState('');
  const [amount, setAmount] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(new Map());

  const refundableProducts = () => {
    const items = [];
    filteredProducts.forEach((value, key) =>
      items.push(
        <MenuItem key={key} value={key}>
          {`${value.name} (${value.payments.length})`}
        </MenuItem>
      )
    );
    return items;
  };

  useEffect(() => {
    if (users) {
      const products = new Map();
      users.forEach((user) => {
        user.eventPayments.forEach((payment) => {
          if (payment.status === 'confirmed' || payment.status === 'accepted') {
            let { _id, name, price } = payment.products[0];
            if (products.has(_id)) {
              products.get(_id).payments.push(payment._id);
            } else {
              let payments = [];
              payments.push(payment._id);
              products.set(_id, { name, price, payments });
            }
          }
        });
        user.additionalServices.forEach((payment) => {
          if (payment.status === 'confirmed' || payment.status === 'accepted') {
            let { _id, name, price } = payment.products[0];
            if (products.has(_id)) {
              products.get(_id).payments.push(payment._id);
            } else {
              let payments = [];
              payments.push(payment._id);
              products.set(_id, { name, price, payments });
            }
          }
        });
      });
      setFilteredProducts(products);
    }
  }, [users]);

  const handleSubmit = () => {
    dispatch(refundPayments(amount, filteredProducts.get(product).payments));
  };

  return (
    <div className={classes.content}>
      <h1>Refund</h1>
      <AlertMessage />
      {!done ? (
        <Fragment>
          {!loading && (
            <Fragment>
              <h3>You have selected {users.length} users.</h3>
              <FormControl className={classes.formItem}>
                <InputLabel id="groupSelectionId">
                  Select product to refund
                </InputLabel>
                <Select
                  labelId="groupSelectionId"
                  id="groupSelection"
                  defaultValue=""
                  value={product}
                  onChange={({ target: { value } }) => setProduct(value)}
                >
                  {refundableProducts()}
                </Select>
              </FormControl>
              {product && (
                <h3>
                  Max amount to refund: {filteredProducts.get(product).price} €
                </h3>
              )}
              <TextField
                className={classes.formItem}
                label="Amount to refund"
                id="amount"
                value={amount}
                onChange={({ target: { value } }) => setAmount(value)}
              />
              <div>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <div>
          {success.length > 0 && <CsvDownloaderSucceed data={success} />}
          {failed.length > 0 && <CsvDownloaderFailed data={failed} />}
        </div>
      )}
      <div>{loading && <CircularProgress />}</div>
    </div>
  );
};

export default MassRefund;
