import {
  SELECT_NEW_DISCOUNT_CODE,
  UPDATE_DISCOUNT_CODE_DESCRIPTION,
  UPDATE_DISCOUNT_CODE_NAME,
  UPDATE_DISCOUNT_CODE_CODE,
  UPDATE_DISCOUNT_CODE_AMOUNT,
  UPDATE_DISCOUNT_CODE_DISCOUNT,
  UPDATE_DISCOUNT_CODE_ORGANIZATION,
  UPDATE_DISCOUNT_CODE_VALID_UNTIL,
  UPDATE_DISCOUNT_CODE_APPLY_TO,
  UPDATE_DISCOUNT_CODE_DISABLED,
  SAVE_DISCOUNT_CODE_REQUESTED,
  SAVE_DISCOUNT_CODE_RESPONSE,
  SAVE_DISCOUNT_CODE_ERROR,
  FETCH_DISCOUNT_CODES_REQUESTED,
  FETCH_DISCOUNT_CODES_RESPONSE,
  SELECT_EXISTING_DISCOUNT_CODE,
  FETCH_USED_DISCOUNT_CODES_RESPONSE,
  SET_DISCOUNT_CODE_FILTER_ORG,
} from 'redux/actions/discountCodes';

const initialState = {
  discountCodes: [],
  discountCode: {
    name: '',
    code: '',
    maxUsages: 0,
    timesUsed: 0,
    validUntil: new Date(),
    discount: 0,
    description: '',
    applyTo: [],
    organization: null,
    disabled: false,
    usedFor: [],
  },
  filterDiscountCodesOrg: 'all',
};

const updateDiscountCodesArray = (discountCodes, updated) => {
  const filtered = discountCodes.filter((code) => code._id !== updated._id);
  filtered.push(updated);
  return filtered;
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SELECT_NEW_DISCOUNT_CODE:
      return { ...state, discountCode: initialState.discountCode };
    case UPDATE_DISCOUNT_CODE_NAME:
      return {
        ...state,
        discountCode: { ...state.discountCode, name: payload },
      };
    case UPDATE_DISCOUNT_CODE_VALID_UNTIL:
      return {
        ...state,
        discountCode: { ...state.discountCode, validUntil: payload },
      };
    case UPDATE_DISCOUNT_CODE_CODE:
      return {
        ...state,
        discountCode: { ...state.discountCode, code: payload },
      };
    case UPDATE_DISCOUNT_CODE_AMOUNT:
      return {
        ...state,
        discountCode: { ...state.discountCode, maxUsages: payload },
      };
    case UPDATE_DISCOUNT_CODE_DISCOUNT:
      return {
        ...state,
        discountCode: { ...state.discountCode, discount: payload },
      };
    case UPDATE_DISCOUNT_CODE_DESCRIPTION:
      return {
        ...state,
        discountCode: { ...state.discountCode, description: payload },
      };
    case UPDATE_DISCOUNT_CODE_ORGANIZATION:
      return {
        ...state,
        discountCode: { ...state.discountCode, organization: payload },
      };
    case UPDATE_DISCOUNT_CODE_APPLY_TO:
      return {
        ...state,
        discountCode: { ...state.discountCode, applyTo: payload },
      };
    case UPDATE_DISCOUNT_CODE_DISABLED:
      return {
        ...state,
        discountCode: { ...state.discountCode, disabled: payload },
      };
    case SAVE_DISCOUNT_CODE_RESPONSE:
      return {
        ...state,
        discountCodes: updateDiscountCodesArray(state.discountCodes, payload),
      };
    case FETCH_DISCOUNT_CODES_RESPONSE:
      return { ...state, discountCodes: payload };

    case SELECT_EXISTING_DISCOUNT_CODE:
      return { ...state, discountCode: payload ? payload : null };

    case FETCH_USED_DISCOUNT_CODES_RESPONSE:
      return {
        ...state,
        discountCode: {
          ...state.discountCode,
          usedFor: payload ? payload : [],
        },
      };
    case SET_DISCOUNT_CODE_FILTER_ORG:
      return {
        ...state,
        filterDiscountCodesOrg: action.payload,
      };
    case FETCH_DISCOUNT_CODES_REQUESTED:
    case SAVE_DISCOUNT_CODE_REQUESTED:
    case SAVE_DISCOUNT_CODE_ERROR:
    default:
      return state;
  }
}
