import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import AdminApp from 'components/layouts/AdminApp';
import CustomerApp from 'components/layouts/CustomerApp';

const useStyles = makeStyles((theme) => ({
  app: {
    height: '100%',
    width: '100%',
  },
}));

export const App = () => {
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <Router>
        <Route
          exact
          path={[
            '/admin-dashboard',
            '/groups',
            '/group-form',
            '/events',
            '/event-form',
            '/organization',
            '/admins',
            '/emails',
            '/products',
            '/customers',
            '/customer-form',
            '/ferrys',
            '/ferry-form',
            '/accomodations',
            '/accomodation-form',
            '/discount-codes',
            '/discountcode-form',
          ]}
        >
          <AdminApp></AdminApp>
        </Route>
        <Route
          exact
          path={[
            '/',
            '/login',
            '/register/:orgId',
            '/organization/:identifier',
            '/user-dashboard',
            '/payment-success',
            '/payment-cancel',
            '/reset-password',
            '/password-reset-request',
          ]}
        >
          <CustomerApp></CustomerApp>
        </Route>
      </Router>
    </div>
  );
};

export default App;
