import {
  TOGGLE_PASSWORD,
  TOGGLE_MARKETING_VIEW,
  UPDATE_REGISTERING_USER_FIRST_NAME,
  UPDATE_REGISTERING_USER_LAST_NAME,
  UPDATE_REGISTERING_USER_EMAIL,
  UPDATE_REGISTERING_USER_PHONE,
  UPDATE_REGISTERING_USER_ADDRESS_STREET,
  UPDATE_REGISTERING_USER_ADDRESS_POSTCODE,
  UPDATE_REGISTERING_USER_ADDRESS_CITY,
  UPDATE_REGISTERING_USER_PASSWORD,
  UPDATE_REGISTERING_USER_BIRTHDAY,
  UPDATE_REGISTERING_USER_MARKETING,
  UPDATE_REGISTERING_USER_GROUP,
  UPDATE_REGISTERING_USER_COUNTRY,
  UPDATE_REGISTERING_USER_GENDER,
  FETCH_GROUPS_RESPONSE,
  FETCH_GROUPS_REQUESTED,
  FETCH_GROUPS_ERROR,
  SAVE_USER_REQUESTED,
  SAVE_USER_ERROR,
} from 'redux/actions/register';

const initialState = {
  groups: [],
  user: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: {
      street: '',
      postcode: '',
      city: '',
    },
    password: '',
    country: '',
    gender: '',
    birthday: null,
    marketing: false,
    group: '',
  },
  showPassword: false,
  marketingTextView: false,
  error: {},
};

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SAVE_USER_ERROR:
      return { ...state, error: action.payload };

    case FETCH_GROUPS_RESPONSE:
      return { ...state, groups: action.payload };

    case FETCH_GROUPS_ERROR:
      return { ...state, error: action.payload };

    case TOGGLE_PASSWORD:
      return { ...state, showPassword: !state.showPassword };

    case TOGGLE_MARKETING_VIEW:
      return { ...state, marketingTextView: !state.marketingTextView };

    case UPDATE_REGISTERING_USER_FIRST_NAME:
      return {
        ...state,
        user: { ...state.user, firstName: action.payload },
      };

    case UPDATE_REGISTERING_USER_LAST_NAME:
      return {
        ...state,
        user: { ...state.user, lastName: action.payload },
      };

    case UPDATE_REGISTERING_USER_EMAIL:
      return {
        ...state,
        user: { ...state.user, email: action.payload },
      };

    case UPDATE_REGISTERING_USER_PHONE:
      return {
        ...state,
        user: { ...state.user, phone: action.payload },
      };

    case UPDATE_REGISTERING_USER_PASSWORD:
      return {
        ...state,
        user: { ...state.user, password: action.payload },
      };

    case UPDATE_REGISTERING_USER_BIRTHDAY:
      return {
        ...state,
        user: { ...state.user, birthday: action.payload },
      };

    case UPDATE_REGISTERING_USER_MARKETING:
      return {
        ...state,
        user: { ...state.user, marketing: action.payload },
      };

    case UPDATE_REGISTERING_USER_GROUP:
      return {
        ...state,
        user: { ...state.user, group: action.payload },
      };
    case UPDATE_REGISTERING_USER_GENDER:
      return {
        ...state,
        user: { ...state.user, gender: action.payload },
      };

    case UPDATE_REGISTERING_USER_COUNTRY:
      return {
        ...state,
        user: { ...state.user, country: action.payload },
      };

    case UPDATE_REGISTERING_USER_ADDRESS_CITY:
      return {
        ...state,
        user: {
          ...state.user,
          address: { ...state.user.address, city: action.payload },
        },
      };

    case UPDATE_REGISTERING_USER_ADDRESS_POSTCODE:
      return {
        ...state,
        user: {
          ...state.user,
          address: { ...state.user.address, postcode: action.payload },
        },
      };

    case UPDATE_REGISTERING_USER_ADDRESS_STREET:
      return {
        ...state,
        user: {
          ...state.user,
          address: { ...state.user.address, street: action.payload },
        },
      };

    case SAVE_USER_REQUESTED:
    case FETCH_GROUPS_REQUESTED:
    default:
      return state;
  }
}
