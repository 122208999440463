import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  submit: {
    '&:hover': {
      background: '#AD00FF',
    },
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    order: 1,
    alignSelf: 'center',
    margin: '8px 0px',
  },
}));

const SubmitButton = ({ children, mobileScreen, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      type="submit"
      fullWidth={mobileScreen}
      className={classes.submit}
      onClick={(e) => onClick(e)}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
