import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  priceText: {
    marginTop: '2%',
    marginLeft: '10%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '31px',
    color: '#828282',
  },
  priceTextMobile: {
    marginTop: '2%',
    marginLeft: '6%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '31px',
    color: '#828282',
  },
}));

export const Price = ({ mobileScreen, payment }) => {
  const classes = useStyles();
  const product = payment.products[0];

  const getDiscountedPrice = () => {
    return product.price * (1 - payment.discountCode.discount / 100);
  };

  const getPriceTextWithDiscount = () => {
    let price = getDiscountedPrice();
    if (payment.discountCode.discount === 100) {
      price = 0;
    } else if (product.serviceFee) {
      price += product.serviceFee;
    }

    return `${price.toFixed(2)}€ (${getPriceText()})`;
  };

  const getPriceText = () => {
    let price = product.price;
    if (product.serviceFee) {
      price += product.serviceFee;
    }
    return `${price.toFixed(2)}€`;
  };

  return (
    <Typography
      className={mobileScreen ? classes.priceTextMobile : classes.priceText}
    >
      {payment.discountCode ? getPriceTextWithDiscount() : getPriceText()}
    </Typography>
  );
};
