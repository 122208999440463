import React from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';

const TabBar = ({ handleChange, value }) => {
  return (
    <AppBar position="static">
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Disable" />
        <Tab label="Change group" />
        <Tab label="Refund" />
      </Tabs>
    </AppBar>
  );
};
export default TabBar;
