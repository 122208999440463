function formatUrl(url) {
  if (!url) return url;
  const hasProtocol = url.startsWith('http://') || url.startsWith('https://');
  if (hasProtocol) return url;
  return 'http://' + url;
}

module.exports = {
  formatUrl,
};
