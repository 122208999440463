import React from 'react';
import { Icon, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paymentItemHeader: {
    marginTop: '11%',
    marginLeft: '9%',
    display: 'flex',
    flexDirection: 'row',
  },
  paymentItemHeaderMobile: {
    marginTop: '7%',
    marginLeft: '6%',
    display: 'flex',
    flexDirection: 'row',
  },
  paymentIconWaiting: {
    textAlign: 'center',
    height: '50px',
    width: '52px',
    borderRadius: '50%',
    background: '#F2C94C',
  },
  paymentWaitingText: {
    marginTop: '3%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '21px',
    marginRight: '5%',
    marginLeft: '5%',
    color: '#F2C94C',
  },
  paymentIconPayed: {
    textAlign: 'center',
    height: '50px',
    width: '52px',
    background: '#6FCF97',
    borderRadius: '50%',
  },
  paymentPayedText: {
    marginTop: '3%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '21px',
    marginRight: '5%',
    marginLeft: '5%',
    color: '#6FCF97',
  },
}));
export const PaymentStatus = ({ additional, mobileScreen, payment }) => {
  const classes = useStyles();

  const getPaymentStatus = (payment) => {
    switch (payment.status) {
      case 'new':
        if (additional) {
          return null;
        } else {
          return (
            <div
              className={
                mobileScreen
                  ? classes.paymentItemHeaderMobile
                  : classes.paymentItemHeader
              }
            >
              <div className={classes.paymentIconWaiting}>
                <Icon
                  className="fas fa-hourglass-half"
                  style={{
                    color: '#FFFFFF',
                    marginTop: '25%',
                  }}
                />{' '}
              </div>
              <Typography className={classes.paymentWaitingText}>
                Erääntyvä maksu
              </Typography>
            </div>
          );
        }
      case 'confirmed':
        return (
          <div
            className={
              mobileScreen
                ? classes.paymentItemHeaderMobile
                : classes.paymentItemHeader
            }
          >
            <div className={classes.paymentIconPayed}>
              <Icon
                className="fas fa-check"
                style={{
                  color: '#FFFFFF',
                  marginTop: '25%',
                }}
              />{' '}
            </div>
            <Typography className={classes.paymentPayedText}>
              Maksu suoritettu
            </Typography>
          </div>
        );
      case 'accepted':
        return (
          <div
            className={
              mobileScreen
                ? classes.paymentItemHeaderMobile
                : classes.paymentItemHeader
            }
          >
            <div className={classes.paymentIconPayed}>
              <Icon
                className="fas fa-check"
                style={{
                  color: '#FFFFFF',
                  marginTop: '25%',
                }}
              />{' '}
            </div>
            <Typography className={classes.paymentPayedText}>
              Hyväksytty
            </Typography>
          </div>
        );

      default:
        return null;
    }
  };
  return getPaymentStatus(payment);
};
