import React from 'react';

import RootAdminOptions from './RootAdminOptions';
import OwnerOptions from './OwnerOptions';
import AdminOptions from './AdminOptions';

import { ROOT, OWNER } from '../../constants/roles';
import { useSelector } from 'react-redux';

const SideNavigationOptions = () => {
  const { role } = useSelector((state) => state.auth);
  return (
    <div>
      {role === ROOT ? (
        <RootAdminOptions></RootAdminOptions>
      ) : role === OWNER ? (
        <OwnerOptions></OwnerOptions>
      ) : (
        <AdminOptions></AdminOptions>
      )}
    </div>
  );
};

export default SideNavigationOptions;
