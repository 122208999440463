export const getProductFromState = (state) => {
  const { product, products } = state;
  return { product, products };
};

export const getProductsFromState = (state) => {
  const { filterProductsOrg, products } = state;
  if (filterProductsOrg !== 'all') {
    let filteredProducts = products.filter(
      (product) => product.organization === filterProductsOrg
    );
    return { products: filteredProducts };
  } else {
    return { products };
  }
};

export const getOrgProducts = (state) => {
  const { organizationProducts } = state;
  return { organizationProducts };
};
