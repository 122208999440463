import React from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { countries } from 'constants/countries';
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  popupIndicator: {
    paddingBottom: '20px',
  },
  clearIndicator: {
    paddingBottom: '20px',
  },
});

export const CountrySelector = ({
  variant,
  currentValue,
  setCountry,
  inputProps,
  inputLabelProps,
  label,
  required,
  fullWidth,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Autocomplete
      id="country selector"
      options={countries}
      fullWidth={fullWidth}
      classes={{
        option: classes.option,
        popupIndicator: classes.popupIndicator,
        clearIndicator: classes.clearIndicator,
      }}
      autoHighlight
      value={
        countries.find((country) => country.code === currentValue)
          ? countries.find((country) => country.code === currentValue)
          : null
      }
      getOptionLabel={(option) => option.label}
      onChange={(event, value, reason) =>
        dispatch(setCountry(value ? value.code : ''))
      }
      renderOption={(option) => (
        <React.Fragment>
          <span>{countryToFlag(option.code)}</span>
          {option.label} ({option.code})
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          required={required}
          inputProps={{
            ...params.inputProps,
            className: inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          InputLabelProps={{
            classes: {
              root: inputLabelProps,
              focused: 'focused',
              shrink: 'shrink',
            },
          }}
        />
      )}
    />
  );
};

export default CountrySelector;
