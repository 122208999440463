import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlertMessage from 'components/layouts/AlertMessage';
import DeleteConfirm from 'components/layouts/DeleteConfirm';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import {
  fetchProducts,
  saveProduct,
  deleteProduct,
  updateProduct,
  selectExistingProduct,
  selectNewProduct,
  updateProductName,
  updateProductDescription,
  updateProductOrganization,
  updateProductPrice,
  updateProductVat,
  updateProductVatPercent,
  updateProductTaxAmount,
  updateProductTaxDescription,
  selectOrgToFilter,
  updateProductMandatory,
  updateProductMaximumAmount,
  updateProductInfoLink,
  updateProductServiceFee,
  updateProductServiceFeeVat,
} from 'redux/actions/product';

import { getOrganizations } from 'redux/actions/organization';

import { getOrganizationsFromState } from 'redux/selectors/organization';
import {
  getProductFromState,
  getProductsFromState,
} from 'redux/selectors/product';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  alertBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  vatInfo: {
    fontSize: 14,
  },
}));

const objectDoesNotContainNull = (obj) => {
  const notMandatory = ['description', 'infoLink'];

  if (obj.vat) {
    notMandatory.push(
      'taxAmount',
      'taxDescription',
      'serviceFee',
      'serviceFeeVat'
    );
  } else {
    notMandatory.push('vatPercent');
  }

  const keys = Object.keys(obj);
  const values = keys
    .filter((key) => !notMandatory.includes(key))
    .map((key) => obj[key]);
  return !values.includes(null);
};

const getMenuItems = (products) =>
  products.map((product) => (
    <MenuItem key={product._id} value={product._id}>
      {product.name}
    </MenuItem>
  ));

const OrganizationsOptions = (organizations) => {
  return organizations.map((organization) => (
    <MenuItem key={organization._id} value={organization._id}>
      {organization.name}
    </MenuItem>
  ));
};

const Product = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  const { product } = useSelector((state) =>
    getProductFromState(state.product)
  );

  const { products } = useSelector((state) =>
    getProductsFromState(state.product)
  );

  const getProductObjectFromId = (id) => products.find((org) => org._id === id);

  const { organizations } = useSelector((state) =>
    getOrganizationsFromState(state.organization)
  );

  const handleAgree = () => dispatch(deleteProduct(product));
  const closeDialog = () => setConfirmDelete(false);

  return (
    <div>
      <h2> Products </h2>

      <Fragment>
        <FormControl
          className={classes.formControl}
          key={`amountOfSelections_${organizations.length}`}
        >
          <InputLabel id="organizationSelectionLabel">Filter org</InputLabel>
          <Select
            labelId="organizationSelectionLabel"
            id="organizationSelection"
            defaultValue=""
            onChange={({ target: { value } }) =>
              dispatch(selectOrgToFilter(value))
            }
          >
            <MenuItem key="all" value={'all'}>
              All
            </MenuItem>
            {OrganizationsOptions(organizations)}
          </Select>
        </FormControl>
      </Fragment>

      {products && (
        <FormControl
          className={classes.formControl}
          key={`amountOfSelections_${products.length}`}
        >
          <InputLabel id="orgSelectionLabel">Select</InputLabel>
          <Select
            labelId="orgSelectionLabel"
            id="orgSelection"
            defaultValue=""
            onChange={({ target: { value } }) =>
              value !== 'new'
                ? dispatch(selectExistingProduct(getProductObjectFromId(value)))
                : dispatch(selectNewProduct())
            }
          >
            <MenuItem key="new" value={'new'}>
              New
            </MenuItem>
            {getMenuItems(products)}
          </Select>
        </FormControl>
      )}

      <h2> Product to add / edit </h2>
      <form
        key={product._id}
        className={classes.root}
        noValidate
        autoComplete="off"
      >
        <div className={classes.alertBox}>
          <AlertMessage />
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <TextField
              id="name"
              defaultValue={product.name}
              label="Name"
              required
              onChange={(event) => {
                dispatch(updateProductName(event.target.value));
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="price"
              defaultValue={product.price}
              label="Price (tax included)"
              required
              onChange={(event) =>
                dispatch(updateProductPrice(event.target.value))
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Switch
                  checked={product.vat}
                  onChange={() => {
                    dispatch(updateProductVat(!product.vat));
                  }}
                  name="product vat"
                  color="primary"
                />
              }
              label={
                <Typography
                  className={classes.vatInfo}
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  Under VAT
                </Typography>
              }
              labelPlacement="top"
              required
            />
          </FormControl>
        </div>
        <div>
          {product.vat ? (
            <Fragment>
              <FormControl className={classes.formControl}>
                <TextField
                  id="productVat"
                  value={product.vatPercent ? product.vatPercent : ''}
                  label="VAT percent"
                  required
                  onChange={(event) =>
                    dispatch(updateProductVatPercent(event.target.value))
                  }
                />
              </FormControl>
            </Fragment>
          ) : (
            <Fragment>
              <FormControl className={classes.formControl}>
                <TextField
                  id="taxAmount"
                  value={product.taxAmount ? product.taxAmount : ''}
                  label="Tax amount"
                  required
                  onChange={(event) =>
                    dispatch(updateProductTaxAmount(event.target.value))
                  }
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  id="taxDescription"
                  defaultValue={product.taxDescription}
                  label="Tax description"
                  required
                  onChange={(event) =>
                    dispatch(updateProductTaxDescription(event.target.value))
                  }
                />
              </FormControl>
            </Fragment>
          )}
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Switch
                  checked={product.mandatory}
                  onChange={() => {
                    dispatch(updateProductMandatory(!product.mandatory));
                  }}
                  name="mandatory"
                  color="primary"
                />
              }
              label={
                <Typography
                  className={classes.vatInfo}
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  Optional/Mandatory
                </Typography>
              }
              labelPlacement="top"
              required
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="maximumAmount"
              defaultValue={product.maximumAmount}
              label="Maximum amount"
              onChange={(event) =>
                dispatch(updateProductMaximumAmount(event.target.value))
              }
            />
          </FormControl>
          {!product.mandatory && (
            <FormControl className={classes.formControl}>
              <TextField
                id="infoLink"
                defaultValue={product.infoLink}
                label="Info link"
                placeholder="https://example.com"
                helperText="Syötä osoite muodossa https://osoite.fi"
                onChange={(event) =>
                  dispatch(updateProductInfoLink(event.target.value))
                }
              />
            </FormControl>
          )}
        </div>
        <div>
          <FormControl>
            <TextField
              className={classes.formControl}
              id="service-fee"
              defaultValue={product.serviceFee}
              label="Service fee"
              onChange={(event) =>
                dispatch(updateProductServiceFee(event.target.value))
              }
            />
          </FormControl>
          <FormControl>
            <TextField
              className={classes.formControl}
              id="service-fee-vat"
              defaultValue={product.serviceFeeVat}
              label="Service fee VAT"
              onChange={(event) =>
                dispatch(updateProductServiceFeeVat(event.target.value))
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <TextField
              id="description"
              defaultValue={product.description}
              label="Description"
              multiline
              onChange={(event) =>
                dispatch(updateProductDescription(event.target.value))
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label="Organization"
              id="organization"
              select
              disabled={product._id ? true : false}
              value={product.organization ? product.organization : ''}
              onChange={(event) => {
                dispatch(updateProductOrganization(event.target.value));
              }}
            >
              {OrganizationsOptions(organizations)}
            </TextField>
          </FormControl>
        </div>
      </form>
      <div className={classes.buttonContainer}>
        {objectDoesNotContainNull(product) && (
          <Button
            className={classes.button}
            aria-label="save"
            color="primary"
            variant="contained"
            onClick={() =>
              dispatch(
                product._id ? updateProduct(product) : saveProduct(product)
              )
            }
          >
            Save
          </Button>
        )}
        {product._id && (
          <Button
            className={classes.button}
            aria-label="delete"
            color="secondary"
            variant="contained"
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
        )}
      </div>
      <DeleteConfirm
        visible={confirmDelete}
        handleAgree={handleAgree}
        closeDialog={closeDialog}
      ></DeleteConfirm>
    </div>
  );
};

export default Product;
