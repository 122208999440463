import {
  FETCH_EVENTS_REQUESTED,
  FETCH_EVENTS_RESPONSE,
  FETCH_EVENTS_ERROR,
  UPDATE_EVENT_REQUESTED,
  UPDATE_EVENT_RESPONSE,
  UPDATE_EVENT_ERROR,
  SAVE_EVENT_REQUESTED,
  SAVE_EVENT_RESPONSE,
  SAVE_EVENT_ERROR,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_REQUESTED,
  DELETE_EVENT_RESPONSE,
  UPDATE_EVENT_NAME,
  UPDATE_EVENT_DATE,
  UPDATE_EVENT_DESCRIPTION,
  UPDATE_EVENT_IDENTIFIER,
  UPDATE_EVENT_ORGANIZATION,
  UPDATE_EVENT_PRODUCTS,
  UPDATE_EVENT_PRODUCT_COLLECT_PAYMENT,
  UPDATE_EVENT_ALLOW_USERS_EDIT_INFO,
  UPDATE_EVENT_INFOBUTTON1_NAME,
  UPDATE_EVENT_INFOBUTTON1_URL,
  UPDATE_EVENT_INFOBUTTON1_VISIBLE,
  UPDATE_EVENT_INFOBUTTON2_NAME,
  UPDATE_EVENT_INFOBUTTON2_URL,
  UPDATE_EVENT_INFOBUTTON2_VISIBLE,
  UPDATE_EVENT_INFOBUTTON3_NAME,
  UPDATE_EVENT_INFOBUTTON3_URL,
  UPDATE_EVENT_INFOBUTTON3_VISIBLE,
  SELECT_NEW_EVENT,
  SELECT_EXISTING_EVENT,
  SET_EVENT_FILTER_ORG,
  SET_EVENT_FILTER_ORG_FOR_GROUPS,
} from 'redux/actions/event';

const initialState = {
  events: [],
  event: {
    name: null,
    description: null,
    identifier: null,
    date: new Date(),
    organization: null,
    allowUsersEditInfo: true,
    products: [],
    _id: '',
    infoButton1: {
      name: null,
      url: null,
      visible: false,
    },
    infoButton2: {
      name: null,
      url: null,
      visible: false,
    },
    infoButton3: {
      name: null,
      url: null,
      visible: false,
    },
  },
  filterEventsOrg: 'all',
  filterEventsOrgForGroups: 'all',
  error: {},
};

const updateEventsArray = (events, updated) => {
  const filtered = events.filter((event) => event._id !== updated._id);
  filtered.push(updated);
  return filtered;
};

const updateProductColletPayment = (products, updatedProduct) => {
  const updatedProducts = products.map((obj) =>
    obj.product._id === updatedProduct.product._id ? updatedProduct : obj
  );
  return updatedProducts;
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_EVENT_FILTER_ORG:
      return {
        ...state,
        filterEventsOrg: action.payload,
      };

    case SET_EVENT_FILTER_ORG_FOR_GROUPS:
      return {
        ...state,
        filterEventsOrgForGroups: action.payload,
      };

    case SELECT_EXISTING_EVENT:
      return {
        ...state,
        event: action.payload ? action.payload : null,
      };

    case SELECT_NEW_EVENT:
      return {
        ...state,
        event: initialState.event,
      };

    case SAVE_EVENT_RESPONSE:
      return {
        ...state,
        events: updateEventsArray(state.events, action.payload),
      };

    case UPDATE_EVENT_RESPONSE:
      return {
        ...state,
        events: updateEventsArray(state.events, action.payload),
      };

    case DELETE_EVENT_RESPONSE:
      return {
        ...state,
        events: state.events.filter(
          (event) => event._id !== action.payload._id
        ),
        event: initialState.event,
      };

    case SAVE_EVENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EVENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_EVENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_EVENTS_RESPONSE:
      return { ...state, events: payload };

    case FETCH_EVENTS_ERROR:
      return { ...state, error: payload };

    case UPDATE_EVENT_NAME:
      return {
        ...state,
        event: { ...state.event, name: action.payload },
      };

    case UPDATE_EVENT_DATE:
      return {
        ...state,
        event: { ...state.event, date: action.payload },
      };

    case UPDATE_EVENT_DESCRIPTION:
      return {
        ...state,
        event: { ...state.event, description: action.payload },
      };

    case UPDATE_EVENT_IDENTIFIER:
      return {
        ...state,
        event: { ...state.event, identifier: action.payload },
      };

    case UPDATE_EVENT_ORGANIZATION:
      return {
        ...state,
        event: { ...state.event, organization: action.payload },
      };

    case UPDATE_EVENT_PRODUCTS:
      return {
        ...state,
        event: {
          ...state.event,
          products: action.payload,
        },
      };

    case UPDATE_EVENT_PRODUCT_COLLECT_PAYMENT:
      return {
        ...state,
        event: {
          ...state.event,
          products: updateProductColletPayment(
            state.event.products,
            action.payload
          ),
        },
      };

    case UPDATE_EVENT_INFOBUTTON1_NAME:
      return {
        ...state,
        event: {
          ...state.event,
          infoButton1: { ...state.event.infoButton1, name: action.payload },
        },
      };

    case UPDATE_EVENT_INFOBUTTON1_URL:
      return {
        ...state,
        event: {
          ...state.event,
          infoButton1: { ...state.event.infoButton1, url: action.payload },
        },
      };

    case UPDATE_EVENT_INFOBUTTON1_VISIBLE:
      return {
        ...state,
        event: {
          ...state.event,
          infoButton1: { ...state.event.infoButton1, visible: action.payload },
        },
      };

    case UPDATE_EVENT_INFOBUTTON2_NAME:
      return {
        ...state,
        event: {
          ...state.event,
          infoButton2: { ...state.event.infoButton2, name: action.payload },
        },
      };

    case UPDATE_EVENT_INFOBUTTON2_URL:
      return {
        ...state,
        event: {
          ...state.event,
          infoButton2: { ...state.event.infoButton2, url: action.payload },
        },
      };

    case UPDATE_EVENT_INFOBUTTON2_VISIBLE:
      return {
        ...state,
        event: {
          ...state.event,
          infoButton2: { ...state.event.infoButton2, visible: action.payload },
        },
      };

    case UPDATE_EVENT_INFOBUTTON3_NAME:
      return {
        ...state,
        event: {
          ...state.event,
          infoButton3: { ...state.event.infoButton3, name: action.payload },
        },
      };

    case UPDATE_EVENT_INFOBUTTON3_URL:
      return {
        ...state,
        event: {
          ...state.event,
          infoButton3: { ...state.event.infoButton3, url: action.payload },
        },
      };

    case UPDATE_EVENT_INFOBUTTON3_VISIBLE:
      return {
        ...state,
        event: {
          ...state.event,
          infoButton3: { ...state.event.infoButton3, visible: action.payload },
        },
      };

    case UPDATE_EVENT_ALLOW_USERS_EDIT_INFO:
      return {
        ...state,
        event: { ...state.event, allowUsersEditInfo: action.payload },
      };

    case DELETE_EVENT_REQUESTED:
    case UPDATE_EVENT_REQUESTED:
    case SAVE_EVENT_REQUESTED:
    case FETCH_EVENTS_REQUESTED:
    default:
      return state;
  }
}
