import React, { Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const DeleteUserDialog = ({ isOpen, handleClose, deleteUser }) => {
  const handleDelete = () => {
    deleteUser();
    handleClose();
  };
  return (
    <Fragment>
      {true && (
        <Dialog
          open={isOpen}
          onClose={() => handleClose()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Delete user?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div>
                Do you really want to delete user? This operation is permanent!!
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDelete}
              variant="contained"
              color="secondary"
            >
              Delete user
            </Button>
            <Button
              onClick={() => handleClose()}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default DeleteUserDialog;
