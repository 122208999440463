import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

export const FETCH_ORGANIZATION_CUSTOMERS_REQUESTED =
  'FETCH_ORGANIZATION_CUSTOMERS_REQUESTED';
export const FETCH_ORGANIZATION_CUSTOMERS_RESPONSE =
  'FETCH_ORGANIZATION_CUSTOMERS_RESPONSE';
export const FETCH_ORGANIZATION_CUSTOMERS_ERROR =
  'FETCH_ORGANIZATION_CUSTOMERS_ERROR';
export const RESET_ORGANIZATION_CUSTOMERS = 'RESET_ORGANIZATION_CUSTOMERS';

export const UPDATE_CUSTOMER_REQUESTED = 'UPDATE_CUSTOMER_REQUESTED';
export const UPDATE_CUSTOMER_RESPONSE = 'UPDATE_CUSTOMER_RESPONSE';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';

export const DELETE_USER_REQUESTED = 'DELETE_USER_REQUESTED';
export const DELETE_USER_RESPONSE = 'DELETE_USER_RESPONSE';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const SELECT_EXISTING_USER = 'SELECT_EXISTING_USER';
export const SELECT_NEW_USER = 'SELECT_NEW_USER';

export const TOGGLE_FILTER_BY_ROOM = 'TOGGLE_FILTER_BY_ROOM';

export const TOGGLE_SHOWPASSWORD = 'TOGGLE_SHOWPASSWORD';
export const UPDATE_USER_FIRST_NAME = 'UPDATE_USER_FIRST_NAME';
export const UPDATE_USER_LAST_NAME = 'UPDATE_USER_LAST_NAME';
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const UPDATE_USER_PHONE = 'UPDATE_USER_PHONE';
export const UPDATE_USER_ADDRESS_STREET = 'UPDATE_USER_ADDRESS_STREET';
export const UPDATE_USER_ADDRESS_POSTCODE = 'UPDATE_USER_ADDRESS_POSTCODE';
export const UPDATE_USER_ADDRESS_CITY = 'UPDATE_USER_ADDRESS_CITY';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_USER_BIRTHDAY = 'UPDATE_USER_BIRTHDAY';
export const UPDATE_USER_MARKETING = 'UPDATE_USER_MARKETING';
export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';
export const UPDATE_USER_COUNTRY = 'UPDATE_USER_COUNTRY';
export const UPDATE_USER_GENDER = 'UPDATE_USER_GENDER';
export const UPDATE_USER_OUTBOUND_TICKET = 'UPDATE_USER_OUTBOUND_TICKET';
export const UPDATE_USER_RETURN_TICKET = 'UPDATE_USER_RETURN_TICKET';
export const UPDATE_USER_ROOM_TYPE = 'UPDATE_USER_ROOM_TYPE';
export const UPDATE_USER_ROOM_USERS = 'UPDATE_USER_ROOM_USERS';
export const UPDATE_USER_ROOM_PARTNER_CHOSEN =
  'UPDATE_USER_ROOM_PARTNER_CHOSEN';
export const UPDATE_USER_ADDITIONAL_INFO = 'UPDATE_USER_ADDITIONAL_INFO';
export const UPDATE_USER_DISABLED = 'UPDATE_USER_DISABLED';
export const UPDATE_DISABLED_USERS = 'UPDATE_DISABLED_USERS';
export const UPDATE_ENABLED_USERS = 'UPDATE_ENABLED_USERS';

export const OPEN_PAYMENT_DIALOG = 'OPEN_PAYMENT_DIALOG';
export const CLOSE_PAYMENT_DIALOG = 'CLOSE_PAYMENT_DIALOG';

export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';

export const fetchOrganizationsCustomers = (orgId) => async (dispatch) => {
  dispatch({ type: FETCH_ORGANIZATION_CUSTOMERS_REQUESTED });
  try {
    const res = await API.get(`/api/admin/users/${orgId}`);
    dispatch({
      type: FETCH_ORGANIZATION_CUSTOMERS_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_ORGANIZATION_CUSTOMERS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const resetOrganizationCustomers = () => (dispatch) =>
  dispatch({ type: RESET_ORGANIZATION_CUSTOMERS });

export const selectExistingUser = (user) => async (dispatch) => {
  dispatch({ type: SELECT_EXISTING_USER, payload: user });
};

export const saveUserInfo = (user) => async (dispatch) => {
  dispatch({ type: UPDATE_CUSTOMER_REQUESTED });
  try {
    const res = await API.put(`/api/admin/users/${user._id}`, user);
    dispatch(setAlert('Customer updated successfully', 'success'));
    dispatch({ type: UPDATE_CUSTOMER_RESPONSE, payload: res.data });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to update customer', 'error'));
    }
    dispatch({
      type: UPDATE_CUSTOMER_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  dispatch({ type: DELETE_USER_REQUESTED });
  try {
    await API.delete(`/api/admin/users/${userId}`);
    dispatch(setAlert('Customer deleted successfully', 'success'));
    dispatch({ type: DELETE_USER_RESPONSE });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to delete customer', 'error'));
    }
    dispatch({
      type: DELETE_USER_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const toggleFilterByRoom = (payload) => async (dispatch) => {
  dispatch({
    type: TOGGLE_FILTER_BY_ROOM,
    payload,
  });
};

export const closePaymentDialog = () => async (dispatch) => {
  dispatch({ type: CLOSE_PAYMENT_DIALOG });
};

export const openPaymentDialog = (payload) => async (dispatch) => {
  dispatch({ type: OPEN_PAYMENT_DIALOG, payload: payload });
};

export const toggleShowPassword = () => async (dispatch) => {
  dispatch({ type: TOGGLE_SHOWPASSWORD });
};
export const updateUserFirstName = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_FIRST_NAME, payload: payload });
};
export const updateUserLastName = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_LAST_NAME, payload: payload });
};
export const updateUserAddressCity = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_ADDRESS_CITY, payload: payload });
};
export const updateUserAddressPostCode = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_ADDRESS_POSTCODE, payload: payload });
};
export const updateUserAddressStreet = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_ADDRESS_STREET, payload: payload });
};
export const updateUserBirthday = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_BIRTHDAY, payload: payload });
};
export const updateUserEmail = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_EMAIL, payload: payload });
};
export const updateUserMarketing = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_MARKETING, payload: payload });
};
export const updateUserPhone = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_PHONE, payload: payload });
};
export const updateUserPassword = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_PASSWORD, payload: payload });
};
export const updateUserGroup = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_GROUP, payload: payload });
};
export const updateUserGender = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_GENDER, payload: payload });
};
export const updateUserCountry = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_COUNTRY, payload: payload });
};
export const updateUserOutboundTicket = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_OUTBOUND_TICKET, payload: payload });
};
export const updateUserReturnTicket = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_RETURN_TICKET, payload: payload });
};
export const updateUserRoomType = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_ROOM_TYPE, payload: payload });
};
export const updateUserRoomUsers = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_ROOM_USERS, payload: payload });
};
export const updateUserRoompartnerChosen = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_ROOM_PARTNER_CHOSEN, payload: payload });
};
export const updateUserDisabled = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_DISABLED, payload: payload });
};
export const updateUserAdditionalInformation =
  (payload) => async (dispatch) => {
    dispatch({ type: UPDATE_USER_ADDITIONAL_INFO, payload: payload });
  };

export const updatePaymentStatus = (user, paymentId) => async (dispatch) => {
  try {
    const res = await API.post(
      `/api/admin/payments/change-payment-status/${paymentId}`
    );
    dispatch(setAlert('Payment status changed successfully', 'success'));
    dispatch({ type: UPDATE_PAYMENT_STATUS, payload: res.data.payment });
  } catch (error) {
    dispatch(setAlert('Failed to change payment status', 'error'));
    console.log(error);
  }
};
