import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';

import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

import { DatePicker } from '@material-ui/pickers';

import MarketingText from 'components/views/customer/register/MarketingText';
import AlertMessage from 'components/layouts/AlertMessage';
import CountrySelector from 'components/layouts/CountrySelector';

import {
  fetchGroups,
  updateUserAddressCity,
  updateUserAddressPostCode,
  updateUserAddressStreet,
  updateUserBirthday,
  updateUserEmail,
  updateUserGroup,
  updateUserGender,
  updateUserFirstName,
  updateUserLastName,
  updateUserPassword,
  updateUserPhone,
  updateUserCountry,
  togglePassword,
  toggleMarketingView,
  saveUser,
} from 'redux/actions/register';

import { fetchCustomerOrganizationById } from 'redux/actions/organization';

import { getRegisterInfo } from 'redux/selectors/register';
import { getCustomerOrganization } from 'redux/selectors/organization';

import { formatUrl } from 'utils';

import { usePhoneValidator } from 'hooks/usePhoneValidator';

const useStyles = makeStyles((theme) => ({
  registerContainer: {
    marginTop: '3%',
    minWidth: '100%',
    minHeight: '90%',
    overflowY: 'scroll',
    overFlowX: 'hidden',
    background: '#FFFFFF',
    /* Drop */
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    zIndex: 1000,
  },
  marketingTextContainer: {
    marginTop: '6%',
    marginLeft: '8%',
    marginRight: '8%',
    overflowX: 'hidden',
  },
  registerHeader: {
    marginTop: '5%',
    marginLeft: '8%',
    marginRight: '8%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '102%',

    color: '#20D2B7',
  },
  registerInfo: {
    marginTop: theme.spacing(2),
    marginLeft: '8%',
    marginRight: '8%',

    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '102%',
    color: '#828282',
  },
  registerForm: {
    overFlowX: 'hidden',
    marginLeft: '8%',
    marginRight: '8%',
    width: '84%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  registerSubmit: {
    '&:hover': {
      background: '#AD00FF',
    },
    margin: theme.spacing(3, 0, 2),
    background: '#20D2B7',
    color: '#FFFFFF',
    maxWidth: 200,
  },
  formControlGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  formControlLabel: {
    marginLeft: theme.spacing(2),
  },

  formControl: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  formControlCountry: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  formControlBirthday: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  formControlTermsOfService: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  formControlMarketing: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  alertBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    marginTop: '3%',
    marginLeft: '8%',
    marginBottom: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  yesToMarketingButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    width: 'auto',
    height: '42px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 108% */
    textAlign: 'center',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    /* Teal */
    background: '#20D2B7',
    zIndex: 100,
  },
  noToMarketingButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    width: 'auto',
    height: '42px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 108% */
    textAlign: 'center',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    border: '3px solid #20D2B7',
    color: '#20D2B7',
    /* Teal */
    background: 'transparent',
    zIndex: 100,
    marginTop: '2%',
  },
}));

const GroupOptions = (groups) => {
  return groups.map((group) => {
    if (group.users.length < group.capacity) {
      return (
        <MenuItem key={group._id} value={group._id}>
          {group.name}
        </MenuItem>
      );
    } else {
      return (
        <MenuItem disabled key={group._id} value={group._id}>
          {group.name + ' (TÄYNNÄ)'}
        </MenuItem>
      );
    }
  });
};

export const RegisterFormDesktop = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { orgId } = useParams();

  const [termsOfService, setTermsOfService] = useState(false);

  const { customerOrg } = useSelector((state) =>
    getCustomerOrganization(state.organization)
  );

  useEffect(() => {
    if (!customerOrg) {
      dispatch(fetchCustomerOrganizationById(orgId));
    }
  }, [dispatch, orgId, customerOrg]);

  useEffect(() => {
    if (orgId) {
      dispatch(fetchGroups(orgId));
    }
  }, [dispatch, orgId]);

  const { isAuthenticated } = useSelector((state) => state.auth);

  const { AlertComponent, setIsPhoneValid, validatePhoneNumber } =
    usePhoneValidator();

  const { groups, user, showPassword, marketingTextView } = useSelector(
    (state) => getRegisterInfo(state.register)
  );

  if (isAuthenticated) {
    return <Redirect to="/login"></Redirect>;
  }

  return (
    <Fragment>
      {customerOrg && (
        <div className={classes.registerContainer} key={customerOrg._id}>
          {marketingTextView ? (
            <Fragment>
              <div className={classes.marketingTextContainer}>
                <MarketingText></MarketingText>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.yesToMarketingButton}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(saveUser(user, true));
                    dispatch(toggleMarketingView());
                  }}
                >
                  Kyllä kiitos, tämä ok
                </Button>
                <Button
                  className={classes.noToMarketingButton}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(saveUser(user, false));
                    dispatch(toggleMarketingView());
                  }}
                >
                  Ei kiitos
                </Button>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Typography className={classes.registerHeader}>
                Rekisteröidy
              </Typography>
              <Typography className={classes.registerInfo}>
                Täytäthän alle matkalle lähtevän ihmisen tiedot. Huom: On
                tärkeää, että tiedot ovat oikein. Etu- ja sukunimen, syntymäajan
                sekä kansalaisuuden tulee vastata niitä tietoja, jotka lukevat
                poliisiviranomaisen myöntämässä passissa tai henkilökortissa.
                Osallistujan on oltava matkan aikana 18 -vuotias.
              </Typography>
              <form className={classes.registerForm} noValidate>
                <div className={classes.alertBox}>
                  <AlertMessage />
                </div>
                {groups && groups.length > 0 && (
                  <Fragment>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          label="Ryhmä"
                          id="group"
                          required
                          select
                          value={user.group}
                          onChange={(event) => {
                            dispatch(updateUserGroup(event.target.value));
                          }}
                        >
                          {GroupOptions(groups)}
                        </TextField>
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="first name"
                          label="Etunimi"
                          type="first name"
                          id="first name"
                          value={user.firstName}
                          onChange={(e) => {
                            dispatch(updateUserFirstName(e.target.value));
                          }}
                          autoComplete="first name"
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="last name"
                          label="Sukunimi"
                          type="last name"
                          id="last name"
                          value={user.lastName}
                          onChange={(e) => {
                            dispatch(updateUserLastName(e.target.value));
                          }}
                          autoComplete="last name"
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControlBirthday}>
                        <DatePicker
                          required
                          disableFuture
                          openTo="date"
                          format="DD/MM/yyyy"
                          label="Syntymäaika"
                          views={['year', 'month', 'date']}
                          id="birthday"
                          variant="inline"
                          value={user.birthday}
                          onChange={(date) => {
                            dispatch(updateUserBirthday(date));
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="street"
                          label="Katuosoite"
                          type="street"
                          id="street"
                          value={user.address.street}
                          onChange={(e) => {
                            dispatch(updateUserAddressStreet(e.target.value));
                          }}
                          autoComplete="street"
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="postCode"
                          name="postCode"
                          label="Postinumero"
                          value={user.address.postcode}
                          onChange={(e) => {
                            dispatch(updateUserAddressPostCode(e.target.value));
                          }}
                          autoComplete="postCode"
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="city"
                          name="city"
                          label="Kaupunki"
                          value={user.address.city}
                          onChange={(e) => {
                            dispatch(updateUserAddressCity(e.target.value));
                          }}
                          autoComplete="city"
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="gender"
                          name="gender"
                          label="Sukupuoli"
                          select
                          value={user.gender}
                          onChange={(e) => {
                            dispatch(updateUserGender(e.target.value));
                          }}
                          autoComplete="gender"
                        >
                          <MenuItem key={'F'} value={'F'}>
                            Nainen
                          </MenuItem>
                          <MenuItem key={'M'} value={'M'}>
                            Mies
                          </MenuItem>
                        </TextField>
                        <FormHelperText id="gender-text">
                          Meidän pitää kysyä tätä tietoa matkustussäädösten
                          takia. Kerrothan siis passissa / virallisessa
                          henkilöllisyystodistuksessa näkyvän sukupuolen.
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControlCountry}>
                        <CountrySelector
                          currentValue={user.country ? user.country : ''}
                          setCountry={updateUserCountry}
                          label={'Kansallisuus'}
                          required={true}
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="phone"
                          label="Puhelin"
                          placeholder="+3581234567890"
                          type="phone"
                          id="phone"
                          value={user.phone}
                          onChange={(e) => {
                            setIsPhoneValid(true);
                            dispatch(updateUserPhone(e.target.value));
                          }}
                          autoComplete="phone"
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          name="email"
                          label="Sähköposti"
                          value={user.email}
                          onChange={(e) => {
                            dispatch(updateUserEmail(e.target.value));
                          }}
                          autoComplete="email"
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Salasana"
                          type={showPassword ? 'text' : 'password'}
                          id="password"
                          value={user.password}
                          onChange={(e) => {
                            dispatch(updateUserPassword(e.target.value));
                          }}
                          autoComplete="current-password"
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={(e) => dispatch(togglePassword())}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formControlGroup}>
                      <FormControl
                        className={classes.formControlTermsOfService}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={termsOfService}
                              onChange={(e) =>
                                setTermsOfService(e.target.checked)
                              }
                              name="Terms of service"
                            />
                          }
                          label={
                            <span>
                              Hyväksyn{' '}
                              <a
                                target="_blank"
                                href={
                                  formatUrl(customerOrg.travelConditions) ??
                                  'https://www.matkapojat.fi/dokumentit/AGT2022ehdot.pdf'
                                }
                                rel="noopener noreferrer"
                                style={{
                                  color: '#20D2B7',
                                  textDecoration: 'none',
                                }}
                              >
                                {' '}
                                matkaehdot
                              </a>{' '}
                              sekä tietojeni tallentamisen ja hallinnoinnin
                              <a
                                target="_blank"
                                href={
                                  formatUrl(customerOrg.privacyStatement) ??
                                  'https://www.matkapojat.fi/dokumentit/Iconic%20Travel%20tietosuojaseloste.pdf'
                                }
                                rel="noopener noreferrer"
                                style={{
                                  color: '#20D2B7',
                                  textDecoration: 'none',
                                }}
                              >
                                {' '}
                                tietosuojaselosteen mukaisesti
                              </a>{' '}
                            </span>
                          }
                        />
                      </FormControl>
                    </div>
                    <AlertComponent color="black" />
                    <div className={classes.formControlGroup}>
                      <Button
                        fullWidth
                        disabled={!termsOfService}
                        className={classes.registerSubmit}
                        onClick={() => {
                          if (!validatePhoneNumber(user.phone)) {
                            return;
                          }
                          dispatch(toggleMarketingView());
                        }}
                      >
                        Rekisteröidy
                      </Button>
                    </div>
                  </Fragment>
                )}
              </form>
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default RegisterFormDesktop;
