import React from 'react';
import { useState } from 'react';
import Alert from '@material-ui/lab/Alert';

export const usePhoneValidator = () => {
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\+[1-9]\d{1,14}$/;
    const result = regex.test(phoneNumber);
    setIsPhoneValid(result);
    return result;
  };

  const AlertComponent = ({ color = 'white' }) => {
    if (!isPhoneValid) {
      return (
        <Alert
          severity="error"
          style={{
            color: color,
            marginTop: '10px',
            background: 'transparent',
          }}
        >
          Puhelinnumerosta puuttuu maatunnus tai se on virheellinen
        </Alert>
      );
    } else {
      return null;
    }
  };
  return {
    isPhoneValid,
    validatePhoneNumber,
    AlertComponent,
    setIsPhoneValid,
  };
};
