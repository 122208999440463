import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import AlertMessage from 'components/layouts/AlertMessage';
import DeleteConfirm from 'components/layouts/DeleteConfirm';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import {
  updateEventDate,
  updateEventDescription,
  updateEventIdentifier,
  updateEventName,
  updateEventOrganization,
  updateEventProducts,
  updateEventInfoButton1Name,
  updateEventInfoButton1Url,
  updateEventInfoButton1Visible,
  updateEventInfoButton2Name,
  updateEventInfoButton2Url,
  updateEventInfoButton2Visible,
  updateEventInfoButton3Name,
  updateEventInfoButton3Url,
  updateEventInfoButton3Visible,
  updateEvent,
  saveEvent,
  deleteEvent,
  toggleCollectPaymentForEventProduct,
  toggleAllowUsersToEditInfo,
} from 'redux/actions/event';

import { fetchOrgProducts } from 'redux/actions/product';

import { getOrganizations } from 'redux/actions/organization';

import { getRoleAndOrganization } from 'redux/selectors/auth';

import { getOrgProducts } from 'redux/selectors/product';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  doubleRootWidth: {
    width: '50ch!important',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  alertBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    minHeight: 140,
    minWidth: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  control: {
    padding: theme.spacing(2),
  },
  productTitle: {
    fontSize: 14,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  productInfo: {
    fontSize: 12,
  },
}));

const OrganizationsOptions = (organizations) => {
  return organizations.map((organization) => (
    <MenuItem key={organization._id} value={organization._id}>
      {organization.name}
    </MenuItem>
  ));
};

const createProductOptions = (products) => {
  const productOptions = [];
  products.forEach((product) =>
    productOptions.push({ product, collectPayment: false })
  );

  return productOptions;
};

const objectDoesNotContainNull = (obj) => {
  const notMandatory = [
    'description',
    'infoButton1',
    'infoButton2',
    'infoButton3',
  ];
  const keys = Object.keys(obj);
  const values = keys
    .filter((key) => !notMandatory.includes(key))
    .map((key) => obj[key]);
  return !values.includes(null);
};

const EventForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const { event } = useSelector((state) => state.event);
  const { organizations } = useSelector((state) => state.organization);
  const { role, organization } = useSelector((state) =>
    getRoleAndOrganization(state.auth)
  );

  useEffect(() => {
    if (event.organization) {
      dispatch(fetchOrgProducts(event.organization));
    }
  }, [event.organization, dispatch]);

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  const { organizationProducts } = useSelector((state) =>
    getOrgProducts(state.product)
  );

  const handleAgree = () => {
    dispatch(deleteEvent(event));
    setConfirmDelete(false);
  };
  const closeDialog = () => setConfirmDelete(false);

  return (
    <div>
      <h2> Event </h2>
      {event.date && (
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          key={event._id}
        >
          <div className={classes.alertBox}>
            <AlertMessage />
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                defaultValue={event.name}
                label="Event name"
                required
                onChange={(event) =>
                  dispatch(updateEventName(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="identifier"
                label="Event identifier"
                defaultValue={event.identifier}
                required
                onChange={(event) =>
                  dispatch(updateEventIdentifier(event.target.value))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="description"
                defaultValue={event.description}
                label="Event infos description"
                multiline={true}
                onChange={(event) =>
                  dispatch(updateEventDescription(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="event date"
                label="Event date"
                value={event.date}
                onChange={(event, val) => {
                  const formattedDateFromVal = moment(val, 'DD/MM/YYYY');
                  dispatch(updateEventDate(formattedDateFromVal.toDate()));
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              {role === 'owner' || role === 'admin' ? (
                <TextField
                  label="Organization"
                  id="organization"
                  disabled
                  value={organization._id}
                  select
                  InputProps={{
                    readOnly: true,
                  }}
                >
                  <MenuItem key={organization._id} value={organization._id}>
                    {organization.name}
                  </MenuItem>
                </TextField>
              ) : (
                role === 'root' && (
                  <TextField
                    label="Organization"
                    id="organization"
                    select
                    required
                    value={event.organization ? event.organization : ''}
                    onChange={(event) => {
                      dispatch(updateEventOrganization(event.target.value));
                    }}
                  >
                    {OrganizationsOptions(organizations)}
                  </TextField>
                )
              )}
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Switch
                    checked={event.allowUsersEditInfo}
                    onChange={() => {
                      dispatch(
                        toggleAllowUsersToEditInfo(!event.allowUsersEditInfo)
                      );
                    }}
                    name="allow users to edit info"
                    color="primary"
                  />
                }
                className={classes.productInfo}
                label={
                  <Typography
                    className={classes.productInfo}
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Allow users to edit their info
                  </Typography>
                }
                labelPlacement="top"
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="info button name"
                value={event.infoButton1 ? event.infoButton1.name : ''}
                label="Info button 1 name"
                required
                onChange={(event) =>
                  dispatch(updateEventInfoButton1Name(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="info button url"
                label="Info button 1 url"
                value={event.infoButton1 ? event.infoButton1.url : ''}
                required
                onChange={(event) =>
                  dispatch(updateEventInfoButton1Url(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      event.infoButton1 ? event.infoButton1.visible : false
                    }
                    onChange={() => {
                      dispatch(
                        updateEventInfoButton1Visible(
                          !event.infoButton1.visible
                        )
                      );
                    }}
                    name="Infobutton 1 visible"
                    color="primary"
                  />
                }
                className={classes.productInfo}
                label={
                  <Typography
                    className={classes.productInfo}
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Visible for customers
                  </Typography>
                }
                labelPlacement="top"
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="info button name"
                value={event.infoButton2 ? event.infoButton2.name : ''}
                label="Info button 2 name"
                required
                onChange={(event) =>
                  dispatch(updateEventInfoButton2Name(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="info button url"
                label="Info button 2 url"
                value={event.infoButton2 ? event.infoButton2.url : ''}
                required
                onChange={(event) =>
                  dispatch(updateEventInfoButton2Url(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      event.infoButton2 ? event.infoButton2.visible : false
                    }
                    onChange={() => {
                      dispatch(
                        updateEventInfoButton2Visible(
                          !event.infoButton2.visible
                        )
                      );
                    }}
                    name="Infobutton 2 visible"
                    color="primary"
                  />
                }
                className={classes.productInfo}
                label={
                  <Typography
                    className={classes.productInfo}
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Visible for customers
                  </Typography>
                }
                labelPlacement="top"
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="info button name"
                value={event.infoButton3 ? event.infoButton3.name : ''}
                label="Info button 3 name"
                required
                onChange={(event) =>
                  dispatch(updateEventInfoButton3Name(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="info button url"
                label="Info button 3 url"
                value={event.infoButton3 ? event.infoButton3.url : ''}
                required
                onChange={(event) =>
                  dispatch(updateEventInfoButton3Url(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      event.infoButton3 ? event.infoButton3.visible : false
                    }
                    onChange={() => {
                      dispatch(
                        updateEventInfoButton3Visible(
                          !event.infoButton3.visible
                        )
                      );
                    }}
                    name="Infobutton 3 visible"
                    color="primary"
                  />
                }
                className={classes.productInfo}
                label={
                  <Typography
                    className={classes.productInfo}
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Visible for customers
                  </Typography>
                }
                labelPlacement="top"
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              {event.organization && organizationProducts ? (
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={createProductOptions(organizationProducts)}
                  getOptionLabel={(option) => option.product.name}
                  value={event.products ? event.products : []}
                  onChange={(event, value, reason) =>
                    dispatch(updateEventProducts(value))
                  }
                  getOptionSelected={(option, value) =>
                    option.product.name === value.product.name
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.doubleRootWidth}
                      variant="standard"
                      label="Products"
                    />
                  )}
                />
              ) : (
                <Fragment>
                  <h1>Please add products for organization first</h1>
                </Fragment>
              )}
            </FormControl>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={2}>
                {event.products.map((productObj) => (
                  <Grid key={productObj.product._id} item>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography
                        className={classes.productTitle}
                        color="textPrimary"
                        gutterBottom
                        variant="h2"
                      >
                        {productObj.product.name +
                          ' (' +
                          (productObj.product.mandatory
                            ? 'mandatory)'
                            : 'unmandatory)')}
                      </Typography>
                      <Typography
                        className={classes.productInfo}
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                      >
                        Price: {productObj.product.price} €
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={productObj.collectPayment}
                            onChange={() => {
                              dispatch(
                                toggleCollectPaymentForEventProduct({
                                  ...productObj,
                                  collectPayment: !productObj.collectPayment,
                                })
                              );
                            }}
                            name="collect payment"
                            color="primary"
                          />
                        }
                        className={classes.productInfo}
                        label={
                          <Typography
                            className={classes.productInfo}
                            color="textPrimary"
                            gutterBottom
                            variant="h5"
                          >
                            Collect payment
                          </Typography>
                        }
                        labelPlacement="top"
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </div>
        </form>
      )}
      <div className={classes.buttonContainer}>
        {objectDoesNotContainNull(event) && (
          <Button
            className={classes.button}
            aria-label="save"
            color="primary"
            variant="contained"
            onClick={() =>
              dispatch(event._id ? updateEvent(event) : saveEvent(event))
            }
          >
            Save
          </Button>
        )}
        {event._id && (
          <Button
            className={classes.button}
            aria-label="delete"
            color="secondary"
            variant="contained"
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
        )}
      </div>
      <DeleteConfirm
        visible={confirmDelete}
        handleAgree={handleAgree}
        closeDialog={closeDialog}
      ></DeleteConfirm>
    </div>
  );
};

export default EventForm;
