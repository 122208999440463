import {
  FETCH_GROUPS_REQUESTED,
  FETCH_GROUPS_RESPONSE,
  FETCH_GROUPS_ERROR,
  UPDATE_GROUP_REQUESTED,
  UPDATE_GROUP_RESPONSE,
  UPDATE_GROUP_ERROR,
  SAVE_GROUP_REQUESTED,
  SAVE_GROUP_RESPONSE,
  SAVE_GROUP_ERROR,
  DELETE_GROUP_ERROR,
  DELETE_GROUP_REQUESTED,
  DELETE_GROUP_RESPONSE,
  UPDATE_GROUP_NAME,
  UPDATE_GROUP_CAPACITY,
  UPDATE_GROUP_CONTACT_NAME,
  UPDATE_GROUP_CONTACT_EMAIL,
  UPDATE_GROUP_CONTACT_PHONE,
  UPDATE_GROUP_EVENT,
  UPDATE_GROUP_PRODUCTS,
  UPDATE_GROUP_OUTBOUND_FERRY,
  UPDATE_GROUP_RETURN_FERRY,
  UPDATE_GROUP_ACCOMODATION,
  UPDATE_GROUP_PRODUCT_COLLECT_PAYMENT,
  SELECT_NEW_GROUP,
  SELECT_EXISTING_GROUP,
  SET_GROUP_FILTER_ORG,
  SET_GROUP_FILTER_EVENT,
  SET_GROUP_FILTER_ORG_CUSTOMER_FORM,
} from 'redux/actions/group';

const initialState = {
  groups: [],
  group: {
    name: null,
    capacity: null,
    contact: {
      name: null,
      email: null,
      phone: null,
    },
    outboundFerry: null,
    returnFerry: null,
    accomodation: null,
    products: [],
    event: null,
    _id: '',
  },
  filterGroupsOrg: 'all',
  filterGroupsEvent: 'all',
  filterGroupsOrgCustomerForm: 'all',
  error: {},
};

const updateGroupsArray = (groups, updated) => {
  const filtered = groups.filter((group) => group._id !== updated._id);
  filtered.push(updated);
  return filtered;
};

const updateProductCollectPayment = (products, updatedProduct) => {
  const updatedProducts = products.map((obj) =>
    obj.product._id === updatedProduct.product._id ? updatedProduct : obj
  );
  return updatedProducts;
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_GROUP_FILTER_ORG:
      return {
        ...state,
        filterGroupsOrg: action.payload,
      };

    case SET_GROUP_FILTER_EVENT:
      return {
        ...state,
        filterGroupsEvent: action.payload,
      };

    case SET_GROUP_FILTER_ORG_CUSTOMER_FORM:
      return {
        ...state,
        filterGroupsOrgCustomerForm: action.payload,
      };

    case SELECT_EXISTING_GROUP:
      return {
        ...state,
        group: action.payload ? action.payload : null,
      };

    case SELECT_NEW_GROUP:
      return {
        ...state,
        group: initialState.group,
      };

    case SAVE_GROUP_RESPONSE:
      return {
        ...state,
        groups: updateGroupsArray(state.groups, action.payload),
      };

    case UPDATE_GROUP_RESPONSE:
      return {
        ...state,
        groups: updateGroupsArray(state.groups, action.payload),
      };

    case DELETE_GROUP_RESPONSE:
      return {
        ...state,
        groups: state.groups.filter(
          (group) => group._id !== action.payload._id
        ),
        group: initialState.group,
      };

    case SAVE_GROUP_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_GROUP_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_GROUP_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_GROUPS_RESPONSE:
      return { ...state, groups: payload };

    case FETCH_GROUPS_ERROR:
      return { ...state, error: payload };

    case UPDATE_GROUP_NAME:
      return {
        ...state,
        group: { ...state.group, name: action.payload },
      };

    case UPDATE_GROUP_CAPACITY:
      return {
        ...state,
        group: { ...state.group, capacity: action.payload },
      };

    case UPDATE_GROUP_EVENT:
      return {
        ...state,
        group: { ...state.group, event: action.payload },
      };

    case UPDATE_GROUP_OUTBOUND_FERRY:
      return {
        ...state,
        group: { ...state.group, outboundFerry: action.payload },
      };

    case UPDATE_GROUP_RETURN_FERRY:
      return {
        ...state,
        group: { ...state.group, returnFerry: action.payload },
      };

    case UPDATE_GROUP_ACCOMODATION:
      return {
        ...state,
        group: { ...state.group, accomodation: action.payload },
      };

    case UPDATE_GROUP_CONTACT_NAME:
      return {
        ...state,
        group: {
          ...state.group,
          contact: { ...state.group.contact, name: action.payload },
        },
      };

    case UPDATE_GROUP_CONTACT_EMAIL:
      return {
        ...state,
        group: {
          ...state.group,
          contact: { ...state.group.contact, email: action.payload },
        },
      };

    case UPDATE_GROUP_CONTACT_PHONE:
      return {
        ...state,
        group: {
          ...state.group,
          contact: { ...state.group.contact, phone: action.payload },
        },
      };

    case UPDATE_GROUP_PRODUCTS:
      return {
        ...state,
        group: {
          ...state.group,
          products: action.payload,
        },
      };

    case UPDATE_GROUP_PRODUCT_COLLECT_PAYMENT:
      return {
        ...state,
        group: {
          ...state.group,
          products: updateProductCollectPayment(
            state.group.products,
            action.payload
          ),
        },
      };

    case DELETE_GROUP_REQUESTED:
    case UPDATE_GROUP_REQUESTED:
    case SAVE_GROUP_REQUESTED:
    case FETCH_GROUPS_REQUESTED:
    default:
      return state;
  }
}
