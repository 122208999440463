import React, { Fragment } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';

import PrivateRoute from 'components/routes/PrivateRoute';
import Register from 'components/views/customer/register/Register';
import UserDashboard from 'components/views/customer/userDashboard/UserDashboard';
import PaymentSuccess from 'components/views/customer/payments/PaymentSuccess';
import PaymentCancel from 'components/views/customer/payments/PaymentCancel';
import Login from 'components/auth/Login';

import { logout } from 'redux/actions/auth';
import { getCustomerOrganization } from 'redux/selectors/organization';
import { getUserEventFromState } from 'redux/selectors/customer';
import PasswordResetRequest from 'components/auth/PasswordResetRequest';
import ResetPassword from 'components/auth/ResetPassword';

const useStyles = makeStyles((theme) => ({
  logoutContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '4%',
    alignItems: 'center',
    marginLeft: '5%',
    marginRight: '9%',
  },
  orgAndButtonContaner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  eventName: {
    height: '3%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '120%',
    /* or 74px */
    color: '#FFFFFF',
    zIndex: 1000,
  },
  orgName: {
    marginTop: '7%',
    height: '3%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '120%',
    /* or 74px */
    color: '#FFFFFF',
    zIndex: 1000,
  },
  logoutButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    width: '2,71%',
    height: '4,81%',
    marginLeft: '50%',
    /* White */

    background: '#FFFFFF',
    borderRadius: '69px',
  },
}));

const CustomerContentRoutes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { customerOrg } = useSelector((state) =>
    getCustomerOrganization(state.organization)
  );

  const { event } = useSelector((state) =>
    getUserEventFromState(state.customer)
  );

  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <Fragment>
      <Fragment>
        <div className={classes.logoutContainer}>
          <div>
            {event && (
              <Typography className={classes.eventName}>
                {event.name}
              </Typography>
            )}
          </div>
          <div className={classes.orgAndButtonContaner}>
            <div>
              {customerOrg && (
                <Typography className={classes.orgName}>
                  {customerOrg.name}
                </Typography>
              )}
            </div>
            {isAuthenticated && (
              <IconButton
                className={classes.logoutButton}
                component={NavLink}
                to="/"
                onClick={() => dispatch(logout())}
              >
                <ExitToAppIcon style={{ color: '#20D2B7' }} />
              </IconButton>
            )}
          </div>
        </div>
      </Fragment>
      <Route exact path="/" component={Login}></Route>
      <Route exact path="/login" component={Login}></Route>
      <Route exact path="/register/:orgId" component={Register}></Route>
      <Route
        exact
        path="/password-reset-request"
        component={PasswordResetRequest}
      ></Route>
      <Route exact path="/reset-password" component={ResetPassword}></Route>
      <PrivateRoute
        exact
        path="/user-dashboard"
        component={UserDashboard}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/payment-success"
        component={PaymentSuccess}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/payment-cancel"
        component={PaymentCancel}
      ></PrivateRoute>
    </Fragment>
  );
};

export default CustomerContentRoutes;
