import {
  FETCH_PRODUCTS_REQUESTED,
  FETCH_PRODUCTS_RESPONSE,
  FETCH_PRODUCTS_ERROR,
  FETCH_ORG_PRODUCTS_REQUESTED,
  FETCH_ORG_PRODUCTS_RESPONSE,
  FETCH_ORG_PRODUCTS_ERROR,
  UPDATE_PRODUCT_REQUESTED,
  UPDATE_PRODUCT_RESPONSE,
  UPDATE_PRODUCT_ERROR,
  SAVE_PRODUCT_REQUESTED,
  SAVE_PRODUCT_RESPONSE,
  SAVE_PRODUCT_ERROR,
  DELETE_PRODUCT_ERROR,
  DELETE_PRODUCT_REQUESTED,
  DELETE_PRODUCT_RESPONSE,
  SELECT_EXISTING_PRODUCT,
  SELECT_NEW_PRODUCT,
  SET_PRODUCT_FILTER_ORG,
  UPDATE_PRODUCT_NAME,
  UPDATE_PRODUCT_PRICE,
  UPDATE_PRODUCT_VAT,
  UPDATE_PRODUCT_VAT_PERCENT,
  UPDATE_PRODUCT_TAX_AMOUNT,
  UPDATE_PRODUCT_TAX_DESCRIPTION,
  UPDATE_PRODUCT_DESCRIPTION,
  UPDATE_PRODUCT_ORGANIZATION,
  UPDATE_PRODUCT_MANDATORY,
  UPDATE_PRODUCT_MAXIMUM_AMOUNT,
  UPDATE_PRODUCT_INFO_LINK,
  UPDATE_PRODUCT_SERVICE_FEE,
  UPDATE_PRODUCT_SERVICE_FEE_VAT,
} from 'redux/actions/product';

const initialState = {
  products: [],
  product: {
    name: null,
    description: null,
    price: null,
    vat: true,
    vatPercent: null,
    taxAmount: null,
    taxDescription: null,
    organization: null,
    mandatory: true,
    maximumAmount: 0,
    infoLink: null,
    serviceFee: null,
    serviceFeeVat: null,
    _id: '',
  },
  filterProductsOrg: 'all',
  organizationProducts: [],
  error: {},
};

const updateProductsArray = (products, updated) => {
  const filtered = products.filter((product) => product._id !== updated._id);
  filtered.push(updated);
  return filtered;
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_PRODUCT_RESPONSE:
      return {
        ...state,
        products: updateProductsArray(state.products, action.payload),
      };

    case UPDATE_PRODUCT_RESPONSE:
      return {
        ...state,
        products: updateProductsArray(state.products, action.payload),
      };

    case DELETE_PRODUCT_RESPONSE:
      return {
        ...state,
        products: state.products.filter(
          (product) => product._id !== action.payload._id
        ),
        product: initialState.product,
      };

    case SAVE_PRODUCT_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PRODUCT_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case SET_PRODUCT_FILTER_ORG:
      return {
        ...state,
        filterProductsOrg: action.payload,
      };

    case FETCH_ORG_PRODUCTS_RESPONSE:
      return { ...state, organizationProducts: payload };

    case FETCH_ORG_PRODUCTS_ERROR:
      return { ...state, error: payload };

    case FETCH_PRODUCTS_RESPONSE:
      return { ...state, products: payload };

    case FETCH_PRODUCTS_ERROR:
      return { ...state, error: payload };

    case SELECT_EXISTING_PRODUCT:
      return { ...state, product: action.payload };

    case SELECT_NEW_PRODUCT:
      return { ...state, product: initialState.product };

    case UPDATE_PRODUCT_NAME:
      return {
        ...state,
        product: { ...state.product, name: action.payload },
      };

    case UPDATE_PRODUCT_DESCRIPTION:
      return {
        ...state,
        product: { ...state.product, description: action.payload },
      };

    case UPDATE_PRODUCT_PRICE:
      return {
        ...state,
        product: { ...state.product, price: action.payload },
      };

    case UPDATE_PRODUCT_VAT:
      return {
        ...state,
        product: { ...state.product, vat: action.payload },
      };

    case UPDATE_PRODUCT_VAT_PERCENT:
      return {
        ...state,
        product: { ...state.product, vatPercent: action.payload },
      };

    case UPDATE_PRODUCT_TAX_AMOUNT:
      return {
        ...state,
        product: { ...state.product, taxAmount: action.payload },
      };

    case UPDATE_PRODUCT_TAX_DESCRIPTION:
      return {
        ...state,
        product: { ...state.product, taxDescription: action.payload },
      };
    case UPDATE_PRODUCT_ORGANIZATION:
      return {
        ...state,
        product: { ...state.product, organization: action.payload },
      };
    case UPDATE_PRODUCT_MANDATORY:
      return {
        ...state,
        product: { ...state.product, mandatory: action.payload },
      };
    case UPDATE_PRODUCT_MAXIMUM_AMOUNT:
      return {
        ...state,
        product: { ...state.product, maximumAmount: action.payload },
      };
    case UPDATE_PRODUCT_INFO_LINK:
      return {
        ...state,
        product: { ...state.product, infoLink: action.payload },
      };
    case UPDATE_PRODUCT_SERVICE_FEE:
      return {
        ...state,
        product: { ...state.product, serviceFee: action.payload },
      };
    case UPDATE_PRODUCT_SERVICE_FEE_VAT:
      return {
        ...state,
        product: { ...state.product, serviceFeeVat: action.payload },
      };
    case DELETE_PRODUCT_REQUESTED:
    case UPDATE_PRODUCT_REQUESTED:
    case SAVE_PRODUCT_REQUESTED:
    case FETCH_PRODUCTS_REQUESTED:
    case FETCH_ORG_PRODUCTS_REQUESTED:
    default:
      return state;
  }
}
