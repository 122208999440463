import React, { Fragment, useEffect, useState } from 'react';

import API from 'services/api';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import { makeStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paymentHeader: {
    marginTop: '3%',
    marginLeft: '5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '46px',
    marginBottom: theme.spacing(2),
    color: '#20D2B7',
  },
  paymentInfo: {
    marginLeft: '5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  circularProgress: {
    '&.MuiCircularProgress-colorPrimary': {
      color: '#20D2B7',
    },
    margin: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    width: '18px',
    height: '24px',
    right: '26px',
    top: '20px',
    color: '#20D2B7',
  },
}));

const parameterToInput = (param) => (
  <input key={param.name} type="hidden" name={param.name} value={param.value} />
);

const responseToHtml = (response, classname, id) =>
  response.providers.map((provider, index) => (
    <Fragment key={provider.name}>
      <form
        method="POST"
        action={provider.url}
        style={{ minWidth: '20%' }}
        id={`form-${id}-${index}`}
      >
        {provider.parameters.map((param) => parameterToInput(param))}
        <input
          type="submit"
          style={{ display: 'none' }}
          id={`submit-${id}-${index}`}
        />
        <Button
          variant="outlined"
          style={{ minWidth: '150px', margin: '5px' }}
          size="large"
          onClick={async (e) => {
            e.preventDefault();
            await API.get(`/api/payments/proceed/${id}`);
            document.getElementById(`submit-${id}-${index}`).click();
          }}
        >
          <img src={provider.svg} alt="Payment provider logo" />
        </Button>
      </form>
    </Fragment>
  ));

const calculatePrice = (payment) => {
  const product = payment.products[0];
  let total = product.price;

  if (payment.discountCode) {
    total = total - (total / 100) * payment.discountCode.discount;
  }
  if (payment.products[0].serviceFee) {
    total += payment.products[0].serviceFee;
  }
  return total;
};

export const PaymentDialog = ({ visible, closeDialog, payment, mobile }) => {
  const classes = useStyles();
  //   const dispatch = useDispatch();

  const [isLoadingPayment, setIsLoadingPayment] = useState(true);
  const [paymentData, setPaymentData] = useState();

  useEffect(() => {
    const fetchPayment = async () => {
      const resp = await API.get(`/api/payments/create/${payment._id}`);
      setPaymentData(resp.data);
      setIsLoadingPayment(false);
    };

    if (visible) {
      setIsLoadingPayment(true);
      fetchPayment();
    }
  }, [payment, visible]);

  return (
    <Dialog
      fullScreen={mobile}
      fullWidth
      scroll="body"
      maxWidth="lg"
      open={visible}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-payment"
      aria-describedby="alert-dialog-payment-form"
    >
      <DialogTitle className={classes.paymentHeader}>
        {payment.products[0].name}
      </DialogTitle>
      {isLoadingPayment ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress
            className={classes.circularProgress}
            size={50}
            thickness={5}
          />
        </div>
      ) : (
        <Fragment>
          <DialogContent>
            <Typography className={classes.paymentInfo}>
              Tuotteen hinta: {payment.products[0].price}€
            </Typography>
            {payment.products[0]?.serviceFee !== undefined && (
              <Typography className={classes.paymentInfo}>
                Palvelumaksu: {payment.products[0].serviceFee}€
              </Typography>
            )}
            {payment.discountCode !== undefined && (
              <Fragment>
                <Typography className={classes.paymentInfo}>
                  Alennuskoodi: {payment.discountCode.name}
                </Typography>
                <Typography className={classes.paymentInfo}>
                  Alennus: {payment.discountCode.discount}%
                </Typography>
              </Fragment>
            )}
            <Typography className={classes.paymentInfo}>
              Hinta yhteensä: {calculatePrice(payment)}€
            </Typography>
            <Typography className={classes.paymentInfo}>
              Valitsemalla maksutavan hyväksyt{' '}
              <a
                href="https://www.paytrail.com/kuluttaja/maksupalveluehdot"
                target="_blank"
                rel="noopener noreferrer"
              >
                maksupalveluehdot
              </a>
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '80%',
                paddingTop: '2%',
              }}
            >
              {responseToHtml(paymentData, classes.paymentButton, payment._id)}
            </div>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={closeDialog} className={classes.closeButton}>
              <Icon className="fas fa-times"></Icon>
            </IconButton>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
};

export default PaymentDialog;
