import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

export const PaymentCancel = () => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => setRedirect(true), 3000);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return redirect ? (
    <Redirect to="/user-dashboard"></Redirect>
  ) : (
    <Typography>Payment cancelled</Typography>
  );
};

export default PaymentCancel;
