import React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getCustomerOrganization } from 'redux/selectors/organization';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => ({
  marketingHeader: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '46px',
    color: '#20D2B7',
  },
  marketingSubHeader: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '31px',
    marginTop: '5%',
    color: '#20D2B7',
  },
  marketingInfo: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
}));

export const MarketingText = () => {
  const classes = useStyles();
  const { customerOrg } = useSelector((state) =>
    getCustomerOrganization(state.organization)
  );
  return (
    <div>
      {customerOrg.marketingAuthorization ? (
        ReactHtmlParser(customerOrg.marketingAuthorization)
      ) : (
        <div>
          <Typography className={classes.marketingHeader}>
            Markkinointilupa
          </Typography>
          <Typography className={classes.marketingSubHeader}>
            Saisimmeko lähettää ajoittain spostia?
          </Typography>
          <Typography className={classes.marketingInfo}>
            <br />
            Miksi tämä on tärkeää
            <br />
            <br />
            AGT:lla tärkein asia on aina ollut asiakaskokemus. Tämä saattaa
            joskus tarkoittaa sitä, että viestimme matkan aikana olevista
            kumppaneidemme tarjoamista alennuksista ja palveluista joista on
            sinulle etua. Lupaamme, ettemme koskaan lähetä roskapostia ja
            sisältö on valikoitunut tarkkojen kriteerien mukaan. Arvostaisimme
            todella paljon, jos annat meidän lähettää näitä tarkasti valittuja
            etuja sinulle. Tämä auttaa meitä pitämään matkan mahdollisimman
            laadukkaana.
            <br />
            <br />
            Kiitos jo etukäteen!
            <br />
            <br />
            Palveluksessasi,
            <br />
            AGT -tiimi
          </Typography>
        </div>
      )}
    </div>
  );
};

export default MarketingText;
