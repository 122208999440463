export const getOrganizationFerrys = (state) => {
  const { filterFerrysOrg, ferrys } = state;
  if (filterFerrysOrg !== 'all') {
    let filteredFerrys = ferrys.filter(
      (ferry) => ferry.organization === filterFerrysOrg
    );
    return { ferrys: filteredFerrys };
  } else {
    return { ferrys };
  }
};

export const getOrganizationFerrysGroupForm = (state) => {
  const { filterFerrysOnGroupFormByOrg, ferrys } = state;
  if (filterFerrysOnGroupFormByOrg !== 'all') {
    let filteredFerrys = ferrys.filter(
      (ferry) => ferry.organization === filterFerrysOnGroupFormByOrg
    );

    return { ferrys: filteredFerrys };
  } else {
    return { ferrys };
  }
};

export const getFilterFerrysOrg = (state) => {
  const { filterFerrysOrg } = state;
  return { filterFerrysOrg };
};

export const getFerryFromState = (state) => {
  const { ferry } = state;
  return { ferry };
};

export const getCSVDialogFromState = (state) => {
  const { visible, csvJSON, ferryId } = state.importCSVDialog;
  return { visible, csvJSON, ferryId };
};
