export const getInfoFromState = (state) => {
  const { info } = state;
  return { info };
};

export const getUserInfoForPaymentFromState = (state) => {
  const { info } = state;
  const customer = {
    firstName: info.firstMame,
    lastName: info.lastName,
    email: info.email,
    phone: info.phone.replaceAll(' ', ''),
    address: {
      street: info.address.street,
      postalCode: info.address.postcode,
      town: info.address.city,
      country: 'FI',
    },
  };
  return { customer };
};

export const getUserIdFormState = (state) => {
  const currentUserId = state.info._id;
  return { currentUserId };
};

export const getRoomPartnerInfo = (state) => {
  const { roomPartner } = state;
  return { roomPartner };
};

export const getUsersGroup = (state) => {
  const { group } = state;
  return { group };
};

export const getUserFerrys = (state) => {
  const { outboundFerry, returnFerry } = state.group;
  return { outboundFerry, returnFerry };
};

export const getUserAccomodation = (state) => {
  const { accomodation } = state.group;
  return { accomodation };
};
export const getUsersQRTickets = (state) => {
  const { outboundTicket, returnTicket } = state.info;
  return { outboundTicket, returnTicket };
};

export const getUserInfoFormState = (state) => {
  const { info, showPassword, editUserInfo, error } = state;
  return { info, showPassword, editUserInfo, error };
};

export const getUserEventFromState = (state) => {
  const { event } = state;
  return { event };
};

export const getEventInfoButtons = (state) => {
  const { description, infoButton1, infoButton2, infoButton3 } = state.event;
  return { description, infoButton1, infoButton2, infoButton3 };
};

export const getUserCode = (state) => {
  const { userCode } = state.customer.info;
  return { userCode };
};
