import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import AlertMessage from 'components/layouts/AlertMessage';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

const DISABLE = 'disable';
const ENABLE = 'enable';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  submitButton: {
    margin: '2em',
  },
  label: {
    padding: '1em',
  },
}));

const DisableUsers = ({ users, disableUsers, enableUsers }) => {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.mass.disable);
  const [value, setValue] = useState(null);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    switch (value) {
      case DISABLE:
        disableUsers();
        break;
      case ENABLE:
        enableUsers();
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.content}>
      <h1>Disable users</h1>
      <AlertMessage />
      <h3>You have selected {users.length} users.</h3>
      {!loading && (
        <Fragment>
          <div>
            <FormControl component="fieldset">
              <FormLabel className={classes.label} component="legend">
                Select action to take
              </FormLabel>
              <RadioGroup name="action" value={value} onChange={handleChange}>
                <FormControlLabel
                  value={DISABLE}
                  control={<Radio />}
                  label="Disable"
                />
                <FormControlLabel
                  value={ENABLE}
                  control={<Radio />}
                  label="Enable"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <Button
              className={classes.submitButton}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Fragment>
      )}
      <div>{loading && <CircularProgress />}</div>
    </div>
  );
};

export default DisableUsers;
