import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlertMessage from 'components/layouts/AlertMessage';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';

import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircle';

import { DatePicker } from '@material-ui/pickers';

import {
  saveUserInfo,
  toggleShowPassword,
  updateUserFirstName,
  updateUserLastName,
  updateUserAddressCity,
  updateUserAddressPostCode,
  updateUserAddressStreet,
  updateUserBirthday,
  updateUserEmail,
  updateUserMarketing,
  updateUserPhone,
  updateUserPassword,
  updateUserGroup,
  updateUserGender,
  updateUserCountry,
  openPaymentDialog,
  updateUserOutboundTicket,
  updateUserReturnTicket,
  updateUserRoomType,
  updateUserRoomUsers,
  updateUserRoompartnerChosen,
  updateUserDisabled,
  updateUserAdditionalInformation,
  deleteUser,
  updatePaymentStatus,
} from 'redux/actions/user';

import { openRefund } from 'redux/actions/payment';

import {
  fetchGroups,
  selectOrgToFilterForGroupForm,
} from 'redux/actions/group';

import { getOrganizations } from 'redux/actions/organization';

import {
  getOrganizationGroupsCustomerForm,
  getFilterGroupsOrgCustomerForm,
} from 'redux/selectors/group';
import { getUserFromState } from 'redux/selectors/user';
import { getRole } from 'redux/selectors/auth';
import { getOrganizationsFromState } from 'redux/selectors/organization';
import PaymentDialog from 'components/views/user/PaymentDialog';
import RefundDialog from 'components/views/user/RefundDialog';
import DeleteUserDialog from 'components/views/user/DeleteUserDialog';
import CountrySelector from 'components/layouts/CountrySelector';
import API from 'services/api';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    maxWidth: 200,
  },
  formControlGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  formControlLabel: {
    marginLeft: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  formControlGender: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    minWidth: 200,
  },
  alertBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    margin: theme.spacing(2),
  },
  paymentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: theme.spacing(1),
  },
  paper: {
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  productTitle: {
    margin: theme.spacing(1),
    fontSize: 14,
  },
  productInfo: {
    fontSize: 12,
  },
  paymentIcon: {
    margin: theme.spacing(1),
  },
  refundButton: {
    margin: theme.spacing(1),
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RedCheckbox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const groupOptions = (groups) => {
  return groups.map((group) => (
    <MenuItem key={group._id} value={group._id}>
      {group.name}
    </MenuItem>
  ));
};

const organizationOptions = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

const createPartners = (users, user) => {
  const userOptions = [];

  let roomPartnerIds = user.room.users.map((item) => item.id);

  users = users.filter((obj) => !roomPartnerIds.includes(obj._id));
  users.forEach((user) =>
    userOptions.push({
      name: user.firstName + ' ' + user.lastName,
      id: user._id,
    })
  );

  return userOptions;
};

export const UserForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedGroupUsers, setSelectedGroupUsers] = useState([]);
  const [isDeleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);

  const { role } = useSelector((state) => getRole(state.auth));

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  const { filterGroupsOrgCustomerForm } = useSelector((state) =>
    getFilterGroupsOrgCustomerForm(state.group)
  );

  const { showPassword, user } = useSelector((state) =>
    getUserFromState(state.user)
  );

  useEffect(() => {
    const fetchSelectedGroupUsers = async () => {
      try {
        if (!user.group) {
          return;
        }

        setSelectedGroupUsers([]);

        const res = await API.get(`/api/admin/groups/${user.group}`);
        let users = res.data.users.filter((obj) => !obj.disabled);
        users.sort(function (a, b) {
          var nameA = a.firstName.toUpperCase() + a.lastName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.firstName.toUpperCase() + b.lastName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        setSelectedGroupUsers(users);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSelectedGroupUsers();
  }, [user.group]);

  const changePaymentStatus = async (paymentId) => {
    dispatch(updatePaymentStatus(user, paymentId));
  };

  const { organizations } = useSelector((state) =>
    getOrganizationsFromState(state.organization)
  );

  const { groups } = useSelector((state) =>
    getOrganizationGroupsCustomerForm(state.group)
  );

  const handleClose = () => {
    setDeleteUserDialogOpen(false);
  };

  const handleUserDelete = () => {
    dispatch(deleteUser(user._id));
  };

  return (
    <div>
      {user && (
        <Fragment>
          <h2>Edit {user.firstName + ' ' + user.lastName} info</h2>
          <form className={classes.form} noValidate>
            <div className={classes.alertBox}>
              <AlertMessage />
            </div>
            <div className={classes.formControlGroup}>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  labelPlacement="top"
                  control={
                    <RedCheckbox
                      checked={user.disabled}
                      onChange={(e) => {
                        dispatch(updateUserDisabled(e.target.checked));
                      }}
                      name="user disabled"
                    />
                  }
                  label="User disabled"
                />
              </FormControl>
            </div>
            <div className={classes.formControlGroup}>
              <FormControl className={classes.formControl}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="firstName"
                  label="First name"
                  type="name"
                  id="firstName"
                  value={user.firstName}
                  onChange={(e) => {
                    dispatch(updateUserFirstName(e.target.value));
                  }}
                  autoComplete="first name"
                />
              </FormControl>{' '}
              <FormControl className={classes.formControl}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="lastName"
                  label="Last name"
                  type="name"
                  id="lastName"
                  value={user.lastName}
                  onChange={(e) => {
                    dispatch(updateUserLastName(e.target.value));
                  }}
                  autoComplete="last name"
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={user.email}
                  onChange={(e) => {
                    dispatch(updateUserEmail(e.target.value));
                  }}
                  autoComplete="email"
                />
              </FormControl>
            </div>
            <div className={classes.formControlGroup}>
              <FormControl className={classes.formControl}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="phone"
                  label="Phone"
                  type="phone"
                  id="phone"
                  value={user.phone}
                  onChange={(e) => {
                    dispatch(updateUserPhone(e.target.value));
                  }}
                  autoComplete="phone"
                />
              </FormControl>
              <FormControl className={classes.formControlGender}>
                <TextField
                  label="Gender"
                  id="gender selection"
                  select
                  value={user.gender}
                  onChange={({ target: { value } }) =>
                    dispatch(updateUserGender(value))
                  }
                >
                  <MenuItem key="female" value={'F'}>
                    Female
                  </MenuItem>
                  <MenuItem key="male" value={'M'}>
                    Male
                  </MenuItem>
                </TextField>
              </FormControl>
              <FormControl className={classes.formControlGender}>
                <DatePicker
                  disableFuture
                  openTo="year"
                  format="DD/MM/yyyy"
                  label="Date of birth"
                  views={['year', 'month', 'date']}
                  id="birthday"
                  value={user.birthday}
                  onChange={(date) => {
                    dispatch(updateUserBirthday(date));
                  }}
                />
              </FormControl>
            </div>
            <div className={classes.formControlGroup}>
              <FormControl className={classes.formControl}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="street"
                  label="Street"
                  type="street"
                  id="street"
                  value={user.address.street}
                  onChange={(e) => {
                    dispatch(updateUserAddressStreet(e.target.value));
                  }}
                  autoComplete="street"
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="city"
                  name="city"
                  label="City"
                  value={user.address.city}
                  onChange={(e) => {
                    dispatch(updateUserAddressCity(e.target.value));
                  }}
                  autoComplete="city"
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="postCode"
                  name="postCode"
                  label="Post code"
                  value={user.address.postcode}
                  onChange={(e) => {
                    dispatch(updateUserAddressPostCode(e.target.value));
                  }}
                  autoComplete="postCode"
                />
              </FormControl>
            </div>
            <div className={classes.formControlGroup}>
              <FormControl className={classes.formControl}>
                <CountrySelector
                  setCountry={updateUserCountry}
                  currentValue={user.country}
                  label={'Country'}
                />
              </FormControl>
            </div>
            <div className={classes.formControlGroup}>
              <FormControl className={classes.formControl}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="outbound ticket"
                  label="Outbound ticket QR code"
                  type="outbound ticket"
                  id="outbound ticket"
                  value={user.outboundTicket}
                  onChange={(e) => {
                    dispatch(updateUserOutboundTicket(e.target.value));
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="return ticket"
                  label="Return ticket QR code"
                  type="return ticket"
                  id="return ticket"
                  value={user.returnTicket}
                  onChange={(e) => {
                    dispatch(updateUserReturnTicket(e.target.value));
                  }}
                />
              </FormControl>
            </div>
            <div
              className={classes.formControlGroup}
              key={filterGroupsOrgCustomerForm}
            >
              {role === 'root' && organizations && (
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Filter org"
                    id="organization selection"
                    select
                    value={
                      filterGroupsOrgCustomerForm
                        ? filterGroupsOrgCustomerForm
                        : ''
                    }
                    onChange={({ target: { value } }) =>
                      dispatch(selectOrgToFilterForGroupForm(value))
                    }
                  >
                    <MenuItem key="all" value={'all'}>
                      All
                    </MenuItem>
                    {organizationOptions(organizations)}
                  </TextField>
                </FormControl>
              )}
              {groups && (
                <FormControl className={classes.formControl} key={groups}>
                  <TextField
                    label="Group"
                    id="group"
                    select
                    required
                    value={user.group ? user.group : ''}
                    onChange={(e) => {
                      dispatch(updateUserGroup(e.target.value));
                    }}
                  >
                    {groupOptions(groups)}
                  </TextField>
                </FormControl>
              )}
            </div>
            <div className={classes.formControlGroup}>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  labelPlacement="top"
                  control={
                    <GreenCheckbox
                      checked={user.roomPartnerChosen}
                      onChange={(e) => {
                        dispatch(updateUserRoompartnerChosen(e.target.checked));
                      }}
                      name="Roompartner selection"
                    />
                  }
                  label="Room partner selection done"
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Room type"
                  id="room type selection"
                  select
                  value={user.room.roomType}
                  onChange={({ target: { value } }) => {
                    dispatch(updateUserRoomType(value));
                  }}
                >
                  <MenuItem key="female" value={'F'}>
                    Female partner
                  </MenuItem>
                  <MenuItem key="male" value={'M'}>
                    Male partner
                  </MenuItem>
                  <MenuItem key="1h" value={'1h'}>
                    One person room
                  </MenuItem>
                  <MenuItem key="2h" value={'2h'}>
                    Two person room
                  </MenuItem>
                  <MenuItem key="3h" value={'3h'}>
                    Three person room
                  </MenuItem>
                </TextField>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={createPartners(
                    selectedGroupUsers.filter((obj) => obj._id !== user._id),
                    user
                  )}
                  getOptionLabel={(option) => option.name}
                  value={user.room.users}
                  onChange={(event, value, reason) =>
                    dispatch(updateUserRoomUsers(value))
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.doubleRootWidth}
                      variant="standard"
                      label="Room partners"
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className={classes.formControlGroup}>
              <FormControl className={classes.formControl}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={user.password}
                  onChange={(e) => {
                    dispatch(updateUserPassword(e.target.value));
                  }}
                  autoComplete="current-password"
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => dispatch(toggleShowPassword())}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <div className={classes.formControlGroup}>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Additional information"
                  id="additionalInfo"
                  margin="normal"
                  fullWidth
                  value={
                    user.additionalInformation ? user.additionalInformation : ''
                  }
                  onChange={(e) => {
                    dispatch(updateUserAdditionalInformation(e.target.value));
                  }}
                ></TextField>
              </FormControl>
            </div>
            <div className={classes.formControlGroup}>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  control={
                    <GreenCheckbox
                      checked={user.marketing}
                      onChange={(e) => {
                        dispatch(updateUserMarketing(e.target.checked));
                      }}
                      name="marketing"
                    />
                  }
                  label="Allow marketing"
                />
              </FormControl>
            </div>
            {user.eventPayments.length > 0 && (
              <div>
                <Typography
                  component="h5"
                  variant="h5"
                  color="inherit"
                  align="center"
                  noWrap
                >
                  Event payments
                </Typography>
                <div className={classes.paymentContainer}>
                  {user.eventPayments.map((payment) => (
                    <Paper
                      key={payment._id}
                      elevation={3}
                      className={classes.paper}
                    >
                      <Typography
                        className={classes.productTitle}
                        color="textPrimary"
                        gutterBottom
                        variant="h2"
                      >
                        <Button
                          onClick={() => dispatch(openPaymentDialog(payment))}
                          color="primary"
                        >
                          {payment.products[0].name}
                        </Button>
                      </Typography>
                      <Typography
                        className={classes.productInfo}
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                      >
                        Price:{' '}
                        {payment.products
                          .map((product) => {
                            let serviceFee = 0;
                            if (product.serviceFee) {
                              serviceFee = product.serviceFee;
                            }
                            return product.price + serviceFee;
                          })
                          .reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue
                          )}{' '}
                        €
                      </Typography>
                      {payment.discountCode && (
                        <Typography
                          className={classes.productInfo}
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Discount code: {payment.discountCode.name}
                        </Typography>
                      )}
                      {payment.refundAmount && (
                        <Typography
                          className={classes.productInfo}
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                          style={{ color: red[500] }}
                        >
                          Refunded: {payment.refundAmount} €
                        </Typography>
                      )}
                      {{
                        paying: (
                          <Tooltip title="Payment in process">
                            <HourglassEmptyIcon
                              className={classes.paymentIcon}
                              style={{ color: yellow[500] }}
                            />
                          </Tooltip>
                        ),
                        waiting: (
                          <Tooltip title="Payment in process">
                            <HourglassEmptyIcon
                              className={classes.paymentIcon}
                              style={{ color: yellow[500] }}
                            />
                          </Tooltip>
                        ),
                        new: (
                          <Fragment>
                            <BlockIcon
                              className={classes.paymentIcon}
                              style={{ color: red[500] }}
                            ></BlockIcon>
                            <Button
                              size="small"
                              color="secondary"
                              variant="contained"
                              onClick={() => changePaymentStatus(payment._id)}
                            >
                              Mark as paid
                            </Button>
                          </Fragment>
                        ),
                        confirmed: (
                          <Fragment>
                            <CheckCircleOutlineIcon
                              className={classes.paymentIcon}
                              style={{ color: green[500] }}
                            />
                            <Button
                              size="small"
                              variant="outlined"
                              color="secondary"
                              className={classes.refundButton}
                              onClick={() => dispatch(openRefund(payment))}
                            >
                              Refund
                            </Button>
                            <Button
                              size="small"
                              color="secondary"
                              variant="contained"
                              onClick={() => changePaymentStatus(payment._id)}
                            >
                              Mark as unpaid
                            </Button>
                          </Fragment>
                        ),
                      }[payment.status] ?? (
                        <BlockIcon
                          className={classes.paymentIcon}
                          style={{ color: red[500] }}
                        ></BlockIcon>
                      )}
                    </Paper>
                  ))}
                </div>
              </div>
            )}
            {user.additionalServices.length > 0 && (
              <div>
                <Typography
                  component="h5"
                  variant="h5"
                  color="inherit"
                  align="center"
                  noWrap
                >
                  Optional products
                </Typography>
                <div className={classes.paymentContainer}>
                  {user.additionalServices.map((payment) => (
                    <Paper
                      key={payment._id}
                      elevation={3}
                      className={classes.paper}
                    >
                      <Typography
                        className={classes.productTitle}
                        color="textPrimary"
                        gutterBottom
                        variant="h2"
                      >
                        <Button
                          onClick={() => dispatch(openPaymentDialog(payment))}
                          color="primary"
                        >
                          {payment.products[0].name}
                        </Button>
                      </Typography>
                      <Typography
                        className={classes.productInfo}
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                      >
                        Price:{' '}
                        {payment.products
                          .map((product) => {
                            let serviceFee = 0;
                            if (product.serviceFee) {
                              serviceFee = product.serviceFee;
                            }
                            return product.price + serviceFee;
                          })
                          .reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue
                          )}{' '}
                        €
                      </Typography>
                      {payment.discountCode && (
                        <Typography
                          className={classes.productInfo}
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Discount code: {payment.discountCode.name}
                        </Typography>
                      )}
                      {payment.refundAmount && (
                        <Typography
                          className={classes.productInfo}
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                          style={{ color: red[500] }}
                        >
                          Refunded: {payment.refundAmount} €
                        </Typography>
                      )}
                      {{
                        paying: (
                          <Tooltip title="Payment in process">
                            <HourglassEmptyIcon
                              className={classes.paymentIcon}
                              style={{ color: yellow[500] }}
                            />
                          </Tooltip>
                        ),
                        waiting: (
                          <Tooltip title="Payment in process">
                            <HourglassEmptyIcon
                              className={classes.paymentIcon}
                              style={{ color: yellow[500] }}
                            />
                          </Tooltip>
                        ),
                        new: (
                          <Fragment>
                            <BlockIcon
                              className={classes.paymentIcon}
                              style={{ color: red[500] }}
                            ></BlockIcon>
                            <Button
                              size="small"
                              color="secondary"
                              variant="contained"
                              onClick={() => changePaymentStatus(payment._id)}
                            >
                              Mark as paid
                            </Button>
                          </Fragment>
                        ),
                        confirmed: (
                          <Fragment>
                            <CheckCircleOutlineIcon
                              className={classes.paymentIcon}
                              style={{ color: green[500] }}
                            />
                            <Button
                              size="small"
                              variant="outlined"
                              color="secondary"
                              className={classes.refundButton}
                              onClick={() => dispatch(openRefund(payment))}
                            >
                              Refund
                            </Button>
                            <Button
                              size="small"
                              color="secondary"
                              variant="contained"
                              onClick={() => changePaymentStatus(payment._id)}
                            >
                              Mark as unpaid
                            </Button>
                          </Fragment>
                        ),
                      }[payment.status] ?? (
                        <BlockIcon
                          className={classes.paymentIcon}
                          style={{ color: red[500] }}
                        ></BlockIcon>
                      )}
                    </Paper>
                  ))}
                </div>
              </div>
            )}
            <div className={classes.formControlGroup}>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(saveUserInfo(user));
                  }}
                >
                  Save
                </Button>
              </div>
              {role === 'root' && (
                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setDeleteUserDialogOpen(true);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          </form>
        </Fragment>
      )}
      <PaymentDialog />
      <RefundDialog />
      <DeleteUserDialog
        deleteUser={handleUserDelete}
        isOpen={isDeleteUserDialogOpen}
        handleClose={handleClose}
      />
    </div>
  );
};

export default UserForm;
