import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Moment from 'react-moment';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { getOrganizations } from 'redux/actions/organization';
import {
  fetchAccomodations,
  selectExistingAccomodation,
  selectNewAccomodation,
  selectOrgToFilter,
} from 'redux/actions/accomodation';

import {
  getOrganizationAccomodations,
  getFilterAccomodationsOrg,
} from 'redux/selectors/accomodation';

import { getOrganizationsFromState } from 'redux/selectors/organization';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  accomodationInfo: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  cardActionCenter: {
    justifyContent: 'center',
  },
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: 120,
  },
  fab: {
    background: theme.palette.success.main,
    color: 'white',
    position: 'fixed',
    top: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

const getMenuItems = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

export const Accomodations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();

  const { role } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchAccomodations());
  }, [dispatch]);

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  const { organizations } = useSelector((state) =>
    getOrganizationsFromState(state.organization)
  );

  const { filterAccomodationsOrg } = useSelector((state) =>
    getFilterAccomodationsOrg(state.accomodation)
  );

  const { accomodations } = useSelector((state) =>
    getOrganizationAccomodations(state.accomodation)
  );

  const handleEditDetailsClick = (accomodationObject) => {
    dispatch(selectExistingAccomodation(accomodationObject));
    history.push('/accomodation-form');
  };

  return (
    <div className={classes.root}>
      <h2> Accomodations </h2>

      {role === 'root' && organizations && (
        <Fragment>
          <FormControl
            className={classes.formControl}
            key={`amountOfSelections_${organizations.length}`}
          >
            <InputLabel id="organizationSelectionLabel">Filter org</InputLabel>
            <Select
              labelId="organizationSelectionLabel"
              id="organizationSelection"
              defaultValue=""
              onChange={({ target: { value } }) =>
                dispatch(selectOrgToFilter(value))
              }
            >
              <MenuItem key="all" value={'all'}>
                All
              </MenuItem>
              {getMenuItems(organizations)}
            </Select>
          </FormControl>
        </Fragment>
      )}

      <Fab
        className={classes.fab}
        component={NavLink}
        to="/accomodation-form"
        onClick={() => dispatch(selectNewAccomodation())}
        aria-label="add"
        variant="extended"
      >
        <AddIcon />
        New
      </Fab>
      <div key={filterAccomodationsOrg}>
        <Grid container justifyContent="center" spacing={3}>
          {accomodations && accomodations.length > 0 ? (
            accomodations.map((accomodationObject) => (
              <Fragment key={accomodationObject._id}>
                <Grid item>
                  <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                        variant="h2"
                      >
                        {accomodationObject.name}
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                        variant="h3"
                      >
                        {' '}
                        Date:
                        <Moment format="DD/MM/YYYY">
                          {accomodationObject.date}
                        </Moment>
                      </Typography>
                      <Typography className={classes.accomodationInfo}>
                        One person rooms:{' '}
                        {(accomodationObject?.onePersonRoom?.reserved ?? 0) +
                          ' / ' +
                          (accomodationObject?.onePersonRoom?.capacity ?? 0)}
                      </Typography>
                      <Typography className={classes.accomodationInfo}>
                        Two person rooms:{' '}
                        {(accomodationObject?.twoPersonRoom?.reserved ?? 0) +
                          ' / ' +
                          (accomodationObject?.twoPersonRoom?.capacity ?? 0)}
                      </Typography>
                      <Typography className={classes.accomodationInfo}>
                        Three person rooms:{' '}
                        {(accomodationObject?.threePersonRoom?.reserved ?? 0) +
                          ' / ' +
                          (accomodationObject?.threePersonRoom?.capacity ?? 0)}
                      </Typography>
                      <Typography className={classes.accomodationInfo}>
                        Groups:{' '}
                        {accomodationObject.groups
                          ? accomodationObject.groups.length
                          : '0'}
                      </Typography>
                    </CardContent>
                    <CardActions className={classes.cardActionCenter}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() =>
                          handleEditDetailsClick(accomodationObject)
                        }
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Fragment>
            ))
          ) : (
            <Fragment>
              <h3>Please start by adding a accomodation</h3>
            </Fragment>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Accomodations;
