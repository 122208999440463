import {
  Button,
  CircularProgress,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import AlertMessage from 'components/layouts/AlertMessage';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroups } from 'redux/actions/group';
import { getOrganizationGroups } from 'redux/selectors/group';
import { changeGroups } from 'redux/actions/massEdit';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  formControl: {
    minWidth: '200px',
  },
  submitButton: {
    margin: '2em',
  },
}));

const ChangeUser = ({ users }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.mass.change);
  const [group, setGroup] = useState('');

  const { groups } = useSelector((state) => getOrganizationGroups(state.group));

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  const getMenuItems = (groups) =>
    groups.map((group) => (
      <MenuItem key={group._id} value={group._id}>
        {group.name}
      </MenuItem>
    ));

  const handleSubmit = () => {
    const userIds = users.map((user) => user._id);
    dispatch(changeGroups(group, userIds));
  };

  return (
    <div className={classes.content}>
      <h1>Change group for selected users</h1>
      <AlertMessage />
      <h3>You have selected {users.length} users.</h3>
      {!loading && (
        <Fragment>
          <div>
            <FormControl className={classes.formControl}>
              Select group
              <Select
                labelId="groupSelectionId"
                id="groupSelection"
                defaultValue=""
                value={group}
                onChange={({ target: { value } }) => setGroup(value)}
              >
                {getMenuItems(groups)}
              </Select>
            </FormControl>
            <div>
              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Change group
              </Button>
            </div>
          </div>
        </Fragment>
      )}
      <div>{loading && <CircularProgress />}</div>
    </div>
  );
};

export default ChangeUser;
