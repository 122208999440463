import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { userAddsDiscountCode } from '../../../../redux/actions/customer';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      background: '#AD00FF',
    },
    marginLeft: '7%',
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    margin: '8px 0px',
  },
  formInput: {
    background: 'rgba(255, 255, 255, 0.1)',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '119%',
    /* or 74px */
    color: '#FFFFFF',
    opacity: 0.5,
  },
  subtitle: {
    position: 'relative',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '120%',
    zIndex: 1000,
    color: '#FFFFFF',
    marginBottom: '5px',
  },
  inputText: {
    color: '#FFFFFF',
    opacity: 0.95,
  },
  inputLabel: {
    color: '#FFFFFF',
    opacity: 0.95,
    '&.focused': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
    '&.shrink': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
  },
}));

const DiscountCodeSubmit = ({ userId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [discountCode, setDiscountCode] = useState('');

  const handleAddingDiscountCode = () => {
    dispatch(userAddsDiscountCode({ userId, code: discountCode }));
  };

  return (
    <div>
      <Typography className={classes.subtitle}>Syötä alennuskoodi</Typography>
      <TextField
        id="discount-code"
        name="discount-code"
        label="Alennuskoodi"
        className={classes.formInput}
        InputProps={{
          className: classes.inputText,
        }}
        InputLabelProps={{
          classes: {
            root: classes.inputLabel,
            focused: 'focused',
            shrink: 'shrink',
          },
        }}
        value={discountCode}
        onChange={(e) => setDiscountCode(e.target.value)}
      />
      <div>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleAddingDiscountCode}
        >
          Tallenna
        </Button>
      </div>
    </div>
  );
};

export default DiscountCodeSubmit;
