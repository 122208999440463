import React, { Fragment } from 'react';
import {
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Payment from './Payment';
import { PaymentStatus } from './PaymentStatus';
import { Price } from './Price';
import { Title } from './Title';
import { RemoveDiscountCodeButton } from './RemoveDiscountCodeButton';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paymentContainer: {
    width: '100%',
    background: '#FFFFFF',
    /* Drop */
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    zIndex: 1000,
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  paymentItem: {
    background: '#F9F9F9',
    width: '77%',
    marginLeft: '12%',
    margnRight: '12%',
    marginTop: '9%',
  },
  paymentItemMobile: {
    background: '#F9F9F9',
    width: '83%',
    marginLeft: '10%',
    margnRight: '7%',
    marginTop: '9%',
  },
  nameText: {
    marginTop: '2%',
    marginLeft: '10%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '31px',
    color: '#20D2B7',
  },
  nameTextMobile: {
    marginTop: '2%',
    marginLeft: '6%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '31px',
    color: '#20D2B7',
  },
  discountCodeName: {
    marginTop: '2%',
    marginLeft: '10%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#828282',
  },
  discountCodeNameMobile: {
    marginTop: '2%',
    marginLeft: '6%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#828282',
  },
  description: {
    marginTop: '2%',
    marginLeft: '10%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '23px',
    color: '#828282',
  },
  descriptionMobile: {
    marginTop: '2%',
    marginLeft: '6%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '16px',
    color: '#828282',
  },
  paymentButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    marginLeft: '7%',
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    background: '#20D2B7',
    flex: 'none',
    margin: '8px 0px',
  },
  infoButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    marginLeft: '7%',
    padding: '6px 12px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    background: '#20D2B7',
    flex: 'none',
    margin: '8px 0px',
  },
  payingText: {
    marginBottom: '20px',
  },
}));

export const Payments = ({ additional = false, eventPayments }) => {
  const classes = useStyles();
  const mobileScreen = useMediaQuery('(max-width:990px)');

  const canBeSold = (product) => {
    if (product.maximumAmount && product.maximumAmount !== 0 && product.sold) {
      return product.sold < product.maximumAmount;
    }
    return true;
  };

  return (
    <Fragment>
      {eventPayments && (
        <div className={classes.paymentContainer}>
          <Title additional={additional} mobileScreen={mobileScreen} />

          {eventPayments.map((payment) => (
            <Paper
              key={payment._id}
              elevation={3}
              className={
                mobileScreen ? classes.paymentItemMobile : classes.paymentItem
              }
            >
              <PaymentStatus
                additional={additional}
                mobileScreen={mobileScreen}
                payment={payment}
              />

              <Typography
                className={
                  mobileScreen ? classes.nameTextMobile : classes.nameText
                }
              >
                {payment.products[0].name}
              </Typography>

              {additional && (
                <Typography
                  className={
                    mobileScreen
                      ? classes.descriptionMobile
                      : classes.description
                  }
                >
                  {payment.products[0].description}
                </Typography>
              )}

              {additional && payment.products[0].infoLink && (
                <Button
                  href={payment.products[0].infoLink}
                  className={classes.infoButton}
                >
                  Lisätietoja
                </Button>
              )}

              {payment.discountCode && (
                <Typography
                  className={
                    mobileScreen
                      ? classes.discountCodeNameMobile
                      : classes.discountCodeName
                  }
                >
                  Alennuskoodi: {payment.discountCode.code}{' '}
                  {payment.status === 'new' && (
                    <RemoveDiscountCodeButton id={payment._id} />
                  )}
                </Typography>
              )}

              <Price payment={payment} />

              {payment.status === 'paying' && (
                <Typography
                  className={`${classes.payingText} ${
                    mobileScreen
                      ? classes.description
                      : classes.descriptionMobile
                  }`}
                >
                  <b>Maksu kesken</b> <br />
                  Päivitä sivu nähdäksesi maksun tilan. Maksu poistuu
                  automaattisesti 2 tunnin kuluttua, jos se jumittuu.
                </Typography>
              )}

              {payment.status === 'new' && canBeSold(payment.products[0]) && (
                <Payment payment={payment} />
              )}
              {!canBeSold(payment.products[0]) && (
                <Button className={classes.paymentButton} disabled>
                  Loppuunmyyty
                </Button>
              )}
            </Paper>
          ))}
        </div>
      )}
    </Fragment>
  );
};
