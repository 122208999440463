import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

export const SEND_PAYMENT_SUCCESS_REQUESTED = 'SEND_PAYMENT_SUCCESS_REQUESTED';
export const SEND_PAYMENT_SUCCESS_RESPONSE = 'SEND_PAYMENT_SUCCESS_RESPONSE';
export const SEND_PAYMENT_SUCCESS_ERROR = 'SEND_PAYMENT_SUCCESS_ERROR';

export const FETCH_PAYMENTS_REQUESTED = 'FETCH_PAYMENTS_REQUESTED';
export const FETCH_PAYMENTS_RESPONSE = 'FETCH_PAYMENTS_RESPONSE';
export const FETCH_PAYMENTS_ERROR = 'FETCH_PAYMENTS_ERROR';

export const CREATE_REFUND_REQUESTED = 'CREATE_REFUND_REQUESTED';
export const CREATE_REFUND_RESPONSE = 'CREATE_REFUND_RESPONSE';
export const CREATE_REFUND_ERROR = 'CREATE_REFUND_ERROR';

export const OPEN_REFUND = 'OPEN_REFUND';
export const CLOSE_REFUND = 'CLOSE_REFUND';
export const UPDATE_REFUND_AMOUNT = 'UPDATE_REFUND_AMOUNT';

export const fetchEventPayments = () => async (dispatch) => {
  dispatch({ type: FETCH_PAYMENTS_REQUESTED });
  try {
    const res = await API.get('/api/payments/event-payments');
    dispatch({ type: FETCH_PAYMENTS_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch({
      type: FETCH_PAYMENTS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const sendPaymentSuccess =
  (paymentId, transactionId) => async (dispatch) => {
    dispatch({ type: SEND_PAYMENT_SUCCESS_REQUESTED });
    try {
      const res = await API.get(
        `/api/payments/success/${paymentId}?transactionId=${transactionId}`
      );

      dispatch({ type: SEND_PAYMENT_SUCCESS_RESPONSE, payload: res.data });
    } catch (error) {
      dispatch({
        type: SEND_PAYMENT_SUCCESS_ERROR,
        payload: {
          msg: error.response.statusText,
          status: error.response.status,
        },
      });
    }
  };

export const createRefund =
  (paymentId, amount, refundFromOtherChannel) => async (dispatch) => {
    dispatch({ type: CREATE_REFUND_REQUESTED });
    try {
      const res = await API.post(`/api/admin/payments/refund/${paymentId}`, {
        amount: amount,
        refundFromOtherChannel: refundFromOtherChannel,
      });

      dispatch(setAlert('Refund succesfully created', 'success'));
      dispatch({ type: CLOSE_REFUND });
      dispatch({ type: CREATE_REFUND_RESPONSE, payload: res.data });
    } catch (error) {
      console.log(error);
      if (error.response) {
        const errors = error.response.data.errors;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        }
      } else {
        dispatch(setAlert('Error while creating refund', 'error'));
      }

      dispatch({
        type: CREATE_REFUND_ERROR,
        payload: {
          msg: error.response.statusText,
          status: error.response.status,
        },
      });
    }
  };

export const openRefund = (paymentId) => async (dispatch) => {
  dispatch({ type: OPEN_REFUND, payload: paymentId });
};

export const closeRefund = () => async (dispatch) => {
  dispatch({ type: CLOSE_REFUND });
};

export const updateRefundAmount = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_REFUND_AMOUNT, payload: payload });
};
