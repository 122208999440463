import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { saveAs } from 'file-saver';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import API from 'services/api';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import CustomSearchRender from './SearchBar';

import EditIcon from '@material-ui/icons/Edit';

import MUIDataTable from 'mui-datatables';

import { getOrganizations } from 'redux/actions/organization';
import {
  selectExistingUser,
  toggleFilterByRoom,
  fetchOrganizationsCustomers,
} from 'redux/actions/user';
import {
  setRowsPerPage,
  fetchOrganizationsCustomersPagination,
  saveSelectedOrgId,
  savePageToState,
  resetOrganizationPaginatedCustomers,
  fetchOrganizationsFilters,
  saveActiveFilters,
  resetPaginationData,
} from 'redux/actions/paginatedUsers';
import {
  getFilterByRoomFromState,
  getFetchingUsersFromState,
} from 'redux/selectors/user';
import {
  getPaginatedUsersFromState,
  getValuesFromState,
} from 'redux/selectors/paginatedUsers';
import { selectOrgToFilter } from 'redux/actions/group';
import { getOrganizationsFromState } from 'redux/selectors/organization';
import { getRoleAndOrganization } from 'redux/selectors/auth';
import CustomToolbarSelect from './CustomToolbarSelect';
import MassEditDialog from './MassEditDialog';
import { combineReqData, combineCsvDownloadData } from './CombineReqData';
import { TextField } from '@material-ui/core';
import FullDataTable from './FullDataTable';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    heigth: '100%',
    width: '100%',
  },
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: 120,
  },
}));

const getMenuItems = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

const RedCheckbox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const flatFilterNameValues = (arr) => {
  let nameArr = arr.map(({ name }) => [name]);
  return [].concat(...nameArr);
};

const combineBooleanObj = (name, value, type) => {
  let obj = {
    name,
    value,
    type,
  };
  return obj;
};

export const Users = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showFullData, setShowFullData] = useState(false);
  const [columns, setColumns] = useState(null);

  const [fullDataUsers, setFullDataUsers] = useState([]);
  const [paymentFilters, setPaymentFilters] = useState([]);

  useEffect(() => {
    return () => {
      dispatch(resetOrganizationPaginatedCustomers());
    };
  }, [dispatch]);

  const { role, organization } = useSelector((state) =>
    getRoleAndOrganization(state.auth)
  );
  const {
    rowsPerPage,
    page,
    count,
    selectedOrg,
    filters,
    activeFilters,
    searchBarValue,
    roomFilters,
  } = useSelector((state) => getValuesFromState(state.paginatedUsers));

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);
  useEffect(() => {
    if (role !== 'root' && organization) {
      dispatch(fetchOrganizationsFilters(organization._id));
      dispatch(saveSelectedOrgId(organization._id));
      dispatch(
        fetchOrganizationsCustomersPagination(
          combineReqData(
            false,
            false,
            rowsPerPage,
            organization._id,
            null,
            null
          )
        )
      );
    }
  }, [dispatch, role, organization, rowsPerPage]);

  const { organizations } = useSelector((state) =>
    getOrganizationsFromState(state.organization)
  );
  const { users } = useSelector((state) =>
    getPaginatedUsersFromState(state.paginatedUsers)
  );
  const { fetchingUsers } = useSelector((state) =>
    getFetchingUsersFromState(state.user)
  );

  let groupFilters = filters.groups ? filters.groups : [];
  let eventFilters = filters.events ? filters.events : [];
  let ferryFilters = filters.ferries ? filters.ferries : [];
  let discountFilters = filters.discountCodes ? filters.discountCodes : [];
  let accomodationFilters = filters.accomodations ? filters.accomodations : [];
  let productFilters = filters.products ? filters.products : [];
  let mandatoryProductFilters = filters.mandatoryProducts
    ? filters.mandatoryProducts
    : [];

  let paymentFilterOptions = [
    ...mandatoryProductFilters.map((val) => {
      return { name: `Not paid ${val.name}`, _id: val._id };
    }),
    ...mandatoryProductFilters.map((val) => {
      return { name: `Paid ${val.name}`, _id: val._id };
    }),
  ];

  const { filterByRoom } = useSelector((state) =>
    getFilterByRoomFromState(state.user)
  );

  const handleEditUserClick = (user) => {
    dispatch(selectExistingUser(user));
    history.push('/customer-form');
  };

  const getTableData = (users) => {
    users.forEach((user) => {
      if (user !== '') {
        user.paidProducts = [];
        user.additionalProducts = [];
        const discountCodeSet = new Set();
        user.eventPayments.forEach((payment) => {
          if (payment.status === 'confirmed' || payment.status === 'accepted') {
            user.paidProducts.push(payment.products[0].name);
          }
          if (payment.discountCode) {
            discountCodeSet.add(payment.discountCode.name);
          }
        });

        user.additionalServices.forEach((payment) => {
          if (payment.status === 'confirmed' || payment.status === 'accepted') {
            user.additionalProducts.push(payment.products[0].name);
          }
          if (payment.discountCode) {
            discountCodeSet.add(payment.discountCode.name);
          }
        });

        user.discountCodes = [...discountCodeSet];
      }
    });
    return users;
  };

  const downloadCsv = async (columns) => {
    // Create a JSON with current organization, activefilters and chosen columns. Send that to the backend which creates a desired CSV file

    const json = combineCsvDownloadData(
      selectedOrg,
      activeFilters,
      columns,
      searchBarValue
    );

    const res = await API.get(
      `/api/admin/users/download/${JSON.stringify(json)}`
    );

    let file = new File([res.data], 'customers.csv', {
      type: 'text/csv;charset=utf-8',
    });
    saveAs(file);
  };

  const options = {
    enableNestedDataAccess: '.',
    pagination: true,
    serverSide: true,
    count: count,
    rowsPerPage: rowsPerPage,
    page,
    confirmFilters: true,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      /* Here we trigger action which downloads the csv from server. 
      Return false so that mui-datatable does not trigger the own csv creation */
      downloadCsv(columns);
      return false;
    },
    customToolbarSelect: (selectedRows) => (
      <CustomToolbarSelect selectedRows={selectedRows} />
    ),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setColumns(tableState.columns);
          if (page > tableState.page) {
            let lastId = users[0]._id;
            dispatch(
              fetchOrganizationsCustomersPagination(
                combineReqData(
                  false,
                  true,
                  rowsPerPage,
                  selectedOrg,
                  lastId,
                  activeFilters,
                  searchBarValue
                )
              )
            );
          }
          if (page < tableState.page) {
            let lastId = users[users.length - 1]._id;
            dispatch(
              fetchOrganizationsCustomersPagination(
                combineReqData(
                  true,
                  false,
                  rowsPerPage,
                  selectedOrg,
                  lastId,
                  activeFilters,
                  searchBarValue
                )
              )
            );
          }
          dispatch(savePageToState(tableState.page));
          break;
        case 'changeRowsPerPage':
          setColumns(tableState.columns);
          dispatch(setRowsPerPage(tableState.rowsPerPage));
          dispatch(
            fetchOrganizationsCustomersPagination(
              combineReqData(
                false,
                false,
                tableState.rowsPerPage,
                selectedOrg,
                null,
                activeFilters,
                searchBarValue
              )
            )
          );
          break;
        default:
      }
    },

    // Calling the applyNewFilters parameter applies the selected filters to the table
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    // callback that gets executed when filters are confirmed
    onFilterConfirm: (filterList) => {},
    onFilterDialogOpen: () => {},
    onFilterDialogClose: () => {},
    onFilterChange: (column, filterList, type) => {},
    onFilterChipClose: (index, removedFilter, filterList) => {
      dispatch(saveActiveFilters(filterList));
      dispatch(
        fetchOrganizationsCustomersPagination(
          combineReqData(
            false,
            false,
            rowsPerPage,
            selectedOrg,
            null,
            filterList,
            searchBarValue
          )
        )
      );
    },
  };

  const handleFilterSubmit = (applyFilters) => {
    let filterList = applyFilters();
    dispatch(saveActiveFilters(filterList));
    dispatch(savePageToState(0));
    dispatch(
      fetchOrganizationsCustomersPagination(
        combineReqData(
          false,
          false,
          rowsPerPage,
          selectedOrg,
          null,
          filterList,
          searchBarValue
        )
      )
    );
  };

  const defaultColumns = [
    {
      name: 'firstName',
      label: 'First name',
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => value[0].value,
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <TextField
                  value={
                    filterList[index][0]?.value
                      ? filterList[index][0].value
                      : ''
                  }
                  onChange={(event) => {
                    filterList[index] = [
                      {
                        type: 'firstName',
                        value: event.target.value,
                      },
                    ];
                    onChange(filterList[index], index, column);
                  }}
                  label="First name"
                ></TextField>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'lastName',
      label: 'Last name',
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => value[0].value,
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <TextField
                  value={
                    filterList[index][0]?.value
                      ? filterList[index][0].value
                      : ''
                  }
                  onChange={(event) => {
                    filterList[index] = [
                      {
                        type: 'lastName',
                        value: event.target.value,
                      },
                    ];
                    onChange(filterList[index], index, column);
                  }}
                  label="Last name"
                ></TextField>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'userCode',
      label: 'Code',
      options: {
        filter: false,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'birthday',
      label: 'Birthday',
      options: {
        filter: false,
        filterType: 'textField',
        sort: true,
        display: false,
        customBodyRenderLite: (dataIndex) => {
          return moment(users[dataIndex].birthday).format('DD.MM.yyyy');
        },
      },
    },
    {
      name: 'gender',
      label: 'Gender',
      options: {
        filter: false,
        filterType: 'checkbox',
        sort: true,
        display: false,
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: false,
        filterType: 'textField',
        sort: true,
        display: false,
      },
    },
    {
      name: 'address.street',
      label: 'Street',
      options: {
        filter: false,
        filterType: 'textField',
        sort: true,
        display: false,
      },
    },
    {
      name: 'address.postcode',
      label: 'Postal code',
      options: {
        filter: false,
        filterType: 'textField',
        sort: true,
        display: false,
      },
    },
    {
      name: 'address.city',
      label: 'City',
      options: {
        filter: false,
        filterType: 'textField',
        sort: true,
        display: false,
      },
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        filter: false,
        filterType: 'textField',
        sort: true,
        display: false,
      },
    },
    {
      name: 'marketing',
      label: 'Marketing',
      options: {
        filter: false,
        filterType: 'checkbox',
        filterOptions: { renderValue: (v) => (v ? 'Yes' : 'No') },
        customFilterListOptions: { render: (v) => (v ? 'Yes' : 'No') },
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormControlLabel
              value={users[dataIndex].marketing ? 'Yes' : 'No'}
              control={
                <Checkbox
                  color="primary"
                  checked={users[dataIndex].marketing}
                  value={users[dataIndex].marketing ? 'Yes' : 'No'}
                />
              }
            />
          );
        },
      },
    },
    {
      name: 'group.name',
      label: 'Group',
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value) return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = groupFilters.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Group</InputLabel>
                <Select
                  multiple
                  value={optionValues.filter(
                    (item) =>
                      filterList[index]
                        .map((obj) => obj._id)
                        .indexOf(item._id) !== -1
                  )}
                  renderValue={(selected) => {
                    return flatFilterNameValues(selected).join(', ');
                  }}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item.name} value={item}>
                      <Checkbox
                        color="primary"
                        checked={
                          filterList[index]
                            .map((obj) => obj._id)
                            .indexOf(item._id) !== -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'group.event.name',
      label: 'Event',
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value) return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = eventFilters.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Event</InputLabel>
                <Select
                  multiple
                  value={optionValues.filter(
                    (item) =>
                      filterList[index]
                        .map((obj) => obj._id)
                        .indexOf(item._id) !== -1
                  )}
                  renderValue={(selected) => {
                    return flatFilterNameValues(selected).join(', ');
                  }}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item.name} value={item}>
                      <Checkbox
                        color="primary"
                        checked={
                          filterList[index]
                            .map((obj) => obj._id)
                            .indexOf(item._id) !== -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'eventPayments',
      label: 'Event payments',
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value) return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = mandatoryProductFilters;
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">
                  Event payments
                </InputLabel>
                <Select
                  multiple
                  value={optionValues.filter(
                    (item) =>
                      filterList[index]
                        .map((obj) => obj._id)
                        .indexOf(item._id) !== -1
                  )}
                  renderValue={(selected) => {
                    return flatFilterNameValues(selected).join(', ');
                  }}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item.name} value={item}>
                      <Checkbox
                        color="primary"
                        checked={
                          filterList[index]
                            .map((obj) => obj._id)
                            .indexOf(item._id) !== -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
        customBodyRender: (value) => {
          if (value) {
            let payed = value.filter(
              (payment) =>
                payment.products[0].mandatory && payment.status === 'confirmed'
            ).length;

            let total = value.filter(
              (payment) => payment.products[0].mandatory
            ).length;

            return payed.toString() + '/' + total.toString();
          }
        },
      },
    },
    {
      name: 'paidProducts',
      label: 'Paid products',
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customBodyRender: (value) => {
          if (value) return value.map((val) => `${val}, `);
        },
        customFilterListOptions: {
          render: (value) => {
            return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = productFilters.filter(
              (product) => product.mandatory
            );
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">
                  Paid products
                </InputLabel>
                <Select
                  multiple
                  value={optionValues.filter(
                    (item) =>
                      filterList[index]
                        .map((obj) => obj._id)
                        .indexOf(item._id) !== -1
                  )}
                  renderValue={(selected) => {
                    return flatFilterNameValues(selected).join(', ');
                  }}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item.name} value={item}>
                      <Checkbox
                        color="primary"
                        checked={
                          filterList[index]
                            .map((obj) => obj._id)
                            .indexOf(item._id) !== -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'additionalProducts',
      label: 'Optional products',
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customBodyRender: (value) => {
          if (value) return value.map((val) => `${val}, `);
        },
        customFilterListOptions: {
          render: (value) => {
            return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = productFilters.filter(
              (product) => !product.mandatory
            );
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">
                  Optional products
                </InputLabel>
                <Select
                  multiple
                  value={optionValues.filter(
                    (item) =>
                      filterList[index]
                        .map((obj) => obj._id)
                        .indexOf(item._id) !== -1
                  )}
                  renderValue={(selected) => {
                    return flatFilterNameValues(selected).join(', ');
                  }}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item.name} value={item}>
                      <Checkbox
                        color="primary"
                        checked={
                          filterList[index]
                            .map((obj) => obj._id)
                            .indexOf(item._id) !== -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'discountCodes',
      label: 'Discount codes',
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customBodyRender: (value) => {
          if (value) return value.map((val) => `${val}, `);
        },
        customFilterListOptions: {
          render: (value) => {
            if (value) return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = discountFilters.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">
                  Promo codes
                </InputLabel>
                <Select
                  multiple
                  value={optionValues.filter(
                    (item) =>
                      filterList[index]
                        .map((obj) => obj._id)
                        .indexOf(item._id) !== -1
                  )}
                  renderValue={(selected) => {
                    return flatFilterNameValues(selected).join(', ');
                  }}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item.name} value={item}>
                      <Checkbox
                        color="primary"
                        checked={
                          filterList[index]
                            .map((obj) => obj._id)
                            .indexOf(item._id) !== -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'ferry',
      label: 'ferry',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
        filterType: 'custom',
        download: false,
        customFilterListOptions: {
          render: (value) => {
            if (value) return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = ferryFilters.sort(
              (a, b) =>
                a.name.localeCompare(b.name) ||
                new Date(a.pointOfDeparture.dateTime) -
                  new Date(b.pointOfDeparture.dateTime)
            );

            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Ferry</InputLabel>
                <Select
                  multiple
                  value={optionValues.filter(
                    (item) =>
                      filterList[index]
                        .map((obj) => obj._id)
                        .indexOf(item._id) !== -1
                  )}
                  renderValue={(selected) => {
                    return flatFilterNameValues(selected).join(', ');
                  }}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item.name} value={item}>
                      <Checkbox
                        color="primary"
                        checked={
                          filterList[index]
                            .map((obj) => obj._id)
                            .indexOf(item._id) !== -1
                        }
                      />
                      <ListItemText
                        primary={
                          item.name +
                          ' (' +
                          item.pointOfDeparture.city +
                          '-' +
                          item.destination.city +
                          ' ' +
                          moment(item.pointOfDeparture.dateTime).format(
                            'DD/MM/YYYY HH:mm'
                          ) +
                          ')'
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'group.outboundFerry',
      label: 'Outbound ferry',
      options: {
        filter: false,
        filterType: 'multiselect',
        sort: false,
        customBodyRender: (value) => {
          if (!value) {
            return '-';
          }

          const ferry =
            value.name +
            ' (' +
            value.pointOfDeparture.city +
            '-' +
            value.destination.city +
            ' ' +
            moment(value.pointOfDeparture.dateTime).format('DD/MM/YYYY HH:mm') +
            ')';

          return ferry;
        },
      },
    },
    {
      name: 'group.returnFerry',
      label: 'Return ferry',
      options: {
        filter: false,
        filterType: 'multiselect',
        sort: false,
        customBodyRender: (value) => {
          if (!value) {
            return '-';
          }

          const ferry =
            value.name +
            ' (' +
            value.pointOfDeparture.city +
            '-' +
            value.destination.city +
            ' ' +
            moment(value.pointOfDeparture.dateTime).format('DD/MM/YYYY HH:mm') +
            ')';

          return ferry;
        },
      },
    },
    {
      name: 'group.accomodation.name',
      label: 'Accomodation',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value) return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = accomodationFilters.sort(
              (a, b) =>
                a.name.localeCompare(b.name) ||
                new Date(a.date) - new Date(b.date)
            );
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">
                  Accomodation
                </InputLabel>
                <Select
                  multiple
                  value={optionValues.filter(
                    (item) =>
                      filterList[index]
                        .map((obj) => obj._id)
                        .indexOf(item._id) !== -1
                  )}
                  renderValue={(selected) => {
                    return flatFilterNameValues(selected).join(', ');
                  }}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item.name} value={item}>
                      <Checkbox
                        color="primary"
                        checked={
                          filterList[index]
                            .map((obj) => obj._id)
                            .indexOf(item._id) !== -1
                        }
                      />
                      <ListItemText
                        primary={
                          item.name +
                          ' ' +
                          moment(item.date).format('DD/MM/YYYY')
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'roomPartnerChosen',
      label: 'Room partner chosen',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value) return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = ['Yes', 'No'];
            return (
              <FormControl component="fieldset">
                <FormLabel component="legend">Room partner chosen</FormLabel>
                <FormGroup aria-label="position" row>
                  {optionValues.map((item) => (
                    <FormControlLabel
                      value={item}
                      key={item}
                      control={
                        <Checkbox
                          multiple
                          color="primary"
                          value={item}
                          defaultChecked={filterList[index].some((option) => {
                            return item === 'Yes'
                              ? option.name === 'Yes' && option.value === true
                              : option.name === 'No' && option.value === false;
                          })}
                          onChange={(event) => {
                            if (event.target.value === 'Yes') {
                              if (event.target.checked) {
                                if (
                                  !filterList[index].some((option) => {
                                    return (
                                      option.name === 'Yes' &&
                                      option.value === true
                                    );
                                  })
                                ) {
                                  filterList[index].push(
                                    combineBooleanObj(
                                      'Yes',
                                      true,
                                      'roomPartnerChosen'
                                    )
                                  );
                                }
                              } else {
                                filterList[index] = filterList[index].filter(
                                  (v) => v.name === 'No'
                                );
                              }
                            } else if (event.target.value === 'No') {
                              if (event.target.checked) {
                                if (
                                  !filterList[index].some((option) => {
                                    return (
                                      option.name === 'NO' &&
                                      option.value === true
                                    );
                                  })
                                ) {
                                  filterList[index].push(
                                    combineBooleanObj(
                                      'No',
                                      false,
                                      'roomPartnerChosen'
                                    )
                                  );
                                }
                              } else {
                                filterList[index] = filterList[index].filter(
                                  (v) => v.name === 'Yes'
                                );
                              }
                            }
                          }}
                        />
                      }
                      label={item}
                      labelPlacement="start"
                    />
                  ))}
                </FormGroup>
              </FormControl>
            );
          },
        },
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormControlLabel
              value={users[dataIndex].roomPartnerChosen ? 'Yes' : 'No'}
              control={
                <Checkbox
                  color="primary"
                  checked={users[dataIndex].roomPartnerChosen}
                  value={users[dataIndex].roomPartnerChosen ? 'Yes' : 'No'}
                />
              }
            />
          );
        },
      },
    },
    {
      name: 'room.roomType',
      label: 'Room type',
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value) return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = roomFilters;
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">
                  Room type
                </InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={(selected) => {
                    return flatFilterNameValues(selected).join(', ');
                  }}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item.name} value={item}>
                      <Checkbox
                        color="primary"
                        checked={filterList[index].indexOf(item) > -1}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: 'room',
      label: 'Room partners',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value) {
            if (!value.roomType) return '-';

            if (value.roomType === 'F' || value.roomType === 'M')
              return value.roomType;

            return value.users
              .map((partner) => {
                return partner.firstName + ' ' + partner.lastName;
              })
              .join(', ');
          }
        },
      },
    },
    { name: '_id', label: 'Id', options: { filter: false, display: false } },
    {
      name: 'disabled',
      label: 'Disabled',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value) return flatFilterNameValues(value);
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = ['Yes', 'No'];
            return (
              <FormControl component="fieldset">
                <FormLabel component="legend">Disabled</FormLabel>
                <FormGroup aria-label="position" row>
                  {optionValues.map((item) => (
                    <FormControlLabel
                      value={item}
                      key={item}
                      control={
                        <Checkbox
                          multiple
                          color="primary"
                          value={item}
                          defaultChecked={filterList[index].some((option) => {
                            return item === 'Yes'
                              ? option.name === 'Yes' && option.value === true
                              : option.name === 'No' && option.value === false;
                          })}
                          onChange={(event) => {
                            if (event.target.value === 'Yes') {
                              if (event.target.checked) {
                                if (
                                  !filterList[index].some((option) => {
                                    return (
                                      option.name === 'Yes' &&
                                      option.value === true
                                    );
                                  })
                                ) {
                                  filterList[index].push(
                                    combineBooleanObj('Yes', true, 'disabled')
                                  );
                                }
                              } else {
                                filterList[index] = filterList[index].filter(
                                  (v) => v.name === 'No'
                                );
                              }
                            } else if (event.target.value === 'No') {
                              if (event.target.checked) {
                                if (
                                  !filterList[index].some((option) => {
                                    return (
                                      option.name === 'NO' &&
                                      option.value === true
                                    );
                                  })
                                ) {
                                  filterList[index].push(
                                    combineBooleanObj('No', false, 'disabled')
                                  );
                                }
                              } else {
                                filterList[index] = filterList[index].filter(
                                  (v) => v.name === 'Yes'
                                );
                              }
                            }
                          }}
                        />
                      }
                      label={item}
                      labelPlacement="start"
                    />
                  ))}
                </FormGroup>
              </FormControl>
            );
          },
        },
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormControlLabel
              value={users[dataIndex].disabled ? 'Yes' : 'No'}
              control={
                <RedCheckbox
                  checked={users[dataIndex].disabled}
                  value={users[dataIndex].disabled ? 'Yes' : 'No'}
                />
              }
            />
          );
        },
      },
    },
    {
      name: '',
      label: 'Edit',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          if (users[0] !== '') {
            return (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => handleEditUserClick(users[dataIndex])}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            );
          }
        },
        download: false,
      },
    },
  ];

  // We need to filter the columns which we get from MuiDataTable tableState to match the structure of defaultColumns
  const getColumns = () => {
    if (columns === null) {
      return defaultColumns;
    }
    let colsToReturn = [];

    for (let col of columns) {
      let newCol = { options: {} };
      for (const key in col) {
        if (Object.prototype.hasOwnProperty.call(col, key)) {
          if (key === 'name' || key === 'label') {
            newCol[key] = col[key];
          } else {
            newCol.options[key] = col[key];
          }
        }
      }
      colsToReturn.push(newCol);
    }
    return colsToReturn;
  };

  return (
    <div className={classes.root}>
      <h2> Customers </h2>

      {role === 'root' && organizations && (
        <Fragment>
          <FormControl
            className={classes.formControl}
            key={`amountOfSelections_${organizations.length}`}
          >
            {'Select organization first'}
            <Select
              labelId="organizationSelectionLabel"
              id="organizationSelection"
              defaultValue=""
              onChange={({ target: { value } }) => {
                dispatch(resetPaginationData());
                dispatch(fetchOrganizationsFilters(value));
                dispatch(savePageToState(0));
                dispatch(saveSelectedOrgId(value));
                dispatch(
                  fetchOrganizationsCustomersPagination(
                    combineReqData(false, false, rowsPerPage, value, null, null)
                  )
                );
                dispatch(selectOrgToFilter(value));
              }}
            >
              {getMenuItems(organizations)}
            </Select>
          </FormControl>
        </Fragment>
      )}
      {!!users.length && (
        <>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Switch
                  checked={showFullData}
                  onChange={(e) => {
                    setShowFullData(e.target.checked);
                    if (e.target.checked) {
                      dispatch(fetchOrganizationsCustomers(selectedOrg));
                    }
                  }}
                  name="filter list by room"
                  color="primary"
                />
              }
              label={
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="subtitle1"
                >
                  Show full data
                </Typography>
              }
              labelPlacement="top"
            />
          </FormControl>
          <FormControl>
            {showFullData && (
              <FormControlLabel
                control={
                  <Switch
                    checked={filterByRoom}
                    onChange={() => {
                      dispatch(toggleFilterByRoom(!filterByRoom));
                    }}
                    name="filter list by room"
                    color="primary"
                  />
                }
                label={
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="subtitle1"
                  >
                    Filter by room
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          </FormControl>
          {showFullData && (
            <FormControl>
              Filter by payments
              <Select
                id="payment-filters"
                multiple
                value={paymentFilters}
                onChange={(e) => {
                  setPaymentFilters(e.target.value);
                }}
                input={<Input id="spayment-filters-chip" />}
                renderValue={(selected) =>
                  selected
                    .map((value) => paymentFilterOptions[value].name)
                    .join(', ')
                }
              >
                {paymentFilterOptions.map((option, i) => (
                  <MenuItem key={i} value={i}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {!showFullData && (
            <MUIDataTable
              title={'Customers'}
              data={getTableData(users)}
              columns={getColumns()}
              options={options}
            />
          )}
          {showFullData && !fetchingUsers ? (
            <FullDataTable
              handleEditUserClick={handleEditUserClick}
              getTableData={getTableData}
              paymentFilters={paymentFilters}
              paymentFilterOptions={paymentFilterOptions}
              setFullDataUsers={setFullDataUsers}
              filterByRoom={filterByRoom}
            />
          ) : (
            <Backdrop
              sx={{ color: '#fff', zIndex: 999, opacity: '25%' }}
              open={fetchingUsers}
            >
              <CircularProgress color="primary" />
            </Backdrop>
          )}
        </>
      )}
      <MassEditDialog
        fullDataUsers={fullDataUsers}
        showFullData={showFullData}
      />
    </div>
  );
};

export default Users;
