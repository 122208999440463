import React from 'react';
import { NavLink } from 'react-router-dom';
import AdminOptions from './AdminOptions';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EmailIcon from '@material-ui/icons/Email';
import EuroIcon from '@material-ui/icons/Euro';

const OwnerOptions = () => {
  return (
    <>
      <ListItem button component={NavLink} to="/admins">
        <ListItemIcon>
          <SupervisorAccountIcon />
        </ListItemIcon>
        <ListItemText primary="Admin" />
      </ListItem>
      <ListItem button component={NavLink} to="/emails">
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Email" />
      </ListItem>
      <ListItem button component={NavLink} to="/discount-codes">
        <ListItemIcon>
          <EuroIcon />
        </ListItemIcon>
        <ListItemText primary="Discount codes" />
      </ListItem>
      <ListSubheader component="div">Admin actions</ListSubheader>
      <AdminOptions />
    </>
  );
};

export default OwnerOptions;
