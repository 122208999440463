import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { closePaymentDialog } from 'redux/actions/user';

import { getPaymentDialogFromState } from 'redux/selectors/user';

export const PaymentDialog = () => {
  const dispatch = useDispatch();
  const { visible, payment } = useSelector((state) =>
    getPaymentDialogFromState(state.user)
  );

  return (
    <Fragment>
      {visible && payment && (
        <Dialog
          open={visible}
          onClose={() => dispatch(closePaymentDialog())}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Payment Info</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div>Id: {payment._id}</div>
              <div>
                Total:{' '}
                {payment.products
                  .map((product) => {
                    let serviceFee = 0;
                    if (product.serviceFee) {
                      serviceFee = product.serviceFee;
                    }
                    return product.price + serviceFee;
                  })
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                  )}{' '}
                €
              </div>
              <h4>Products:</h4>
              {payment.products.map((product) => (
                <div key={product._id}>
                  Name: {product.name} | Price: {product.price} € | Tax:{' '}
                  {product.vat
                    ? product.vatPercent + '%'
                    : product.taxDescription +
                      '(amount: ' +
                      product.taxAmount +
                      ')'}{' '}
                  {product.serviceFee && (
                    <div>
                      Service fee: {product.serviceFee} € | Service fee tax:{' '}
                      {product.serviceFeeVat} %
                    </div>
                  )}
                </div>
              ))}
              <h4>Refund info</h4>
              {payment.refundStatus && (
                <Fragment>
                  <div>Refund status: {payment.refundStatus}</div>
                  <div>Refund amount: {payment.refundAmount} €</div>
                  <div>Refund token: {payment.refundToken}</div>
                </Fragment>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => dispatch(closePaymentDialog())}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default PaymentDialog;
