import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import red from '@material-ui/core/colors/red';
import EditIcon from '@material-ui/icons/Edit';
import MUIDataTable from 'mui-datatables';
import CustomToolbarSelect from './CustomToolbarSelect';
import { getUsersFromState } from 'redux/selectors/user';

const RedCheckbox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const FullDataTable = ({
  getTableData,
  handleEditUserClick,
  paymentFilters,
  paymentFilterOptions,
  setFullDataUsers,
  filterByRoom,
}) => {
  const { users } = useSelector((state) => getUsersFromState(state.user));

  const [filteredUsers, setFilteredUsers] = React.useState(users);

  const filterUsers = () => {
    const newUsers = users.filter((user) => {
      let keep = true;
      if (paymentFilters.length > 0) {
        paymentFilters.forEach((filterI) => {
          const filter = paymentFilterOptions[filterI].name;
          if (filter && typeof filter === 'string') {
            // Paid
            if (filter.includes('Paid')) {
              if (!user.paidProducts.includes(filter.replace('Paid ', ''))) {
                keep = false;
              }
            }
            // Not paid
            if (filter.includes('Not')) {
              if (user.paidProducts.includes(filter.replace('Not paid ', ''))) {
                keep = false;
              }
            }
          }
        });
      }
      return keep;
    });
    setFilteredUsers(newUsers);
    setFullDataUsers(newUsers);
  };

  /*eslint-disable */
  useEffect(() => {
    if (!filterByRoom) {
      filterUsers();
    }
  }, [users, paymentFilters, filterByRoom]);
  /*eslint-disable */

  const clientSideOptions = {
    enableNestedDataAccess: '.',
    pagination: true,
    rowsPerPage: 100,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      // Format Birthday to readable form
      const birhtdayIndex = columns.findIndex(
        (element) => element.name === 'birthday'
      );

      data.forEach((row) => {
        row.data[birhtdayIndex] = moment(row.data[birhtdayIndex]).format(
          'DD-MM-YYYY'
        );
      });

      return buildHead(columns) + buildBody(data);
    },
    customToolbarSelect: (selectedRows) => (
      <CustomToolbarSelect selectedRows={selectedRows} />
    ),
  };

  const clientSideColumns = [
    {
      name: 'firstName',
      label: 'First name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lastName',
      label: 'Last name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
      },
    },
    {
      name: 'birthday',
      label: 'Birthday',
      options: {
        sort: false,
        filter: false,
        display: false,
        customBodyRenderLite: (dataIndex) => {
          return moment(filteredUsers[dataIndex].birthday).format('DD.MM.yyyy');
        },
      },
    },
    {
      name: 'gender',
      label: 'Gender',
      options: {
        filter: true,
        filterType: 'checkbox',
        sort: false,
        display: false,
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        sort: false,
        filter: false,
        display: false,
      },
    },
    {
      name: 'address.street',
      label: 'Street',
      options: {
        sort: false,
        filter: false,
        display: false,
      },
    },
    {
      name: 'address.postcode',
      label: 'Postal code',
      options: {
        sort: false,
        filter: false,
        display: false,
      },
    },
    {
      name: 'address.city',
      label: 'City',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
        display: false,
      },
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'marketing',
      label: 'Marketing',
      options: {
        filter: true,
        filterType: 'checkbox',
        filterOptions: { renderValue: (v) => (v ? 'Yes' : 'No') },
        customFilterListOptions: { render: (v) => (v ? 'Yes' : 'No') },
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormControlLabel
              value={filteredUsers[dataIndex].marketing ? 'Yes' : 'No'}
              control={
                <Checkbox
                  color="primary"
                  checked={filteredUsers[dataIndex].marketing}
                  value={filteredUsers[dataIndex].marketing ? 'Yes' : 'No'}
                />
              }
            />
          );
        },
      },
    },
    {
      name: 'group.name',
      label: 'Group',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: false,
      },
    },
    {
      name: 'group.event.name',
      label: 'Event',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: false,
      },
    },
    {
      name: 'eventPayments',
      label: 'Event payments',
      options: {
        filter: true,
        filterType: 'checkbox',
        display: false,
        customBodyRender: (value) => {
          let payed = value.filter(
            (payment) =>
              payment.products[0].mandatory &&
              (payment.status === 'confirmed' || payment.status === 'accepted')
          ).length;

          let total = value.filter(
            (payment) => payment.products[0].mandatory
          ).length;

          return payed.toString() + '/' + total.toString();
        },
      },
    },
    {
      name: 'paidProducts',
      label: 'Paid products',
      options: {
        filter: true,
        filterType: 'multiselect',
        customBodyRender: (value) => {
          return value.map((val) => `${val}, `);
        },
      },
    },
    {
      name: 'additionalProducts',
      label: 'Optional products',
      options: {
        filter: true,
        filterType: 'multiselect',
        customBodyRender: (value) => {
          if (value) return value.map((val) => `${val}, `);
        },
      },
    },
    {
      name: 'discountCodes',
      label: 'Discount codes',
      options: {
        filter: true,
        filterType: 'multiselect',
        customBodyRender: (value) => {
          return value.map((val) => `${val}, `);
        },
      },
    },
    {
      name: 'group.outboundFerry',
      label: 'Outbound ferry',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: false,
        customBodyRender: (value) => {
          if (!value) {
            return '-';
          }

          const ferry =
            value.name +
            ' (' +
            value.pointOfDeparture.city +
            '-' +
            value.destination.city +
            ' ' +
            moment(value.pointOfDeparture.dateTime).format('DD/MM/YYYY HH:mm') +
            ')';

          return ferry;
        },
      },
    },
    {
      name: 'group.returnFerry',
      label: 'Return ferry',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: false,
        customBodyRender: (value) => {
          if (!value) {
            return '-';
          }

          const ferry =
            value.name +
            ' (' +
            value.pointOfDeparture.city +
            '-' +
            value.destination.city +
            ' ' +
            moment(value.pointOfDeparture.dateTime).format('DD/MM/YYYY HH:mm') +
            ')';

          return ferry;
        },
      },
    },
    {
      name: 'group.accomodation.name',
      label: 'Accomodation',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: false,
      },
    },
    {
      name: 'roomPartnerChosen',
      label: 'Room partner chosen',
      options: {
        filter: true,
        filterType: 'checkbox',
        filterOptions: { renderValue: (v) => (v ? 'Yes' : 'No') },
        customFilterListOptions: { render: (v) => (v ? 'Yes' : 'No') },
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormControlLabel
              value={filteredUsers[dataIndex].roomPartnerChosen ? 'Yes' : 'No'}
              control={
                <Checkbox
                  color="primary"
                  checked={filteredUsers[dataIndex].roomPartnerChosen}
                  value={
                    filteredUsers[dataIndex].roomPartnerChosen ? 'Yes' : 'No'
                  }
                />
              }
            />
          );
        },
      },
    },
    {
      name: 'room.roomType',
      label: 'Room type',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: true,
      },
    },
    {
      name: 'room',
      label: 'Room partners',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value === null) {
            return '-';
          }
          if (!value.roomType) return '-';

          if (value.roomType === 'F' || value.roomType === 'M')
            return value.roomType;

          return value.users
            .map((partner) => {
              return partner.firstName + ' ' + partner.lastName;
            })
            .join(', ');
        },
      },
    },
    {
      name: '_id',
      label: 'Id',
      options: { filter: false, sort: false, display: false },
    },
    {
      name: 'disabled',
      label: 'Disabled',
      options: {
        filter: true,
        filterType: 'checkbox',
        filterOptions: { renderValue: (v) => (v ? 'Yes' : 'No') },
        customFilterListOptions: { render: (v) => (v ? 'Yes' : 'No') },
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormControlLabel
              value={filteredUsers[dataIndex].disabled ? 'Yes' : 'No'}
              control={
                <RedCheckbox
                  checked={filteredUsers[dataIndex].disabled}
                  value={filteredUsers[dataIndex].disabled ? 'Yes' : 'No'}
                />
              }
            />
          );
        },
      },
    },
    {
      name: '',
      label: 'Edit',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => handleEditUserClick(filteredUsers[dataIndex])}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          );
        },
        download: false,
      },
    },
  ];

  return (
    <MUIDataTable
      title={'Customers'}
      data={getTableData(filterByRoom ? users : filteredUsers)}
      columns={clientSideColumns}
      options={clientSideOptions}
    />
  );
};

export default FullDataTable;
