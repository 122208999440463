import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({
  visible,
  closeDialog,
  type,
  onConfirm,
  roomPartners,
}) {
  return (
    <div>
      <Dialog
        open={visible}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-roompartner-confirmation"
        aria-describedby="alert-dialog-roompartner-decision-confirmation"
      >
        <DialogTitle id="alert-dialog-title">
          {type === 'automatic'
            ? 'Oletko varma, että haluat meidän valitsevan sinulle huoneparin?'
            : roomPartners.length === 0
            ? `Oletko varma, että haluat valita yhden hengen huoneen`
            : `Oletko varma, että haluat valita ${roomPartners
                .map((user) => user.name)
                .join(' ja ')} huonepariksesi?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeDialog}>Takaisin</Button>
          <Button onClick={onConfirm} autoFocus>
            {type === 'automatic'
              ? 'Anna meidän valita puolesta'
              : roomPartners.length === 0
              ? 'Vahvista'
              : 'Vahvista huonepari'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
