import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { getOrganizations } from 'redux/actions/organization';
import {
  fetchFerrys,
  selectExistingFerry,
  selectNewFerry,
  selectOrgToFilter,
} from 'redux/actions/ferry';

import {
  getOrganizationFerrys,
  getFilterFerrysOrg,
} from 'redux/selectors/ferry';

import { getOrganizationsFromState } from 'redux/selectors/organization';
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  ferryInfo: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  cardActionCenter: {
    justifyContent: 'center',
  },
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: 120,
  },
  fab: {
    background: theme.palette.success.main,
    color: 'white',
    position: 'fixed',
    top: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

const getMenuItems = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

export const Ferrys = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();

  const { role } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchFerrys());
  }, [dispatch]);

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  const { organizations } = useSelector((state) =>
    getOrganizationsFromState(state.organization)
  );

  const { filterFerrysOrg } = useSelector((state) =>
    getFilterFerrysOrg(state.ferry)
  );

  const { ferrys } = useSelector((state) => getOrganizationFerrys(state.ferry));

  const handleEditDetailsClick = (ferryObject) => {
    dispatch(selectExistingFerry(ferryObject));
    history.push('/ferry-form');
  };

  return (
    <div className={classes.root}>
      <h2> Ferrys </h2>

      {role === 'root' && organizations && (
        <Fragment>
          <FormControl
            className={classes.formControl}
            key={`amountOfSelections_${organizations.length}`}
          >
            <InputLabel id="organizationSelectionLabel">Filter org</InputLabel>
            <Select
              labelId="organizationSelectionLabel"
              id="organizationSelection"
              defaultValue=""
              onChange={({ target: { value } }) =>
                dispatch(selectOrgToFilter(value))
              }
            >
              <MenuItem key="all" value={'all'}>
                All
              </MenuItem>
              {getMenuItems(organizations)}
            </Select>
          </FormControl>
        </Fragment>
      )}

      <Fab
        className={classes.fab}
        component={NavLink}
        to="/ferry-form"
        onClick={() => dispatch(selectNewFerry())}
        aria-label="add"
        variant="extended"
      >
        <AddIcon />
        New
      </Fab>
      <div key={filterFerrysOrg}>
        <Grid container justifyContent="center" spacing={3}>
          {ferrys && ferrys.length > 0 ? (
            ferrys.map((ferryObject) => (
              <Fragment key={ferryObject._id}>
                <Grid item>
                  <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                        variant="h2"
                      >
                        {ferryObject.name}
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                        variant="h3"
                      >
                        {' '}
                        Date:
                        <Moment format="DD/MM/YYYY">
                          {ferryObject.pointOfDeparture.dateTime}
                        </Moment>
                      </Typography>
                      <Typography className={classes.ferryInfo}>
                        From:{' '}
                        {ferryObject.pointOfDeparture.city +
                          ' (' +
                          moment(ferryObject.pointOfDeparture.dateTime).format(
                            'HH:mm'
                          ) +
                          ')'}
                      </Typography>
                      <Typography className={classes.ferryInfo}>
                        To:{' '}
                        {ferryObject.destination.city +
                          ' (' +
                          moment(ferryObject.destination.dateTime).format(
                            'HH:mm'
                          ) +
                          ')'}
                      </Typography>
                      <Typography className={classes.ferryInfo}>
                        Groups:{' '}
                        {ferryObject.groups ? ferryObject.groups.length : '0'}
                      </Typography>
                    </CardContent>
                    <CardActions className={classes.cardActionCenter}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => handleEditDetailsClick(ferryObject)}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Fragment>
            ))
          ) : (
            <Fragment>
              <h3>Please start by adding a ferry</h3>
            </Fragment>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Ferrys;
