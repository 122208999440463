import {
  FETCH_ACCOMODATIONS_REQUESTED,
  FETCH_ACCOMODATIONS_RESPONSE,
  FETCH_ACCOMODATIONS_ERROR,
  UPDATE_ACCOMODATION_REQUESTED,
  UPDATE_ACCOMODATION_RESPONSE,
  UPDATE_ACCOMODATION_ERROR,
  SAVE_ACCOMODATION_REQUESTED,
  SAVE_ACCOMODATION_RESPONSE,
  SAVE_ACCOMODATION_ERROR,
  DELETE_ACCOMODATION_ERROR,
  DELETE_ACCOMODATION_REQUESTED,
  DELETE_ACCOMODATION_RESPONSE,
  UPDATE_ACCOMODATION_NAME,
  UPDATE_ACCOMODATION_DESCRIPTION,
  UPDATE_ACCOMODATION_ORGANIZATION,
  UPDATE_ACCOMODATION_DATE,
  UPDATE_ACCOMODATION_ADDRESS_STREET,
  UPDATE_ACCOMODATION_ADDRESS_CITY,
  UPDATE_ACCOMODATION_ADDRESS_COUNTRY,
  UPDATE_ACCOMODATION_TWO_PERSON_ROOM_CAPACITY,
  UPDATE_ACCOMODATION_TWO_PERSON_ROOM_RESERVED,
  UPDATE_ACCOMODATION_ONE_PERSON_ROOM_CAPACITY,
  UPDATE_ACCOMODATION_ONE_PERSON_ROOM_RESERVED,
  UPDATE_ACCOMODATION_THREE_PERSON_ROOM_CAPACITY,
  UPDATE_ACCOMODATION_THREE_PERSON_ROOM_RESERVED,
  UPDATE_ACCOMODATION_ROOMPARTNER_SELECTION_LIVE,
  SELECT_NEW_ACCOMODATION,
  SELECT_EXISTING_ACCOMODATION,
  SET_ACCOMODATION_FILTER_ORG,
  SET_ACCOMODATION_FILTER_GROUP_FORM_ORG,
  UPDATE_ACCOMODATION_ROOM_ALLOCATION_TYPE,
  UPDATE_ACCOMODATION_PRODUCTS,
  UPDATE_ACCOMODATION_PRODUCT_COLLECT_PAYMENT,
} from 'redux/actions/accomodation';

const { updateProductCollectPayment } = require('utils/product');

const initialState = {
  accomodations: [],
  accomodation: {
    name: null,
    date: new Date(),
    address: {
      street: null,
      city: null,
      country: null,
    },
    onePersonRoom: {
      capacity: null,
      reserved: 0,
    },
    twoPersonRoom: {
      capacity: null,
      reserved: 0,
    },
    threePersonRoom: {
      capacity: null,
      reserved: 0,
    },
    roompartnerSelectionLive: false,
    description: null,
    organization: null,
    groups: [],
    roomAllocationType: 'code',
    products: [],
  },
  filterAccomodationsOrg: 'all',
  filterAccomodationsOnGroupFormByOrg: 'all',
  error: {},
};

const updateAccomodationsArray = (accomodations, updated) => {
  const filtered = accomodations.filter(
    (accomodation) => accomodation._id !== updated._id
  );
  filtered.push(updated);
  return filtered;
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_ACCOMODATION_RESPONSE:
      return {
        ...state,
        accomodations: updateAccomodationsArray(
          state.accomodations,
          action.payload
        ),
      };

    case UPDATE_ACCOMODATION_RESPONSE:
      return {
        ...state,
        accomodations: updateAccomodationsArray(
          state.accomodations,
          action.payload
        ),
      };

    case DELETE_ACCOMODATION_RESPONSE:
      return {
        ...state,
        accomodations: state.accomodations.filter(
          (accomodation) => accomodation._id !== action.payload._id
        ),
        accomodation: initialState.accomodation,
      };

    case SAVE_ACCOMODATION_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ACCOMODATION_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_ACCOMODATION_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_ACCOMODATIONS_RESPONSE:
      return { ...state, accomodations: payload };

    case FETCH_ACCOMODATIONS_ERROR:
      return { ...state, error: payload };

    case SET_ACCOMODATION_FILTER_ORG:
      return {
        ...state,
        filterAccomodationsOrg: action.payload,
      };

    case SET_ACCOMODATION_FILTER_GROUP_FORM_ORG:
      return {
        ...state,
        filterAccomodationsOnGroupFormByOrg: action.payload,
      };

    case SELECT_EXISTING_ACCOMODATION:
      return {
        ...state,
        accomodation: action.payload ? action.payload : null,
      };

    case SELECT_NEW_ACCOMODATION:
      return {
        ...state,
        accomodation: initialState.accomodation,
      };

    case UPDATE_ACCOMODATION_NAME:
      return {
        ...state,
        accomodation: { ...state.accomodation, name: action.payload },
      };

    case UPDATE_ACCOMODATION_DATE:
      return {
        ...state,
        accomodation: { ...state.accomodation, date: action.payload },
      };

    case UPDATE_ACCOMODATION_DESCRIPTION:
      return {
        ...state,
        accomodation: { ...state.accomodation, description: action.payload },
      };

    case UPDATE_ACCOMODATION_ORGANIZATION:
      return {
        ...state,
        accomodation: { ...state.accomodation, organization: action.payload },
      };

    case UPDATE_ACCOMODATION_ADDRESS_STREET:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          address: { ...state.accomodation.address, street: action.payload },
        },
      };

    case UPDATE_ACCOMODATION_ADDRESS_CITY:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          address: { ...state.accomodation.address, city: action.payload },
        },
      };

    case UPDATE_ACCOMODATION_ADDRESS_COUNTRY:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          address: { ...state.accomodation.address, country: action.payload },
        },
      };

    case UPDATE_ACCOMODATION_ONE_PERSON_ROOM_CAPACITY:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          onePersonRoom: {
            ...state.accomodation.onePersonRoom,
            capacity: action.payload,
          },
        },
      };

    case UPDATE_ACCOMODATION_TWO_PERSON_ROOM_CAPACITY:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          twoPersonRoom: {
            ...state.accomodation.twoPersonRoom,
            capacity: action.payload,
          },
        },
      };

    case UPDATE_ACCOMODATION_TWO_PERSON_ROOM_RESERVED:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          twoPersonRoom: {
            ...state.accomodation.twoPersonRoom,
            reserved: action.payload,
          },
        },
      };

    case UPDATE_ACCOMODATION_ONE_PERSON_ROOM_RESERVED:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          onePersonRoom: {
            ...state.accomodation.onePersonRoom,
            reserved: action.payload,
          },
        },
      };

    case UPDATE_ACCOMODATION_THREE_PERSON_ROOM_CAPACITY:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          threePersonRoom: {
            ...state.accomodation.threePersonRoom,
            capacity: action.payload,
          },
        },
      };

    case UPDATE_ACCOMODATION_THREE_PERSON_ROOM_RESERVED:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          threePersonRoom: {
            ...state.accomodation.threePersonRoom,
            reserved: action.payload,
          },
        },
      };

    case UPDATE_ACCOMODATION_ROOMPARTNER_SELECTION_LIVE:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          roompartnerSelectionLive: action.payload,
        },
      };

    case UPDATE_ACCOMODATION_ROOM_ALLOCATION_TYPE:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          roomAllocationType: action.payload,
        },
      };

    case UPDATE_ACCOMODATION_PRODUCTS:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          products: action.payload,
        },
      };

    case UPDATE_ACCOMODATION_PRODUCT_COLLECT_PAYMENT:
      return {
        ...state,
        accomodation: {
          ...state.accomodation,
          products: updateProductCollectPayment(
            state.accomodation.products,
            action.payload
          ),
        },
      };

    case DELETE_ACCOMODATION_REQUESTED:
    case UPDATE_ACCOMODATION_REQUESTED:
    case SAVE_ACCOMODATION_REQUESTED:
    case FETCH_ACCOMODATIONS_REQUESTED:
    default:
      return state;
  }
}
