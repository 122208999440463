import API from 'services/api';
import { setAlert } from 'redux/actions/alert';
import RichTextEditor from 'react-rte';

export const FETCH_CUSTOMER_ORG_REQUESTED = 'FETCH_CUSTOMER_ORG_REQUESTED';
export const FETCH_CUSTOMER_ORG_RESPONSE = 'FETCH_CUSTOMER_ORG_RESPONSE';
export const FETCH_CUSTOMER_ORG_ERROR = 'FETCH_CUSTOMER_ORG_ERROR';

export const SAVE_ORG_REQUESTED = 'SAVE_ORG_REQUESTED';
export const SAVE_ORG_RESPONSE = 'SAVE_ORG_RESPONSE';
export const SAVE_ORG_ERROR = 'SAVE_ORG_ERROR';

export const UPDATE_ORG_REQUESTED = 'UPDATE_ORG_REQUESTED';
export const UPDATE_ORG_RESPONSE = 'UPDATE_ORG_RESPONSE';
export const UPDATE_ORG_ERROR = 'UPDATE_ORG_ERROR';

export const DELETE_ORG_REQUESTED = 'DELETE_ORG_REQUESTED';
export const DELETE_ORG_RESPONSE = 'DELETE_ORG_RESPONSE';
export const DELETE_ORG_ERROR = 'DELETE_ORG_ERROR';

export const GET_ORGS_REQUESTED = 'GET_ORGS_REQUESTED';
export const GET_ORGS_RESPONSE = 'GET_ORGS_RESPONSE';
export const GET_ORGS_ERROR = 'GET_ORGS_ERROR';

export const SELECT_EXISTING_ORG = 'SELECT_EXISTING_ORG';
export const SELECT_NEW_ORG = 'SELECT_NEW_ORG';

export const EDIT_ORG_NAME = 'EDIT_ORG_NAME';
export const EDIT_ORG_IDENTIFIER = 'EDIT_ORG_IDENTIFIER';
export const EDIT_ORG_BACKGROUND_IMAGE = 'EDIT_ORG_BACKGROUND_IMAGE';
export const EDIT_ORG_CONTACT_NAME = 'EDIT_ORG_CONTACT_NAME';
export const EDIT_ORG_CONTACT_EMAIL = 'EDIT_ORG_CONTACT_EMAIL';
export const EDIT_ORG_CONTACT_PHONE = 'EDIT_ORG_CONTACT_PHONE';
export const EDIT_ORG_DESCRIPTION = 'EDIT_ORG_DESCRIPTION';
export const EDIT_ORG_THINGS_TO_NOTE = 'EDIT_ORG_THINGS_TO_NOTE';
export const EDIT_ORG_QUESTIONS = 'EDIT_ORG_QUESTIONS';
export const EDIT_ORG_WEBSITE = 'EDIT_ORG_WEBSITE';
export const EDIT_ORG_STREET = 'EDIT_ORG_STREET';
export const EDIT_ORG_POSTAL_CODE = 'EDIT_ORG_POSTAL_CODE';
export const EDIT_ORG_CITY = 'EDIT_ORG_CITY';
export const EDIT_ORG_COUNTRY = 'EDIT_ORG_COUNTRY';
export const EDIT_ORG_BUSINESS_CODE = 'EDIT_ORG_BUSINESS_CODE';
export const EDIT_ORG_BANK_ACCOUNT = 'EDIT_ORG_BANK_ACCOUNT';
export const EDIT_ORG_TRAVEL_CONDITIONS = 'EDIT_ORG_TRAVEL_CONDITIONS';
export const EDIT_ORG_PRIVACY_STATEMENT = 'EDIT_ORG_PRIVACY_STATEMENT';
export const EDIT_ORG_MARKETING_AUTHORIZATION =
  'EDIT_ORG_MARKETING_AUTHORIZATION';
export const UPDATE_ORG_REGISTERING = 'UPDATE_ORG_REGISTERING';

export const saveOrganization = (organization) => async (dispatch) => {
  dispatch({ type: SAVE_ORG_REQUESTED });

  const organizationToSave = {
    ...organization,
    description: organization.description.toString('html'),
    thingsToNote: organization.thingsToNote.toString('html'),
    questions: organization.questions.toString('html'),
    marketingAuthorization:
      organization.marketingAuthorization.toString('html'),
  };

  delete organizationToSave._id;
  try {
    const res = await API.post('/api/admin/organization', organizationToSave);
    dispatch(setAlert('Organization saved succesfully', 'success'));
    dispatch({ type: SAVE_ORG_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: SAVE_ORG_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateOrganization = (organization) => async (dispatch) => {
  dispatch({ type: UPDATE_ORG_REQUESTED });
  const organizationToUpdate = {
    ...organization,
    description: organization.description.toString('html'),
    thingsToNote: organization.thingsToNote.toString('html'),
    questions: organization.questions.toString('html'),
    marketingAuthorization:
      organization.marketingAuthorization.toString('html'),
  };
  try {
    const res = await API.put(
      `/api/admin/organization/${organization._id}`,
      organizationToUpdate
    );
    dispatch(setAlert('Organization updated successfully', 'success'));
    dispatch({ type: UPDATE_ORG_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    errors
      ? errors.forEach((error) => dispatch(setAlert(error.msg, 'error')))
      : dispatch(setAlert('Unable to update update organization', 'error'));

    dispatch({ type: UPDATE_ORG_ERROR, payload: error });
  }
};

export const deleteOrganization = (organization) => async (dispatch) => {
  dispatch({ type: DELETE_ORG_REQUESTED });
  try {
    const res = await API.delete(`/api/admin/organization/${organization._id}`);
    dispatch(setAlert('Organization deleted success', 'success'));
    dispatch({ type: DELETE_ORG_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch(
      setAlert(
        'Error deleting the organization, please make sure all eventes are ',
        'error'
      )
    );
    dispatch({ type: DELETE_ORG_ERROR, payload: { payload: error } });
  }
};

export const getOrganizations = () => async (dispatch) => {
  dispatch({ type: GET_ORGS_REQUESTED });
  try {
    const res = await API.get('/api/admin/organization');
    dispatch({ type: GET_ORGS_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch({ type: GET_ORGS_ERROR, payload: error });
  }
};

export const fetchCustomerOrganization = (identifier) => async (dispatch) => {
  dispatch({ type: FETCH_CUSTOMER_ORG_RESPONSE });
  try {
    const res = await API.get(`/api/organizations/identifier/${identifier}`);
    dispatch({ type: FETCH_CUSTOMER_ORG_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch({ type: FETCH_CUSTOMER_ORG_ERROR, payload: error });
  }
};

export const fetchCustomerOrganizationById = (id) => async (dispatch) => {
  dispatch({ type: FETCH_CUSTOMER_ORG_RESPONSE });
  try {
    const res = await API.get(`/api/organizations/${id}`);
    dispatch({ type: FETCH_CUSTOMER_ORG_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch({ type: FETCH_CUSTOMER_ORG_ERROR, payload: error });
  }
};

export const selectExistingOrg = (payload) => async (dispatch) => {
  const organization = {
    ...payload,
    description: payload.description
      ? RichTextEditor.createValueFromString(payload.description, 'html')
      : RichTextEditor.createEmptyValue(),
    thingsToNote: payload.thingsToNote
      ? RichTextEditor.createValueFromString(payload.thingsToNote, 'html')
      : RichTextEditor.createEmptyValue(),
    questions: payload.questions
      ? RichTextEditor.createValueFromString(payload.questions, 'html')
      : RichTextEditor.createEmptyValue(),
    marketingAuthorization: payload.marketingAuthorization
      ? RichTextEditor.createValueFromString(
          payload.marketingAuthorization,
          'html'
        )
      : RichTextEditor.createEmptyValue(),
  };

  dispatch({
    type: SELECT_EXISTING_ORG,
    payload: organization,
  });
};

export const selectNewOrg = () => ({ type: SELECT_NEW_ORG });

export const editOrganizationName = (payload) => ({
  type: EDIT_ORG_NAME,
  payload,
});

export const editOrganizationIdentifier = (payload) => ({
  type: EDIT_ORG_IDENTIFIER,
  payload,
});

export const editOrganizationBackgroundImage = (payload) => ({
  type: EDIT_ORG_BACKGROUND_IMAGE,
  payload,
});

export const editOrganizationTravelConditions = (payload) => ({
  type: EDIT_ORG_TRAVEL_CONDITIONS,
  payload,
});

export const editOrganizationPrivacyStatement = (payload) => ({
  type: EDIT_ORG_PRIVACY_STATEMENT,
  payload,
});

export const editOrganizationContactName = (payload) => ({
  type: EDIT_ORG_CONTACT_NAME,
  payload,
});

export const editOrganizationContactEmail = (payload) => ({
  type: EDIT_ORG_CONTACT_EMAIL,
  payload,
});

export const editOrganizationContactPhone = (payload) => ({
  type: EDIT_ORG_CONTACT_PHONE,
  payload,
});

export const editOrganizationDescription = (payload) => ({
  type: EDIT_ORG_DESCRIPTION,
  payload,
});

export const editOrganizationThingsToNote = (payload) => ({
  type: EDIT_ORG_THINGS_TO_NOTE,
  payload,
});

export const editOrganizationQuestions = (payload) => ({
  type: EDIT_ORG_QUESTIONS,
  payload,
});

export const editOrganizationWebsite = (payload) => ({
  type: EDIT_ORG_WEBSITE,
  payload,
});

export const editOrganizationStreet = (payload) => ({
  type: EDIT_ORG_STREET,
  payload,
});

export const editOrganizationPostalCode = (payload) => ({
  type: EDIT_ORG_POSTAL_CODE,
  payload,
});

export const editOrganizationCity = (payload) => ({
  type: EDIT_ORG_CITY,
  payload,
});

export const editOrganizationCountry = (payload) => ({
  type: EDIT_ORG_COUNTRY,
  payload,
});

export const editOrganizationBusinessCode = (payload) => ({
  type: EDIT_ORG_BUSINESS_CODE,
  payload,
});

export const editOrganizationBankAccount = (payload) => ({
  type: EDIT_ORG_BANK_ACCOUNT,
  payload,
});

export const editOrganizationMarketingAuthorization = (payload) => ({
  type: EDIT_ORG_MARKETING_AUTHORIZATION,
  payload,
});

export const toggleOrganizationRegistering = (payload) => ({
  type: UPDATE_ORG_REGISTERING,
  payload,
});
