export const getOrganizationAccomodations = (state) => {
  const { filterAccomodationsOrg, accomodations } = state;
  if (filterAccomodationsOrg !== 'all') {
    let filteredAccomodations = accomodations.filter(
      (accomodation) => accomodation.organization === filterAccomodationsOrg
    );
    return { accomodations: filteredAccomodations };
  } else {
    return { accomodations };
  }
};

export const getOrganizationAccomodationsGroupForm = (state) => {
  const { filterAccomodationsOnGroupFormByOrg, accomodations } = state;
  if (filterAccomodationsOnGroupFormByOrg !== 'all') {
    let filteredAccomodations = accomodations.filter(
      (accomodation) =>
        accomodation.organization === filterAccomodationsOnGroupFormByOrg
    );

    return { accomodations: filteredAccomodations };
  } else {
    return { accomodations };
  }
};

export const getFilterAccomodationsOrg = (state) => {
  const { filterAccomodationsOrg } = state;
  return { filterAccomodationsOrg };
};

export const getAccomodationFromState = (state) => {
  const { accomodation } = state;
  return { accomodation };
};
