import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  info: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '120%',
    /* or 74px */
    color: '#FFFFFF',
    zIndex: 1000,
  },
}));

const HeaderInfo = ({ children }) => {
  const classes = useStyles();

  return <Typography className={classes.info}>{children}</Typography>;
};

export default HeaderInfo;
