import React, { Fragment } from 'react';
import AdminRoute from 'components/routes/AdminRoute';
import { ADMIN, OWNER, ROOT } from 'constants/accessGroups';

import Groups from 'components/views/groups/Groups';
import Events from 'components/views/events/Events';
import EventForm from 'components/views/events/EventForm';
import Organization from 'components/views/organization/Organization';
import Admin from 'components/views/admin/Admin';
import Email from 'components/views/email/Email';
import DiscountCodes from 'components/views/discount-codes/DiscountCodes';
import GroupForm from 'components/views/groups/GroupForm';
import AdminDashboard from 'components/views/adminDashboard/AdminDashboard';
import Product from 'components/views/product/Product';
import Users from 'components/views/user/Users';
import UserForm from 'components/views/user/UserForm';
import Ferrys from 'components/views/ferrys/Ferrys';
import FerryForm from 'components/views/ferrys/FerryForm';
import Accomodations from 'components/views/accomodation/Accomodations';
import AccomodationForm from 'components/views/accomodation/AccomodationForm';
import DiscountCodeForm from 'components/views/discount-codes/DiscountCodeForm';

const AdminContentRoutes = () => {
  return (
    <Fragment>
      <AdminRoute
        exact
        path="/admin-dashboard"
        component={AdminDashboard}
        roles={ADMIN}
      />
      <AdminRoute exact path="/groups" component={Groups} roles={ADMIN} />
      <AdminRoute
        exact
        path="/group-form"
        component={GroupForm}
        roles={ADMIN}
      />
      <AdminRoute exact path="/events" component={Events} roles={ADMIN} />
      <AdminRoute
        exact
        path="/event-form"
        component={EventForm}
        roles={ADMIN}
      />
      <AdminRoute exact path="/ferrys" component={Ferrys} roles={ADMIN} />
      <AdminRoute
        exact
        path="/ferry-form"
        component={FerryForm}
        roles={ADMIN}
      />
      <AdminRoute
        exact
        path="/accomodations"
        component={Accomodations}
        roles={ADMIN}
      />
      <AdminRoute
        exact
        path="/accomodation-form"
        component={AccomodationForm}
        roles={ADMIN}
      />

      <AdminRoute exact path="/customers" component={Users} roles={ADMIN} />
      <AdminRoute
        exact
        path="/customer-form"
        component={UserForm}
        roles={ADMIN}
      />
      <AdminRoute
        exact
        path="/organization"
        component={Organization}
        roles={ROOT}
      />
      <AdminRoute exact path="/products" component={Product} roles={ROOT} />
      <AdminRoute exact path="/admins" component={Admin} roles={OWNER} />
      <AdminRoute exact path="/emails" component={Email} roles={OWNER} />
      <AdminRoute
        exact
        path="/discount-codes"
        component={DiscountCodes}
        roles={OWNER}
      />
      <AdminRoute
        exact
        path="/discountcode-form"
        component={DiscountCodeForm}
        roles={OWNER}
      />
    </Fragment>
  );
};

export default AdminContentRoutes;
