import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

export const FETCH_EMAILS_REQUESTED = 'FETCH_EMAILS_REQUESTED';
export const FETCH_EMAILS_RESPONSE = 'FETCH_EMAILS_RESPONSE';
export const FETCH_EMAILS_ERROR = 'FETCH_EMAILS_ERROR';

export const UPDATE_EMAIL_REQUESTED = 'UPDATE_EMAIL_REQUESTED';
export const UPDATE_EMAIL_RESPONSE = 'UPDATE_EMAIL_RESPONSE';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR';

export const SAVE_EMAIL_REQUESTED = 'SAVE_EMAIL_REQUESTED';
export const SAVE_EMAIL_RESPONSE = 'SAVE_EMAIL_RESPONSE';
export const SAVE_EMAIL_ERROR = 'SAVE_EMAIL_ERROR';

export const DELETE_EMAIL_ERROR = 'DELETE_EMAIL_ERROR';
export const DELETE_EMAIL_REQUESTED = 'DELETE_EMAIL_REQUESTED';
export const DELETE_EMAIL_RESPONSE = 'DELETE_EMAIL_RESPONSE';

export const UPDATE_EMAIL_NAME = 'UPDATE_EMAIL_NAME';
export const UPDATE_EMAIL_IDENTIFIER = 'UPDATE_EMAIL_IDENTIFIER';
export const UPDATE_EMAIL_HTML = 'UPDATE_EMAIL_HTML';
export const UPDATE_EMAIL_SUBJECT = 'UPDATE_EMAIL_SUBJECT';
export const UPDATE_EMAIL_ORGANIZATION = 'UPDATE_EMAIL_ORGANIZATION';

export const SELECT_NEW_EMAIL = 'SELECT_NEW_EMAIL';
export const SELECT_EXISTING_EMAIL = 'SELECT_EXISTING_EMAIL';
export const SET_EMAIL_FILTER_ORG = 'SET_EMAIL_FILTER_ORG';

export const saveEmail = (email) => async (dispatch) => {
  dispatch({ type: SAVE_EMAIL_REQUESTED });
  try {
    const res = await API.post('/api/admin/emails', email);

    dispatch(setAlert('Email saved succesfully', 'success'));

    dispatch({ type: SAVE_EMAIL_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: SAVE_EMAIL_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateEmail = (email) => async (dispatch) => {
  dispatch({ type: UPDATE_EMAIL_REQUESTED });
  try {
    const res = await API.put(`/api/admin/emails/${email._id}`, email);

    dispatch(setAlert('Email updated successfully', 'success'));
    dispatch({ type: UPDATE_EMAIL_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch(setAlert('Failed to update email', 'error'));
    dispatch({
      type: UPDATE_EMAIL_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const getEmails = () => async (dispatch) => {
  dispatch({ type: FETCH_EMAILS_REQUESTED });
  try {
    const res = await API.get('/api/admin/emails');
    dispatch({ type: FETCH_EMAILS_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch({
      type: FETCH_EMAILS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteEmail = (email) => async (dispatch) => {
  dispatch({ type: DELETE_EMAIL_REQUESTED });
  try {
    const res = await API.delete(`/api/admin/emails/${email._id}`);
    dispatch(setAlert('Email successfully deleted', 'success'));
    dispatch({ type: DELETE_EMAIL_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch(setAlert('Failed to delete email', 'error'));
    dispatch({
      type: DELETE_EMAIL_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const selectExistingEmail = (payload) => ({
  type: SELECT_EXISTING_EMAIL,
  payload,
});

export const selectNewEmail = (organization) => ({
  type: SELECT_NEW_EMAIL,
  payload: organization,
});

export const updateEmailName = (payload) => ({
  type: UPDATE_EMAIL_NAME,
  payload,
});

export const updateEmailIdentifier = (payload) => ({
  type: UPDATE_EMAIL_IDENTIFIER,
  payload,
});

export const updateEmailHtml = (payload) => ({
  type: UPDATE_EMAIL_HTML,
  payload,
});

export const updateEmailSubject = (payload) => ({
  type: UPDATE_EMAIL_SUBJECT,
  payload,
});

export const updateEmailOrganization = (payload) => ({
  type: UPDATE_EMAIL_ORGANIZATION,
  payload,
});
