import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import {
  saveSearchBarValue,
  fetchOrganizationsCustomersPagination,
} from 'redux/actions/paginatedUsers';
import { getValuesFromState } from 'redux/selectors/paginatedUsers';
import { combineReqData } from './CombineReqData';

const defaultSearchStyles = (theme) => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
  },
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
});

export const CustomSearchRender = (props) => {
  const dispatch = useDispatch();
  const { rowsPerPage, selectedOrg, activeFilters, searchBarValue } =
    useSelector((state) => getValuesFromState(state.paginatedUsers));
  const { classes, options, onHide, searchText } = props;

  const handleTextChange = (event) => {
    props.onSearch(event.target.value);
  };
  const handleSearch = (event) => {
    dispatch(saveSearchBarValue(event.target.value));
  };

  const doSearch = (event) => {
    dispatch(
      fetchOrganizationsCustomersPagination(
        combineReqData(
          false,
          false,
          rowsPerPage,
          selectedOrg,
          null,
          activeFilters,
          searchBarValue
        )
      )
    );
  };
  const clearSearch = (event) => {
    dispatch(saveSearchBarValue(''));
    dispatch(
      fetchOrganizationsCustomersPagination(
        combineReqData(
          false,
          false,
          rowsPerPage,
          selectedOrg,
          null,
          activeFilters,
          ''
        )
      )
    );
    onHide();
  };

  return (
    <Grow appear in={true} timeout={300}>
      <div className={classes.main}>
        <TextField
          placeholder={'Email'}
          className={classes.searchText}
          InputProps={{
            'aria-label': options.textLabels.toolbar.search,
          }}
          value={searchText || ''}
          onChange={handleTextChange}
          onBlur={handleSearch}
          fullWidth={true}
        />

        <IconButton className={classes.SearchIcon} onClick={doSearch}>
          <SearchIcon />
        </IconButton>
        <IconButton className={classes.clearIcon} onClick={clearSearch}>
          <ClearIcon />
        </IconButton>
      </div>
    </Grow>
  );
};

export default withStyles(defaultSearchStyles, { name: 'CustomSearchRender' })(
  CustomSearchRender
);
