import { Button } from '@material-ui/core';
import React, { Fragment } from 'react';
import CsvDownloader from 'react-csv-downloader';

const CsvDownloaderFailed = ({ data }) => {
  const columns = [
    {
      id: 'firstName',
      displayName: 'First name',
    },
    {
      id: 'lastName',
      displayName: 'Last name',
    },
    {
      id: 'email',
      displayName: 'Email',
    },
    {
      id: 'phone',
      displayName: 'Phone number',
    },
    {
      id: 'group',
      displayName: 'Group',
    },
    {
      id: 'event',
      displayName: 'Event',
    },
    {
      id: 'product',
      displayName: 'Product',
    },
    {
      id: 'description',
      displayName: 'Description',
    },
    {
      id: 'workaround',
      displayName: 'Workaround',
    },
    { id: 'meta', displayName: 'Meta info' },
  ];

  const createCsvData = () => {
    return data.map((fail) => {
      let { payment } = fail;
      return {
        firstName: payment.user.firstName,
        lastName: payment.user.lastName,
        email: payment.user.email,
        phone: payment.user.phone,
        group: payment.user.group.name,
        event: payment.event.name,
        product: payment.products[0].name,
        description: fail.description,
        workaround: fail.workaround,
        meta: JSON.stringify(fail.meta),
      };
    });
  };

  return (
    <Fragment>
      <h2>Download list of failed refunds!</h2>
      <Button color="secondary" size="medium" variant="contained">
        <CsvDownloader
          columns={columns}
          datas={createCsvData()}
          filename="failed_refunds"
          extension=".csv"
          separator=";"
          wrapColumnChar=""
        >
          Download csv of failed refunds
        </CsvDownloader>
      </Button>
    </Fragment>
  );
};

export default CsvDownloaderFailed;
