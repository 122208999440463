import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestPasswordReset } from 'redux/actions/auth';

import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

import CustomerAlert from 'components/layouts/CustomerAlert';
import Container from './Container';
import Header from './Header';
import HeaderInfo from './HeaderInfo';
import SubmitButton from './SubmitButton';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  formInput: {
    background: 'rgba(255, 255, 255, 0.1)',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '119%',
    /* or 74px */
    color: '#FFFFFF',
    opacity: 0.5,
  },

  inputText: {
    color: '#FFFFFF',
    opacity: 0.95,
  },

  inputLabel: {
    color: '#FFFFFF',
    opacity: 0.95,
    '&.focused': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
    '&.shrink': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
  },
}));

export const PasswordResetRequest = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const mobileScreen = useMediaQuery('(max-width:990px)');

  const { isResetEmailSent } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    email: '',
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onClick = (e) => {
    e.preventDefault();
    dispatch(requestPasswordReset(email));
  };

  return (
    <Fragment>
      <Container mobileScreen={mobileScreen}>
        {!isResetEmailSent ? (
          <Fragment>
            <Header>Unohditko salasanasi?</Header>
            <HeaderInfo>
              Mikäli olet unohtanut salasanasi, anna käyttämäsi sähköpostiosoite
              ja saat sähköpostilla linkin, jonka kautta voit vaihtaa
              salasanasi.
            </HeaderInfo>
            <CustomerAlert />
            <form className={classes.form} noValidate>
              <TextField
                className={classes.formInput}
                margin="normal"
                required
                fullWidth
                name="email"
                label="Email"
                type="email"
                id="email"
                value={email}
                onChange={(e) => onChange(e)}
                autoFocus
                InputProps={{
                  className: classes.inputText,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: 'focused',
                    shrink: 'shrink',
                  },
                }}
              />
              <SubmitButton fullWidth={mobileScreen} onClick={onClick}>
                Pyydä uusi salasana
              </SubmitButton>
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <Header>Tarkista sähköpostisi!</Header>
            <HeaderInfo>Tarkista myös roskaposti-kansio</HeaderInfo>
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};

export default PasswordResetRequest;
