import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CUSTOMER } from 'constants/roles';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loading, role } = useSelector((state) => state.auth);

  /* If in loading state, return loading message while waiting for 
  loadAuth() to complete */
  if (loading) {
    return <div></div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <Redirect to="/" />
        ) : role !== CUSTOMER ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
