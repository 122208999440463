import {
  UPDATE_INFO_FIRST_NAME,
  UPDATE_INFO_LAST_NAME,
  UPDATE_INFO_EMAIL,
  UPDATE_INFO_PHONE,
  UPDATE_INFO_ADDRESS_STREET,
  UPDATE_INFO_ADDRESS_POSTCODE,
  UPDATE_INFO_ADDRESS_CITY,
  UPDATE_INFO_PASSWORD,
  UPDATE_INFO_BIRTHDAY,
  UPDATE_INFO_MARKETING,
  UPDATE_INFO_COUNTRY,
  UPDATE_INFO_GENDER,
  TOGGLE_EDIT_USER_INFO,
  TOGGLE_SHOW_PASSWORD,
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_REQUESTED,
  FETCH_USER_INFO_RESPONSE,
  SAVE_USER_INFO_ERROR,
  SAVE_USER_INFO_REQUESTED,
  SAVE_USER_INFO_RESPONSE,
  SAVE_ROOM_PARTNER_INFO_REQUESTED,
  SAVE_ROOM_PARTNER_INFO_RESPONSE,
  SAVE_ROOM_PARTNER_INFO_ERROR,
  SET_PARTNER_GENDER,
  SET_ROOMTYPE,
  UPDATE_ROOMPARTNERS,
  USER_ADDS_DISCOUNT_CODE_RESPONSE_OK,
  FETCH_GROUP_USERS_REQUESTED,
  FETCH_GROUP_USERS_RESPONSE,
  FETCH_GROUP_USERS_ERROR,
} from 'redux/actions/customer';

const initialState = {
  group: {
    outboundFerry: null,
    returnFerry: null,
    accomodation: null,
  },
  event: {},
  info: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: {
      street: '',
      postcode: '',
      city: '',
    },
    country: '',
    gender: '',
    password: '',
    birthday: '',
    marketing: false,
    _id: '',
  },
  roomPartner: {
    byGender: null,
    byRoomtype: null,
    roompartners: [],
  },
  showPassword: false,
  editUserInfo: false,
  discountCode: { success: false },
  error: {},
};

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_USER_INFO_RESPONSE:
      return {
        ...state,
        info: action.payload,
        event: action.payload.group.event,
        group: action.payload.group,
      };

    case SAVE_USER_INFO_RESPONSE:
      return {
        ...state,
        info: action.payload,
        event: action.payload.group.event,
        group: action.payload.group,
        editUserInfo: false,
      };

    case SAVE_ROOM_PARTNER_INFO_RESPONSE:
      return {
        ...state,
        info: action.payload,
        event: action.payload.group.event,
        group: action.payload.group,
      };

    case SET_PARTNER_GENDER:
      return {
        ...state,
        roomPartner: {
          ...state.roomPartner,
          byGender:
            action.payload === state.roomPartner.byGender
              ? null
              : action.payload,
        },
      };
    case SET_ROOMTYPE:
      return {
        ...state,
        roomPartner: {
          ...state.roomPartner,
          byRoomtype:
            action.payload === state.roomPartner.byRoomtype
              ? null
              : action.payload,
        },
      };

    case UPDATE_ROOMPARTNERS:
      return {
        ...state,
        roomPartner: {
          ...state.roomPartner,
          roompartners: action.payload,
        },
      };

    case SAVE_ROOM_PARTNER_INFO_ERROR:
      return {
        ...state,
        roomPartner: {
          byGender: null,
          byRoomtype: null,
          roompartners: [],
        },
        error: action.payload,
      };
    case SAVE_USER_INFO_ERROR:
    case FETCH_USER_INFO_ERROR:
      return { ...state, error: action.payload };
    case FETCH_GROUP_USERS_ERROR:
      return { ...state, error: action.payload };
    case TOGGLE_EDIT_USER_INFO:
      return { ...state, editUserInfo: !state.editUserInfo };

    case TOGGLE_SHOW_PASSWORD:
      return { ...state, showPassword: !state.showPassword };

    case UPDATE_INFO_MARKETING:
      return {
        ...state,
        info: { ...state.info, marketing: !state.info.marketing },
      };

    case UPDATE_INFO_FIRST_NAME:
      return {
        ...state,
        info: { ...state.info, firstName: action.payload },
      };

    case UPDATE_INFO_LAST_NAME:
      return {
        ...state,
        info: { ...state.info, lastName: action.payload },
      };

    case UPDATE_INFO_EMAIL:
      return {
        ...state,
        info: { ...state.info, email: action.payload },
      };

    case UPDATE_INFO_PHONE:
      return {
        ...state,
        info: { ...state.info, phone: action.payload },
      };

    case UPDATE_INFO_COUNTRY:
      return {
        ...state,
        info: { ...state.info, country: action.payload },
      };
    case UPDATE_INFO_GENDER:
      return {
        ...state,
        info: { ...state.info, gender: action.payload },
      };

    case UPDATE_INFO_PASSWORD:
      return {
        ...state,
        info: { ...state.info, password: action.payload },
      };

    case UPDATE_INFO_BIRTHDAY:
      return {
        ...state,
        info: { ...state.info, birthday: action.payload },
      };

    case UPDATE_INFO_ADDRESS_CITY:
      return {
        ...state,
        info: {
          ...state.info,
          address: { ...state.info.address, city: action.payload },
        },
      };

    case UPDATE_INFO_ADDRESS_STREET:
      return {
        ...state,
        info: {
          ...state.info,
          address: { ...state.info.address, street: action.payload },
        },
      };

    case UPDATE_INFO_ADDRESS_POSTCODE:
      return {
        ...state,
        info: {
          ...state.info,
          address: { ...state.info.address, postcode: action.payload },
        },
      };
    case USER_ADDS_DISCOUNT_CODE_RESPONSE_OK:
      return {
        ...state,
        discountCode: { success: true },
      };
    case FETCH_GROUP_USERS_RESPONSE:
      return {
        ...state,
        groupUsers: action.payload,
      };
    case SAVE_USER_INFO_REQUESTED:
    case FETCH_USER_INFO_REQUESTED:
    case SAVE_ROOM_PARTNER_INFO_REQUESTED:
    case FETCH_GROUP_USERS_REQUESTED:
    default:
      return state;
  }
}
