import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '16%',
    marginLeft: '25%',
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },

  containerMobile: {
    marginTop: '18%',
    marginLeft: '8%',
    marginRight: '8%',
    width: '84%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
}));

const Container = ({ children, mobileScreen }) => {
  const classes = useStyles();
  return (
    <div className={mobileScreen ? classes.containerMobile : classes.container}>
      {children}
    </div>
  );
};

export default Container;
