export const getEventPaymentsFromState = (state) => {
  const { eventPayments, additionalServices } = state;
  return { eventPayments, additionalServices };
};

export const getMerchantInfo = (state) => {
  const { merchant } = state;
  return { merchant };
};

export const getRefundFromState = (state) => {
  const { refund } = state;
  return { refund };
};
