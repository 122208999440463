import axios from 'axios';
import { handleAuthenticationInterceptor } from './requestInterceptors';

const baseURL = process.env.REACT_APP_BASE_URL;
const API = baseURL ? axios.create({ baseURL }) : axios.create({});

API.interceptors.request.use((request) =>
  handleAuthenticationInterceptor(request)
);

export default API;
