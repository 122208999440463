import React from 'react';
import { NavLink } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PeopleIcon from '@material-ui/icons/People';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import GroupIcon from '@material-ui/icons/Group';
import HotelIcon from '@material-ui/icons/Hotel';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';

const AdminOptions = (props) => {
  return (
    <>
      <ListItem button component={NavLink} to="/ferrys">
        <ListItemIcon>
          <DirectionsBoatIcon />
        </ListItemIcon>
        <ListItemText primary="Ferrys" />
      </ListItem>
      <ListItem button component={NavLink} to="/accomodations">
        <ListItemIcon>
          <HotelIcon />
        </ListItemIcon>
        <ListItemText primary="Accomodations" />
      </ListItem>
      <ListItem button component={NavLink} to="/events">
        <ListItemIcon>
          <EventAvailableIcon />
        </ListItemIcon>
        <ListItemText primary="Events" />
      </ListItem>
      <ListItem button component={NavLink} to="/groups">
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary="Groups" />
      </ListItem>
      <ListItem button component={NavLink} to="/customers">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItem>
    </>
  );
};

export default AdminOptions;
