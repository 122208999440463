import React, { Fragment, useState } from 'react';
import { Button, makeStyles, useMediaQuery } from '@material-ui/core';
import PaymentDialog from 'components/views/customer/payments/PaymentDialog';

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      background: '#AD00FF',
    },
    marginLeft: '7%',
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    background: '#20D2B7',
    flex: 'none',
    margin: '8px 0px',
  },
}));

export const Payment = ({ payment }) => {
  const classes = useStyles();
  const mobileScreen = useMediaQuery('(max-width:990px)');

  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

  const handleClose = () => {
    setPaymentDialogOpen(false);
  };

  return (
    <Fragment>
      <Button
        className={classes.button}
        onClick={() => {
          setPaymentDialogOpen(true);
        }}
      >
        {payment.products[0].mandatory ? 'Maksa' : 'Osta'}
      </Button>
      <PaymentDialog
        visible={paymentDialogOpen}
        closeDialog={handleClose}
        payment={payment}
        mobile={mobileScreen}
      />
    </Fragment>
  );
};

export default Payment;
