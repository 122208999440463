export const getPaginatedUsersFromState = (state) => {
  const { users, filterByRoom } = state;

  if (filterByRoom) {
    const usersToReturn = [];
    const usersInList = [];

    users.forEach((user) => {
      // Check if user is already in list
      if (usersInList.indexOf(user._id) === -1) {
        // Check if user has room partners
        if (user.room && user.room.users) {
          // Check if room partner is already in list and if not add user to list of users to show for admin
          // and also add user and his partners to the list where we check who are already in
          if (
            !user.room.users.some((obj) => usersInList.indexOf(obj._id) !== -1)
          ) {
            usersToReturn.push(user);
            usersInList.push(user._id);
            user.room.users.forEach((obj) => {
              if (usersInList.indexOf(obj._id) === -1) {
                usersInList.push(obj._id);
              }
            });
          }
        }
      }
    });

    return { users: usersToReturn };
  }

  return { users };
};

export const getValuesFromState = (state) => {
  const {
    page,
    rowsPerPage,
    count,
    selectedOrg,
    filters,
    activeFilters,
    searchBarValue,
    roomFilters,
  } = state;
  return {
    rowsPerPage,
    page,
    count,
    selectedOrg,
    filters,
    activeFilters,
    searchBarValue,
    roomFilters,
  };
};
