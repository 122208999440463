import React, { useState, useEffect } from 'react';
import { TextField, Button, Chip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateRoompartners } from 'redux/actions/customer';

import {
  getUserInfoFormState,
  getRoomPartnerInfo,
} from 'redux/selectors/customer';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  confirmButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    width: '90%',
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    margin: '8px 0px',
  },
}));

function RoomPartnerSelector() {
  const classes = useStyles();

  const { groupUsers: users } = useSelector((state) => state.customer);
  const { info: user } = useSelector((state) =>
    getUserInfoFormState(state.customer)
  );

  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [error, setError] = useState(undefined);

  const { roomPartner } = useSelector((state) =>
    getRoomPartnerInfo(state.customer)
  );
  const selectedUsers = roomPartner.roompartners || [];

  const maxUsers = roomPartner.byRoomtype === '2h' ? 1 : 2;

  useEffect(() => {
    setInputValue('');
    setSelectedCodes([]);
  }, [roomPartner.byRoomtype]);

  const handleChange = (event) => {
    if (error) {
      setError(undefined);
    }
    setInputValue(event.target.value);
  };

  const handleAdd = () => {
    try {
      const pickedUser = users.find((x) => x.userCode === inputValue);

      if (pickedUser && pickedUser._id === user._id)
        throw new Error('Et voi valita itseäsi huonekaveriksi');

      if (!pickedUser) throw new Error('Käyttäjää ei löytynyt');

      if (pickedUser.roomPartnerChosen)
        throw new Error('Käyttäjällä on jo huonekaveri');

      if (selectedUsers.length >= maxUsers)
        throw new Error('Maksimimäärä huonekavereita valittu');

      if (selectedUsers.find((x) => x.id === pickedUser._id))
        throw new Error('Käyttäjä on jo valittu huonekaveriksi');

      const val = pickedUser
        ? {
            name: pickedUser.firstName + ' ' + pickedUser.lastName,
            id: pickedUser._id,
          }
        : undefined;

      if (val) {
        setSelectedCodes([...selectedCodes, inputValue]);
        const newSelectedUsers = [...selectedUsers, val];
        dispatch(updateRoompartners(newSelectedUsers));
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleRemove = (userId) => {
    const newSelectedUsers = selectedUsers.filter((user) => user.id !== userId);
    dispatch(updateRoompartners(newSelectedUsers));
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ display: 'flex', gap: '10px' }}>
        {selectedUsers.map((user) => (
          <Chip
            key={user.id}
            label={user.name}
            onDelete={() => handleRemove(user.id)}
          />
        ))}
      </div>

      {selectedUsers.length < maxUsers && (
        <>
          <TextField
            style={{ width: '90%', marginTop: '10px' }}
            value={inputValue}
            onChange={handleChange}
            label={'Huonekaverin koodi'}
            error={typeof error === 'string'}
            helperText={error}
          />
          <Button className={classes.confirmButton} onClick={handleAdd}>
            Lisää
          </Button>
        </>
      )}
    </div>
  );
}

export default RoomPartnerSelector;
