import { v4 as uuid } from 'uuid';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const setAlert =
  (msg, severity, customer, type, timeout = 5000) =>
  (dispatch) => {
    const id = uuid();

    dispatch({
      type: SET_ALERT,
      payload: { msg, severity, id, customer: customer ? customer : false },
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };
