import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { getOrganizations } from 'redux/actions/organization';
import {
  selectExistingGroup,
  selectNewGroup,
  selectOrgToFilter,
  selectEventToFilter,
  fetchGroupsSmall,
} from 'redux/actions/group';

import {
  selectOrgToFilterEventsGroups,
  fetchEventsSmall,
} from 'redux/actions/event';

import {
  getOrganizationGroups,
  getFilterGroupsOptions,
} from 'redux/selectors/group';

import { getOrganizationEventsForGroups } from 'redux/selectors/event';
import { getOrganizationsFromState } from 'redux/selectors/organization';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  paymentTitle: {
    marginTop: theme.spacing(2),
    fontSize: 14,
    fontWeight: 'bold',
  },
  groupInfo: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  cardActionCenter: {
    justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minWidth: 120,
  },
  fab: {
    background: theme.palette.success.main,
    color: 'white',
    position: 'fixed',
    top: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

const getOrganizationsMenuItems = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

const getEventsMenuItems = (events) =>
  events.map((event) => (
    <MenuItem key={event._id} value={event._id}>
      {event.name}
    </MenuItem>
  ));

const countPayedPayments = (productId, users) => {
  let payed = 0;
  if (users) {
    users.forEach((user) => {
      if (user.eventPayments) {
        user.eventPayments.forEach((payment) => {
          if (
            payment.products.indexOf(productId) !== -1 &&
            (payment.status === 'confirmed' || payment.status === 'accepted')
          ) {
            payed++;
          }
        });
      }
    });
    users.forEach((user) => {
      if (user.additionalServices) {
        user.additionalServices.forEach((payment) => {
          if (
            payment.products.indexOf(productId) !== -1 &&
            (payment.status === 'confirmed' || payment.status === 'accepted')
          ) {
            payed++;
          }
        });
      }
    });
  }

  return payed;
};

const groupMandatoryPayments = (group) => {
  let payments = [];
  for (let eventProduct of group?.event?.products ?? []) {
    if (eventProduct.product.mandatory) {
      payments.push(eventProduct);
    }
  }
  for (let groupProduct of group?.products ?? []) {
    if (groupProduct.product.mandatory) {
      payments.push(groupProduct);
    }
  }

  return payments;
};

const Groups = () => {
  const [usedOrgId, setUsedOrgId] = React.useState('');
  const [usedEventId, setUsedEventId] = React.useState('');

  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();

  const { role } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      (usedOrgId !== 'all' && usedOrgId !== '') ||
      (usedEventId !== 'all' && usedEventId !== '')
    ) {
      dispatch(fetchGroupsSmall(usedOrgId, usedEventId));
    }
  }, [dispatch, usedOrgId, usedEventId]);

  useEffect(() => {
    dispatch(fetchEventsSmall());
  }, [dispatch]);

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  const { organizations } = useSelector((state) =>
    getOrganizationsFromState(state.organization)
  );

  const { events } = useSelector((state) =>
    getOrganizationEventsForGroups(state.event)
  );

  const { filterGroupsOrg, filterGroupsEvent } = useSelector((state) =>
    getFilterGroupsOptions(state.group)
  );

  const { groups } = useSelector((state) => getOrganizationGroups(state.group));

  const handleEditDetailsClick = (groupObject) => {
    groupObject.event = groupObject.event._id;
    dispatch(selectExistingGroup(groupObject));
    history.push('/group-form');
  };

  return (
    <div className={classes.root}>
      <h2> Groups </h2>
      <div>
        {role === 'root' && organizations && (
          <Fragment>
            <FormControl
              className={classes.formControl}
              key={`amountOfSelections_${organizations.length}`}
            >
              <InputLabel id="organizationSelectionLabel">
                Filter by org
              </InputLabel>
              <Select
                labelId="organizationSelectionLabel"
                id="organizationSelection"
                defaultValue=""
                onChange={({ target: { value } }) => {
                  setUsedOrgId(value);
                  dispatch(selectOrgToFilter(value));
                  dispatch(selectOrgToFilterEventsGroups(value));
                }}
              >
                <MenuItem key="all" value={'all'}>
                  All
                </MenuItem>
                {getOrganizationsMenuItems(organizations)}
              </Select>
            </FormControl>
          </Fragment>
        )}
        {events && events.length > 0 && (
          <FormControl
            className={classes.formControl}
            key={`amountOfSelections_${events.length}`}
          >
            <InputLabel id="eventSelectionLabel">Filter by event</InputLabel>
            <Select
              labelId="eventSelectionLabel"
              id="eventSelection"
              value={filterGroupsEvent}
              onChange={({ target: { value } }) => {
                setUsedEventId(value);
                dispatch(selectEventToFilter(value));
              }}
            >
              <MenuItem key="all" value={'all'}>
                All
              </MenuItem>
              {getEventsMenuItems(events)}
            </Select>
          </FormControl>
        )}
      </div>

      <Fab
        className={classes.fab}
        component={NavLink}
        to="/group-form"
        onClick={() => dispatch(selectNewGroup())}
        aria-label="add"
        variant="extended"
      >
        <AddIcon />
        New
      </Fab>
      <div key={filterGroupsOrg}>
        <Grid
          container
          justifyContent="center"
          spacing={3}
          key={filterGroupsEvent}
        >
          {groups && groups.length > 0 ? (
            groups.map((groupObject) => (
              <Fragment key={groupObject._id}>
                <Grid item>
                  <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                        variant="h2"
                      >
                        {groupObject.name}
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                        variant="h3"
                      >
                        Event:{' '}
                        {groupObject.event.name
                          ? groupObject.event.name
                          : groupObject.event.identifier}
                      </Typography>

                      <Typography className={classes.groupInfo}>
                        Users: {groupObject.users.length}
                      </Typography>
                      <Typography className={classes.groupInfo}>
                        Capacity: {groupObject.capacity}
                      </Typography>
                      <Typography
                        className={classes.paymentTitle}
                        color="textPrimary"
                        gutterBottom
                        variant="h2"
                      >
                        Payments done
                      </Typography>
                      {groupMandatoryPayments(groupObject).length !== 0
                        ? groupMandatoryPayments(groupObject).map((obj) => (
                            <Typography
                              key={obj.product._id}
                              className={classes.eventInfo}
                            >
                              {obj.product.name}:{' '}
                              {countPayedPayments(
                                obj.product._id,
                                groupObject.users
                              )}{' '}
                              / {groupObject.capacity}
                            </Typography>
                          ))
                        : 'No products'}
                    </CardContent>
                    <CardActions className={classes.cardActionCenter}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => handleEditDetailsClick(groupObject)}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Fragment>
            ))
          ) : (
            <Fragment>
              <h3>Please start by adding a group</h3>
            </Fragment>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Groups;
