import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import DiscountCodeSubmit from './discountCodeSubmit';
import { makeStyles } from '@material-ui/core';
import CustomerAlert from 'components/layouts/CustomerAlert';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      background: '#AD00FF',
    },
    marginLeft: '7%',
    width: '60%',
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    margin: '8px 0px',
  },
  discountCodeContainer: {
    marginTop: '20px',
  },
}));

export const DiscountCode = ({ userId }) => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const { success } = useSelector((state) => state.customer.discountCode);

  return (
    <div className={classes.discountCodeContainer}>
      <CustomerAlert />
      {showForm && !success && <DiscountCodeSubmit userId={userId} />}
      {!success && !showForm && (
        <Button
          className={classes.button}
          onClick={() => setShowForm(!showForm)}
        >
          Alennuskoodi?
        </Button>
      )}
    </div>
  );
};

export default DiscountCode;
