import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

import { selectOrgToFilterEventsGroups } from 'redux/actions/event';
import { selectOrgToFilterFerrysGroupForm } from 'redux/actions/ferry';
import { selectOrgToFilterAccomodationsGroupForm } from 'redux/actions/accomodation';

export const FETCH_GROUPS_REQUESTED = 'FETCH_GROUPS_REQUESTED';
export const FETCH_GROUPS_RESPONSE = 'FETCH_GROUPS_RESPONSE';
export const FETCH_GROUPS_ERROR = 'FETCH_GROUPS_ERROR';

export const UPDATE_GROUP_REQUESTED = 'UPDATE_GROUP_REQUESTED';
export const UPDATE_GROUP_RESPONSE = 'UPDATE_GROUP_RESPONSE';
export const UPDATE_GROUP_ERROR = 'UPDATE_GROUP_ERROR';

export const SAVE_GROUP_REQUESTED = 'SAVE_GROUP_REQUESTED';
export const SAVE_GROUP_RESPONSE = 'SAVE_GROUP_RESPONSE';
export const SAVE_GROUP_ERROR = 'SAVE_GROUP_ERROR';

export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';
export const DELETE_GROUP_REQUESTED = 'DELETE_GROUP_REQUESTED';
export const DELETE_GROUP_RESPONSE = 'DELETE_GROUP_RESPONSE';

export const UPDATE_GROUP_NAME = 'UPDATE_GROUP_NAME';
export const UPDATE_GROUP_CAPACITY = 'UPDATE_GROUP_CAPACITY';
export const UPDATE_GROUP_CONTACT_NAME = 'UPDATE_GROUP_CONTACT_NAME';
export const UPDATE_GROUP_CONTACT_EMAIL = 'UPDATE_GROUP_CONTACT_EMAIL';
export const UPDATE_GROUP_CONTACT_PHONE = 'UPDATE_GROUP_CONTACT_PHONE';
export const UPDATE_GROUP_EVENT = 'UPDATE_GROUP_EVENT';
export const UPDATE_GROUP_PRODUCTS = 'UPDATE_GROUP_PRODUCTS';
export const UPDATE_GROUP_PRODUCT_COLLECT_PAYMENT =
  'UPDATE_GROUP_PRODUCT_COLLECT_PAYMENT';
export const UPDATE_GROUP_OUTBOUND_FERRY = 'UPDATE_GROUP_OUTBOUND_FERRY';
export const UPDATE_GROUP_RETURN_FERRY = 'UPDATE_GROUP_RETURN_FERRY';
export const UPDATE_GROUP_ACCOMODATION = 'UPDATE_GROUP_ACCOMODATION';

export const SELECT_NEW_GROUP = 'SELECT_NEW_GROUP';
export const SELECT_EXISTING_GROUP = 'SELECT_EXISTING_GROUP';

export const SET_GROUP_FILTER_ORG = 'SET_GROUP_FILTER_ORG';
export const SET_GROUP_FILTER_EVENT = 'SET_GROUP_FILTER_EVENT';
export const SET_GROUP_FILTER_ORG_CUSTOMER_FORM =
  'SET_GROUP_FILTER_ORG_CUSTOMER_FORM';

export const fetchGroups = () => async (dispatch) => {
  dispatch({ type: FETCH_GROUPS_REQUESTED });
  try {
    const res = await API.get('/api/admin/groups');
    dispatch({
      type: FETCH_GROUPS_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_GROUPS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const fetchGroupsSmall = (orgId, eventId) => async (dispatch) => {
  dispatch({ type: FETCH_GROUPS_REQUESTED });
  try {
    // Construct query string
    const queryString = new URLSearchParams();
    if (orgId && orgId !== '' && orgId !== 'all') {
      queryString.append('orgId', orgId);
    }
    if (eventId && eventId !== '' && eventId !== 'all') {
      queryString.append('eventId', eventId);
    }

    const res = await API.get(`/api/admin/groups/groups-small?${queryString}`);
    dispatch({
      type: FETCH_GROUPS_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_GROUPS_ERROR,
      payload: {
        msg: error.response?.statusText || 'An error occurred',
        status: error.response?.status || 500,
      },
    });
  }
};

export const selectOrgToFilter = (orgId) => async (dispatch) => {
  dispatch({
    type: SET_GROUP_FILTER_ORG,
    payload: orgId,
  });
};

export const selectEventToFilter = (eventId) => async (dispatch) => {
  dispatch({
    type: SET_GROUP_FILTER_EVENT,
    payload: eventId,
  });
};

export const selectOrgToFilterForGroupForm = (orgId) => async (dispatch) => {
  dispatch({ type: SET_GROUP_FILTER_ORG_CUSTOMER_FORM, payload: orgId });
};

export const selectExistingGroup = (group) => async (dispatch) => {
  dispatch(selectOrgToFilterEventsGroups(group.organization));
  dispatch(selectOrgToFilterFerrysGroupForm(group.organization));
  dispatch(selectOrgToFilterAccomodationsGroupForm(group.organization));
  dispatch({
    type: SELECT_EXISTING_GROUP,
    payload: group,
  });
};

export const selectNewGroup = () => async (dispatch) => {
  dispatch({
    type: SELECT_NEW_GROUP,
  });
};

export const saveGroup = (group) => async (dispatch) => {
  dispatch({ type: SAVE_GROUP_REQUESTED });
  try {
    const res = await API.post('/api/admin/groups', group);

    dispatch(setAlert('Group saved succesfully', 'success'));

    dispatch({ type: SAVE_GROUP_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: SAVE_GROUP_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateGroup = (group) => async (dispatch) => {
  dispatch({ type: UPDATE_GROUP_REQUESTED });
  try {
    delete group.users;
    const res = await API.put(`/api/admin/groups/${group._id}`, group);
    dispatch(setAlert('Group updated successfully', 'success'));
    dispatch({ type: UPDATE_GROUP_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch(setAlert('Failed to update group', 'error'));
    dispatch({
      type: UPDATE_GROUP_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteGroup = (group) => async (dispatch) => {
  dispatch({ type: DELETE_GROUP_REQUESTED });
  try {
    const res = await API.delete(`/api/admin/groups/${group._id}`);
    dispatch(setAlert('Group successfully deleted', 'success'));
    dispatch({ type: DELETE_GROUP_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch(setAlert('Failed to delete group', 'error'));
    dispatch({
      type: DELETE_GROUP_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateGroupName = (payload) => ({
  type: UPDATE_GROUP_NAME,
  payload,
});

export const updateGroupCapacity = (payload) => ({
  type: UPDATE_GROUP_CAPACITY,
  payload,
});

export const updateGroupContactName = (payload) => ({
  type: UPDATE_GROUP_CONTACT_NAME,
  payload,
});

export const updateGroupContactEmail = (payload) => ({
  type: UPDATE_GROUP_CONTACT_EMAIL,
  payload,
});

export const updateGroupContactPhone = (payload) => ({
  type: UPDATE_GROUP_CONTACT_PHONE,
  payload,
});

export const updateGroupEvent = (payload) => ({
  type: UPDATE_GROUP_EVENT,
  payload,
});

export const updateGroupOutboundFerry = (payload) => ({
  type: UPDATE_GROUP_OUTBOUND_FERRY,
  payload,
});

export const updateGroupReturnFerry = (payload) => ({
  type: UPDATE_GROUP_RETURN_FERRY,
  payload,
});

export const updateGroupAccomodation = (payload) => ({
  type: UPDATE_GROUP_ACCOMODATION,
  payload,
});

export const updateGroupProducts = (payload) => ({
  type: UPDATE_GROUP_PRODUCTS,
  payload,
});

export const toggleCollectPaymentForGroupProduct = (payload) => ({
  type: UPDATE_GROUP_PRODUCT_COLLECT_PAYMENT,
  payload,
});
