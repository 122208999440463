import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import AlertMessage from 'components/layouts/AlertMessage';

import { KeyboardDatePicker } from '@material-ui/pickers';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

import { getOrganizations } from 'redux/actions/organization';

import {
  updateDiscountCodeAmount,
  updateDiscountCodeCode,
  updateDiscountCodeDescription,
  updateDiscountCodeDiscount,
  updateDiscountCodeName,
  updateDiscountCodeOrganization,
  updateDiscountCodeValidUntil,
  updateDiscountCodeApplyTo,
  saveDiscountCode,
  updateDiscountCode,
  fetchUsersWhoUsedDiscountCode,
  updateDiscountCodeDisabled,
} from 'redux/actions/discountCodes';
import { getDiscountCodeFromState } from 'redux/selectors/discountCodes';
import { getRoleAndOrganization } from 'redux/selectors/auth';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getOrgProducts } from 'redux/selectors/product';
import { fetchOrgProducts } from 'redux/actions/product';
import {
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  doubleRootWidth: {
    width: '50ch!important',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  formControlDescription: {
    margin: theme.spacing(1),
    width: 360,
  },

  alertBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    minHeight: 140,
    minWidth: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  control: {
    padding: theme.spacing(2),
  },
  productTitle: {
    fontSize: 14,
  },
  productInfo: {
    fontSize: 12,
  },
  popupIndicator: {
    paddingBottom: '20px',
  },
  clearIndicator: {
    paddingBottom: '20px',
  },
  tableContainer: {
    margin: theme.spacing(1),
    width: '65%',
  },
  tableDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionField: {
    width: '100%!important',
  },
}));

const organizationOptions = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

const DiscountCodeForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { discountCode } = useSelector((state) =>
    getDiscountCodeFromState(state.discountCode)
  );

  const { role } = useSelector((state) => getRoleAndOrganization(state.auth));

  const { organizationProducts } = useSelector((state) =>
    getOrgProducts(state.product)
  );

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  useEffect(() => {
    if (discountCode.organization) {
      dispatch(fetchOrgProducts(discountCode.organization));
    }
  }, [discountCode.organization, dispatch]);

  useEffect(() => {
    if (discountCode._id) {
      dispatch(fetchUsersWhoUsedDiscountCode(discountCode._id));
    }
  }, [discountCode._id, dispatch]);

  const { organizations } = useSelector((state) => state.organization);

  const createProductOptions = (products) => {
    const productOptions = [];
    products.forEach((product) => productOptions.push(product));
    return productOptions;
  };

  const RedCheckbox = withStyles({
    root: {
      color: red[400],
      '&$checked': {
        color: red[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <div>
      <h2>
        {discountCode._id ? 'Edit discount code' : 'Add new discount code'}{' '}
      </h2>

      {discountCode && (
        <Fragment>
          <form className={classes.root} noValidate autoComplete="off">
            <div className={classes.alertBox}>
              <AlertMessage />
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  labelPlacement="top"
                  control={
                    <RedCheckbox
                      checked={discountCode.disabled}
                      onChange={(e) =>
                        dispatch(updateDiscountCodeDisabled(e.target.checked))
                      }
                      name="Discount code disabled"
                    />
                  }
                  label="Discount code disabled"
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  id="name"
                  label="Name"
                  defaultValue={discountCode.name}
                  required
                  onChange={(e) =>
                    dispatch(updateDiscountCodeName(e.target.value))
                  }
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  id="code"
                  label="Code"
                  defaultValue={discountCode.code}
                  required
                  onChange={(e) =>
                    dispatch(updateDiscountCodeCode(e.target.value))
                  }
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  id="description"
                  label="Description"
                  defaultValue={discountCode.description}
                  required
                  multiline
                  onChange={(e) =>
                    dispatch(updateDiscountCodeDescription(e.target.value))
                  }
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  id="discount"
                  label="Discount percentage"
                  defaultValue={discountCode.discount}
                  required
                  onChange={(e) =>
                    dispatch(updateDiscountCodeDiscount(e.target.value))
                  }
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  id="amount"
                  label="Maximum usages"
                  defaultValue={discountCode.maxUsages}
                  required
                  onChange={(e) =>
                    dispatch(updateDiscountCodeAmount(e.target.value))
                  }
                />
              </FormControl>
              {discountCode._id && (
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Usages"
                    id="usages"
                    disabled
                    value={discountCode.timesUsed}
                  />
                </FormControl>
              )}
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/yyyy"
                  margin="normal"
                  id="validUntil"
                  label="Valid until"
                  value={discountCode.validUntil}
                  onChange={(_, val) => {
                    const formattedDateFromVal = moment(val, 'DD/MM/YYYY');
                    dispatch(
                      updateDiscountCodeValidUntil(
                        formattedDateFromVal.toDate()
                      )
                    );
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Organization"
                  id="organization"
                  select
                  required
                  value={
                    discountCode.organization ? discountCode.organization : ''
                  }
                  onChange={(event) => {
                    dispatch(
                      updateDiscountCodeOrganization(event.target.value)
                    );
                    dispatch(updateDiscountCodeApplyTo([]));
                  }}
                >
                  {organizationOptions(organizations)}
                </TextField>
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="products"
                  classes={{
                    popupIndicator: classes.popupIndicator,
                    clearIndicator: classes.clearIndicator,
                  }}
                  options={createProductOptions(organizationProducts)}
                  getOptionLabel={(option) => option.name}
                  value={discountCode.applyTo ? discountCode.applyTo : []}
                  onChange={(_, value) => {
                    dispatch(updateDiscountCodeApplyTo(value));
                  }}
                  getOptionSelected={(option, value) => option._id === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.doubleRootWidth}
                      variant="standard"
                      label="Products"
                    />
                  )}
                />
              </FormControl>
            </div>
          </form>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              aria-label="save"
              color="primary"
              variant="contained"
              onClick={() =>
                dispatch(
                  discountCode._id
                    ? updateDiscountCode(discountCode)
                    : saveDiscountCode(discountCode)
                )
              }
            >
              Save
            </Button>
          </div>
          {discountCode.usedFor && discountCode.usedFor.length > 0 && (
            <Fragment>
              <h2>Discount code used by users</h2>
              <div className={classes.tableDiv}>
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>First name</TableCell>
                        <TableCell>Last name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Group</TableCell>
                        <TableCell>Id</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {discountCode.usedFor.map(
                        (user) =>
                          user && (
                            <TableRow key={user._id}>
                              <TableCell>{user.firstName}</TableCell>
                              <TableCell>{user.lastName}</TableCell>
                              <TableCell>{user.email}</TableCell>
                              <TableCell>{user.group.name}</TableCell>
                              <TableCell>{user._id}</TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>Times used</TableCell>
                        <TableCell colSpan={4} align="right">
                          {discountCode.timesUsed}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default DiscountCodeForm;
