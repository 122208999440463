import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOAD_AUTH_FAILED = 'LOAD_AUTH_FAILED';
export const PASSWORD_RESET_REQUEST_SUCCESS = 'PASSWORD_RESET_REQUEST_SUCCESS';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';

const CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const loadAuth = () => async (dispatch) => {
  const tokenFromLocalStorage = localStorage.getItem('jwtToken');
  if (tokenFromLocalStorage) {
    try {
      const res = await API.get('/api/auth');
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILED });
    }
  } else {
    dispatch({ type: LOGIN_FAILED });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const login = (email, password) => async (dispatch) => {
  const body = JSON.stringify({ email, password });
  try {
    const res = await API.post('/api/auth', body, CONFIG);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error', true)));
    }

    dispatch({
      type: LOGIN_FAILED,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const requestPasswordReset = (email) => async (dispatch) => {
  const body = JSON.stringify({ email });

  try {
    await API.post('/api/auth/passwordresetrequest', body, CONFIG);
    dispatch({
      type: PASSWORD_RESET_REQUEST_SUCCESS,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error', true)));
    }
  }
};

export const resetPassword = (token, userId, password) => async (dispatch) => {
  const body = JSON.stringify({ token, userId, password });

  try {
    await API.post('/api/auth/resetpassword', body, CONFIG);
    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error', true)));
    }
  }
};

export const loginAfterRegister = (data) => async (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: data,
  });
};
