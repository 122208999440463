import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RichTextEditor from 'react-rte';

import AlertMessage from 'components/layouts/AlertMessage';
import DeleteConfirm from 'components/layouts/DeleteConfirm';

import { Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import {
  getOrganizations,
  saveOrganization,
  deleteOrganization,
  updateOrganization,
  selectExistingOrg,
  selectNewOrg,
  editOrganizationName,
  editOrganizationCity,
  editOrganizationContactEmail,
  editOrganizationContactName,
  editOrganizationContactPhone,
  editOrganizationCountry,
  editOrganizationDescription,
  editOrganizationPostalCode,
  editOrganizationStreet,
  editOrganizationWebsite,
  editOrganizationBankAccount,
  editOrganizationBusinessCode,
  editOrganizationThingsToNote,
  editOrganizationBackgroundImage,
  editOrganizationIdentifier,
  editOrganizationQuestions,
  editOrganizationTravelConditions,
  editOrganizationPrivacyStatement,
  editOrganizationMarketingAuthorization,
  toggleOrganizationRegistering,
} from 'redux/actions/organization';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  alertBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formLabel: {
    fontSize: '1rem',
    fontWeight: 'semibold',
    color: 'gray',
  },
}));

const objectDoesNotContainNull = (obj) => {
  const keys = Object.keys(obj);
  const values = keys.map((key) => obj[key]);
  return !values.includes(null);
};

const getMenuItems = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org.name} value={org.name}>
      {org.name}
    </MenuItem>
  ));

const Organization = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { organization, organizations } = useSelector(
    (state) => state.organization
  );

  // Local state for rich text editors
  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  const { address, contact } = organization;

  const getOrganizationObjectToName = (name) =>
    organizations.find((org) => org.name === name);

  const isDuplicateName = (name) =>
    getOrganizationObjectToName(name)
      ? setShowAlert(true)
      : setShowAlert(false);

  const handleAgree = () => dispatch(deleteOrganization(organization));
  const closeDialog = () => setConfirmDelete(false);

  return (
    <div>
      <h2> Organization </h2>
      {organizations && (
        <FormControl
          className={classes.formControl}
          key={`amountOfSelections_${organizations.length}`}
        >
          <InputLabel id="orgSelectionLabel">Select</InputLabel>
          <Select
            labelId="orgSelectionLabel"
            id="orgSelection"
            defaultValue=""
            onChange={({ target: { value } }) =>
              value !== 'new'
                ? dispatch(
                    selectExistingOrg(getOrganizationObjectToName(value))
                  )
                : dispatch(selectNewOrg())
            }
          >
            <MenuItem key="new" value={'new'}>
              New
            </MenuItem>
            {getMenuItems(organizations)}
          </Select>
        </FormControl>
      )}

      {organization && (
        <form
          key={organization._id}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <div className={classes.alertBox}>
            {showAlert && (
              <Alert severity="error">
                Organization name should be unique!
              </Alert>
            )}
            <AlertMessage />
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                defaultValue={organization.name}
                label="Name"
                required
                onChange={(event) => {
                  isDuplicateName(event.target.value);
                  dispatch(editOrganizationName(event.target.value));
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="identifier"
                defaultValue={organization.identifier}
                label="Identifier"
                required
                onChange={(event) =>
                  dispatch(editOrganizationIdentifier(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Switch
                    checked={organization.registerEnabled}
                    onChange={() => {
                      dispatch(
                        toggleOrganizationRegistering(
                          !organization.registerEnabled
                        )
                      );
                    }}
                    name="allow users to register"
                    color="primary"
                  />
                }
                className={classes.productInfo}
                label={
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    className={classes.formLabel}
                  >
                    Registering enabled
                  </Typography>
                }
                labelPlacement="top"
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="contactName"
                defaultValue={contact.name}
                label="Contact name"
                required
                onChange={(event) =>
                  dispatch(editOrganizationContactName(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="contactEmail"
                defaultValue={contact.email}
                label="Contact email"
                required
                onChange={(event) =>
                  dispatch(editOrganizationContactEmail(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="contactPhone"
                defaultValue={contact.phone}
                label="Contact phone"
                required
                onChange={(event) =>
                  dispatch(editOrganizationContactPhone(event.target.value))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="street"
                defaultValue={address.street}
                label="Street"
                required
                onChange={(event) =>
                  dispatch(editOrganizationStreet(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="postalCode"
                defaultValue={address.postalCode}
                label="Postal code"
                required
                onChange={(event) =>
                  dispatch(editOrganizationPostalCode(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="city"
                defaultValue={address.city}
                label="City"
                required
                onChange={(event) =>
                  dispatch(editOrganizationCity(event.target.value))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="country"
                defaultValue={address.country}
                label="Country"
                required
                onChange={(event) =>
                  dispatch(editOrganizationCountry(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="businessCode"
                defaultValue={organization.businessCode}
                label="Business code"
                required
                onChange={(event) =>
                  dispatch(editOrganizationBusinessCode(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="bank account"
                defaultValue={organization.bankAccount}
                label="Bank account"
                multiline={true}
                required
                onChange={(event) =>
                  dispatch(editOrganizationBankAccount(event.target.value))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="website"
                defaultValue={organization.website}
                label="Website"
                required
                onChange={(event) =>
                  dispatch(editOrganizationWebsite(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="background image"
                defaultValue={organization.backgroundImage}
                label="Landing background"
                required
                onChange={(event) =>
                  dispatch(editOrganizationBackgroundImage(event.target.value))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="travelConditions"
                defaultValue={organization.travelConditions}
                label="Travel conditions"
                required
                onChange={(event) =>
                  dispatch(editOrganizationTravelConditions(event.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="privacyStatement"
                defaultValue={organization.privacyStatement}
                label="Privacy Statement"
                required
                onChange={(event) =>
                  dispatch(editOrganizationPrivacyStatement(event.target.value))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControlRte}>
              <h4>Description</h4>
              <RichTextEditor
                value={organization.description}
                onChange={(value) =>
                  dispatch(editOrganizationDescription(value))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControlRte}>
              <h4>Things to note</h4>
              <RichTextEditor
                value={organization.thingsToNote}
                onChange={(value) =>
                  dispatch(dispatch(editOrganizationThingsToNote(value)))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControlRte}>
              <h4>Questions</h4>
              <RichTextEditor
                value={organization.questions}
                onChange={(value) =>
                  dispatch(dispatch(editOrganizationQuestions(value)))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControlRte}>
              <h4>Marketing authorization</h4>
              <RichTextEditor
                value={organization.marketingAuthorization}
                onChange={(value) =>
                  dispatch(
                    dispatch(editOrganizationMarketingAuthorization(value))
                  )
                }
              />
            </FormControl>
          </div>
        </form>
      )}
      <div className={classes.buttonContainer}>
        {objectDoesNotContainNull(organization) &&
          objectDoesNotContainNull(address) &&
          objectDoesNotContainNull(contact) &&
          !showAlert && (
            <Button
              className={classes.button}
              aria-label="save"
              color="primary"
              variant="contained"
              onClick={() =>
                dispatch(
                  organization._id
                    ? updateOrganization(organization)
                    : saveOrganization(organization)
                )
              }
            >
              Save
            </Button>
          )}
        {organization._id && (
          <Button
            className={classes.button}
            aria-label="delete"
            color="secondary"
            variant="contained"
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
        )}
      </div>
      <DeleteConfirm
        visible={confirmDelete}
        handleAgree={handleAgree}
        closeDialog={closeDialog}
      ></DeleteConfirm>
      {showAlert && (
        <Alert severity="error">Organization name should be unique!</Alert>
      )}
    </div>
  );
};

export default Organization;
