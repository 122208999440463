import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

export const FETCH_EVENTS_REQUESTED = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_RESPONSE = 'FETCH_EVENTS_RESPONSE';
export const FETCH_EVENTS_ERROR = 'FETCH_EVENTS_ERROR';

export const SAVE_EVENT_REQUESTED = 'SAVE_EVENT_REQUESTED';
export const SAVE_EVENT_RESPONSE = 'SAVE_EVENT_RESPONSE';
export const SAVE_EVENT_ERROR = 'SAVE_EVENT_ERROR';

export const UPDATE_EVENT_REQUESTED = 'UPDATE_EVENT_REQUESTED';
export const UPDATE_EVENT_RESPONSE = 'UPDATE_EVENT_RESPONSE';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';

export const DELETE_EVENT_REQUESTED = 'DELETE_EVENT_REQUESTED';
export const DELETE_EVENT_RESPONSE = 'DELETE_EVENT_RESPONSE';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

export const SELECT_EXISTING_EVENT = 'SELECT_EXISTING_EVENT';
export const SELECT_NEW_EVENT = 'SELECT_NEW_EVENT';

export const SET_EVENT_FILTER_ORG = 'SET_EVENT_FILTER_ORG';
export const SET_EVENT_FILTER_ORG_FOR_GROUPS =
  'SET_EVENT_FILTER_ORG_FOR_GROUPS';

export const UPDATE_EVENT_NAME = 'UPDATE_EVENT_NAME';
export const UPDATE_EVENT_IDENTIFIER = 'UPDATE_EVENT_IDENTIFIER';
export const UPDATE_EVENT_DESCRIPTION = 'UPDATE_EVENT_DESCRIPTION';
export const UPDATE_EVENT_DATE = 'UPDATE_EVENT_DATE';
export const UPDATE_EVENT_ORGANIZATION = 'UPDATE_EVENT_ORGANIZATION';
export const UPDATE_EVENT_PRODUCTS = 'UPDATE_EVENT_PRODUCTS';
export const UPDATE_EVENT_PRODUCT_COLLECT_PAYMENT =
  'UPDATE_EVENT_PRODUCT_COLLECT_PAYMENT';
export const UPDATE_EVENT_ALLOW_USERS_EDIT_INFO =
  'UPDATE_EVENT_ALLOW_USERS_EDIT_INFO';

export const UPDATE_EVENT_INFOBUTTON1_NAME = 'UPDATE_EVENT_INFOBUTTON1_NAME';
export const UPDATE_EVENT_INFOBUTTON1_URL = 'UPDATE_EVENT_INFOBUTTON1_URL';
export const UPDATE_EVENT_INFOBUTTON1_VISIBLE =
  'UPDATE_EVENT_INFOBUTTON1_VISIBLE';

export const UPDATE_EVENT_INFOBUTTON2_NAME = 'UPDATE_EVENT_INFOBUTTON2_NAME';
export const UPDATE_EVENT_INFOBUTTON2_URL = 'UPDATE_EVENT_INFOBUTTON2_URL';
export const UPDATE_EVENT_INFOBUTTON2_VISIBLE =
  'UPDATE_EVENT_INFOBUTTON2_VISIBLE';

export const UPDATE_EVENT_INFOBUTTON3_NAME = 'UPDATE_EVENT_INFOBUTTON3_NAME';
export const UPDATE_EVENT_INFOBUTTON3_URL = 'UPDATE_EVENT_INFOBUTTON3_URL';
export const UPDATE_EVENT_INFOBUTTON3_VISIBLE =
  'UPDATE_EVENT_INFOBUTTON3_VISIBLE';

export const fetchEvents = () => async (dispatch) => {
  dispatch({ type: FETCH_EVENTS_REQUESTED });
  try {
    const res = await API.get('/api/admin/events');
    dispatch({
      type: FETCH_EVENTS_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_EVENTS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const fetchEventsForOrganization = (orgId) => async (dispatch) => {
  dispatch({ type: FETCH_EVENTS_REQUESTED });
  try {
    const res = await API.get('/api/admin/events?organization=' + orgId);
    dispatch({
      type: FETCH_EVENTS_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_EVENTS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const fetchEventsSmall = () => async (dispatch) => {
  dispatch({ type: FETCH_EVENTS_REQUESTED });
  try {
    const res = await API.get('/api/admin/events/events-small');
    dispatch({
      type: FETCH_EVENTS_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_EVENTS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const selectOrgToFilter = (orgId) => async (dispatch) => {
  dispatch({
    type: SET_EVENT_FILTER_ORG,
    payload: orgId,
  });
};

export const selectOrgToFilterEventsGroups = (orgId) => async (dispatch) => {
  dispatch({
    type: SET_EVENT_FILTER_ORG_FOR_GROUPS,
    payload: orgId,
  });
};

export const selectExistingEvent = (event) => async (dispatch) => {
  dispatch({
    type: SELECT_EXISTING_EVENT,
    payload: event,
  });
};

export const selectNewEvent = () => async (dispatch) => {
  dispatch({
    type: SELECT_NEW_EVENT,
  });
};

export const saveEvent = (event) => async (dispatch) => {
  dispatch({ type: SAVE_EVENT_REQUESTED });

  try {
    const res = await API.post('/api/admin/events', event);

    dispatch(setAlert('Event saved succesfully', 'success'));

    dispatch({ type: SAVE_EVENT_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: SAVE_EVENT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateEvent = (event) => async (dispatch) => {
  dispatch({ type: UPDATE_EVENT_REQUESTED });
  try {
    delete event.groups;
    const res = await API.put(`/api/admin/events/${event._id}`, event);
    dispatch(setAlert('Event updated successfully', 'success'));
    dispatch({ type: UPDATE_EVENT_RESPONSE, payload: res.data });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to update event', 'error'));
    }
    dispatch({
      type: UPDATE_EVENT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteEvent = (event) => async (dispatch) => {
  dispatch({ type: DELETE_EVENT_REQUESTED });
  try {
    const res = await API.delete(`/api/admin/events/${event._id}`);
    dispatch(setAlert('Event successfully deleted', 'success'));
    dispatch({ type: DELETE_EVENT_RESPONSE, payload: res.data });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to delete event', 'error'));
    }
    dispatch({
      type: DELETE_EVENT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateEventName = (payload) => ({
  type: UPDATE_EVENT_NAME,
  payload,
});

export const updateEventIdentifier = (payload) => ({
  type: UPDATE_EVENT_IDENTIFIER,
  payload,
});

export const updateEventDescription = (payload) => ({
  type: UPDATE_EVENT_DESCRIPTION,
  payload,
});

export const updateEventDate = (payload) => ({
  type: UPDATE_EVENT_DATE,
  payload,
});

export const updateEventOrganization = (payload) => ({
  type: UPDATE_EVENT_ORGANIZATION,
  payload,
});

export const updateEventProducts = (payload) => ({
  type: UPDATE_EVENT_PRODUCTS,
  payload,
});

export const toggleCollectPaymentForEventProduct = (payload) => ({
  type: UPDATE_EVENT_PRODUCT_COLLECT_PAYMENT,
  payload,
});

export const toggleAllowUsersToEditInfo = (payload) => ({
  type: UPDATE_EVENT_ALLOW_USERS_EDIT_INFO,
  payload,
});

export const updateEventInfoButton1Name = (payload) => ({
  type: UPDATE_EVENT_INFOBUTTON1_NAME,
  payload,
});

export const updateEventInfoButton1Url = (payload) => ({
  type: UPDATE_EVENT_INFOBUTTON1_URL,
  payload,
});

export const updateEventInfoButton1Visible = (payload) => ({
  type: UPDATE_EVENT_INFOBUTTON1_VISIBLE,
  payload,
});

export const updateEventInfoButton2Name = (payload) => ({
  type: UPDATE_EVENT_INFOBUTTON2_NAME,
  payload,
});

export const updateEventInfoButton2Url = (payload) => ({
  type: UPDATE_EVENT_INFOBUTTON2_URL,
  payload,
});

export const updateEventInfoButton2Visible = (payload) => ({
  type: UPDATE_EVENT_INFOBUTTON2_VISIBLE,
  payload,
});

export const updateEventInfoButton3Name = (payload) => ({
  type: UPDATE_EVENT_INFOBUTTON3_NAME,
  payload,
});

export const updateEventInfoButton3Url = (payload) => ({
  type: UPDATE_EVENT_INFOBUTTON3_URL,
  payload,
});

export const updateEventInfoButton3Visible = (payload) => ({
  type: UPDATE_EVENT_INFOBUTTON3_VISIBLE,
  payload,
});
