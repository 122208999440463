import {
  FETCH_ORGANIZATION_CUSTOMERS_REQUESTED,
  FETCH_ORGANIZATION_CUSTOMERS_RESPONSE,
  FETCH_ORGANIZATION_CUSTOMERS_ERROR,
  RESET_ORGANIZATION_CUSTOMERS,
  SELECT_EXISTING_USER,
  TOGGLE_SHOWPASSWORD,
  UPDATE_USER_FIRST_NAME,
  UPDATE_USER_LAST_NAME,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_MARKETING,
  UPDATE_USER_EMAIL,
  UPDATE_USER_BIRTHDAY,
  UPDATE_USER_ADDRESS_STREET,
  UPDATE_USER_ADDRESS_POSTCODE,
  UPDATE_USER_ADDRESS_CITY,
  UPDATE_USER_PHONE,
  UPDATE_USER_GROUP,
  UPDATE_USER_COUNTRY,
  UPDATE_USER_GENDER,
  UPDATE_USER_OUTBOUND_TICKET,
  UPDATE_USER_RETURN_TICKET,
  UPDATE_USER_ROOM_TYPE,
  UPDATE_USER_ROOM_USERS,
  UPDATE_USER_ROOM_PARTNER_CHOSEN,
  UPDATE_USER_DISABLED,
  OPEN_PAYMENT_DIALOG,
  CLOSE_PAYMENT_DIALOG,
  TOGGLE_FILTER_BY_ROOM,
  UPDATE_DISABLED_USERS,
  UPDATE_ENABLED_USERS,
  UPDATE_USER_ADDITIONAL_INFO,
  DELETE_USER_RESPONSE,
  UPDATE_PAYMENT_STATUS,
} from 'redux/actions/user';

import { CREATE_REFUND_RESPONSE } from 'redux/actions/payment';
import { UPDATE_GROUP_CHANGED_USERS } from 'redux/actions/massEdit';

const initialState = {
  users: [],
  user: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: {
      street: '',
      postcode: '',
      city: '',
    },
    roomPartnerChosen: false,
    room: {
      roomType: '',
      users: [],
    },
    password: '',
    birthday: '',
    marketing: false,
    group: '',
    country: '',
    gender: '',
    disabled: false,
    eventPayments: [],
    additionalServices: [],
    outboundTicket: '',
    returnTicket: '',
    _id: '',
    additionalInformation: '',
  },
  fetchingUsers: false,
  showPassword: false,
  paymentDialog: {
    visible: false,
    payment: null,
  },
  filterByRoom: false,
  error: {},
};

const updateUsersEventPayments = (payments, updatedPayment) => {
  const updatedPayments = payments.map((obj) =>
    obj._id === updatedPayment._id ? updatedPayment : obj
  );
  return updatedPayments;
};

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SELECT_EXISTING_USER:
      return {
        ...state,
        user: {
          ...action.payload,
          group: action.payload.group._id,
          room: {
            ...action.payload.room,
            users: action.payload.room.users.map((user) => {
              return {
                name: user.firstName + ' ' + user.lastName,
                id: user._id,
              };
            }),
          },
        },
      };

    case RESET_ORGANIZATION_CUSTOMERS:
      return { ...state, users: [] };

    case FETCH_ORGANIZATION_CUSTOMERS_RESPONSE:
      return { ...state, users: action.payload, fetchingUsers: false };

    case FETCH_ORGANIZATION_CUSTOMERS_ERROR:
      return { ...state, error: action.payload };

    case FETCH_ORGANIZATION_CUSTOMERS_REQUESTED:
      return { ...state, fetchingUsers: true };

    case TOGGLE_FILTER_BY_ROOM:
      return { ...state, filterByRoom: action.payload };

    case TOGGLE_SHOWPASSWORD:
      return { ...state, showPassword: !state.showPassword };

    case UPDATE_USER_FIRST_NAME:
      return { ...state, user: { ...state.user, firstName: action.payload } };

    case UPDATE_USER_LAST_NAME:
      return { ...state, user: { ...state.user, lastName: action.payload } };

    case UPDATE_USER_EMAIL:
      return { ...state, user: { ...state.user, email: action.payload } };

    case UPDATE_USER_PHONE:
      return { ...state, user: { ...state.user, phone: action.payload } };

    case UPDATE_USER_PASSWORD:
      return { ...state, user: { ...state.user, password: action.payload } };

    case UPDATE_USER_BIRTHDAY:
      return { ...state, user: { ...state.user, birthday: action.payload } };

    case UPDATE_USER_MARKETING:
      return { ...state, user: { ...state.user, marketing: action.payload } };

    case UPDATE_USER_GROUP:
      return { ...state, user: { ...state.user, group: action.payload } };

    case UPDATE_USER_COUNTRY:
      return { ...state, user: { ...state.user, country: action.payload } };

    case UPDATE_USER_GENDER:
      return { ...state, user: { ...state.user, gender: action.payload } };

    case UPDATE_USER_OUTBOUND_TICKET:
      return {
        ...state,
        user: { ...state.user, outboundTicket: action.payload },
      };

    case UPDATE_USER_RETURN_TICKET:
      return {
        ...state,
        user: { ...state.user, returnTicket: action.payload },
      };

    case UPDATE_USER_ADDRESS_CITY:
      return {
        ...state,
        user: {
          ...state.user,
          address: { ...state.user.address, city: action.payload },
        },
      };

    case UPDATE_USER_ADDRESS_POSTCODE:
      return {
        ...state,
        user: {
          ...state.user,
          address: { ...state.user.address, postcode: action.payload },
        },
      };
    case UPDATE_USER_ADDRESS_STREET:
      return {
        ...state,
        user: {
          ...state.user,
          address: { ...state.user.address, street: action.payload },
        },
      };

    case UPDATE_USER_ROOM_PARTNER_CHOSEN:
      return {
        ...state,
        user: { ...state.user, roomPartnerChosen: action.payload },
      };

    case UPDATE_USER_ROOM_TYPE:
      return {
        ...state,
        user: {
          ...state.user,
          room: { ...state.user.room, roomType: action.payload },
        },
      };

    case UPDATE_USER_ROOM_USERS:
      return {
        ...state,
        user: {
          ...state.user,
          room: { ...state.user.room, users: action.payload },
        },
      };

    case UPDATE_USER_ADDITIONAL_INFO:
      return {
        ...state,
        user: { ...state.user, additionalInformation: action.payload },
      };

    case UPDATE_USER_DISABLED:
      return {
        ...state,
        user: { ...state.user, disabled: action.payload },
      };

    case CREATE_REFUND_RESPONSE:
      return {
        ...state,
        user: {
          ...state.user,
          eventPayments: updateUsersEventPayments(
            state.user.eventPayments,
            action.payload
          ),
        },
      };

    case UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          additionalServices: updateUsersEventPayments(
            state.user.additionalServices,
            action.payload
          ),
          eventPayments: updateUsersEventPayments(
            state.user.eventPayments,
            action.payload
          ),
        },
      };

    case OPEN_PAYMENT_DIALOG:
      return {
        ...state,
        paymentDialog: { visible: true, payment: action.payload },
      };

    case CLOSE_PAYMENT_DIALOG:
      return { ...state, paymentDialog: { visible: false, payment: null } };

    case UPDATE_DISABLED_USERS: {
      const disabledUsers = action.payload;
      let users = state.users.map((user) => {
        if (disabledUsers.includes(user._id)) {
          user.disabled = true;
        }
        return user;
      });
      return { ...state, users };
    }
    case UPDATE_ENABLED_USERS: {
      const enabledUsers = action.payload;
      let newUsers = state.users.map((user) => {
        if (enabledUsers.includes(user._id)) {
          user.disabled = false;
        }
        return user;
      });

      return { ...state, users: newUsers };
    }

    case UPDATE_GROUP_CHANGED_USERS: {
      let changedUsers = state.users.map((user) => {
        if (action.payload.users.includes(user._id)) {
          user.group = action.payload.group;
        }
        return user;
      });
      return { ...state, users: changedUsers };
    }
    case DELETE_USER_RESPONSE:
      return { ...state, user: initialState.user };

    default:
      return state;
  }
}
