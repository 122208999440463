import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

export const FETCH_PRODUCTS_REQUESTED = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_RESPONSE = 'FETCH_PRODUCTS_RESPONSE';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

export const FETCH_ORG_PRODUCTS_REQUESTED = 'FETCH_ORG_PRODUCTS_REQUESTED';
export const FETCH_ORG_PRODUCTS_RESPONSE = 'FETCH_ORG_PRODUCTS_RESPONSE';
export const FETCH_ORG_PRODUCTS_ERROR = 'FETCH_ORG_PRODUCTS_ERROR';

export const SAVE_PRODUCT_REQUESTED = 'SAVE_PRODUCT_REQUESTED';
export const SAVE_PRODUCT_RESPONSE = 'SAVE_PRODUCT_RESPONSE';
export const SAVE_PRODUCT_ERROR = 'SAVE_PRODUCT_ERROR';

export const UPDATE_PRODUCT_REQUESTED = 'UPDATE_PRODUCT_REQUESTED';
export const UPDATE_PRODUCT_RESPONSE = 'UPDATE_PRODUCT_RESPONSE';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';

export const DELETE_PRODUCT_REQUESTED = 'DELETE_PRODUCT_REQUESTED';
export const DELETE_PRODUCT_RESPONSE = 'DELETE_PRODUCT_RESPONSE';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';

export const SELECT_EXISTING_PRODUCT = 'SELECT_EXISTING_PRODUCT';
export const SELECT_NEW_PRODUCT = 'SELECT_NEW_PRODUCT';
export const SET_PRODUCT_FILTER_ORG = 'SET_PRODUCT_FILTER_ORG';

export const UPDATE_PRODUCT_NAME = 'UPDATE_PRODUCT_NAME';
export const UPDATE_PRODUCT_PRICE = 'UPDATE_PRODUCT_PRICE';
export const UPDATE_PRODUCT_DESCRIPTION = 'UPDATE_PRODUCT_DESCRIPTION';
export const UPDATE_PRODUCT_VAT = 'UPDATE_PRODUCT_VAT';
export const UPDATE_PRODUCT_VAT_PERCENT = 'UPDATE_PRODUCT_VAT_PERCENT';
export const UPDATE_PRODUCT_TAX_AMOUNT = 'UPDATE_PRODUCT_TAX_AMOUNT';
export const UPDATE_PRODUCT_TAX_DESCRIPTION = 'UPDATE_PRODUCT_TAX_DESCRIPTION';
export const UPDATE_PRODUCT_ORGANIZATION = 'UPDATE_PRODUCT_ORGANIZATION';
export const UPDATE_PRODUCT_MANDATORY = 'UPDATE_PRODUCT_MANDATORY';
export const UPDATE_PRODUCT_MAXIMUM_AMOUNT = 'UPDATE_PRODUCT_MAXIMUM_AMOUNT';
export const UPDATE_PRODUCT_INFO_LINK = 'UPDATE_PRODUCT_INFO_LINK';
export const UPDATE_PRODUCT_SERVICE_FEE = 'UPDATE_PRODUCT_SERVICE_FEE';
export const UPDATE_PRODUCT_SERVICE_FEE_VAT = 'UPDATE_PRODUCT_SERVICE_FEE_VAT';

export const fetchProducts = () => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCTS_REQUESTED });
  try {
    const res = await API.get('/api/admin/products');
    dispatch({
      type: FETCH_PRODUCTS_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_PRODUCTS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const fetchOrgProducts = (orgId) => async (dispatch) => {
  dispatch({ type: FETCH_ORG_PRODUCTS_REQUESTED });
  try {
    const res = await API.get(`/api/admin/products/${orgId}`);
    dispatch({
      type: FETCH_ORG_PRODUCTS_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_ORG_PRODUCTS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const saveProduct = (product) => async (dispatch) => {
  dispatch({ type: SAVE_PRODUCT_REQUESTED });
  delete product._id;
  try {
    const res = await API.post('/api/admin/products', product);

    dispatch(setAlert('Product saved succesfully', 'success'));

    dispatch({ type: SAVE_PRODUCT_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: SAVE_PRODUCT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateProduct = (product) => async (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT_REQUESTED });
  try {
    const res = await API.put(`/api/admin/products/${product._id}`, product);
    dispatch(setAlert('Product updated successfully', 'success'));
    dispatch({ type: UPDATE_PRODUCT_RESPONSE, payload: res.data });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to update product', 'error'));
    }
    dispatch({
      type: UPDATE_PRODUCT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteProduct = (product) => async (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_REQUESTED });
  try {
    const res = await API.delete(`/api/admin/products/${product._id}`);
    dispatch(setAlert('Product successfully deleted', 'success'));
    dispatch({ type: DELETE_PRODUCT_RESPONSE, payload: res.data });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to delete product', 'error'));
    }
    dispatch({
      type: DELETE_PRODUCT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const selectOrgToFilter = (orgId) => async (dispatch) => {
  dispatch({ type: SET_PRODUCT_FILTER_ORG, payload: orgId });
};

export const selectExistingProduct = (product) => async (dispatch) => {
  dispatch({
    type: SELECT_EXISTING_PRODUCT,
    payload: product,
  });
};

export const selectNewProduct = () => async (dispatch) => {
  dispatch({
    type: SELECT_NEW_PRODUCT,
  });
};

export const updateProductName = (payload) => ({
  type: UPDATE_PRODUCT_NAME,
  payload,
});

export const updateProductDescription = (payload) => ({
  type: UPDATE_PRODUCT_DESCRIPTION,
  payload,
});

export const updateProductPrice = (payload) => ({
  type: UPDATE_PRODUCT_PRICE,
  payload,
});

export const updateProductOrganization = (payload) => ({
  type: UPDATE_PRODUCT_ORGANIZATION,
  payload,
});

export const updateProductVat = (payload) => ({
  type: UPDATE_PRODUCT_VAT,
  payload,
});

export const updateProductVatPercent = (payload) => ({
  type: UPDATE_PRODUCT_VAT_PERCENT,
  payload,
});

export const updateProductTaxAmount = (payload) => ({
  type: UPDATE_PRODUCT_TAX_AMOUNT,
  payload,
});

export const updateProductTaxDescription = (payload) => ({
  type: UPDATE_PRODUCT_TAX_DESCRIPTION,
  payload,
});

export const updateProductMandatory = (payload) => ({
  type: UPDATE_PRODUCT_MANDATORY,
  payload,
});

export const updateProductMaximumAmount = (payload) => ({
  type: UPDATE_PRODUCT_MAXIMUM_AMOUNT,
  payload,
});

export const updateProductInfoLink = (payload) => ({
  type: UPDATE_PRODUCT_INFO_LINK,
  payload,
});

export const updateProductServiceFee = (payload) => ({
  type: UPDATE_PRODUCT_SERVICE_FEE,
  payload,
});

export const updateProductServiceFeeVat = (payload) => ({
  type: UPDATE_PRODUCT_SERVICE_FEE_VAT,
  payload,
});
