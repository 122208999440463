import { combineReducers } from 'redux';

import auth from './auth';
import event from './event';
import organization from './organization';
import admin from './admin';
import alert from './alert';
import group from './group';
import register from './register';
import product from './product';
import customer from './customer';
import payment from './payment';
import user from './user';
import email from './email';
import ferry from './ferry';
import accomodation from './accomodation';
import mass from './massEdit';
import discountCode from './discountCodes';
import paginatedUsers from './paginatedUsers';

const appReducer = combineReducers({
  auth,
  event,
  organization,
  admin,
  alert,
  group,
  register,
  product,
  customer,
  payment,
  user,
  email,
  ferry,
  accomodation,
  mass,
  discountCode,
  paginatedUsers,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    localStorage.removeItem('jwtToken');
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
