import {
  FETCH_PAYMENTS_REQUESTED,
  FETCH_PAYMENTS_RESPONSE,
  FETCH_PAYMENTS_ERROR,
  SEND_PAYMENT_SUCCESS_REQUESTED,
  SEND_PAYMENT_SUCCESS_RESPONSE,
  SEND_PAYMENT_SUCCESS_ERROR,
  OPEN_REFUND,
  CLOSE_REFUND,
  UPDATE_REFUND_AMOUNT,
} from 'redux/actions/payment';

const updatePayments = (payments, updatedPayment) => {
  const updatedPayments = payments.map((obj) =>
    obj._id === updatedPayment._id ? updatedPayment : obj
  );
  return updatedPayments;
};

const initialState = {
  eventPayments: [],
  additionalServices: [],
  refund: {
    amount: 0,
    payment: null,
    showRefund: false,
  },
  error: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEND_PAYMENT_SUCCESS_RESPONSE:
      return {
        ...state,
        eventPayments: updatePayments(state.eventPayments, action.payload),
        additionalServices: updatePayments(
          state.additionalServices,
          action.payload
        ),
      };

    case FETCH_PAYMENTS_RESPONSE:
      return {
        ...state,
        eventPayments: action.payload.eventPayments,
        additionalServices: action.payload.additionalServices,
      };

    case SEND_PAYMENT_SUCCESS_ERROR:
    case FETCH_PAYMENTS_ERROR:
      return { ...state, error: action.payload };

    case OPEN_REFUND:
      return {
        ...state,
        refund: { ...state.refund, payment: action.payload, showRefund: true },
      };

    case CLOSE_REFUND:
      return {
        ...state,
        refund: { amount: 0, payment: null, showRefund: false },
      };

    case UPDATE_REFUND_AMOUNT:
      return { ...state, refund: { ...state.refund, amount: action.payload } };

    case SEND_PAYMENT_SUCCESS_REQUESTED:
    case FETCH_PAYMENTS_REQUESTED:
    default:
      return state;
  }
}
