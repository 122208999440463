import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fi';

import { makeStyles, createTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';

import CustomerContentRoutes from 'components/routes/CustomerContentRoutes';
import CustomerContentRoutesMobile from 'components/routes/CustomerContentRoutesMobile';
import Register from 'components/views/customer/register/Register';
import OrganizationHome from 'components/views/customer/organization/OrganizationHome';

moment.locale('fi');

const customTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        background:
          'linear-gradient(90deg, #AD00FF 9.42%, rgba(255, 255, 255, 0) 120.89%), #20D2B7;',
        borderRadius: '0px',
        mixBlendMode: 'multiply',
        color: '#FFFFFF',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#AD00FF',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
  },
  desktopContainer: {
    position: 'relative',
    minHeight: '100vh',
    width: '100%',
    background:
      'linear-gradient(90deg, #AD00FF -18.47%, rgba(255, 255, 255, 0) 89.64%), #20D2B7',
    mixBlendMode: 'multiply',
  },
  mobileContainer: {
    position: 'relative',
    minHeight: '100vh',
    width: '100%',
    overflow: 'scroll',
    background:
      'linear-gradient(#AD00FF -18.47%, rgba(255, 255, 255, 0) 89.64%), #20D2B7',
    mixBlendMode: 'multiply',
  },
  orgName: {
    position: 'absolute',
    width: '10%',
    height: '3%',
    left: '85%',
    top: '5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '120%',
    /* or 74px */
    color: '#FFFFFF',
    zIndex: 1000,
  },
  logoutButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    position: 'absolute',
    width: '2,71%',
    height: '4,81%',
    top: '4%',
    left: '95%',
    /* White */

    background: '#FFFFFF',
    borderRadius: '69px',
  },
}));

const CustomerApp = () => {
  const classes = useStyles();

  const mobileScreen = useMediaQuery('(max-width:990px)');

  return (
    <Fragment>
      <ThemeProvider theme={customTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {mobileScreen ? (
            <div className={classes.mobileContainer}>
              <Container maxWidth="xl" className={classes.container}>
                <Switch>
                  <CustomerContentRoutesMobile />
                </Switch>
              </Container>
            </div>
          ) : (
            <div className={classes.desktopContainer}>
              <Container maxWidth="xl" className={classes.container}>
                <Switch>
                  <Route
                    exact
                    path="/register/:orgId"
                    component={Register}
                  ></Route>
                  <Route
                    exact
                    path="/organization/:identifier"
                    component={OrganizationHome}
                  ></Route>
                  <CustomerContentRoutes />
                </Switch>
              </Container>
            </div>
          )}
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Fragment>
  );
};

export default CustomerApp;
