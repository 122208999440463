import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import AlertMessage from 'components/layouts/AlertMessage';
import DeleteConfirm from 'components/layouts/DeleteConfirm';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';

import {
  updateGroupName,
  updateGroupCapacity,
  updateGroupContactEmail,
  updateGroupContactName,
  updateGroupContactPhone,
  updateGroupEvent,
  updateGroupProducts,
  updateGroupReturnFerry,
  updateGroupOutboundFerry,
  updateGroupAccomodation,
  updateGroup,
  saveGroup,
  deleteGroup,
  toggleCollectPaymentForGroupProduct,
} from 'redux/actions/group';

import {
  fetchEvents,
  selectOrgToFilterEventsGroups,
} from 'redux/actions/event';
import { fetchOrgProducts } from 'redux/actions/product';
import { getOrganizations } from 'redux/actions/organization';
import {
  selectOrgToFilterFerrysGroupForm,
  fetchFerrys,
} from 'redux/actions/ferry';
import {
  selectOrgToFilterAccomodationsGroupForm,
  fetchAccomodations,
} from 'redux/actions/accomodation';

import { getOrganizationFerrysGroupForm } from 'redux/selectors/ferry';
import { getOrganizationAccomodationsGroupForm } from 'redux/selectors/accomodation';
import { getOrganizationEventsForGroups } from 'redux/selectors/event';
import { getRoleAndOrganization } from 'redux/selectors/auth';
import { getOrgProducts } from 'redux/selectors/product';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  doubleRootWidth: {
    width: '50ch!important',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  alertBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    minHeight: 140,
    minWidth: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  control: {
    padding: theme.spacing(2),
  },
  productTitle: {
    fontSize: 14,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  productInfo: {
    fontSize: 12,
  },
  popupIndicator: {
    paddingBottom: '20px',
  },
  clearIndicator: {
    paddingBottom: '20px',
  },
}));

const eventOptions = (events) => {
  return events.map((event) => (
    <MenuItem key={event._id} value={event._id}>
      {event.name ? event.name : event.identifier}
    </MenuItem>
  ));
};

const ferryOptions = (ferrys) => {
  return ferrys.map((ferry) => (
    <MenuItem key={ferry._id} value={ferry._id}>
      {ferry.name +
        ' (' +
        ferry.pointOfDeparture.city +
        '-' +
        ferry.destination.city +
        ' ' +
        moment(ferry.pointOfDeparture.dateTime).format('DD/MM/YYYY HH:mm') +
        ')'}
    </MenuItem>
  ));
};

const accomodationOptions = (accomodations) => {
  return accomodations.map((accomodation) => (
    <MenuItem key={accomodation._id} value={accomodation._id}>
      {accomodation.name +
        ' ( ' +
        moment(accomodation.date).format('DD/MM/YYYY') +
        ' )'}
    </MenuItem>
  ));
};

const organizationOptions = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

const createProductOptions = (products) => {
  const productOptions = [];
  products.forEach((product) =>
    productOptions.push({ product, collectPayment: false })
  );

  return productOptions;
};

const objectDoesNotContainNull = (obj) => {
  const notMandatory = ['accomodation', 'outboundFerry', 'returnFerry'];
  const keys = Object.keys(obj);
  const values = keys
    .filter((key) => !notMandatory.includes(key))
    .map((key) => obj[key]);
  return !values.includes(null);
};

const GroupForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { group } = useSelector((state) => state.group);
  const { filterEventsOrgForGroups } = useSelector((state) => state.event);

  const { events } = useSelector((state) =>
    getOrganizationEventsForGroups(state.event)
  );

  useEffect(() => {
    dispatch(fetchFerrys());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAccomodations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  useEffect(() => {
    if (group.organization) {
      dispatch(fetchOrgProducts(group.organization));
    }
  }, [group.organization, dispatch]);

  const { role } = useSelector((state) => getRoleAndOrganization(state.auth));

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  const { organizationProducts } = useSelector((state) =>
    getOrgProducts(state.product)
  );

  const { organizations } = useSelector((state) => state.organization);

  const { ferrys } = useSelector((state) =>
    getOrganizationFerrysGroupForm(state.ferry)
  );

  const { accomodations } = useSelector((state) =>
    getOrganizationAccomodationsGroupForm(state.accomodation)
  );

  const handleAgree = () => {
    dispatch(deleteGroup(group));
    setConfirmDelete(false);
  };

  const closeDialog = () => setConfirmDelete(false);

  return (
    <div>
      <h2> {group._id ? 'Edit group' : 'Add new group'} </h2>

      {events && (
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          key={group._id}
        >
          <div className={classes.alertBox}>
            <AlertMessage />
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                defaultValue={group.name}
                label="Group name"
                required
                onChange={(e) => dispatch(updateGroupName(e.target.value))}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="capacity"
                label="Group maximum capacity"
                defaultValue={group.capacity}
                required
                onChange={(e) => dispatch(updateGroupCapacity(e.target.value))}
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="contact name"
                defaultValue={group.contact.name}
                label="Contact person name"
                required
                onChange={(e) =>
                  dispatch(updateGroupContactName(e.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="contact email"
                defaultValue={group.contact.email}
                label="Contact person email"
                required
                onChange={(e) =>
                  dispatch(updateGroupContactEmail(e.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="contact phone"
                defaultValue={group.contact.phone}
                label="Contact person phone"
                required
                onChange={(e) =>
                  dispatch(updateGroupContactPhone(e.target.value))
                }
              />
            </FormControl>
          </div>
          <div key={filterEventsOrgForGroups}>
            {role === 'root' && organizations && (
              <div>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Filter org"
                    id="organization selection"
                    select
                    value={
                      filterEventsOrgForGroups ? filterEventsOrgForGroups : ''
                    }
                    onChange={({ target: { value } }) => {
                      dispatch(selectOrgToFilterEventsGroups(value));
                      dispatch(selectOrgToFilterFerrysGroupForm(value));
                      dispatch(selectOrgToFilterAccomodationsGroupForm(value));
                    }}
                  >
                    <MenuItem key="all" value={'all'}>
                      All
                    </MenuItem>
                    {organizationOptions(organizations)}
                  </TextField>
                </FormControl>
              </div>
            )}
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Event"
                  id="event"
                  select
                  required
                  value={group.event ? group.event : ''}
                  onChange={(e) => {
                    dispatch(updateGroupEvent(e.target.value));
                  }}
                >
                  {eventOptions(events)}
                </TextField>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Accomodation"
                  id="accomodation"
                  select
                  value={
                    group.accomodation
                      ? group.accomodation._id
                        ? group.accomodation._id
                        : group.accomodation
                      : ''
                  }
                  onChange={(e) => {
                    dispatch(updateGroupAccomodation(e.target.value));
                  }}
                >
                  <MenuItem value={''} key={'empty'}>
                    -
                  </MenuItem>
                  {accomodationOptions(accomodations)}
                </TextField>
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Outbound ferry"
                  id="outbound ferry"
                  select
                  value={
                    group.outboundFerry
                      ? group.outboundFerry._id
                        ? group.outboundFerry._id
                        : group.outboundFerry
                      : ''
                  }
                  onChange={(e) => {
                    dispatch(updateGroupOutboundFerry(e.target.value));
                  }}
                >
                  <MenuItem value={''} key={'empty'}>
                    -
                  </MenuItem>
                  {ferryOptions(ferrys)}
                </TextField>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Return ferry"
                  id="return ferry"
                  select
                  value={
                    group.returnFerry
                      ? group.returnFerry._id
                        ? group.returnFerry._id
                        : group.returnFerry
                      : ''
                  }
                  onChange={(e) => {
                    dispatch(updateGroupReturnFerry(e.target.value));
                  }}
                >
                  <MenuItem value={''} key={'empty'}>
                    -
                  </MenuItem>
                  {ferryOptions(ferrys)}
                </TextField>
              </FormControl>
            </div>
          </div>
          {group._id && (
            <Fragment>
              <FormControl className={classes.formControl}>
                {group.organization && organizationProducts ? (
                  <Autocomplete
                    multiple
                    id="products"
                    classes={{
                      popupIndicator: classes.popupIndicator,
                      clearIndicator: classes.clearIndicator,
                    }}
                    options={createProductOptions(organizationProducts)}
                    getOptionLabel={(option) => option.product.name}
                    value={group.products ? group.products : []}
                    onChange={(event, value, reason) =>
                      dispatch(updateGroupProducts(value))
                    }
                    getOptionSelected={(option, value) =>
                      option.product.name === value.product.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.doubleRootWidth}
                        variant="standard"
                        label="Products"
                      />
                    )}
                  />
                ) : (
                  <Fragment>
                    <h1>Please add products for events organization first</h1>
                  </Fragment>
                )}
              </FormControl>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                  {group.products.map((productObj) => (
                    <Grid key={productObj.product._id} item>
                      <Paper elevation={3} className={classes.paper}>
                        <Typography
                          className={classes.productTitle}
                          color="textPrimary"
                          gutterBottom
                          variant="h2"
                        >
                          {productObj.product.name +
                            ' (' +
                            (productObj.product.mandatory
                              ? 'mandatory)'
                              : 'unmandatory)')}
                        </Typography>
                        <Typography
                          className={classes.productInfo}
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Price: {productObj.product.price} €
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={productObj.collectPayment}
                              onChange={() => {
                                dispatch(
                                  toggleCollectPaymentForGroupProduct({
                                    ...productObj,
                                    collectPayment: !productObj.collectPayment,
                                  })
                                );
                              }}
                              name="collect payment"
                              color="primary"
                            />
                          }
                          className={classes.productInfo}
                          label={
                            <Typography
                              className={classes.productInfo}
                              color="textPrimary"
                              gutterBottom
                              variant="h5"
                            >
                              Collect payment
                            </Typography>
                          }
                          labelPlacement="top"
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Fragment>
          )}
        </form>
      )}
      <div className={classes.buttonContainer}>
        {objectDoesNotContainNull(group) &&
          objectDoesNotContainNull(group.contact) && (
            <Button
              className={classes.button}
              aria-label="save"
              color="primary"
              variant="contained"
              onClick={() =>
                dispatch(group._id ? updateGroup(group) : saveGroup(group))
              }
            >
              Save
            </Button>
          )}
        {group._id && (
          <Button
            className={classes.button}
            aria-label="delete"
            color="secondary"
            variant="contained"
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
        )}
      </div>

      <DeleteConfirm
        visible={confirmDelete}
        handleAgree={handleAgree}
        closeDialog={closeDialog}
      ></DeleteConfirm>
    </div>
  );
};

export default GroupForm;
