import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlertMessage from 'components/layouts/AlertMessage';
import DeleteConfirm from 'components/layouts/DeleteConfirm';
import ImportCSVDialog from 'components/views/ferrys/ImportCSVDialog';

import { KeyboardDateTimePicker } from '@material-ui/pickers';

import { createProductOptions } from 'utils/product';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';

import {
  updateFerryDepartureCity,
  updateFerryDepartureDateTime,
  updateFerryDepartureTerminal,
  updateFerryDescription,
  updateFerryInfo,
  updateFerryDestinationCity,
  updateFerryDestinationDateTime,
  updateFerryDestinationTerminal,
  updateFerryName,
  updateFerryOrganization,
  saveFerry,
  updateFerry,
  deleteFerry,
  setCSVDialogVisibleTrue,
  updateFerryProducts,
  toggleCollectPaymentForFerryProduct,
} from 'redux/actions/ferry';
import { getOrganizations } from 'redux/actions/organization';
import { fetchOrgProducts } from 'redux/actions/product';

import { getFerryFromState } from 'redux/selectors/ferry';
import { getRoleAndOrganization } from 'redux/selectors/auth';
import { getOrgProducts } from 'redux/selectors/product';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  doubleRootWidth: {
    width: '50ch!important',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  alertBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    minHeight: 140,
    minWidth: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  control: {
    padding: theme.spacing(2),
  },
  formControlInfo: {
    margin: theme.spacing(1),
    width: 360,
  },
  infoField: {
    width: '100%!important',
  },
  productTitle: {
    fontSize: 14,
  },
  productInfo: {
    fontSize: 12,
  },
  popupIndicator: {
    paddingBottom: '20px',
  },
  clearIndicator: {
    paddingBottom: '20px',
  },
  tableContainer: {
    margin: theme.spacing(1),
    width: '50ch',
  },
  tableDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const organizationOptions = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

const objectDoesNotContainNull = (obj) => {
  const notMandatory = ['description'];
  const keys = Object.keys(obj);
  const values = keys
    .filter((key) => !notMandatory.includes(key))
    .map((key) => obj[key]);
  return !values.includes(null);
};

const FerryForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { ferry } = useSelector((state) => getFerryFromState(state.ferry));

  const { role, organization } = useSelector((state) =>
    getRoleAndOrganization(state.auth)
  );

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  useEffect(() => {
    if (ferry.organization) {
      dispatch(fetchOrgProducts(ferry.organization));
    }
  }, [ferry.organization, dispatch]);

  const { organizations } = useSelector((state) => state.organization);

  const handleAgree = () => {
    dispatch(deleteFerry(ferry));
    setConfirmDelete(false);
  };
  const closeDialog = () => setConfirmDelete(false);

  const { organizationProducts } = useSelector((state) =>
    getOrgProducts(state.product)
  );

  return (
    <div>
      <h2> {ferry._id ? 'Edit ferry' : 'Add new ferry'} </h2>

      {ferry && (
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          key={ferry._id}
        >
          <div className={classes.alertBox}>
            <AlertMessage />
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                label="Ferry name"
                defaultValue={ferry.name}
                required
                onChange={(e) => dispatch(updateFerryName(e.target.value))}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="description"
                label="Ferry description"
                defaultValue={ferry.description}
                onChange={(e) =>
                  dispatch(updateFerryDescription(e.target.value))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControlInfo}>
              <TextField
                id="info"
                multiline
                rows={4}
                className={classes.infoField}
                label="Ferry info text"
                defaultValue={ferry.info}
                onChange={(e) => dispatch(updateFerryInfo(e.target.value))}
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="departure city"
                label="Departure from"
                defaultValue={ferry.pointOfDeparture.city}
                required
                onChange={(e) =>
                  dispatch(updateFerryDepartureCity(e.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="departure terminal"
                label="Departure terminal"
                defaultValue={ferry.pointOfDeparture.terminal}
                required
                onChange={(e) =>
                  dispatch(updateFerryDepartureTerminal(e.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="Departure date & time"
                value={ferry.pointOfDeparture.dateTime}
                onChange={(date, value) => {
                  dispatch(updateFerryDepartureDateTime(date));
                }}
                format="DD/MM/YYYY HH:mm"
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="destination city"
                label="Destination city"
                defaultValue={ferry.destination.city}
                required
                onChange={(e) =>
                  dispatch(updateFerryDestinationCity(e.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="destination terminal"
                label="Destination terminal"
                defaultValue={ferry.destination.terminal}
                required
                onChange={(e) =>
                  dispatch(updateFerryDestinationTerminal(e.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="Destination date & time"
                value={ferry.destination.dateTime}
                onChange={(date, value) => {
                  dispatch(updateFerryDestinationDateTime(date));
                }}
                format="DD/MM/YYYY HH:mm"
              />
            </FormControl>
          </div>
          <div className={classes.tableDiv}>
            {ferry.groups.length > 0 ? (
              <Fragment>
                <FormControl className={classes.formControl}>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Groups</TableCell>
                          <TableCell align="right">Capacity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ferry.groups.map((group) => (
                          <TableRow key={group.name}>
                            <TableCell component="th" scope="row">
                              {group.name}
                            </TableCell>
                            <TableCell align="right">
                              {group.users ? group.users.length : 0}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell>TOTAL CAPACITY</TableCell>
                          <TableCell align="right">
                            {ferry.groups
                              ? ferry.groups
                                  .map((group) =>
                                    group.users ? group.users.length : 0
                                  )
                                  .reduce(
                                    (accumulator, currentValue) =>
                                      accumulator + currentValue
                                  )
                              : 0}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(setCSVDialogVisibleTrue(ferry._id))}
                  >
                    Import QR tickets
                  </Button>
                </FormControl>
              </Fragment>
            ) : (
              'No Groups yet selected for this ferry'
            )}
          </div>
          <div>
            {role !== 'root' ? (
              <TextField
                label="Ferry organization"
                id="organization"
                disabled
                value={organization._id}
                select
                InputProps={{
                  readOnly: true,
                }}
              >
                <MenuItem key={organization._id} value={organization._id}>
                  {organization.name}
                </MenuItem>
              </TextField>
            ) : role === 'root' ? (
              <TextField
                label="Ferry organization"
                id="organization"
                select
                required
                value={ferry.organization ? ferry.organization : ''}
                onChange={(event) => {
                  dispatch(updateFerryOrganization(event.target.value));
                }}
              >
                {organizationOptions(organizations)}
              </TextField>
            ) : (
              <></>
            )}
          </div>
          {ferry._id && (
            <Fragment>
              <FormControl className={classes.formControl}>
                {ferry.organization && organizationProducts ? (
                  <Autocomplete
                    multiple
                    id="products"
                    classes={{
                      popupIndicator: classes.popupIndicator,
                      clearIndicator: classes.clearIndicator,
                    }}
                    options={createProductOptions(organizationProducts)}
                    getOptionLabel={(option) => option.product.name}
                    value={ferry.products ?? []}
                    onChange={(event, value, reason) =>
                      dispatch(updateFerryProducts(value))
                    }
                    getOptionSelected={(option, value) =>
                      option.product.name === value.product.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.doubleRootWidth}
                        variant="standard"
                        label="Products"
                      />
                    )}
                  />
                ) : (
                  <Fragment>
                    <h1>Please add products for events organization first</h1>
                  </Fragment>
                )}
              </FormControl>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                  {ferry?.products?.map((productObj) => (
                    <Grid key={productObj.product._id} item>
                      <Paper elevation={3} className={classes.paper}>
                        <Typography
                          className={classes.productTitle}
                          color="textPrimary"
                          gutterBottom
                          variant="h2"
                        >
                          {productObj.product.name +
                            ' (' +
                            (productObj.product.mandatory
                              ? 'mandatory)'
                              : 'unmandatory)')}
                        </Typography>
                        <Typography
                          className={classes.productInfo}
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Price: {productObj.product.price} €
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={productObj.collectPayment}
                              onChange={() => {
                                dispatch(
                                  toggleCollectPaymentForFerryProduct({
                                    ...productObj,
                                    collectPayment: !productObj.collectPayment,
                                  })
                                );
                              }}
                              name="collect payment"
                              color="primary"
                            />
                          }
                          className={classes.productInfo}
                          label={
                            <Typography
                              className={classes.productInfo}
                              color="textPrimary"
                              gutterBottom
                              variant="h5"
                            >
                              Collect payment
                            </Typography>
                          }
                          labelPlacement="top"
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Fragment>
          )}
        </form>
      )}
      <div className={classes.buttonContainer}>
        {objectDoesNotContainNull(ferry) &&
          objectDoesNotContainNull(ferry.pointOfDeparture) &&
          objectDoesNotContainNull(ferry.destination) && (
            <Button
              className={classes.button}
              aria-label="save"
              color="primary"
              variant="contained"
              onClick={() =>
                dispatch(ferry._id ? updateFerry(ferry) : saveFerry(ferry))
              }
            >
              Save
            </Button>
          )}
        {ferry._id && (
          <Button
            className={classes.button}
            aria-label="delete"
            color="secondary"
            variant="contained"
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
        )}
      </div>

      <ImportCSVDialog />

      <DeleteConfirm
        visible={confirmDelete}
        handleAgree={handleAgree}
        closeDialog={closeDialog}
      ></DeleteConfirm>
    </div>
  );
};

export default FerryForm;
