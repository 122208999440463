import {
  FETCH_FERRYS_REQUESTED,
  FETCH_FERRYS_RESPONSE,
  FETCH_FERRYS_ERROR,
  UPDATE_FERRY_REQUESTED,
  UPDATE_FERRY_RESPONSE,
  UPDATE_FERRY_ERROR,
  SAVE_FERRY_REQUESTED,
  SAVE_FERRY_RESPONSE,
  SAVE_FERRY_ERROR,
  DELETE_FERRY_ERROR,
  DELETE_FERRY_REQUESTED,
  DELETE_FERRY_RESPONSE,
  SEND_QR_TICKETS_REQUESTED,
  SEND_QR_TICKETS_RESPONSE,
  SEND_QR_TICKETS_ERROR,
  UPDATE_FERRY_NAME,
  UPDATE_FERRY_DEPARTURE_CITY,
  UPDATE_FERRY_DEPARTURE_TERMINAL,
  UPDATE_FERRY_DEPARTURE_DATE_TIME,
  UPDATE_FERRY_DESTINATION_CITY,
  UPDATE_FERRY_DESTINATION_TERMINAL,
  UPDATE_FERRY_DESTINATION_DATE_TIME,
  UPDATE_FERRY_DESCRIPTION,
  UPDATE_FERRY_ORGANIZATION,
  UPDATE_FERRY_INFO,
  SELECT_NEW_FERRY,
  SELECT_EXISTING_FERRY,
  SET_FERRY_FILTER_ORG,
  SET_FERRY_FILTER_GROUP_FORM_ORG,
  SET_CSV_DIALOG_VISIBLE_TRUE,
  SET_CSV_DIALOG_VISIBLE_FALSE,
  UPDATE_CSV_JSON,
  UPDATE_FERRY_PRODUCTS,
  UPDATE_FERRY_PRODUCT_COLLECT_PAYMENT,
} from 'redux/actions/ferry';

const { updateProductCollectPayment } = require('utils/product');

const initialState = {
  ferrys: [],
  ferry: {
    name: null,
    pointOfDeparture: {
      city: null,
      terminal: null,
      dateTime: new Date(),
    },
    destination: {
      city: null,
      terminal: null,
      dateTime: new Date(),
    },
    description: null,
    info: null,
    organization: null,
    groups: [],
    products: [],
  },
  filterFerrysOrg: 'all',
  filterFerrysOnGroupFormByOrg: 'all',
  importCSVDialog: {
    visible: false,
    csvJSON: null,
    ferryId: null,
  },
  error: {},
};

const updateFerrysArray = (ferrys, updated) => {
  const filtered = ferrys.filter((ferry) => ferry._id !== updated._id);
  filtered.push(updated);
  return filtered;
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEND_QR_TICKETS_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case SAVE_FERRY_RESPONSE:
      return {
        ...state,
        ferrys: updateFerrysArray(state.ferrys, action.payload),
      };

    case UPDATE_FERRY_RESPONSE:
      return {
        ...state,
        ferrys: updateFerrysArray(state.ferrys, action.payload),
      };

    case DELETE_FERRY_RESPONSE:
      return {
        ...state,
        ferrys: state.ferrys.filter(
          (ferry) => ferry._id !== action.payload._id
        ),
        ferry: initialState.ferry,
      };

    case SAVE_FERRY_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_FERRY_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_FERRY_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_FERRYS_RESPONSE:
      return { ...state, ferrys: payload };

    case FETCH_FERRYS_ERROR:
      return { ...state, error: payload };

    case SET_CSV_DIALOG_VISIBLE_TRUE:
      return {
        ...state,
        importCSVDialog: {
          ...state.importCSVDialog,
          visible: true,
          ferryId: action.payload,
        },
      };

    case SET_CSV_DIALOG_VISIBLE_FALSE:
      return {
        ...state,
        importCSVDialog: { visible: false, csvJSON: null, ferryId: null },
      };

    case UPDATE_CSV_JSON:
      return {
        ...state,
        importCSVDialog: { ...state.importCSVDialog, csvJSON: action.payload },
      };

    case SET_FERRY_FILTER_ORG:
      return {
        ...state,
        filterFerrysOrg: action.payload,
      };

    case SET_FERRY_FILTER_GROUP_FORM_ORG:
      return {
        ...state,
        filterFerrysOnGroupFormByOrg: action.payload,
      };

    case SELECT_EXISTING_FERRY:
      return {
        ...state,
        ferry: action.payload ? action.payload : null,
      };

    case SELECT_NEW_FERRY:
      return {
        ...state,
        ferry: initialState.ferry,
      };

    case UPDATE_FERRY_NAME:
      return {
        ...state,
        ferry: { ...state.ferry, name: action.payload },
      };

    case UPDATE_FERRY_DESCRIPTION:
      return {
        ...state,
        ferry: { ...state.ferry, description: action.payload },
      };

    case UPDATE_FERRY_INFO:
      return {
        ...state,
        ferry: { ...state.ferry, info: action.payload },
      };

    case UPDATE_FERRY_ORGANIZATION:
      return {
        ...state,
        ferry: { ...state.ferry, organization: action.payload },
      };

    case UPDATE_FERRY_DEPARTURE_CITY:
      return {
        ...state,
        ferry: {
          ...state.ferry,
          pointOfDeparture: {
            ...state.ferry.pointOfDeparture,
            city: action.payload,
          },
        },
      };

    case UPDATE_FERRY_DEPARTURE_TERMINAL:
      return {
        ...state,
        ferry: {
          ...state.ferry,
          pointOfDeparture: {
            ...state.ferry.pointOfDeparture,
            terminal: action.payload,
          },
        },
      };

    case UPDATE_FERRY_DEPARTURE_DATE_TIME:
      return {
        ...state,
        ferry: {
          ...state.ferry,
          pointOfDeparture: {
            ...state.ferry.pointOfDeparture,
            dateTime: action.payload,
          },
        },
      };

    case UPDATE_FERRY_DESTINATION_CITY:
      return {
        ...state,
        ferry: {
          ...state.ferry,
          destination: {
            ...state.ferry.destination,
            city: action.payload,
          },
        },
      };

    case UPDATE_FERRY_DESTINATION_TERMINAL:
      return {
        ...state,
        ferry: {
          ...state.ferry,
          destination: {
            ...state.ferry.destination,
            terminal: action.payload,
          },
        },
      };

    case UPDATE_FERRY_DESTINATION_DATE_TIME:
      return {
        ...state,
        ferry: {
          ...state.ferry,
          destination: {
            ...state.ferry.destination,
            dateTime: action.payload,
          },
        },
      };

    case UPDATE_FERRY_PRODUCTS:
      return {
        ...state,
        ferry: { ...state.ferry, products: action.payload },
      };

    case UPDATE_FERRY_PRODUCT_COLLECT_PAYMENT:
      return {
        ...state,
        ferry: {
          ...state.ferry,
          products: updateProductCollectPayment(
            state.ferry.products,
            action.payload
          ),
        },
      };

    case SEND_QR_TICKETS_RESPONSE:
    case SEND_QR_TICKETS_REQUESTED:
    case DELETE_FERRY_REQUESTED:
    case UPDATE_FERRY_REQUESTED:
    case SAVE_FERRY_REQUESTED:
    case FETCH_FERRYS_REQUESTED:
    default:
      return state;
  }
}
