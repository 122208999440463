import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from 'components/routes/PrivateRoute';
import Register from 'components/views/customer/register/Register';
import UserDashboard from 'components/views/customer/userDashboard/UserDashboard';
import OrganizationHome from 'components/views/customer/organization/OrganizationHome';
import PaymentSuccess from 'components/views/customer/payments/PaymentSuccess';
import PaymentCancel from 'components/views/customer/payments/PaymentCancel';
import Login from 'components/auth/Login';
import PasswordResetRequest from 'components/auth/PasswordResetRequest';
import ResetPassword from 'components/auth/ResetPassword';

const CustomerContentRoutes = () => {
  return (
    <Fragment>
      <Route exact path="/" component={Login}></Route>
      <Route exact path="/login" component={Login}></Route>
      <Route exact path="/register/:orgId" component={Register}></Route>
      <Route
        exact
        path="/password-reset-request"
        component={PasswordResetRequest}
      ></Route>
      <Route exact path="/reset-password" component={ResetPassword}></Route>
      <Route
        exact
        path="/organization/:identifier"
        component={OrganizationHome}
      ></Route>
      <PrivateRoute
        exact
        path="/user-dashboard"
        component={UserDashboard}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/payment-success"
        component={PaymentSuccess}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/payment-cancel"
        component={PaymentCancel}
      ></PrivateRoute>
    </Fragment>
  );
};

export default CustomerContentRoutes;
