import { Button } from '@material-ui/core';
import React, { Fragment } from 'react';
import CsvDownloader from 'react-csv-downloader';

const CsvDownloaderSucceed = ({ data }) => {
  const columns = [
    {
      id: 'firstName',
      displayName: 'First name',
    },
    {
      id: 'lastName',
      displayName: 'Last name',
    },
    {
      id: 'email',
      displayName: 'Email',
    },
    {
      id: 'phone',
      displayName: 'Phone number',
    },
    {
      id: 'group',
      displayName: 'Group',
    },
    {
      id: 'event',
      displayName: 'Event',
    },
    {
      id: 'product',
      displayName: 'Product',
    },
    {
      id: 'refundAmount',
      displayName: 'Refund amount',
    },
    {
      id: 'allTimeRefundAmount',
      displayName: 'All time refunded',
    },
  ];

  const createCsvData = () => {
    return data.map((payment) => {
      return {
        firstName: payment.user.firstName,
        lastName: payment.user.lastName,
        email: payment.user.email,
        phone: payment.user.phone,
        group: payment.user.group.name,
        event: payment.event.name,
        product: payment.products[0].name,
        refundAmount: payment.thisRefundAmount,
        allTimeRefundAmount: payment.refundAmount,
      };
    });
  };

  return (
    <Fragment>
      <h2>Download list of succeeded refunds!</h2>
      <Button color="primary" size="medium" variant="contained">
        <CsvDownloader
          columns={columns}
          datas={createCsvData()}
          filename="succeed_refunds"
          extension=".csv"
          separator=";"
          wrapColumnChar=""
        >
          Download csv of succeed refunds
        </CsvDownloader>
      </Button>
    </Fragment>
  );
};

export default CsvDownloaderSucceed;
