import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

export const SELECT_NEW_DISCOUNT_CODE = 'SELECT_NEW_DISCOUNT_CODE';
export const UPDATE_DISCOUNT_CODE_NAME = 'UPDATE_DISCOUNT_CODE_NAME';
export const UPDATE_DISCOUNT_CODE_VALID_UNTIL =
  'UPDATE_DISCOUNT_CODE_VALID_UNTIL';
export const UPDATE_DISCOUNT_CODE_AMOUNT = 'UPDATE_DISCOUNT_CODE_AMOUNT';
export const UPDATE_DISCOUNT_CODE_CODE = 'UPDATE_DISCOUNT_CODE_CODE';
export const UPDATE_DISCOUNT_CODE_DISCOUNT = 'UPDATE_DISCOUNT_CODE_DISCOUNT';
export const UPDATE_DISCOUNT_CODE_DESCRIPTION =
  'UPDATE_DISCOUNT_CODE_DESCRIPTION';
export const UPDATE_DISCOUNT_CODE_APPLY_TO = 'UPDATE_DISCOUNT_CODE_APPLY_TO';
export const UPDATE_DISCOUNT_CODE_ORGANIZATION =
  'UPDATE_DISCOUNT_CODE_ORGANIZATION';

export const SAVE_DISCOUNT_CODE_REQUESTED = 'SAVE_DISCOUNT_CODE_REQUESTED';
export const SAVE_DISCOUNT_CODE_RESPONSE = 'SAVE_DISCOUNT_CODE_RESPONSE';
export const SAVE_DISCOUNT_CODE_ERROR = 'SAVE_DISCOUNT_CODE_ERROR';

export const FETCH_DISCOUNT_CODES_REQUESTED = 'FETCH_DISCOUNT_CODES_REQUESTED';
export const FETCH_DISCOUNT_CODES_RESPONSE = ' FETCH_DISCOUNT_CODES_RESPONSE';
export const FETCH_DISCOUNT_CODE_ERROR = 'FETCH_DISCOUNT_CODE_ERROR';

export const SELECT_EXISTING_DISCOUNT_CODE = 'SELECT_EXISTING_DISCOUNT_CODE';

export const UPDATE_DISCOUNT_CODE_REQUESTED = 'UPDATE_DISCOUNT_CODE_REQUESTED';
export const UPDATE_DISCOUNT_CODE_ERROR = 'UPDATE_DISCOUNT_CODE_ERROR';
export const UPDATE_DISCOUNT_CODE_RESPONSE = 'UPDATE_DISCOUNT_CODE_RESPONSE';

export const FETCH_USED_DISCOUNT_CODES_REQUESTED =
  'FETCH_USED_DISCOUNT_CODES_REQUESTED';
export const FETCH_USED_DISCOUNT_CODES_RESPONSE =
  'FETCH_USED_DISCOUNT_CODES_RESPONSE';
export const FETCH_USED_DISCOUNT_CODES_ERROR =
  'FETCH_USED_DISCOUNT_CODES_ERROR';

export const SET_DISCOUNT_CODE_FILTER_ORG = 'SET_DISCOUNT_CODE_FILTER_ORG';
export const UPDATE_DISCOUNT_CODE_DISABLED = 'UPDATE_DISCOUNT_CODE_DISABLED';
export const selectNewDiscountCode = () => async (dispatch) => {
  dispatch({
    type: SELECT_NEW_DISCOUNT_CODE,
  });
};

export const updateDiscountCodeName = (payload) => ({
  type: UPDATE_DISCOUNT_CODE_NAME,
  payload,
});

export const updateDiscountCodeValidUntil = (payload) => ({
  type: UPDATE_DISCOUNT_CODE_VALID_UNTIL,
  payload,
});

export const updateDiscountCodeCode = (payload) => ({
  type: UPDATE_DISCOUNT_CODE_CODE,
  payload,
});

export const updateDiscountCodeAmount = (payload) => ({
  type: UPDATE_DISCOUNT_CODE_AMOUNT,
  payload,
});

export const updateDiscountCodeDiscount = (payload) => ({
  type: UPDATE_DISCOUNT_CODE_DISCOUNT,
  payload,
});

export const updateDiscountCodeDescription = (payload) => ({
  type: UPDATE_DISCOUNT_CODE_DESCRIPTION,
  payload,
});

export const updateDiscountCodeOrganization = (payload) => ({
  type: UPDATE_DISCOUNT_CODE_ORGANIZATION,
  payload,
});

export const updateDiscountCodeApplyTo = (payload) => ({
  type: UPDATE_DISCOUNT_CODE_APPLY_TO,
  payload,
});

export const updateDiscountCodeDisabled = (payload) => ({
  type: UPDATE_DISCOUNT_CODE_DISABLED,
  payload,
});

export const saveDiscountCode = (discountCode) => async (dispatch) => {
  dispatch({ type: SAVE_DISCOUNT_CODE_REQUESTED });
  try {
    const res = await API.post('/api/admin/discountCodes', discountCode);
    dispatch(setAlert('Discount code saved succesfully', 'success'));
    dispatch({ type: SAVE_DISCOUNT_CODE_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: SAVE_DISCOUNT_CODE_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateDiscountCode = (discountCode) => async (dispatch) => {
  dispatch({ type: UPDATE_DISCOUNT_CODE_REQUESTED });
  try {
    const res = await API.put(
      `/api/admin/discountCodes/${discountCode._id}`,
      discountCode
    );
    dispatch(setAlert('Discount code updated successfully', 'success'));
    dispatch({ type: UPDATE_DISCOUNT_CODE_RESPONSE, payload: res.data });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to update discount code', 'error'));
    }
    dispatch({
      type: UPDATE_DISCOUNT_CODE_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const fetchDiscountCodes = () => async (dispatch) => {
  dispatch({ type: FETCH_DISCOUNT_CODES_REQUESTED });
  try {
    const res = await API.get('/api/admin/discountCodes');
    dispatch({ type: FETCH_DISCOUNT_CODES_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: FETCH_DISCOUNT_CODE_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const selectExistingDiscountCode =
  (discountCode) => async (dispatch) => {
    dispatch({
      type: SELECT_EXISTING_DISCOUNT_CODE,
      payload: discountCode,
    });
  };

export const fetchUsersWhoUsedDiscountCode = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_USED_DISCOUNT_CODES_REQUESTED,
  });

  try {
    const res = await API.get(`/api/admin/discountCodes/usedCodes/${id}`);
    dispatch({
      type: FETCH_USED_DISCOUNT_CODES_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: FETCH_USED_DISCOUNT_CODES_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const selectOrgToFilter = (orgId) => async (dispatch) => {
  dispatch({
    type: SET_DISCOUNT_CODE_FILTER_ORG,
    payload: orgId,
  });
};
