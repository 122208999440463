import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'react-moment';

import { getOrganizations } from 'redux/actions/organization';
import { getOrganizationEvents } from 'redux/selectors/event';
import {
  selectExistingEvent,
  selectNewEvent,
  selectOrgToFilter,
  FETCH_EVENTS_RESPONSE,
  fetchEventsForOrganization,
} from 'redux/actions/event';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  paymentTitle: {
    marginTop: theme.spacing(2),
    fontSize: 14,
    fontWeight: 'bold',
  },
  eventInfo: {
    fontSize: 12,
  },
  cardActionCenter: {
    justifyContent: 'center',
  },
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: 120,
  },
  fab: {
    background: theme.palette.success.main,
    color: 'white',
    position: 'fixed',
    top: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

const getMenuItems = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

const countPayedPayments = (productId, groups) => {
  let payed = 0;

  groups.forEach((group) => {
    if (group.users) {
      group.users.forEach((user) => {
        if (user.eventPayments) {
          user.eventPayments.forEach((payment) => {
            if (
              payment.products.indexOf(productId) !== -1 &&
              (payment.status === 'confirmed' || payment.status === 'accepted')
            ) {
              payed++;
            }
          });
        }
      });
    }
  });

  groups.forEach((group) => {
    if (group.users) {
      group.users.forEach((user) => {
        if (user.additionalServices) {
          user.additionalServices.forEach((payment) => {
            if (
              payment.products.indexOf(productId) !== -1 &&
              (payment.status === 'confirmed' || payment.status === 'accepted')
            ) {
              payed++;
            }
          });
        }
      });
    }
  });

  return payed;
};

const Events = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();

  const { role } = useSelector((state) => state.auth);

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  const { organizations } = useSelector((state) => state.organization);

  const { filterEventsOrg } = useSelector((state) => state.event);
  const selectedEvents = useSelector((state) =>
    getOrganizationEvents(state.event)
  );

  useEffect(() => {
    if (
      filterEventsOrg === '-' ||
      filterEventsOrg === null ||
      filterEventsOrg === 'all'
    ) {
      dispatch({
        type: FETCH_EVENTS_RESPONSE,
        payload: [],
      });
    } else {
      dispatch(fetchEventsForOrganization(filterEventsOrg));
    }
  }, [dispatch, filterEventsOrg]);

  const handleEditDetailsClick = (eventObject) => {
    dispatch(selectExistingEvent(eventObject));
    history.push('/event-form');
  };

  return (
    <div className={classes.root}>
      <h2> Events </h2>

      {role === 'root' && organizations && (
        <FormControl
          className={classes.formControl}
          key={`amountOfSelections_${organizations.length}`}
        >
          <InputLabel id="organizationSelectionLabel">Filter org</InputLabel>
          <Select
            labelId="organizationSelectionLabel"
            id="organizationSelection"
            defaultValue="all"
            onChange={({ target: { value } }) =>
              dispatch(selectOrgToFilter(value))
            }
          >
            <MenuItem key="-" value={'-'}>
              -
            </MenuItem>
            {getMenuItems(organizations)}
          </Select>
        </FormControl>
      )}

      <Fab
        className={classes.fab}
        component={NavLink}
        to="/event-form"
        onClick={() => dispatch(selectNewEvent())}
        aria-label="add"
        variant="extended"
      >
        <AddIcon />
        New
      </Fab>
      <div key={filterEventsOrg}>
        <Grid container justifyContent="center" spacing={3}>
          {selectedEvents && selectedEvents.length > 0 ? (
            selectedEvents.map((eventObject) => (
              <Fragment key={eventObject._id}>
                <Grid item>
                  <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                        variant="h2"
                      >
                        {eventObject.name
                          ? eventObject.name
                          : eventObject.identifier}
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                        variant="h3"
                      >
                        <Moment format="DD/MM/yyyy">{eventObject.date}</Moment>
                      </Typography>

                      <Typography className={classes.eventInfo}>
                        Groups: {eventObject.groups.length}
                      </Typography>
                      <Typography className={classes.eventInfo}>
                        Capacity: {eventObject.capacity}
                      </Typography>
                      <Typography
                        className={classes.paymentTitle}
                        color="textPrimary"
                        gutterBottom
                        variant="h2"
                      >
                        Payments done
                      </Typography>
                      {eventObject.products.filter(
                        (obj) => obj.product.mandatory
                      ).length !== 0
                        ? eventObject.products
                            .filter((obj) => obj.product.mandatory)
                            .map((obj) => (
                              <Typography
                                key={obj.product._id}
                                className={classes.eventInfo}
                              >
                                {obj.product.name}:{' '}
                                {countPayedPayments(
                                  obj.product._id,
                                  eventObject.groups
                                )}{' '}
                                / {eventObject.capacity}
                              </Typography>
                            ))
                        : 'No mandatory products'}
                    </CardContent>
                    <CardActions className={classes.cardActionCenter}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => handleEditDetailsClick(eventObject)}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Fragment>
            ))
          ) : (
            <Fragment>
              <h3>Please start by adding an event</h3>
            </Fragment>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Events;
