import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

import CustomerAlert from 'components/layouts/CustomerAlert';
import { resetPassword } from 'redux/actions/auth';
import Container from './Container';
import Header from './Header';
import HeaderInfo from './HeaderInfo';
import SubmitButton from './SubmitButton';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  formInput: {
    background: 'rgba(255, 255, 255, 0.1)',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '119%',
    /* or 74px */
    color: '#FFFFFF',
    opacity: 0.5,
  },

  inputText: {
    color: '#FFFFFF',
    opacity: 0.95,
  },

  inputLabel: {
    color: '#FFFFFF',
    opacity: 0.95,
    '&.focused': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
    '&.shrink': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
  },

  submit: {
    '&:hover': {
      background: '#AD00FF',
    },
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    order: 1,
    alignSelf: 'center',
    margin: '8px 0px',
  },

  link: {
    textDecoration: 'none',
  },
}));

const ResetPassword = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const mobileScreen = useMediaQuery('(max-width:990px)');

  const query = new URLSearchParams(props.location.search);
  const token = query.get('token');
  const userId = query.get('id');

  const { isPasswordReseted } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    password: '',
  });

  const { password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onClick = (e) => {
    e.preventDefault();
    dispatch(resetPassword(token, userId, password));
  };

  return (
    <Fragment>
      <Container mobileScreen={mobileScreen}>
        {!isPasswordReseted ? (
          <Fragment>
            <Header>Syötä uusi salasana</Header>
            <CustomerAlert />
            <form className={classes.form} noValidate>
              <TextField
                className={classes.formInput}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Salasana"
                type="password"
                id="password"
                value={password}
                onChange={(e) => onChange(e)}
                autoFocus
                InputProps={{
                  className: classes.inputText,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: 'focused',
                    shrink: 'shrink',
                  },
                }}
              />
              <SubmitButton mobileScreen={mobileScreen} onClick={onClick}>
                Vaihda salasana
              </SubmitButton>
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <Header>Salasana vaihdettu</Header>
            <HeaderInfo>Ole hyvä ja kirjaudu sisään!</HeaderInfo>
            <Link className={classes.link} to="/login">
              <Button
                type="button"
                fullWidth={mobileScreen}
                className={classes.submit}
              >
                Kirjaudu
              </Button>
            </Link>
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};

export default ResetPassword;
