const createProductOptions = (products) => {
  const productOptions = [];
  products.forEach((product) =>
    productOptions.push({ product, collectPayment: false })
  );

  return productOptions;
};

const updateProductCollectPayment = (products, updatedProduct) => {
  const updatedProducts = products.map((obj) =>
    obj.product._id === updatedProduct.product._id ? updatedProduct : obj
  );
  return updatedProducts;
};

module.exports = {
  createProductOptions,
  updateProductCollectPayment,
};
