import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Navigation from '../navigation/Navigation';
import { Switch } from 'react-router-dom';

import AdminContentRoutes from '../routes/AdminContentRoutes';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    textAlign: 'center',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export const AdminApp = () => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.root}>
        <CssBaseline />
        <Navigation />

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Switch>
            <AdminContentRoutes />
          </Switch>
        </main>
      </div>
    </MuiPickersUtilsProvider>
  );
};
export default AdminApp;
