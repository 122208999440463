export const getUsersFromState = (state) => {
  const { users, filterByRoom } = state;

  if (filterByRoom) {
    const usersToReturn = [];
    const usersInList = [];

    users.forEach((user) => {
      // Check if user is already in list
      if (usersInList.indexOf(user._id) === -1) {
        // Check if user has room partners
        if (user.room && user.room.users) {
          // Check if room partner is already in list and if not add user to list of users to show for admin
          // and also add user and his partners to the list where we check who are already in
          if (
            !user.room.users.some((obj) => usersInList.indexOf(obj._id) !== -1)
          ) {
            usersToReturn.push(user);
            usersInList.push(user._id);
            user.room.users.forEach((obj) => {
              if (usersInList.indexOf(obj._id) === -1) {
                usersInList.push(obj._id);
              }
            });
          }
        }
      }
    });

    // Get list of users not in the usersToReturn list
    const usersNotInList = users
      .filter((user) => usersInList.indexOf(user._id) === -1)
      .map((user) => ({
        ...user,
        room: null,
        lastName: `${user.lastName} (pudonnut)`,
      }));

    return { users: [...usersToReturn, ...usersNotInList] };
  }

  return { users };
};

export const getUserFromState = (state) => {
  const { user, showPassword } = state;
  return { user, showPassword };
};

export const getFilterByRoomFromState = (state) => {
  const { filterByRoom } = state;
  return { filterByRoom };
};

export const getEventPaymentsFromState = (state) => {
  const { eventPayments } = state;
  return { eventPayments };
};

export const getPaymentDialogFromState = (state) => {
  const { visible, payment } = state.paymentDialog;
  return { visible, payment };
};

export const getFetchingUsersFromState = (state) => {
  const { fetchingUsers } = state;
  return { fetchingUsers };
};
