export const getOrganizationGroups = (state) => {
  const { filterGroupsOrg, filterGroupsEvent, groups } = state;
  if (filterGroupsOrg !== 'all' || filterGroupsEvent !== 'all') {
    let filteredGroups = groups;

    if (filterGroupsOrg !== 'all') {
      filteredGroups = groups.filter(
        (group) => group.event.organization === filterGroupsOrg
      );
    }

    if (filterGroupsEvent !== 'all') {
      filteredGroups = groups.filter(
        (group) => group.event._id === filterGroupsEvent
      );
    }

    return { groups: filteredGroups };
  } else {
    return { groups };
  }
};

export const getOrganizationGroupsCustomerForm = (state) => {
  const { filterGroupsOrgCustomerForm, groups } = state;
  if (filterGroupsOrgCustomerForm !== 'all') {
    let filteredGroups = groups.filter(
      (group) => group.event.organization === filterGroupsOrgCustomerForm
    );
    return { groups: filteredGroups };
  } else {
    return { groups };
  }
};

export const getFilterGroupsOptions = (state) => {
  const { filterGroupsOrg, filterGroupsEvent } = state;
  return { filterGroupsOrg, filterGroupsEvent };
};

export const getFilterGroupsOrgCustomerForm = (state) => {
  const { filterGroupsOrgCustomerForm } = state;
  return { filterGroupsOrgCustomerForm };
};

export const getGroups = (state) => {
  const { groups } = state;
  return { groups };
};
