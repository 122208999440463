import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { loadCSS } from 'fg-loadcss';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import {
  setPartnerGender,
  setRoomtype,
  postRoomPartnerByGenderConfirmation,
  postRoomPartnerByRoomConfirmation,
  updateRoompartners,
} from 'redux/actions/customer';

import {
  getRoomPartnerInfo,
  getUserInfoFormState,
} from 'redux/selectors/customer';
import CustomerAlert from 'components/layouts/CustomerAlert';

import ConfirmationDialog from './ConfirmationDialog';
import RoomPartnerSelector from './RoomPartnerSelector';

const useStyles = makeStyles((theme) => ({
  roompartnerHeader: {
    marginTop: '3%',
    marginLeft: '5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '46px',
    marginBottom: theme.spacing(2),
    color: '#20D2B7',
  },
  roompartnerInfo: {
    marginLeft: '5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  roomTypeInfo: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
    marginBottom: theme.spacing(1),
  },
  roompartnerInfoHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    marginLeft: '5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  roompartnerCheckIn: {
    marginTop: theme.spacing(2),
    marginLeft: '5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  choosePartnerOptionsContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: '5%',
    display: 'flex',
    flexDirection: 'row',
  },
  choosePartnerOptionsContainerMobile: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
  },
  choosePartnerContainer: {
    minWidth: '30%',
    overflowY: 'visible',
  },

  choosePartnerContainerMobile: {
    minWidth: '30%',
    marginTop: theme.spacing(5),
  },
  chooseByGenderContainer: {
    minWidth: '30%',
  },
  orContainer: {
    minWidth: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileOrContainer: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
  },
  roompartnerOptionsHeader: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '31px',
    marginBottom: theme.spacing(2),
    color: '#20D2B7',
  },
  roompartnerOptionsSpacer: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: '31px',
    marginBottom: theme.spacing(2),
    color: '#20D2B7',
  },
  roompartnerOptionsInfo: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  buttonItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '15%',
  },
  genderFeButton: {
    height: '52px',
    width: '52px',
    '&:hover': { opacity: '1!important', background: '#EF78E3' },
    background: '#EF78E3',
    border: '3px solid #EF78E3',
    boxSizing: 'border-box',
    borderRadius: '69px',
    color: '#FFFFFF',
  },
  genderMaButton: {
    height: '52px',
    width: '52px',
    '&:hover': { opacity: '1!important', background: '#56CCF2' },
    background: '#56CCF2',
    border: '3px solid #56CCF2',
    boxSizing: 'border-box',
    borderRadius: '69px',
    color: '#FFFFFF',
  },
  genderInfo: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },

  roomButton: {
    height: '52px',
    width: '52px',
    '&:hover': { opacity: '1!important', background: '#20D2B7' },
    background: '#20D2B7',
    border: '3px solid #20D2B7',
    boxSizing: 'border-box',
    borderRadius: '69px',
    color: '#FFFFFF',
    opacity: 0.5,
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '28px',
    textAlign: 'center',
  },
  confirmButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    width: '90%',
    marginTop: theme.spacing(3),
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    margin: '8px 0px',
  },
  closeButton: {
    position: 'absolute',
    width: '18px',
    height: '24px',
    right: '26px',
    top: '20px',
    color: '#20D2B7',
  },
  roomPartnerWillBe: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    paddingTop: '8%',
    paddingBottom: '8%',
    lineHeight: '23px',
    color: '#828282',
  },
}));

const createPartners = (users, userInfo, chosenPartners) => {
  const userOptions = [];

  let roomPartnerIds = chosenPartners.map((item) => item.id);

  let groupUsers = users?.filter((user) => !user.roomPartnerChosen);
  groupUsers = groupUsers?.filter((user) => user._id !== userInfo._id);
  groupUsers = groupUsers?.filter((user) => !roomPartnerIds.includes(user._id));

  if (groupUsers) {
    groupUsers.forEach((user) =>
      userOptions.push({
        name: user.firstName + ' ' + user.lastName,
        id: user._id,
      })
    );
  }

  userOptions.sort(function (a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  return userOptions;
};

const checkRoomByRoomConditions = (roomPartner) => {
  if (!roomPartner.byRoomtype || !roomPartner.roompartners) {
    return true;
  }
  if (
    roomPartner.byRoomtype === '2h' &&
    roomPartner.roompartners.length !== 1
  ) {
    return true;
  } else if (
    roomPartner.byRoomtype === '3h' &&
    roomPartner.roompartners.length !== 2
  ) {
    return true;
  }
  return false;
};

export const RoomPartnerDialog = ({
  visible,
  closeDialog,
  accomodation,
  mobile,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const { roomPartner } = useSelector((state) =>
    getRoomPartnerInfo(state.customer)
  );

  const { groupUsers } = useSelector((state) => state.customer);
  const { info } = useSelector((state) => getUserInfoFormState(state.customer));

  useEffect(() => {
    dispatch(setPartnerGender(info.gender));
  }, [info, dispatch]);

  const handleRoomPartnerByRoom = (e) => {
    e.preventDefault();
    setConfirmation(undefined);
    dispatch(postRoomPartnerByRoomConfirmation(roomPartner));

    setTimeout(() => {
      closeDialog();
    }, 3000);
  };

  const [confirmation, setConfirmation] = useState();

  return (
    <Dialog
      fullScreen={mobile}
      fullWidth
      scroll="body"
      maxWidth="lg"
      open={visible}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-roompartner"
      aria-describedby="alert-dialog-roompartner-decision"
    >
      {accomodation && (
        <Fragment>
          <DialogTitle>
            <Typography className={classes.roompartnerHeader}>
              Huonekaveri
            </Typography>
            {}
            <Typography className={classes.roompartnerInfo}>
              {accomodation.description}
            </Typography>

            <Fragment>
              <Typography className={classes.roompartnerInfoHeader}>
                Paikka
              </Typography>
              <Typography className={classes.roompartnerInfo}>
                {accomodation.name}
              </Typography>
              <Typography className={classes.roompartnerCheckIn}>
                Check-in päivämäärä
              </Typography>
              <Typography className={classes.roompartnerInfo}>
                {moment(accomodation.date).format('DD.MM.YYYY')}
              </Typography>
            </Fragment>
          </DialogTitle>
          <DialogContent>
            <CustomerAlert customerStyle={false} />
            <div
              className={
                mobile
                  ? classes.choosePartnerOptionsContainerMobile
                  : classes.choosePartnerOptionsContainer
              }
            >
              <div
                className={
                  mobile
                    ? classes.choosePartnerContainerMobile
                    : classes.choosePartnerContainer
                }
              >
                <Typography className={classes.roompartnerOptionsHeader}>
                  Valitse huonekaveri{' '}
                  {accomodation.roomAllocationType === 'code'
                    ? 'koodilla'
                    : 'nimellä'}
                </Typography>
                <Typography className={classes.roomTypeInfo}>
                  Kysy haluamaltasi huonekaveriltasi hänen profiilissaan näkyvä
                  {accomodation.roomAllocationType === 'code'
                    ? ' koodi'
                    : ' koko nimi (Etunimi Sukunimi)'}{' '}
                  ja syötä se alle.
                </Typography>
                <Typography className={classes.roompartnerOptionsInfo}>
                  Valitse huonetyyppi
                </Typography>
                <div className={classes.buttonContainer}>
                  {accomodation?.onePersonRoom?.capacity > 0 && (
                    <div className={classes.buttonItem}>
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(updateRoompartners([]));
                          dispatch(setRoomtype('1h'));
                        }}
                        className={classes.roomButton}
                        style={{
                          opacity: roomPartner.byRoomtype === '1h' ? 1 : 0.5,
                        }}
                        disabled={
                          accomodation?.onePersonRoom.capacity <=
                          accomodation?.onePersonRoom.reserved
                        }
                      >
                        1h
                      </IconButton>
                    </div>
                  )}
                  {accomodation?.twoPersonRoom?.capacity > 0 && (
                    <div className={classes.buttonItem}>
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(updateRoompartners([]));
                          dispatch(setRoomtype('2h'));
                        }}
                        className={classes.roomButton}
                        style={{
                          opacity: roomPartner.byRoomtype === '2h' ? 1 : 0.5,
                        }}
                        disabled={
                          accomodation.twoPersonRoom.capacity <=
                          accomodation.twoPersonRoom.reserved
                        }
                      >
                        2h
                      </IconButton>
                    </div>
                  )}
                  {accomodation?.threePersonRoom?.capacity > 0 && (
                    <div className={classes.buttonItem}>
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(updateRoompartners([]));
                          dispatch(setRoomtype('3h'));
                        }}
                        className={classes.roomButton}
                        style={{
                          opacity: roomPartner.byRoomtype === '3h' ? 1 : 0.5,
                        }}
                        disabled={
                          accomodation.threePersonRoom.capacity <=
                          accomodation.threePersonRoom.reserved
                        }
                      >
                        3h
                      </IconButton>
                    </div>
                  )}
                </div>
                {(roomPartner.byRoomtype === '3h' ||
                  roomPartner.byRoomtype === '2h') &&
                  (accomodation.roomAllocationType === 'code' ? (
                    <RoomPartnerSelector />
                  ) : roomPartner.byRoomtype === '2h' ? (
                    <Autocomplete
                      key={'2h'}
                      id="room partners"
                      options={createPartners(
                        groupUsers,
                        info,
                        roomPartner.roompartners
                      )}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value, reason) => {
                        dispatch(
                          updateRoompartners(value === null ? [] : [value])
                        );
                      }}
                      defaultValue={
                        roomPartner.roompartners
                          ? roomPartner.roompartners[0]
                          : []
                      }
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.doubleRootWidth}
                          variant="standard"
                          label="Huonekaveri"
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      key={'3h'}
                      multiple
                      id="room partners"
                      options={createPartners(
                        groupUsers,
                        info,
                        roomPartner.roompartners
                      )}
                      getOptionLabel={(option) => option.name}
                      value={
                        roomPartner?.roompartners
                          ? roomPartner.roompartners
                          : []
                      }
                      onChange={(event, value, reason) => {
                        dispatch(updateRoompartners(value));
                      }}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.doubleRootWidth}
                          variant="standard"
                          label="Huonekaveri"
                        />
                      )}
                    />
                  ))}

                <Button
                  disabled={checkRoomByRoomConditions(roomPartner)}
                  onClick={() => setConfirmation('manual')}
                  className={classes.confirmButton}
                >
                  {roomPartner.byRoomtype === '1h'
                    ? 'Vahvista huonetyyppi'
                    : 'Vahvista huoneparivalinta'}
                </Button>
              </div>
              <div
                className={
                  mobile ? classes.mobileOrContainer : classes.orContainer
                }
              >
                <Typography className={classes.roompartnerOptionsSpacer}>
                  TAI
                </Typography>
              </div>
              <div className={classes.chooseByGenderContainer}>
                <Typography className={classes.roompartnerOptionsHeader}>
                  Valitsemme huonekaverisi puolestasi
                </Typography>
                <Typography className={classes.roomPartnerWillBe}>
                  Huonekaverisi tulee olemaan{' '}
                  {roomPartner.byGender === 'M' ? 'mies' : 'nainen'}
                </Typography>
                <Button
                  onClick={() => {
                    setConfirmation('automatic');
                  }}
                  className={classes.confirmButton}
                >
                  Valitse huonekaveri puolestani
                </Button>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={closeDialog} className={classes.closeButton}>
              <Icon className="fas fa-times"></Icon>
            </IconButton>
          </DialogActions>
        </Fragment>
      )}
      <ConfirmationDialog
        type={confirmation}
        visible={confirmation !== undefined}
        closeDialog={() => setConfirmation(undefined)}
        onConfirm={(e) => {
          if (confirmation === 'manual') {
            handleRoomPartnerByRoom(e);
          } else if (confirmation === 'automatic') {
            e.preventDefault();
            dispatch(postRoomPartnerByGenderConfirmation(roomPartner));
            closeDialog();
          }
        }}
        roomPartners={roomPartner.roompartners}
      />
    </Dialog>
  );
};

export default RoomPartnerDialog;
