import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Moment from 'react-moment';

import { getOrganizations } from 'redux/actions/organization';
import { getOrganizationsFromState } from 'redux/selectors/organization';
import { getOrganizationDiscountCodes } from 'redux/selectors/discountCodes';
import {
  fetchDiscountCodes,
  selectExistingDiscountCode,
  selectNewDiscountCode,
  selectOrgToFilter,
} from 'redux/actions/discountCodes';

import { MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  accomodationInfo: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  cardActionCenter: {
    justifyContent: 'center',
  },
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: 120,
  },
  fab: {
    background: theme.palette.success.main,
    color: 'white',
    position: 'fixed',
    top: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

const getMenuItems = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

export const DiscountCodes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { role } = useSelector((state) => state.auth);

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  useEffect(() => {
    dispatch(fetchDiscountCodes());
  }, [dispatch]);

  const { organizations } = useSelector((state) =>
    getOrganizationsFromState(state.organization)
  );

  const { discountCodes } = useSelector((state) =>
    getOrganizationDiscountCodes(state.discountCode)
  );

  const handleEditDetailsClick = (discountCodeObject) => {
    dispatch(selectExistingDiscountCode(discountCodeObject));
    history.push('/discountcode-form');
  };

  const codeList = () => {
    return discountCodes.map((code) => {
      return (
        <Fragment key={code._id}>
          <Grid item>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textPrimary"
                  gutterBottom
                  variant="h2"
                >
                  {code.name}
                </Typography>
                <Typography
                  className={classes.title}
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                >
                  Date: <Moment format="DD/MM/YYYY">{code.validUntil}</Moment>
                </Typography>
                <Typography className={classes.accomodationInfo}>
                  Codes used: {code.timesUsed + ' / ' + code.maxUsages}
                </Typography>
                <Typography className={classes.accomodationInfo}>
                  Applicable to: {code.applyTo ? code.applyTo.length : '0'}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActionCenter}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => handleEditDetailsClick(code)}
                  startIcon={<EditIcon />}
                >
                  Edit
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Fragment>
      );
    });
  };

  return (
    <div className={classes.root}>
      <h2> Discount codes </h2>
      {role === 'root' && organizations && (
        <div>
          <FormControl
            className={classes.formControl}
            key={`amountOfSelections_${organizations.length}`}
          >
            <InputLabel id="organizationSelectionLabel">Filter org</InputLabel>
            <Select
              labelId="organizationSelectionLabel"
              id="organizationSelection"
              defaultValue=""
              onChange={({ target: { value } }) =>
                dispatch(selectOrgToFilter(value))
              }
            >
              <MenuItem key="all" value={'all'}>
                All
              </MenuItem>
              {getMenuItems(organizations)}
            </Select>
          </FormControl>
        </div>
      )}

      <Fab
        className={classes.fab}
        component={NavLink}
        to="/discountcode-form"
        onClick={() => dispatch(selectNewDiscountCode())}
        aria-label="add"
        variant="extended"
      >
        <AddIcon />
        New
      </Fab>
      <Grid container justifyContent="center" spacing={3}>
        {discountCodes && discountCodes.length > 0 ? (
          codeList()
        ) : (
          <Fragment>
            <h3>Please start by adding a discount code</h3>
          </Fragment>
        )}
      </Grid>
    </div>
  );
};

export default DiscountCodes;
