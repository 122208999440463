import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import RoomPartnerDialog from 'components/views/customer/accomodation/RoomPartnerDialog';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles } from '@material-ui/core/styles';

import {
  getUserAccomodation,
  getUsersGroup,
  getInfoFromState,
  getUserCode,
} from 'redux/selectors/customer';

import { fetchGroupUsers } from 'redux/actions/customer';

import { countries } from 'constants/countries';

const useStyles = makeStyles((theme) => ({
  accomodationContainer: {
    width: '100%',
    background: '#FFFFFF',
    /* Drop */
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    zIndex: 1000,
    paddingBottom: theme.spacing(3),
  },

  accomodationHeader: {
    marginTop: '3%',
    marginLeft: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '46px',
    marginBottom: theme.spacing(2),
    color: '#20D2B7',
  },
  accomodationInfo: {
    marginLeft: '7%',
    marginRight: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  noAccomodationInfo: {
    marginTop: theme.spacing(2),
    marginLeft: '7%',
    marginRight: '7%',
    marginBottom: theme.spacing(3),
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  accomodationHeaderMobile: {
    marginTop: '10%',
    marginLeft: '10%',
    marginRigth: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '102%',
    marginBottom: theme.spacing(2),
    color: '#20D2B7',
  },
  accomodationInfoMobile: {
    marginLeft: '10%',
    marginRight: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '102%',
    color: '#828282',
  },
  noAccomodationInfoMobile: {
    marginTop: theme.spacing(2),
    marginLeft: '10%',
    marginRight: '7%',
    marginBottom: theme.spacing(3),
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  accomodationInfoHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  accomodationCheckIn: {
    marginTop: theme.spacing(2),
    marginLeft: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  accomodationPartnerName: {
    marginLeft: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },

  accomodationRoompartner: {
    marginTop: theme.spacing(4),
    marginLeft: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '31px',
    color: '#20D2B7',
  },
  openRoompartnerDialog: {
    '&:hover': {
      background: '#AD00FF',
    },
    marginLeft: '7%',
    width: '60%',
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    margin: '8px 0px',
  },
  accomodationInfoHeaderMobile: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: '10%',
    marginRight: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  accomodationCheckInMobile: {
    marginTop: theme.spacing(2),
    marginLeft: '10%',
    marginRight: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  accomodationPartnerNameMobile: {
    marginLeft: '10%',
    marginRight: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },

  accomodationRoompartnerMobile: {
    marginTop: theme.spacing(4),
    marginLeft: '10%',
    marginRight: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '31px',
    color: '#20D2B7',
  },
  openRoompartnerDialogMobile: {
    '&:hover': {
      background: '#AD00FF',
    },
    marginLeft: '10%',
    marginRight: '7%',
    width: '60%',
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    margin: '8px 0px',
  },
}));

export const UserAccomodation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { accomodation } = useSelector((state) =>
    getUserAccomodation(state.customer)
  );

  const mobileScreen = useMediaQuery('(max-width:990px)');

  const { info } = useSelector((state) => getInfoFromState(state.customer));
  const { group } = useSelector((state) => getUsersGroup(state.customer));
  const { groupUsers } = useSelector((state) => state.group);
  const { userCode } = useSelector(getUserCode);

  const [roomPartnerDialog, setRoomPartnerDialogVisible] = useState(false);

  useEffect(() => {
    if (group._id) {
      dispatch(fetchGroupUsers(group._id));
    }
  }, [dispatch, group]);

  const closeRoomPartnerDialog = () => {
    setRoomPartnerDialogVisible(false);
  };

  return (
    <Fragment>
      <div
        key={info._id}
        className={classes.accomodationContainer}
        id={'accomodation-section'}
      >
        <Typography
          className={
            mobileScreen
              ? classes.accomodationHeaderMobile
              : classes.accomodationHeader
          }
        >
          Yöpyminen
        </Typography>

        {accomodation ? (
          <Fragment>
            <Typography
              className={
                mobileScreen
                  ? classes.accomodationInfoHeaderMobile
                  : classes.accomodationInfoHeader
              }
            >
              Paikka
            </Typography>
            <Typography
              className={
                mobileScreen
                  ? classes.accomodationInfoMobile
                  : classes.accomodationInfo
              }
            >
              {accomodation.name}
            </Typography>
            <Typography
              className={
                mobileScreen
                  ? classes.accomodationInfoMobile
                  : classes.accomodationInfo
              }
            >
              {accomodation.address.street}
            </Typography>
            <Typography
              className={
                mobileScreen
                  ? classes.accomodationInfoMobile
                  : classes.accomodationInfo
              }
            >
              {accomodation.address.city +
                ', ' +
                countries.find(
                  (country) => country.code === accomodation.address.country
                )?.label ?? ''}
            </Typography>
            <Typography
              className={
                mobileScreen
                  ? classes.accomodationCheckInMobile
                  : classes.accomodationCheckIn
              }
            >
              Check-in päivämäärä
            </Typography>
            <Typography
              className={
                mobileScreen
                  ? classes.accomodationInfoMobile
                  : classes.accomodationInfo
              }
            >
              {moment(accomodation.date).format('DD.MM.YYYY')}
            </Typography>

            <Typography
              className={
                mobileScreen
                  ? classes.accomodationRoompartnerMobile
                  : classes.accomodationRoompartner
              }
            >
              Huonekaveri
            </Typography>
            {info?.room?.roomType === '1h' && (
              <Typography
                className={
                  mobileScreen
                    ? classes.accomodationInfoMobile
                    : classes.accomodationInfo
                }
              >
                1h huone
              </Typography>
            )}
            {info.roomPartnerChosen !== true &&
              accomodation.roomAllocationType === 'code' &&
              userCode && (
                <Typography
                  className={
                    mobileScreen
                      ? classes.accomodationInfoMobile
                      : classes.accomodationInfo
                  }
                >
                  Oma koodi: {userCode}
                </Typography>
              )}
            {info.roomPartnerChosen && info.room ? (
              <Fragment>
                {info.room.roomType === 'F' ? (
                  <Typography
                    className={
                      mobileScreen
                        ? classes.accomodationPartnerNameMobile
                        : classes.accomodationPartnerName
                    }
                  >
                    Valitsit naisparin huoneeseesi
                  </Typography>
                ) : info.room.roomType === 'M' ? (
                  <Typography
                    className={
                      mobileScreen
                        ? classes.accomodationPartnerNameMobile
                        : classes.accomodationPartnerName
                    }
                  >
                    Valitsit miesparin huoneeseesi
                  </Typography>
                ) : (
                  <Fragment>
                    {info.room.users &&
                      info.room.users.map((partner) => {
                        return (
                          <Typography
                            key={partner._id}
                            className={
                              mobileScreen
                                ? classes.accomodationPartnerNameMobile
                                : classes.accomodationPartnerName
                            }
                          >
                            {partner.firstName + ' ' + partner.lastName}
                          </Typography>
                        );
                      })}
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {accomodation.roompartnerSelectionLive && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setRoomPartnerDialogVisible(true);
                    }}
                    className={
                      mobileScreen
                        ? classes.openRoompartnerDialogMobile
                        : classes.openRoompartnerDialog
                    }
                  >
                    Valitse huonekaveri
                  </Button>
                )}
              </Fragment>
            )}
          </Fragment>
        ) : (
          <Typography className={classes.noAccomodationInfo}>
            Tiedot hotellistasi tulevat näkymään tässä lähempänä matkaa
          </Typography>
        )}
      </div>
      <RoomPartnerDialog
        visible={roomPartnerDialog}
        closeDialog={closeRoomPartnerDialog}
        accomodation={accomodation}
        users={groupUsers && groupUsers.filter((obj) => obj._id !== info._id)}
        mobile={mobileScreen}
      />
    </Fragment>
  );
};

export default UserAccomodation;
