import API from 'services/api';

export const FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_REQUESTED =
  'FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_REQUESTED';
export const SAVE_ORGANIZATION_FILTERS = 'SAVE_ORGANIZATION_FILTERS';
export const FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_RESPONSE =
  'FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_RESPONSE';
export const FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_ERROR =
  'FETCH_ORGANIZATION_CUSTOMERS_ERROR';
export const SAVE_ORGANIZATION_ID_TO_STATE = 'SAVE_ORGANIZATION_ID_TO_STATE';
export const SAVE_USER_COUNT_TO_STATE = 'SAVE_USER_COUNT_TO_STATE';
export const SAVE_PAGENUM_TO_STATE = 'SAVE_PAGENUM_TO_STATE';
export const RESET_ORGANIZATION_PAGINATED_CUSTOMERS =
  'RESET_ORGANIZATION_PAGINATED_CUSTOMERS';
export const UPDATE_DISABLED_USERS = 'UPDATE_DISABLED_USERS';
export const UPDATE_ENABLED_USERS = 'UPDATE_ENABLED_USERS';
export const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE';
export const SAVE_ACTIVE_FILTERS = 'SAVE_ACTIVE_FILTERS';
export const SAVE_SEARCHBAR_VALUE = 'SAVE_SEARCHBAR_VALUE';
export const RESET_PAGINATION_DATA = 'RESET_PAGINATION_DATA';

export const fetchOrganizationsCustomersPagination =
  (json) => async (dispatch) => {
    dispatch({ type: FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_REQUESTED });
    try {
      const res = await API.get(
        `/api/admin/users/pagination/${encodeURIComponent(
          JSON.stringify(json)
        )}`
      );
      let users = res.data.users.length ? res.data.users : [''];

      dispatch({
        type: SAVE_USER_COUNT_TO_STATE,
        payload: res.data.userCount,
      });
      dispatch({
        type: FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_RESPONSE,
        payload: users,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_ERROR,
        payload: {
          msg: error.response.statusText,
          status: error.response.status,
        },
      });
    }
  };

export const fetchOrganizationsFilters = (orgId) => async (dispatch) => {
  try {
    const orgFilter = await API.get(`/api/admin/filters/${orgId}`);
    dispatch({
      type: SAVE_ORGANIZATION_FILTERS,
      payload: orgFilter.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const saveSelectedOrgId = (orgId) => (dispatch) => {
  if (orgId) {
    dispatch({
      type: SAVE_ORGANIZATION_ID_TO_STATE,
      payload: orgId,
    });
  }
};
export const savePageToState = (page) => (dispatch) => {
  dispatch({
    type: SAVE_PAGENUM_TO_STATE,
    payload: page,
  });
};
export const saveActiveFilters = (filters) => (dispatch) => {
  dispatch({
    type: SAVE_ACTIVE_FILTERS,
    payload: filters,
  });
};

export const saveSearchBarValue = (searchValue) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCHBAR_VALUE,
    payload: searchValue,
  });
};
export const resetOrganizationPaginatedCustomers = () => (dispatch) =>
  dispatch({ type: RESET_ORGANIZATION_PAGINATED_CUSTOMERS });

export const resetPaginationData = () => (dispatch) =>
  dispatch({ type: RESET_PAGINATION_DATA });

export const setRowsPerPage = (rowsPerPage) => (dispatch) =>
  dispatch({ type: SET_ROWS_PER_PAGE, payload: rowsPerPage });
