import API from 'services/api';
import { setAlert } from 'redux/actions/alert';
import { UPDATE_DISABLED_USERS, UPDATE_ENABLED_USERS } from './paginatedUsers';
import { fetchGroups } from './group';
import {
  fetchOrganizationsCustomersPagination,
  savePageToState,
} from 'redux/actions/paginatedUsers';
import { combineReqData } from '../../components/views/user/CombineReqData';

export const OPEN_MASS_EDIT_DIALOG = 'OPEN_MASS_EDIT_DIALOG';
export const CLOSE_MASS_EDIT_DIALOG = 'CLOSE_MASS_EDIT_DIALOG';
export const MASS_DISABLE_REQUEST = 'MASS_DISABLE_REQUEST';
export const MASS_DISABLE_SUCCESS = 'MASS_DISABLE_SUCCESS';
export const MASS_DISABLE_FAILED = 'MASS_DISABLE_FAILED';

export const MASS_ENABLE_REQUEST = 'MASS_ENABLE_REQUEST';
export const MASS_ENABLE_SUCCESS = 'MASS_ENABLE_SUCCESS';
export const MASS_ENABLE_FAILED = 'MASS_ENABLE_FAILED';

export const GROUP_CHANGE_REQUEST = 'GROUP_CHANGE_REQUEST';
export const GROUP_CHANGE_SUCCESS = 'GROUP_CHANGE_SUCCESS';
export const GROUP_CHANGE_FAILED = 'GROUP_CHANGE_FAILED';
export const UPDATE_GROUP_CHANGED_USERS = 'UPDATE_GROUP_CHANGED_USERS';

export const MASS_REFUND_REQUEST = 'MASS_REFUND_REQUEST';
export const MASS_REFUND_SUCCESS = 'MASS_REFUND_SUCCESS';
export const MASS_REFUND_FAILED = 'MASS_REFUND_FAILED';

export const openMassEditDialog = (payload) => async (dispatch) => {
  dispatch({ type: OPEN_MASS_EDIT_DIALOG, payload });
};

export const closeMassEditDialog = () => async (dispatch) => {
  dispatch({ type: CLOSE_MASS_EDIT_DIALOG });
};

export const disableUsers = (payload) => async (dispatch) => {
  dispatch({ type: MASS_DISABLE_REQUEST });
  try {
    const res = await API.post(`/api/admin/mass/disable`, { users: payload });
    dispatch({
      type: MASS_DISABLE_SUCCESS,
    });
    dispatch({
      type: UPDATE_DISABLED_USERS,
      payload: res.data,
    });
    dispatch(setAlert('Users disabled successfully', 'success'));
  } catch (error) {
    console.log(error);
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to disable users', 'error'));
    }
    dispatch({
      type: MASS_DISABLE_FAILED,
    });
  }
};

export const enableUsers = (payload) => async (dispatch) => {
  dispatch({ type: MASS_DISABLE_REQUEST });
  try {
    const res = await API.post(`/api/admin/mass/enable`, { users: payload });
    dispatch({
      type: MASS_ENABLE_SUCCESS,
    });
    dispatch({
      type: UPDATE_ENABLED_USERS,
      payload: res.data,
    });
    dispatch(setAlert('Users enabled successfully', 'success'));
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to enable users', 'error'));
    }
    dispatch({
      type: MASS_ENABLE_FAILED,
    });
  }
};

export const changeGroups = (group, users) => async (dispatch, getState) => {
  dispatch({ type: GROUP_CHANGE_REQUEST });
  try {
    const res = await API.post(`/api/admin/mass/changeGroup`, { users, group });
    const { organization } = res.data.group;
    const statePaginatedUsers = getState().paginatedUsers;
    dispatch({
      type: GROUP_CHANGE_SUCCESS,
    });
    dispatch(fetchGroups());
    dispatch(setAlert('Users groups changed successfully', 'success'));
    dispatch(savePageToState(0));
    dispatch(
      fetchOrganizationsCustomersPagination(
        combineReqData(
          false,
          false,
          statePaginatedUsers.rowsPerPage,
          organization,
          null,
          statePaginatedUsers.activeFilters,
          statePaginatedUsers.searchBarValue
        )
      )
    );
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    } else {
      dispatch(setAlert('Failed to change users groups', 'error'));
    }
    dispatch({
      type: GROUP_CHANGE_FAILED,
    });
  }
};

export const refundPayments = (amount, payments) => async (dispatch) => {
  dispatch({ type: MASS_REFUND_REQUEST });
  try {
    const res = await API.post(`/api/admin/mass/refund`, { payments, amount });
    if (res.data.errors && res.data.errors.length > 0) {
      dispatch(
        setAlert(
          `Failed to refund ${res.data.errors.length} payments. Download CSV for more information`,
          'error'
        )
      );
    }
    if (res.data.success && res.data.success.length > 0) {
      dispatch(
        setAlert(
          `Refunded to ${res.data.success.length} users. Download CSV for more information`,
          'success'
        )
      );
    }
    dispatch({ type: MASS_REFUND_SUCCESS, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    } else {
      dispatch(setAlert('Failed to refund payments', 'error'));
    }
    dispatch({
      type: MASS_REFUND_FAILED,
    });
  }
};
