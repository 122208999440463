import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlertMessage from 'components/layouts/AlertMessage';
import DeleteConfirm from 'components/layouts/DeleteConfirm';

import {
  TextField,
  TextareaAutosize,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {
  getEmails,
  saveEmail,
  updateEmail,
  updateEmailName,
  updateEmailHtml,
  updateEmailSubject,
  updateEmailOrganization,
  updateEmailIdentifier,
  deleteEmail,
  selectExistingEmail,
  selectNewEmail,
} from 'redux/actions/email';
import { getOrganizations } from 'redux/actions/organization';

import { getEmailsAndEmailFromState } from 'redux/selectors/email';
import { getOrganizationsFromState } from 'redux/selectors/organization';
import { getRoleAndOrganization } from 'redux/selectors/auth';

import { IDENTIFIERS } from 'constants/emailIdentifiers';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlTextarea: {
    margin: theme.spacing(1),
    minWidth: '75%',
  },
  alertBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textarea: {
    minWidth: 1,
    minHeight: 1,
  },
}));

const OrganizationsOptions = (organizations) => {
  return organizations.map((organization) => (
    <MenuItem key={organization._id} value={organization._id}>
      {organization.name}
    </MenuItem>
  ));
};

const objectDoesNotContainNull = (obj) => {
  const notMandatory = [];

  const keys = Object.keys(obj);
  const values = keys
    .filter((key) => !notMandatory.includes(key))
    .map((key) => obj[key]);
  return !values.includes(null);
};

const getMenuItems = (emails) =>
  emails.map((email) => (
    <MenuItem key={email._id} value={email._id}>
      {email.name}
    </MenuItem>
  ));

export const Email = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const { role, organization } = useSelector((state) =>
    getRoleAndOrganization(state.auth)
  );

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  useEffect(() => {
    dispatch(getEmails());
  }, [dispatch]);

  const { emails, email } = useSelector((state) =>
    getEmailsAndEmailFromState(state.email)
  );
  const { organizations } = useSelector((state) =>
    getOrganizationsFromState(state.organization)
  );

  useEffect(() => {
    if (organization) {
      dispatch(updateEmailOrganization(organization._id));
    }
  }, [organization, dispatch]);

  const getEmailToObject = (id) => {
    let email = emails.find((email) => email._id === id);
    return email;
  };

  const handleAgree = () => {
    dispatch(deleteEmail(email));
    setConfirmDelete(false);
  };
  const closeDialog = () => setConfirmDelete(false);

  return (
    <div>
      <h2> Email </h2>
      {emails && organizations && (
        <FormControl
          className={classes.formControl}
          key={`amountOfSelections_${emails.length}`}
        >
          <InputLabel id="emailSelectionLabel">Select</InputLabel>
          <Select
            labelId="emailSelectionLabel"
            id="emailSelection"
            defaultValue=""
            onChange={({ target: { value } }) =>
              value !== 'new'
                ? dispatch(selectExistingEmail(getEmailToObject(value)))
                : // If owner role the organization will be defined.
                  dispatch(selectNewEmail(organization))
            }
          >
            <MenuItem key="new" value={'new'}>
              New
            </MenuItem>
            {getMenuItems(emails)}
          </Select>
        </FormControl>
      )}

      <form
        key={email._id}
        className={classes.root}
        noValidate
        autoComplete="off"
      >
        <div className={classes.alertBox}>
          <AlertMessage />
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <TextField
              id="name"
              defaultValue={email.name}
              label="Email name"
              required
              onChange={(event) =>
                dispatch(updateEmailName(event.target.value))
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            {role === 'owner' ? (
              <TextField
                label="Admin organization"
                id="organization"
                disabled
                value={organization._id}
                select
                InputProps={{
                  readOnly: true,
                }}
              >
                <MenuItem key={organization._id} value={organization._id}>
                  {organization.name}
                </MenuItem>
              </TextField>
            ) : role === 'root' ? (
              <TextField
                label="Email organization"
                id="organization"
                select
                required
                value={email.organization ? email.organization : ''}
                onChange={(event) => {
                  dispatch(updateEmailOrganization(event.target.value));
                }}
              >
                {OrganizationsOptions(organizations)}
              </TextField>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label="Email identifier"
              id="identifier"
              select
              required
              value={email.identifier ? email.identifier : ''}
              onChange={(event) => {
                dispatch(updateEmailIdentifier(event.target.value));
              }}
            >
              {IDENTIFIERS.map((identifier) => (
                <MenuItem key={identifier} value={identifier}>
                  {identifier}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <TextField
              id="subject"
              defaultValue={email.emailSubject}
              label="Email subject"
              required
              onChange={(event) =>
                dispatch(updateEmailSubject(event.target.value))
              }
            />
          </FormControl>
        </div>
        <div className={classes.formControlTextarea}>
          <FormControl className={classes.formControlTextarea}>
            <TextareaAutosize
              minRows={10}
              maxRows={20}
              className={classes.textarea}
              id="html"
              defaultValue={email.emailHtml ? email.emailHtml : 'Email content'}
              label="Email html"
              required
              onChange={(event) =>
                dispatch(updateEmailHtml(event.target.value))
              }
            ></TextareaAutosize>
          </FormControl>
        </div>
      </form>
      <div className={classes.buttonContainer}>
        {objectDoesNotContainNull(email) && (
          <Button
            className={classes.button}
            aria-label="save"
            color="primary"
            variant="contained"
            onClick={() =>
              dispatch(email._id ? updateEmail(email) : saveEmail(email))
            }
          >
            Save
          </Button>
        )}
        {email._id && (
          <Button
            className={classes.button}
            aria-label="delete"
            color="secondary"
            variant="contained"
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
        )}
      </div>
      <DeleteConfirm
        visible={confirmDelete}
        handleAgree={handleAgree}
        closeDialog={closeDialog}
      ></DeleteConfirm>
    </div>
  );
};

export default Email;
