export const getDiscountCodeFromState = (state) => {
  const { discountCode } = state;
  return { discountCode };
};

export const getOrganizationDiscountCodes = (state) => {
  const { filterDiscountCodesOrg, discountCodes } = state;
  if (filterDiscountCodesOrg !== 'all') {
    let filteredDiscountCodes = discountCodes.filter(
      (discountCode) => discountCode.organization === filterDiscountCodesOrg
    );
    return { discountCodes: filteredDiscountCodes };
  } else {
    return { discountCodes };
  }
};
