import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FerryTicketDialog from 'components/views/customer/ferrys/FerryTicketDialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { getUserFerrys, getUsersQRTickets } from 'redux/selectors/customer';
const useStyles = makeStyles((theme) => ({
  ferrysContainer: {
    width: '100%',
    background: '#FFFFFF',
    /* Drop */
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    zIndex: 1000,
  },
  ferrysHeader: {
    marginTop: '3%',
    marginLeft: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '46px',
    color: '#20D2B7',
  },
  ferrysInfo: {
    marginTop: theme.spacing(2),
    marginLeft: '7%',
    marginRight: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  noFerrysInfo: {
    marginTop: theme.spacing(2),
    marginLeft: '7%',
    marginRight: '7%',
    marginBottom: theme.spacing(3),
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  ferrysHeaderMobile: {
    marginTop: '10%',
    marginLeft: '10%',
    marginRigth: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '102%',
    marginBottom: theme.spacing(2),
    color: '#20D2B7',
  },
  ferrysInfoMobile: {
    marginLeft: '10%',
    marginRight: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '102%',
    color: '#828282',
  },
  noFerrysInfoMobile: {
    marginTop: theme.spacing(2),
    marginLeft: '10%',
    marginRight: '7%',
    marginBottom: theme.spacing(3),
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  ferryHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    marginLeft: '17%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '31px',
    color: '#20D2B7',
  },
  ferryDescription: {
    marginBottom: theme.spacing(1),
    marginLeft: '17%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  ferryInfoCityInContainer: {
    marginLeft: '1.75%',
    paddingLeft: '12.5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
    borderLeft: '3px solid #20D2B7',
  },
  ferryInfoCity: {
    marginLeft: '19.5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  ferryInfo: {
    marginLeft: '19.5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },
  ferryDate: {
    marginLeft: '19.5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '31px',
    color: '#20D2B7',
  },
  ferryInfoInContainer: {
    marginLeft: '1.75%',
    paddingLeft: '12.5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
    borderLeft: '3px solid #20D2B7',
  },
  ferryDateInContainer: {
    marginLeft: '1.75%',
    paddingLeft: '12.5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '31px',
    color: '#20D2B7',
    borderLeft: '3px solid #20D2B7',
  },
  ferryTime: {
    marginLeft: theme.spacing(1),
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '31px',
    color: '#828282',
  },
  ferryInfoVisual: {
    marginLeft: '5%',
    paddingBottom: '4%',
  },
  openTicketButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    marginLeft: '17%',
    width: '60%',
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    margin: '8px 0px',
  },
  startCircle: {
    position: 'absolute',
    display: 'inline-block',
    paddingRigt: '18px',
    width: '15px',
    height: '15px',
    borderRadius: '7.5px',
    backgroundColor: '#20D2B7',
  },
}));
export const UserFerry = () => {
  const classes = useStyles();
  const mobileScreen = useMediaQuery('(max-width:990px)');
  const { outboundFerry, returnFerry } = useSelector((state) =>
    getUserFerrys(state.customer)
  );
  const { outboundTicket, returnTicket } = useSelector((state) =>
    getUsersQRTickets(state.customer)
  );
  const [outboundFerryTicket, setOutboundFerryTicket] = useState(false);
  const closeOutboundFerryTicket = () => {
    setOutboundFerryTicket(false);
  };
  const [returnFerryTicket, setReturnFerryTicket] = useState(false);
  const closeReturnFerryTicket = () => {
    setReturnFerryTicket(false);
  };
  return (
    <div className={classes.ferrysContainer} id={'ferry-section'}>
      <Typography
        className={
          mobileScreen ? classes.ferrysHeaderMobile : classes.ferrysHeader
        }
      >
        Laivamatka
      </Typography>
      {outboundFerry ? (
        <Fragment key={outboundFerry._id}>
          <Typography className={classes.ferryHeader}>Meno</Typography>
          {outboundFerry.info && (
            <Typography className={classes.ferryDescription}>
              {outboundFerry.info}
            </Typography>
          )}
          <div className={classes.ferryInfoVisual}>
            <span className={classes.startCircle}></span>
            <Typography className={classes.ferryInfoCityInContainer}>
              {outboundFerry.pointOfDeparture.city}
            </Typography>
            <Typography className={classes.ferryInfoInContainer}>
              {outboundFerry.pointOfDeparture.terminal}
            </Typography>
            <Typography className={classes.ferryInfoInContainer}>
              {outboundFerry.name}
            </Typography>
            <Typography className={classes.ferryDateInContainer}>
              {moment(outboundFerry.pointOfDeparture.dateTime).format(
                'DD.MM.YYYY'
              )}{' '}
              <span className={classes.ferryTime}>
                {moment(outboundFerry.pointOfDeparture.dateTime).format(
                  'HH:mm'
                )}
              </span>
            </Typography>
            <span className={classes.startCircle}></span>
          </div>
          <Typography className={classes.ferryInfoCity}>
            {outboundFerry.destination.city}
          </Typography>
          <Typography className={classes.ferryInfo}>
            {outboundFerry.destination.terminal}
          </Typography>
          <Typography className={classes.ferryInfo}>
            {outboundFerry.name}
          </Typography>
          <Typography className={classes.ferryDate}>
            {moment(outboundFerry.destination.dateTime).format('DD.MM.YYYY')}{' '}
            <span className={classes.ferryTime}>
              {moment(outboundFerry.destination.dateTime).format('HH:mm')}
            </span>
          </Typography>
          {outboundTicket && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                setOutboundFerryTicket(true);
              }}
              className={classes.openTicketButton}
            >
              Näytä lippu
            </Button>
          )}
          {returnFerry ? (
            <Fragment key={returnFerry._id}>
              <Typography className={classes.ferryHeader}>Paluu</Typography>

              {returnFerry.info && (
                <Typography className={classes.ferryDescription}>
                  {returnFerry.info}
                </Typography>
              )}

              <div className={classes.ferryInfoVisual}>
                <span className={classes.startCircle}></span>
                <Typography className={classes.ferryInfoCityInContainer}>
                  {returnFerry.pointOfDeparture.city}
                </Typography>
                <Typography className={classes.ferryInfoInContainer}>
                  {returnFerry.pointOfDeparture.terminal}
                </Typography>
                <Typography className={classes.ferryInfoInContainer}>
                  {returnFerry.name}
                </Typography>
                <Typography className={classes.ferryDateInContainer}>
                  {moment(returnFerry.pointOfDeparture.dateTime).format(
                    'DD.MM.YYYY'
                  )}{' '}
                  <span className={classes.ferryTime}>
                    {moment(returnFerry.pointOfDeparture.dateTime).format(
                      'HH:mm'
                    )}
                  </span>
                </Typography>
                <span className={classes.startCircle}></span>
              </div>
              <Typography className={classes.ferryInfoCity}>
                {returnFerry.destination.city}
              </Typography>
              <Typography className={classes.ferryInfo}>
                {returnFerry.destination.terminal}
              </Typography>
              <Typography className={classes.ferryInfo}>
                {returnFerry.name}
              </Typography>
              <Typography className={classes.ferryDate}>
                {moment(returnFerry.destination.dateTime).format('DD.MM.YYYY')}{' '}
                <span className={classes.ferryTime}>
                  {moment(returnFerry.destination.dateTime).format('HH:mm')}
                </span>
              </Typography>
              {returnTicket && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setReturnFerryTicket(true);
                  }}
                  className={classes.openTicketButton}
                >
                  Näytä lippu
                </Button>
              )}
            </Fragment>
          ) : (
            <Typography className={classes.noFerrysInfo}>
              Tähän tulee tietoa matkasi paluu laivasta kunhan se on päätetty
              ryhmäsi osalta
            </Typography>
          )}
        </Fragment>
      ) : (
        <Typography className={classes.noFerrysInfo}>
          Laivamatkojesi tiedot tulevat näkymään tässä lähempänä matkaa.
        </Typography>
      )}
      <FerryTicketDialog
        visible={outboundFerryTicket}
        closeDialog={closeOutboundFerryTicket}
        ticketQRCode={outboundTicket}
      />
      <FerryTicketDialog
        visible={returnFerryTicket}
        closeDialog={closeReturnFerryTicket}
        ticketQRCode={returnTicket}
      />
    </div>
  );
};
export default UserFerry;
