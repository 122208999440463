import React from 'react';
import QRCode from 'qrcode.react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const FerryTicketDialog = ({ visible, closeDialog, ticketQRCode }) => {
  return (
    <Dialog
      open={visible}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{
          fontFamily: 'futura-pt, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '30px',
          lineHeight: '31px',
          color: '#20D2B7',
        }}
      >
        Laivalippu
      </DialogTitle>
      <DialogContent>
        <QRCode value={ticketQRCode} size={220} />
        <DialogContentText>{ticketQRCode}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Sulje
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FerryTicketDialog;
