import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
  updateRefundAmount,
  closeRefund,
  createRefund,
} from 'redux/actions/payment';

import { getRefundFromState } from 'redux/selectors/payment';
import AlertMessage from 'components/layouts/AlertMessage';

export const RefundDialog = () => {
  const dispatch = useDispatch();
  const { refund } = useSelector((state) => getRefundFromState(state.payment));
  const [refundFromOtherChannel, setRefundFromOtherChannel] = useState(false);

  return (
    <Fragment>
      {refund && refund.payment && (
        <Dialog
          open={refund.showRefund}
          onClose={() => dispatch(closeRefund())}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Refund payment {refund.payment.products[0].name}
          </DialogTitle>
          <AlertMessage />
          <DialogContent>
            <DialogContentText>
              <div>Give amount which to refund from the payment. </div>
              <div>
                Maximum amount to refund:{' '}
                {refund.payment.products
                  .map((product) => {
                    let serviceFee = 0;
                    if (product.serviceFee) {
                      serviceFee = product.serviceFee;
                    }
                    return product.price + serviceFee;
                  })
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                  )}
              </div>
              <div style={{ color: 'red' }}>
                {refund.payment.refundStatus &&
                  'Payment has already been refunded for ' +
                    refund.payment.refundAmount +
                    '€'}
              </div>
            </DialogContentText>
            <FormControlLabel
              control={
                <Checkbox
                  value={refundFromOtherChannel}
                  onChange={(e) => setRefundFromOtherChannel(e.target.checked)}
                />
              }
              label="Refund from other channels"
            />
            <TextField
              margin="normal"
              required
              name="amount"
              label="Refund amount"
              type="number"
              id="amount"
              fullWidth
              value={refund.amount ? refund.amount : ''}
              onChange={(e) => {
                const maxAmount = refund.payment.products
                  .map((product) => {
                    let serviceFee = 0;
                    if (product.serviceFee) {
                      serviceFee = product.serviceFee;
                    }
                    return product.price + serviceFee;
                  })
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                  );
                if (e.target.value > maxAmount) {
                  alert(
                    'Refunded amount can not be higher than maximum refundable amount'
                  );
                } else {
                  dispatch(updateRefundAmount(e.target.value));
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                dispatch(
                  createRefund(
                    refund.payment._id,
                    refund.amount,
                    refundFromOtherChannel
                  )
                )
              }
              color="primary"
            >
              Create refund
            </Button>
            <Button onClick={() => dispatch(closeRefund())} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default RefundDialog;
