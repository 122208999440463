import {
  FETCH_EMAILS_REQUESTED,
  FETCH_EMAILS_RESPONSE,
  FETCH_EMAILS_ERROR,
  UPDATE_EMAIL_REQUESTED,
  UPDATE_EMAIL_RESPONSE,
  UPDATE_EMAIL_ERROR,
  SAVE_EMAIL_REQUESTED,
  SAVE_EMAIL_RESPONSE,
  SAVE_EMAIL_ERROR,
  DELETE_EMAIL_ERROR,
  DELETE_EMAIL_REQUESTED,
  DELETE_EMAIL_RESPONSE,
  UPDATE_EMAIL_NAME,
  UPDATE_EMAIL_HTML,
  UPDATE_EMAIL_SUBJECT,
  UPDATE_EMAIL_ORGANIZATION,
  UPDATE_EMAIL_IDENTIFIER,
  SELECT_NEW_EMAIL,
  SELECT_EXISTING_EMAIL,
  SET_EMAIL_FILTER_ORG,
} from 'redux/actions/email';

const initialState = {
  emails: [],
  email: {
    name: null,
    identifier: null,
    emailSubject: null,
    emailHtml: null,
    organization: null,
    _id: '',
  },
  filterEmailsOrg: 'all',
  error: {},
};

const updateEmailsArray = (emails, updated) => {
  const filtered = emails.filter((email) => email._id !== updated._id);
  filtered.push(updated);
  return filtered;
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_EMAIL_FILTER_ORG:
      return {
        ...state,
        filterEmailsOrg: action.payload,
      };

    case SELECT_EXISTING_EMAIL:
      return {
        ...state,
        email: action.payload ? action.payload : null,
      };

    case SELECT_NEW_EMAIL:
      return {
        ...state,
        email: initialState.email,
      };

    case SAVE_EMAIL_RESPONSE:
      return {
        ...state,
        emails: updateEmailsArray(state.emails, action.payload),
      };

    case UPDATE_EMAIL_RESPONSE:
      return {
        ...state,
        emails: updateEmailsArray(state.emails, action.payload),
      };

    case DELETE_EMAIL_RESPONSE:
      return {
        ...state,
        emails: state.emails.filter(
          (email) => email._id !== action.payload._id
        ),
        email: initialState.email,
      };

    case SAVE_EMAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EMAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_EMAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_EMAILS_RESPONSE:
      return { ...state, emails: payload };

    case FETCH_EMAILS_ERROR:
      return { ...state, error: payload };

    case UPDATE_EMAIL_NAME:
      return {
        ...state,
        email: { ...state.email, name: action.payload },
      };

    case UPDATE_EMAIL_HTML:
      return {
        ...state,
        email: { ...state.email, emailHtml: action.payload },
      };

    case UPDATE_EMAIL_IDENTIFIER:
      return {
        ...state,
        email: { ...state.email, identifier: action.payload },
      };

    case UPDATE_EMAIL_SUBJECT:
      return {
        ...state,
        email: { ...state.email, emailSubject: action.payload },
      };

    case UPDATE_EMAIL_ORGANIZATION:
      return {
        ...state,
        email: { ...state.email, organization: action.payload },
      };

    case DELETE_EMAIL_REQUESTED:
    case UPDATE_EMAIL_REQUESTED:
    case SAVE_EMAIL_REQUESTED:
    case FETCH_EMAILS_REQUESTED:
    default:
      return state;
  }
}
