import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import AlertMessage from 'components/layouts/AlertMessage';
import DeleteConfirm from 'components/layouts/DeleteConfirm';
import CountrySelector from 'components/layouts/CountrySelector';

import { KeyboardDatePicker } from '@material-ui/pickers';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

import { createProductOptions } from 'utils/product';

import {
  updateAccomodationName,
  updateAccomodationOrganization,
  updateAccomodationAddressCity,
  updateAccomodationAddressCountry,
  updateAccomodationAddressStreet,
  updateAccomodationDate,
  updateAccomodationDescription,
  updateAccomodationOnePersonRoomCapacity,
  updateAccomodationThreePersonRoomCapacity,
  updateAccomodationTwoPersonRoomCapacity,
  updateAccomodationOnePersonRoomReserved,
  updateAccomodationThreePersonRoomReserved,
  updateAccomodationTwoPersonRoomReserved,
  toggleRoomPartnerSelectionLive,
  saveAccomodation,
  updateAccomodation,
  deleteAccomodation,
  updateAccomodationRoomAllocationType,
  updateAccomodationProducts,
  toggleCollectPaymentForAccomodationProduct,
} from 'redux/actions/accomodation';
import { getOrganizations } from 'redux/actions/organization';

import { fetchOrgProducts } from 'redux/actions/product';

import { getAccomodationFromState } from 'redux/selectors/accomodation';
import { getRoleAndOrganization } from 'redux/selectors/auth';

import { getOrgProducts } from 'redux/selectors/product';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  doubleRootWidth: {
    width: '50ch!important',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  formControlDescription: {
    margin: theme.spacing(1),
    width: 360,
  },

  alertBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    minHeight: 140,
    minWidth: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  control: {
    padding: theme.spacing(2),
  },
  productTitle: {
    fontSize: 14,
  },
  productInfo: {
    fontSize: 12,
  },
  popupIndicator: {
    paddingBottom: '20px',
  },
  clearIndicator: {
    paddingBottom: '20px',
  },
  tableContainer: {
    margin: theme.spacing(1),
    width: '50ch',
  },
  tableDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionField: {
    width: '100%!important',
  },
}));

const organizationOptions = (organizations) =>
  organizations.map((org) => (
    <MenuItem key={org._id} value={org._id}>
      {org.name}
    </MenuItem>
  ));

const objectDoesNotContainNull = (obj) => {
  const notMandatory = ['description'];
  const keys = Object.keys(obj);
  const values = keys
    .filter((key) => !notMandatory.includes(key))
    .map((key) => obj[key]);
  return !values.includes(null);
};

const AccomodationForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { accomodation } = useSelector((state) =>
    getAccomodationFromState(state.accomodation)
  );

  const { role, organization } = useSelector((state) =>
    getRoleAndOrganization(state.auth)
  );

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  useEffect(() => {
    if (accomodation.organization) {
      dispatch(fetchOrgProducts(accomodation.organization));
    }
  }, [accomodation.organization, dispatch]);

  const { organizations } = useSelector((state) => state.organization);

  const handleAgree = () => {
    dispatch(deleteAccomodation(accomodation));
    setConfirmDelete(false);
  };
  const closeDialog = () => setConfirmDelete(false);

  const { organizationProducts } = useSelector((state) =>
    getOrgProducts(state.product)
  );

  return (
    <div>
      <h2>
        {accomodation._id ? 'Edit accomodation' : 'Add new accomodation'}{' '}
      </h2>

      {accomodation && (
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          key={accomodation._id}
        >
          <div className={classes.alertBox}>
            <AlertMessage />
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                label="Accomodation name"
                defaultValue={accomodation.name}
                required
                onChange={(e) =>
                  dispatch(updateAccomodationName(e.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="accomodation date"
                label="Accomodation date"
                value={accomodation.date}
                onChange={(event, val) => {
                  const formattedDateFromVal = moment(val, 'DD/MM/YYYY');
                  dispatch(
                    updateAccomodationDate(formattedDateFromVal.toDate())
                  );
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Switch
                    checked={accomodation.roompartnerSelectionLive}
                    onChange={() => {
                      dispatch(
                        toggleRoomPartnerSelectionLive(
                          !accomodation.roompartnerSelectionLive
                        )
                      );
                    }}
                    name="allow users to select room partners"
                    color="primary"
                  />
                }
                className={classes.productInfo}
                label={
                  <Typography
                    className={classes.productInfo}
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Allow users to select room partners
                  </Typography>
                }
                labelPlacement="top"
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControlDescription}>
              <TextField
                id="description"
                multiline
                rows={4}
                className={classes.descriptionField}
                label="Room partner selection info text"
                defaultValue={accomodation.description}
                onChange={(e) =>
                  dispatch(updateAccomodationDescription(e.target.value))
                }
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                label="Room allocation"
                id="room allocation"
                select
                value={accomodation.roomAllocationType}
                onChange={({ target: { value } }) => {
                  dispatch(updateAccomodationRoomAllocationType(value));
                }}
              >
                <MenuItem key="code" value={'code'}>
                  By code
                </MenuItem>
                <MenuItem key="name" value={'name'}>
                  By name
                </MenuItem>
              </TextField>
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="street"
                label="Street address"
                defaultValue={accomodation.address.street}
                required
                onChange={(e) =>
                  dispatch(updateAccomodationAddressStreet(e.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="city"
                label="City"
                defaultValue={accomodation.address.city}
                required
                onChange={(e) =>
                  dispatch(updateAccomodationAddressCity(e.target.value))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <CountrySelector
                setCountry={updateAccomodationAddressCountry}
                currentValue={accomodation.address.country}
                label={'Choose country'}
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="one person room capacity"
                label="One person rooms"
                defaultValue={accomodation?.onePersonRoom?.capacity}
                required
                type="number"
                onChange={(e) =>
                  dispatch(
                    updateAccomodationOnePersonRoomCapacity(
                      e.target.value < accomodation?.onePersonRoom?.reserved
                        ? accomodation.onePersonRoom.reserved
                        : e.target.value
                    )
                  )
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="two person room capacity"
                label="Two person rooms"
                defaultValue={accomodation?.twoPersonRoom?.capacity}
                required
                type="number"
                onChange={(e) =>
                  dispatch(
                    updateAccomodationTwoPersonRoomCapacity(
                      e.target.value < accomodation?.twoPersonRoom?.reserved
                        ? accomodation.twoPersonRoom.reserved
                        : e.target.value
                    )
                  )
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="three person room capacity"
                label="Three person rooms"
                defaultValue={accomodation?.threePersonRoom?.capacity}
                required
                type="number"
                onChange={(e) => {
                  dispatch(
                    updateAccomodationThreePersonRoomCapacity(
                      e.target.value < accomodation?.threePersonRoom?.reserved
                        ? accomodation.threePersonRoom.reserved
                        : e.target.value
                    )
                  );
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                id="one person room reserved"
                label="One person rooms reserved"
                defaultValue={accomodation?.onePersonRoom?.reserved}
                required
                type="number"
                onChange={(e) =>
                  dispatch(
                    updateAccomodationOnePersonRoomReserved(e.target.value)
                  )
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="two person room reserved"
                label="Two person rooms reserved"
                defaultValue={accomodation?.twoPersonRoom?.reserved}
                required
                type="number"
                onChange={(e) =>
                  dispatch(
                    updateAccomodationTwoPersonRoomReserved(e.target.value)
                  )
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="three person room reserved"
                label="Three person rooms reserved"
                defaultValue={accomodation?.threePersonRoom?.reserved}
                required
                type="number"
                onChange={(e) =>
                  dispatch(
                    updateAccomodationThreePersonRoomReserved(e.target.value)
                  )
                }
              />
            </FormControl>
          </div>
          <div className={classes.tableDiv}>
            {accomodation.groups.length > 0 ? (
              <Fragment>
                <FormControl className={classes.formControl}>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Groups</TableCell>
                          <TableCell align="right">Capacity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {accomodation.groups.map((group) => (
                          <TableRow key={group.name}>
                            <TableCell component="th" scope="row">
                              {group.name}
                            </TableCell>
                            <TableCell align="right">
                              {group.users ? group.users.length : 0}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell>TOTAL CAPACITY</TableCell>
                          <TableCell align="right">
                            {accomodation.groups
                              ? accomodation.groups
                                  .map((group) =>
                                    group.users ? group.users.length : 0
                                  )
                                  .reduce(
                                    (accumulator, currentValue) =>
                                      accumulator + currentValue
                                  )
                              : 0}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </FormControl>
              </Fragment>
            ) : (
              'No Groups yet selected for this accomodation'
            )}
          </div>
          <div>
            {role !== 'root' ? (
              <TextField
                label="Organization"
                id="organization"
                disabled
                value={organization._id}
                select
                InputProps={{
                  readOnly: true,
                }}
              >
                <MenuItem key={organization._id} value={organization._id}>
                  {organization.name}
                </MenuItem>
              </TextField>
            ) : role === 'root' ? (
              <TextField
                label="Organization"
                id="organization"
                select
                required
                value={
                  accomodation.organization ? accomodation.organization : ''
                }
                onChange={(event) => {
                  dispatch(updateAccomodationOrganization(event.target.value));
                }}
              >
                {organizationOptions(organizations)}
              </TextField>
            ) : (
              <></>
            )}
          </div>
          <div>
            <Fragment>
              <FormControl className={classes.formControl}>
                {accomodation.organization && organizationProducts ? (
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={createProductOptions(organizationProducts)}
                    getOptionLabel={(option) => option.product.name}
                    value={accomodation.products ? accomodation.products : []}
                    onChange={(event, value, reason) =>
                      dispatch(updateAccomodationProducts(value))
                    }
                    getOptionSelected={(option, value) =>
                      option.product.name === value.product.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.doubleRootWidth}
                        variant="standard"
                        label="Products"
                      />
                    )}
                  />
                ) : (
                  <Fragment>
                    <h1>Please add products for organization first</h1>
                  </Fragment>
                )}
              </FormControl>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                  {accomodation?.products?.map((productObj) => (
                    <Grid key={productObj.product._id} item>
                      <Paper elevation={3} className={classes.paper}>
                        <Typography
                          className={classes.productTitle}
                          color="textPrimary"
                          gutterBottom
                          variant="h2"
                        >
                          {productObj.product.name +
                            ' (' +
                            (productObj.product.mandatory
                              ? 'mandatory)'
                              : 'unmandatory)')}
                        </Typography>
                        <Typography
                          className={classes.productInfo}
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Price: {productObj.product.price} €
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={productObj.collectPayment}
                              onChange={() => {
                                dispatch(
                                  toggleCollectPaymentForAccomodationProduct({
                                    ...productObj,
                                    collectPayment: !productObj.collectPayment,
                                  })
                                );
                              }}
                              name="collect payment"
                              color="primary"
                            />
                          }
                          className={classes.productInfo}
                          label={
                            <Typography
                              className={classes.productInfo}
                              color="textPrimary"
                              gutterBottom
                              variant="h5"
                            >
                              Collect payment
                            </Typography>
                          }
                          labelPlacement="top"
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Fragment>
          </div>
        </form>
      )}
      <div className={classes.buttonContainer}>
        {objectDoesNotContainNull(accomodation) &&
          objectDoesNotContainNull(accomodation.address) && (
            <Button
              className={classes.button}
              aria-label="save"
              color="primary"
              variant="contained"
              onClick={() =>
                dispatch(
                  accomodation._id
                    ? updateAccomodation(accomodation)
                    : saveAccomodation(accomodation)
                )
              }
            >
              Save
            </Button>
          )}
        {accomodation._id && (
          <Button
            className={classes.button}
            aria-label="delete"
            color="secondary"
            variant="contained"
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
        )}
      </div>

      <DeleteConfirm
        visible={confirmDelete}
        handleAgree={handleAgree}
        closeDialog={closeDialog}
      ></DeleteConfirm>
    </div>
  );
};

export default AccomodationForm;
