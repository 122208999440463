import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

export const FETCH_ACCOMODATIONS_REQUESTED = 'FETCH_ACCOMODATIONS_REQUESTED';
export const FETCH_ACCOMODATIONS_RESPONSE = 'FETCH_ACCOMODATIONS_RESPONSE';
export const FETCH_ACCOMODATIONS_ERROR = 'FETCH_ACCOMODATIONS_ERROR';

export const UPDATE_ACCOMODATION_REQUESTED = 'UPDATE_ACCOMODATION_REQUESTED';
export const UPDATE_ACCOMODATION_RESPONSE = 'UPDATE_ACCOMODATION_RESPONSE';
export const UPDATE_ACCOMODATION_ERROR = 'UPDATE_ACCOMODATION_ERROR';

export const SAVE_ACCOMODATION_REQUESTED = 'SAVE_ACCOMODATION_REQUESTED';
export const SAVE_ACCOMODATION_RESPONSE = 'SAVE_ACCOMODATION_RESPONSE';
export const SAVE_ACCOMODATION_ERROR = 'SAVE_ACCOMODATION_ERROR';

export const DELETE_ACCOMODATION_ERROR = 'DELETE_ACCOMODATION_ERROR';
export const DELETE_ACCOMODATION_REQUESTED = 'DELETE_ACCOMODATION_REQUESTED';
export const DELETE_ACCOMODATION_RESPONSE = 'DELETE_ACCOMODATION_RESPONSE';

export const UPDATE_ACCOMODATION_NAME = 'UPDATE_FERRY_NAME';
export const UPDATE_ACCOMODATION_DATE = 'UPDATE_ACCOMODATION_DATE';
export const UPDATE_ACCOMODATION_DESCRIPTION =
  'UPDATE_ACCOMODATION_DESCRIPTION';
export const UPDATE_ACCOMODATION_ORGANIZATION =
  'UPDATE_ACCOMODATION_ORGANIZATION';
export const UPDATE_ACCOMODATION_ADDRESS_STREET =
  'UPDATE_ACCOMODATION_ADDRESS_STREET';
export const UPDATE_ACCOMODATION_ADDRESS_CITY =
  'UPDATE_ACCOMODATION_ADDRESS_CITY';
export const UPDATE_ACCOMODATION_ADDRESS_COUNTRY =
  'UPDATE_ACCOMODATION_ADDRESS_COUNTRY';
export const UPDATE_ACCOMODATION_ONE_PERSON_ROOM_CAPACITY =
  'UPDATE_ACCOMODATION_ONE_PERSON_ROOM_CAPACITY';
export const UPDATE_ACCOMODATION_TWO_PERSON_ROOM_CAPACITY =
  'UPDATE_ACCOMODATION_TWO_PERSON_ROOM_CAPACITY';
export const UPDATE_ACCOMODATION_ONE_PERSON_ROOM_RESERVED =
  'UPDATE_ACCOMODATION_ONE_PERSON_ROOM_RESERVED';
export const UPDATE_ACCOMODATION_TWO_PERSON_ROOM_RESERVED =
  'UPDATE_ACCOMODATION_TWO_PERSON_ROOM_RESERVED';
export const UPDATE_ACCOMODATION_THREE_PERSON_ROOM_CAPACITY =
  'UPDATE_ACCOMODATION_THREE_PERSON_ROOM_CAPACITY';
export const UPDATE_ACCOMODATION_THREE_PERSON_ROOM_RESERVED =
  'UPDATE_ACCOMODATION_THREE_PERSON_ROOM_RESERVED';
export const UPDATE_ACCOMODATION_ROOMPARTNER_SELECTION_LIVE =
  'UPDATE_ACCOMODATION_ROOMPARTNER_SELECTION_LIVE';
export const UPDATE_ACCOMODATION_ROOM_ALLOCATION_TYPE =
  'UPDATE_ACCOMODATION_ROOM_ALLOCATION_TYPE';

export const UPDATE_ACCOMODATION_PRODUCTS = 'UPDATE_ACCOMODATION_PRODUCTS';
export const UPDATE_ACCOMODATION_PRODUCT_COLLECT_PAYMENT =
  'UPDATE_ACCOMODATION_PRODUCT_COLLECT_PAYMENT';

export const SELECT_EXISTING_ACCOMODATION = 'SELECT_EXISTING_ACCOMODATION';
export const SELECT_NEW_ACCOMODATION = 'SELECT_NEW_ACCOMODATION';
export const SET_ACCOMODATION_FILTER_ORG = 'SET_ACCOMODATION_FILTER_ORG';
export const SET_ACCOMODATION_FILTER_GROUP_FORM_ORG =
  'SET_FERRY_FILTER_GROUP_FORM_ORG';

export const fetchAccomodations = () => async (dispatch) => {
  dispatch({ type: FETCH_ACCOMODATIONS_REQUESTED });
  try {
    const res = await API.get('/api/admin/accomodations');
    dispatch({
      type: FETCH_ACCOMODATIONS_RESPONSE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_ACCOMODATIONS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const saveAccomodation = (accomodation) => async (dispatch) => {
  dispatch({ type: SAVE_ACCOMODATION_REQUESTED });

  try {
    const res = await API.post('/api/admin/accomodations', accomodation);

    dispatch(setAlert('Accomodation saved succesfully', 'success'));

    dispatch({ type: SAVE_ACCOMODATION_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: SAVE_ACCOMODATION_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateAccomodation = (accomodation) => async (dispatch) => {
  dispatch({ type: UPDATE_ACCOMODATION_REQUESTED });
  try {
    const res = await API.put(
      `/api/admin/accomodations/${accomodation._id}`,
      accomodation
    );
    dispatch(setAlert('Accomodation updated successfully', 'success'));
    dispatch({ type: UPDATE_ACCOMODATION_RESPONSE, payload: res.data });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to update accomodation', 'error'));
    }
    dispatch({
      type: UPDATE_ACCOMODATION_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteAccomodation = (accomodation) => async (dispatch) => {
  dispatch({ type: DELETE_ACCOMODATION_REQUESTED });
  try {
    const res = await API.delete(
      `/api/admin/accomodations/${accomodation._id}`
    );
    dispatch(setAlert('Accomodation successfully deleted', 'success'));
    dispatch({ type: DELETE_ACCOMODATION_RESPONSE, payload: res.data });
  } catch (error) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      dispatch(setAlert('Failed to delete accomodation', 'error'));
    }
    dispatch({
      type: DELETE_ACCOMODATION_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const selectOrgToFilter = (orgId) => async (dispatch) => {
  dispatch({
    type: SET_ACCOMODATION_FILTER_ORG,
    payload: orgId,
  });
};

export const selectOrgToFilterAccomodationsGroupForm =
  (orgId) => async (dispatch) => {
    dispatch({
      type: SET_ACCOMODATION_FILTER_GROUP_FORM_ORG,
      payload: orgId,
    });
  };

export const selectExistingAccomodation =
  (accomodation) => async (dispatch) => {
    dispatch({
      type: SELECT_EXISTING_ACCOMODATION,
      payload: accomodation,
    });
  };

export const selectNewAccomodation = () => async (dispatch) => {
  dispatch({
    type: SELECT_NEW_ACCOMODATION,
  });
};

export const updateAccomodationName = (payload) => ({
  type: UPDATE_ACCOMODATION_NAME,
  payload,
});

export const updateAccomodationDate = (payload) => ({
  type: UPDATE_ACCOMODATION_DATE,
  payload,
});

export const updateAccomodationDescription = (payload) => ({
  type: UPDATE_ACCOMODATION_DESCRIPTION,
  payload,
});

export const updateAccomodationOrganization = (payload) => ({
  type: UPDATE_ACCOMODATION_ORGANIZATION,
  payload,
});

export const updateAccomodationAddressStreet = (payload) => ({
  type: UPDATE_ACCOMODATION_ADDRESS_STREET,
  payload,
});

export const updateAccomodationAddressCity = (payload) => ({
  type: UPDATE_ACCOMODATION_ADDRESS_CITY,
  payload,
});

export const updateAccomodationAddressCountry = (payload) => ({
  type: UPDATE_ACCOMODATION_ADDRESS_COUNTRY,
  payload,
});

export const updateAccomodationOnePersonRoomCapacity = (payload) => ({
  type: UPDATE_ACCOMODATION_ONE_PERSON_ROOM_CAPACITY,
  payload,
});

export const updateAccomodationTwoPersonRoomCapacity = (payload) => ({
  type: UPDATE_ACCOMODATION_TWO_PERSON_ROOM_CAPACITY,
  payload,
});

export const updateAccomodationThreePersonRoomCapacity = (payload) => ({
  type: UPDATE_ACCOMODATION_THREE_PERSON_ROOM_CAPACITY,
  payload,
});

export const updateAccomodationOnePersonRoomReserved = (payload) => ({
  type: UPDATE_ACCOMODATION_ONE_PERSON_ROOM_RESERVED,
  payload,
});

export const updateAccomodationTwoPersonRoomReserved = (payload) => ({
  type: UPDATE_ACCOMODATION_TWO_PERSON_ROOM_RESERVED,
  payload,
});

export const updateAccomodationThreePersonRoomReserved = (payload) => ({
  type: UPDATE_ACCOMODATION_THREE_PERSON_ROOM_RESERVED,
  payload,
});
export const toggleRoomPartnerSelectionLive = (payload) => ({
  type: UPDATE_ACCOMODATION_ROOMPARTNER_SELECTION_LIVE,
  payload,
});

export const updateAccomodationRoomAllocationType = (payload) => ({
  type: UPDATE_ACCOMODATION_ROOM_ALLOCATION_TYPE,
  payload,
});

export const updateAccomodationProducts = (payload) => ({
  type: UPDATE_ACCOMODATION_PRODUCTS,
  payload,
});

export const toggleCollectPaymentForAccomodationProduct = (payload) => ({
  type: UPDATE_ACCOMODATION_PRODUCT_COLLECT_PAYMENT,
  payload,
});
