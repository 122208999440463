import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import RegisterFormDesktop from 'components/views/customer/register/RegisterFormDesktop';
import RegisterFormMobile from 'components/views/customer/register/RegisterFormMobile';

import { login } from 'redux/actions/auth';
import { toggleMarketingView } from 'redux/actions/register';
import { getRegisterInfo } from 'redux/selectors/register';

import { getCustomerOrganization } from 'redux/selectors/organization';

import CustomerAlert from 'components/layouts/CustomerAlert';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  pageContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  loginContainer: {
    marginTop: '9%',
    marginLeft: '5%',
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  backToRegisterForm: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    marginRight: '20%',
    marginTop: '3%',
  },
  loginContainerMobile: {
    marginTop: '3%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  loginHeader: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '72px',
    lineHeight: '102.2%',
    /* or 74px */
    color: '#FFFFFF',
    zIndex: 1000,
  },
  loginInfo: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '120%',
    /* or 74px */
    color: '#FFFFFF',
    zIndex: 1000,
  },
  loginForm: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formInput: {
    background: 'rgba(255, 255, 255, 0.1)',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '119%',
    /* or 74px */
    color: '#FFFFFF',
    opacity: 0.5,
  },
  inputText: {
    color: '#FFFFFF',
    opacity: 0.95,
  },
  inputLabel: {
    color: '#FFFFFF',
    opacity: 0.95,
    '&.focused': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
    '&.shrink': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
  },
  loginSubmit: {
    '&:hover': {
      background: '#AD00FF',
    },
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    order: 1,
    alignSelf: 'center',
    margin: '8px 0px',
  },
  loginNavLink: {
    '&:hover': {
      background: '#AD00FF',
    },
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    order: 1,
    alignSelf: 'center',
    margin: '8px 0px',
    marginRight: '5%',
  },
}));

export const Register = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const mobileScreen = useMediaQuery('(max-width:990px)');

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const { isAuthenticated } = useSelector((state) => state.auth);

  const { marketingTextView } = useSelector((state) =>
    getRegisterInfo(state.register)
  );

  const { customerOrg } = useSelector((state) =>
    getCustomerOrganization(state.organization)
  );

  if (isAuthenticated) {
    return <Redirect to="/login"></Redirect>;
  }

  if (customerOrg && customerOrg.registerEnabled === false) {
    return <Redirect to={`/organization/${customerOrg.identifier}`}></Redirect>;
  }

  return (
    <div
      className={
        mobileScreen ? classes.pageContainerMobile : classes.pageContainer
      }
    >
      {mobileScreen ? (
        <div className={classes.loginContainerMobile}>
          {marketingTextView && (
            <div
              className={classes.backToRegisterForm}
              onClick={(e) => {
                e.preventDefault();
                dispatch(toggleMarketingView());
              }}
            >
              <ArrowBackIosIcon fontSize="small" />
              <span>Takaisin rekisteröintiin</span>
            </div>
          )}

          <Button
            component={NavLink}
            to="/login"
            className={classes.loginNavLink}
          >
            Kirjaudu sisään
          </Button>
        </div>
      ) : (
        <div className={classes.loginContainer}>
          <Typography className={classes.loginHeader}>
            Kirjaudu sisään
          </Typography>
          <Typography className={classes.loginInfo}>
            Kirjaudu sisään jo luoduilla tunnuksilla. Mikäli olet unohtanut
            salasanasi, ole yhteydessä agt@matkapojat.fi
          </Typography>
          <CustomerAlert />
          <form className={classes.loginForm} noValidate>
            <TextField
              className={classes.formInput}
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              label="Sähköposti"
              value={email}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              autoComplete="email"
              autoFocus
              InputProps={{
                className: classes.inputText,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: 'focused',
                  shrink: 'shrink',
                },
              }}
            />
            <TextField
              className={classes.formInput}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Salasana"
              type="password"
              id="password"
              value={password}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              autoComplete="current-password"
              InputProps={{
                className: classes.inputText,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: 'focused',
                  shrink: 'shrink',
                },
              }}
            />
            <Button
              type="submit"
              className={classes.loginSubmit}
              onClick={(e) => {
                e.preventDefault();
                dispatch(login(email, password));
              }}
            >
              Kirjaudu sisään
            </Button>
          </form>
        </div>
      )}
      {mobileScreen ? <RegisterFormMobile /> : <RegisterFormDesktop />}
    </div>
  );
};

export default Register;
