import React from 'react';
import { NavLink } from 'react-router-dom';

import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BusinessIcon from '@material-ui/icons/Business';
import KingBedIcon from '@material-ui/icons/KingBed';

import OwnerOptions from './OwnerOptions';

const RootAdminOptions = () => {
  return (
    <>
      <ListItem button component={NavLink} to="/organization">
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Organization" />
      </ListItem>
      <ListItem button component={NavLink} to="/products">
        <ListItemIcon>
          <KingBedIcon />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItem>
      <ListSubheader component="div">Owner actions</ListSubheader>
      <OwnerOptions />
    </>
  );
};

export default RootAdminOptions;
