import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

import { getEventInfoButtons } from 'redux/selectors/customer';

const useStyles = makeStyles((theme) => ({
  eventInfosContainer: {
    width: '100%',
    background: '#FFFFFF',
    /* Drop */
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    zIndex: 1000,
  },

  eventInfosHeader: {
    marginTop: '3%',
    marginLeft: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '46px',

    color: '#20D2B7',
  },
  eventInfosInfo: {
    marginTop: theme.spacing(2),
    marginLeft: '7%',
    marginRight: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
  },

  eventInfosHeaderMobile: {
    marginTop: '10%',
    marginLeft: '10%',
    marginRigth: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '102%',
    marginBottom: theme.spacing(2),
    color: '#20D2B7',
  },
  eventInfosInfoMobile: {
    marginLeft: '10%',
    marginRight: '7%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '102%',
    color: '#828282',
  },

  openInfoButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    marginLeft: '7%',
    width: '60%',
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    margin: '8px 0px',
  },
  openInfoButtonMobile: {
    '&:hover': {
      background: '#AD00FF',
    },
    marginLeft: '10%',
    marginRight: '7%',
    width: '60%',
    padding: '8px 22px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height, or 173% */
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    /* secondary / contrast text */
    color: '#FFFFFF',
    background: '#20D2B7',
    /* Inside Auto Layout */
    flex: 'none',
    margin: '8px 0px',
  },
}));

export const EventInformationPackets = () => {
  const classes = useStyles();
  const mobileScreen = useMediaQuery('(max-width:990px)');

  const { description, infoButton1, infoButton2, infoButton3 } = useSelector(
    (state) => getEventInfoButtons(state.customer)
  );

  return (
    <div className={classes.eventInfosContainer} id={'info-section'}>
      <Typography
        className={
          mobileScreen
            ? classes.eventInfosHeaderMobile
            : classes.eventInfosHeader
        }
      >
        Infopaketit
      </Typography>
      <Typography
        className={
          mobileScreen ? classes.eventInfosInfoMobile : classes.eventInfosInfo
        }
      >
        {description}
      </Typography>
      {infoButton1 && infoButton1.visible && (
        <Button
          onClick={(e) => {
            const url = infoButton1.url;
            window.open(url, '_blank');
          }}
          className={
            mobileScreen ? classes.openInfoButtonMobile : classes.openInfoButton
          }
        >
          {infoButton1.name}
        </Button>
      )}
      {infoButton2 && infoButton2.visible && (
        <Button
          onClick={(e) => {
            const url = infoButton2.url;
            window.open(url, '_blank');
          }}
          className={
            mobileScreen ? classes.openInfoButtonMobile : classes.openInfoButton
          }
        >
          {infoButton2.name}
        </Button>
      )}

      {infoButton3 && infoButton3.visible && (
        <Button
          onClick={(e) => {
            const url = infoButton3.url;
            window.open(url, '_blank');
          }}
          className={
            mobileScreen ? classes.openInfoButtonMobile : classes.openInfoButton
          }
        >
          {infoButton3.name}
        </Button>
      )}
    </div>
  );
};

export default EventInformationPackets;
