import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '50%',
  },
  customerAlert: {
    width: '50%',
    color: '#FFFFFF',
    opacity: 1,
    background: 'transparent',
    zIndex: 1000,
  },
}));

const AlertMessage = () => {
  const classes = useStyles();
  const alerts = useSelector((state) => state.alert);

  return (
    alerts.length > 0 &&
    alerts.map(
      (alert) =>
        !alert.customer && (
          <Alert
            className={classes.alert}
            key={alert.id}
            severity={alert.severity}
          >
            {alert.msg}
          </Alert>
        )
    )
  );
};

export default AlertMessage;
