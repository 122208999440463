import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Redirect, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { fetchCustomerOrganization } from 'redux/actions/organization';

import { getCustomerOrganization } from 'redux/selectors/organization';

const useStyles = makeStyles((theme) => ({
  orgContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '13%',
    marginTop: '14%',
  },
  orgContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '13%',
    marginTop: '5%',
    marginRight: '10%',
  },
  orgName: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '96px',
    lineHeight: '98px',
    /* White */
    color: '#FFFFFF',
    zIndex: 100,
  },
  orgNameMobile: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '48px',
    lineHeight: '102.2%',
    /* White */
    color: '#FFFFFF',
    zIndex: 100,
  },
  infoContainer: {
    width: '100%',
    minHeight: '31%',
    marginTop: '3%',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 100,
  },
  infoContainerMobile: {
    width: '100%',
    minHeight: '31%',
    marginTop: '3%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 100,
  },
  orgDescription: {
    width: '40%',
    height: '31%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '135%',
    /* or 20px */
    /* White */
    color: '#FFFFFF',
    zIndex: 100,
    '& a:link': {
      color: '#FFFFFF',
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  orgDescriptionMobile: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '135%',
    /* or 20px */
    /* White */
    color: '#FFFFFF',
    zIndex: 100,
    '& a:link': {
      color: '#FFFFFF',
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  orgThingsToNote: {
    maxWidth: '23%',
    marginLeft: '5%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '135%',
    /* White */
    color: '#FFFFFF',
    zIndex: 100,
    '& a:link': {
      color: '#FFFFFF',
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },

  orgThingsToNoteMobile: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '135%',
    /* White */
    color: '#FFFFFF',
    zIndex: 100,
    '& a:link': {
      color: '#FFFFFF',
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  orgQuestions: {
    maxWidth: '23%',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '135%',
    /* White */
    color: '#FFFFFF',
    marginLeft: '5%',
    zIndex: 100,
    '& a:link': {
      color: '#FFFFFF',
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  orgQuestionsMobile: {
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '135%',
    /* White */
    color: '#FFFFFF',
    zIndex: 100,
    '& a:link': {
      color: '#FFFFFF',
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  buttonContainer: {
    marginTop: '3%',
    marginBottom: '5%',
    display: 'flex',
    flexDirection: 'row',
  },

  buttonContainerMobile: {
    marginTop: '3%',
    marginBottom: '5%',
    display: 'flex',
    flexDirection: 'column',
  },
  registerButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    width: '331px',
    height: '68px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '108%',
    /* identical to box height, or 108% */
    textAlign: 'center',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    /* Teal */
    background: '#20D2B7',
    zIndex: 100,
  },
  registerButtonMobile: {
    '&:hover': {
      background: '#AD00FF',
    },
    width: '170px',
    height: '36px',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '200%',
    /* identical to box height, or 200% */
    textAlign: 'center',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    /* Teal */
    background: '#20D2B7',
    zIndex: 100,
  },
  loginButton: {
    '&:hover': {
      background: '#AD00FF',
    },
    width: '331px',
    height: '68px',
    marginLeft: theme.spacing(3),
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '108%',
    /* identical to box height, or 108% */
    textAlign: 'center',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    border: '3px solid #FFFFFF',
    color: '#FFFFFF',
    /* Teal */
    background: 'transparent',
    zIndex: 100,
  },
  loginButtonMobile: {
    '&:hover': {
      background: '#AD00FF',
    },
    width: '150px',
    height: '36px',
    marginTop: theme.spacing(2),
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '200%',
    /* identical to box height, or 200% */
    textAlign: 'center',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    border: '3px solid #FFFFFF',
    color: '#FFFFFF',
    /* Teal */
    background: 'transparent',
    zIndex: 100,
  },
}));

export const OrganizationHome = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileScreen = useMediaQuery('(max-width:990px)');

  const { identifier } = useParams();

  useEffect(() => {
    if (identifier) {
      dispatch(fetchCustomerOrganization(identifier));
    }
  }, [identifier, dispatch]);

  const { isAuthenticated } = useSelector((state) => state.auth);

  const { customerOrg } = useSelector((state) =>
    getCustomerOrganization(state.organization)
  );

  if (isAuthenticated) {
    return <Redirect to="/login"></Redirect>;
  }

  return (
    <Fragment>
      {customerOrg && (
        <div
          style={{
            background: `url(${customerOrg.backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '100vh',
            width: '100%',
            zIndex: 1,
          }}
        >
          <div
            className={
              mobileScreen ? classes.orgContainerMobile : classes.orgContainer
            }
          >
            <Typography
              className={mobileScreen ? classes.orgNameMobile : classes.orgName}
            >
              {customerOrg.name}
            </Typography>
            <div
              className={
                mobileScreen
                  ? classes.infoContainerMobile
                  : classes.infoContainer
              }
            >
              <div
                className={
                  mobileScreen
                    ? classes.orgDescriptionMobile
                    : classes.orgDescription
                }
              >
                {customerOrg.description
                  ? ReactHtmlParser(customerOrg.description)
                  : ''}
              </div>
              <div
                className={
                  mobileScreen
                    ? classes.orgThingsToNoteMobile
                    : classes.orgThingsToNote
                }
              >
                {customerOrg.thingsToNote
                  ? ReactHtmlParser(customerOrg.thingsToNote)
                  : ''}
              </div>
              <div
                className={
                  mobileScreen
                    ? classes.orgQuestionsMobile
                    : classes.orgQuestions
                }
              >
                {customerOrg.questions
                  ? ReactHtmlParser(customerOrg.questions)
                  : ''}
              </div>
            </div>
            {!customerOrg.registerEnabled && (
              <Typography
                className={
                  mobileScreen
                    ? classes.infoContainerMobile
                    : classes.infoContainer
                }
              >
                Ilmoittautuminen tähän tapahtumaan on suljettu
              </Typography>
            )}
            <div
              className={
                mobileScreen
                  ? classes.buttonContainerMobile
                  : classes.buttonContainer
              }
            >
              <Button
                component={NavLink}
                to={`/register/${customerOrg._id}`}
                style={{
                  display: customerOrg.registerEnabled ? 'button' : 'none',
                }}
                className={
                  mobileScreen
                    ? classes.registerButtonMobile
                    : classes.registerButton
                }
              >
                Ilmoittaudu tästä
              </Button>
              <Button
                component={NavLink}
                to="/login"
                className={
                  mobileScreen ? classes.loginButtonMobile : classes.loginButton
                }
              >
                {customerOrg.registerEnabled
                  ? 'Kirjaudu sisään'
                  : 'Kirjaudu jo luoduilla tunnuksilla'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default OrganizationHome;
