import {
  FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_REQUESTED,
  FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_RESPONSE,
  FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_ERROR,
  SAVE_ORGANIZATION_ID_TO_STATE,
  SAVE_USER_COUNT_TO_STATE,
  SAVE_PAGENUM_TO_STATE,
  RESET_ORGANIZATION_PAGINATED_CUSTOMERS,
  UPDATE_DISABLED_USERS,
  UPDATE_ENABLED_USERS,
  SET_ROWS_PER_PAGE,
  SAVE_ORGANIZATION_FILTERS,
  SAVE_ACTIVE_FILTERS,
  SAVE_SEARCHBAR_VALUE,
  RESET_PAGINATION_DATA,
} from 'redux/actions/paginatedUsers';

const initialState = {
  page: 0,
  count: -1,
  rowsPerPage: 100,
  fetchingUsers: false,
  users: [],
  error: {},
  selectedOrg: '',
  filters: [],
  roomFilters: [
    { name: 'F', type: 'room' },
    { name: 'M', type: 'room' },
    { name: '1h', type: 'room' },
    { name: '2h', type: 'room' },
    { name: '3h', type: 'room' },
  ],
  activeFilters: [],
  searchBarValue: '',
};

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_RESPONSE:
      return { ...state, users: action.payload };

    case FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_ERROR:
      return { ...state, error: action.payload };

    case FETCH_ORGANIZATION_CUSTOMERS_PAGINATION_REQUESTED:
      return { ...state, fetchingUsers: true };

    case SAVE_ORGANIZATION_ID_TO_STATE:
      return { ...state, selectedOrg: action.payload };

    case SAVE_USER_COUNT_TO_STATE:
      return { ...state, count: action.payload };

    case SAVE_PAGENUM_TO_STATE:
      return { ...state, page: action.payload };

    case SAVE_ACTIVE_FILTERS:
      return { ...state, activeFilters: action.payload };

    case RESET_ORGANIZATION_PAGINATED_CUSTOMERS:
      return { ...state, users: [] };

    case RESET_PAGINATION_DATA:
      return { ...state, activeFilters: [], searchBarValue: '', users: [] };

    case UPDATE_DISABLED_USERS: {
      const disabledUsers = action.payload;
      let users = state.users.map((user) => {
        if (disabledUsers.includes(user._id)) {
          user.disabled = true;
        }
        return user;
      });
      return { ...state, users };
    }
    case UPDATE_ENABLED_USERS: {
      const enabledUsers = action.payload;
      let newUsers = state.users.map((user) => {
        if (enabledUsers.includes(user._id)) {
          user.disabled = false;
        }
        return user;
      });

      return { ...state, users: newUsers };
    }
    case SET_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.payload };
    case SAVE_ORGANIZATION_FILTERS:
      return { ...state, filters: action.payload };
    case SAVE_SEARCHBAR_VALUE:
      return { ...state, searchBarValue: action.payload };
    default:
      return state;
  }
}
