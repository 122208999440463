import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_REQUEST_SUCCESS,
} from 'redux/actions/auth';

const initialState = {
  token: localStorage.getItem('jwtToken'),
  isAuthenticated: false,
  role: null,
  organization: null,
  loading: true,
  error: null,
  isResetEmailSent: false,
  isPasswordReseted: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('jwtToken', payload.token);
      return {
        ...state,
        token: payload.token,
        role: payload.role,
        organization: payload.organization,
        isAuthenticated: true,
        loading: false,
        errors: [],
      };
    case LOGIN_FAILED:
      return { ...state, loading: false, errors: payload };
    case PASSWORD_RESET_REQUEST_SUCCESS:
      return { ...state, isResetEmailSent: true };
    case PASSWORD_RESET_SUCCESS:
      return { ...state, isPasswordReseted: true };
    default:
      return state;
  }
}
