import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadCSS } from 'fg-loadcss';

import { fetchEventPayments } from 'redux/actions/payment';
import {
  getInfoFromState,
  getUserEventFromState,
} from 'redux/selectors/customer';
import { getEventPaymentsFromState } from 'redux/selectors/payment';
import { Payments } from './Payments';

export const UserPayments = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchEventPayments());
  }, [dispatch]);

  const { info } = useSelector((state) => getInfoFromState(state.customer));
  const { event } = useSelector((state) =>
    getUserEventFromState(state.customer)
  );
  const { additionalServices, eventPayments } = useSelector((state) =>
    getEventPaymentsFromState(state.payment)
  );

  return (
    <Fragment>
      {info && event && eventPayments && (
        <Payments eventPayments={eventPayments} />
      )}

      {info && event && additionalServices && (
        <Payments additional={true} eventPayments={additionalServices} />
      )}
    </Fragment>
  );
};

export default UserPayments;
