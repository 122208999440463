import API from 'services/api';
import { setAlert } from 'redux/actions/alert';

export const SAVE_ADMIN_REQUESTED = 'SAVE_ADMIN_REQUESTED';
export const SAVE_ADMIN_RESPONSE = 'SAVE_ADMIN_RESPONSE';
export const SAVE_ADMIN_ERROR = 'SAVE_ADMIN_ERROR';

export const UPDATE_ADMIN_REQUESTED = 'UPDATE_ADMIN_REQUESTED';
export const UPDATE_ADMIN_RESPONSE = 'UPDATE_ADMIN_RESPONSE';
export const UPDATE_ADMIN_ERROR = 'UPDATE_ADMIN_ERROR';

export const GET_ADMINS_REQUESTED = 'GET_ADMINS_REQUESTED';
export const GET_ADMINS_RESPONSE = 'GET_ADMINS_RESPONSE';
export const GET_ADMINS_ERROR = 'GET_ADMINS_ERROR';

export const DELETE_ADMIN_REQUESTED = 'DELETE_ADMIN_REQUESTED';
export const DELETE_ADMIN_RESPONSE = 'DELETE_ADMIN_RESPONSE';
export const DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR';

export const SELECT_EXISTING_ADMIN = 'SELECT_EXISTING_ADMINS';
export const SELECT_NEW_ADMIN = 'SELECT_NEW_ADMIN';

export const UPDATE_ADMIN_NAME = 'UPDATE_ADMIN_NAME';
export const UPDATE_ADMIN_EMAIL = 'UPDATE_ADMIN_EMAIL';
export const UPDATE_ADMIN_PASSWORD = 'UPDATE_ADMIN_PASSWORD';
export const UPDATE_ADMIN_ORGANIZATION = 'UPDATE_ADMIN_ORGANIZATION';
export const UPDATE_ADMIN_ROLE = 'UPDATE_ADMIN_ROLE';
export const TOGGLE_ADMIN_PASSWORD = 'TOGGLE_ADMIN_PASSWORD';

export const saveAdmin = (admin) => async (dispatch) => {
  dispatch({ type: SAVE_ADMIN_REQUESTED });
  try {
    const res = await API.post('/api/admin/admins', admin);

    dispatch(setAlert('Admin saved succesfully', 'success'));

    dispatch({ type: SAVE_ADMIN_RESPONSE, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: SAVE_ADMIN_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateAdmin = (admin) => async (dispatch) => {
  dispatch({ type: UPDATE_ADMIN_REQUESTED });
  try {
    const res = await API.put(`/api/admin/admins/${admin._id}`, admin);

    dispatch(setAlert('Admin updated successfully', 'success'));
    dispatch({ type: UPDATE_ADMIN_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch(setAlert('Failed to update admin', 'error'));
    dispatch({
      type: UPDATE_ADMIN_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const getAdmins = () => async (dispatch) => {
  dispatch({ type: GET_ADMINS_REQUESTED });
  try {
    const res = await API.get('/api/admin/admins');
    dispatch({ type: GET_ADMINS_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch({
      type: GET_ADMINS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteAdmin = (admin) => async (dispatch) => {
  dispatch({ type: DELETE_ADMIN_REQUESTED });
  try {
    const res = await API.delete(`/api/admin/admins/${admin._id}`);
    dispatch(setAlert('Admin successfully deleted', 'success'));
    dispatch({ type: DELETE_ADMIN_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch(setAlert('Failed to delete admin', 'error'));
    dispatch({
      type: DELETE_ADMIN_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const selectExistingAdmin = (payload) => ({
  type: SELECT_EXISTING_ADMIN,
  payload,
});

export const selectNewAdmin = (organization) => ({
  type: SELECT_NEW_ADMIN,
  payload: organization,
});

export const toggleAdminPassword = () => ({
  type: TOGGLE_ADMIN_PASSWORD,
});

export const updateAdminName = (payload) => ({
  type: UPDATE_ADMIN_NAME,
  payload,
});

export const updateAdminEmail = (payload) => ({
  type: UPDATE_ADMIN_EMAIL,
  payload,
});

export const updateAdminPassword = (payload) => ({
  type: UPDATE_ADMIN_PASSWORD,
  payload,
});

export const updateAdminOrganization = (payload) => ({
  type: UPDATE_ADMIN_ORGANIZATION,
  payload,
});

export const updateAdminRole = (payload) => ({
  type: UPDATE_ADMIN_ROLE,
  payload,
});
