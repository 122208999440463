import {
  CLOSE_MASS_EDIT_DIALOG,
  MASS_DISABLE_REQUEST,
  MASS_DISABLE_SUCCESS,
  MASS_DISABLE_FAILED,
  MASS_ENABLE_REQUEST,
  MASS_ENABLE_FAILED,
  MASS_ENABLE_SUCCESS,
  OPEN_MASS_EDIT_DIALOG,
  GROUP_CHANGE_REQUEST,
  GROUP_CHANGE_FAILED,
  GROUP_CHANGE_SUCCESS,
  MASS_REFUND_REQUEST,
  MASS_REFUND_SUCCESS,
  MASS_REFUND_FAILED,
} from 'redux/actions/massEdit';

const initialState = {
  visible: false,
  selected: null,
  disable: { loading: false },
  change: { loading: false },
  refund: { loading: false, success: [], failed: [], done: false },
};

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case OPEN_MASS_EDIT_DIALOG:
      return {
        ...state,
        visible: true,
        selected: action.payload.data,
        disable: { loading: false },
        change: { loading: false },
      };
    case CLOSE_MASS_EDIT_DIALOG:
      return { ...initialState };
    case MASS_DISABLE_REQUEST:
      return { ...state, disable: { loading: true } };
    case MASS_DISABLE_SUCCESS:
      return { ...state, disable: { loading: false } };
    case MASS_DISABLE_FAILED:
      return { ...state, disable: { loading: false } };
    case MASS_ENABLE_REQUEST:
      return { ...state, disable: { loading: true } };
    case MASS_ENABLE_SUCCESS:
      return { ...state, disable: { loading: false } };
    case MASS_ENABLE_FAILED:
      return { ...state, disable: { loading: false } };

    case GROUP_CHANGE_REQUEST:
      return { ...state, change: { loading: true } };
    case GROUP_CHANGE_SUCCESS:
      return { ...state, change: { loading: false } };
    case GROUP_CHANGE_FAILED:
      return { ...state, change: { loading: false } };
    case MASS_REFUND_REQUEST:
      return { ...state, refund: { loading: true } };
    case MASS_REFUND_SUCCESS:
      return {
        ...state,
        refund: {
          loading: false,
          success: action.payload.success,
          failed: action.payload.errors,
          done: true,
        },
      };
    case MASS_REFUND_FAILED:
      return { ...state, refund: { loading: false, done: true } };
    default:
      return state;
  }
}
