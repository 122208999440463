import RichTextEditor from 'react-rte';

import {
  FETCH_CUSTOMER_ORG_REQUESTED,
  FETCH_CUSTOMER_ORG_RESPONSE,
  FETCH_CUSTOMER_ORG_ERROR,
  SAVE_ORG_REQUESTED,
  SAVE_ORG_RESPONSE,
  SAVE_ORG_ERROR,
  UPDATE_ORG_REQUESTED,
  UPDATE_ORG_RESPONSE,
  UPDATE_ORG_ERROR,
  DELETE_ORG_REQUESTED,
  DELETE_ORG_RESPONSE,
  DELETE_ORG_ERROR,
  GET_ORGS_REQUESTED,
  GET_ORGS_RESPONSE,
  GET_ORGS_ERROR,
  SELECT_EXISTING_ORG,
  SELECT_NEW_ORG,
  EDIT_ORG_NAME,
  EDIT_ORG_CONTACT_NAME,
  EDIT_ORG_CONTACT_EMAIL,
  EDIT_ORG_CONTACT_PHONE,
  EDIT_ORG_DESCRIPTION,
  EDIT_ORG_THINGS_TO_NOTE,
  EDIT_ORG_QUESTIONS,
  EDIT_ORG_WEBSITE,
  EDIT_ORG_STREET,
  EDIT_ORG_POSTAL_CODE,
  EDIT_ORG_CITY,
  EDIT_ORG_COUNTRY,
  EDIT_ORG_IDENTIFIER,
  EDIT_ORG_BUSINESS_CODE,
  EDIT_ORG_BANK_ACCOUNT,
  EDIT_ORG_BACKGROUND_IMAGE,
  EDIT_ORG_TRAVEL_CONDITIONS,
  EDIT_ORG_PRIVACY_STATEMENT,
  EDIT_ORG_MARKETING_AUTHORIZATION,
  UPDATE_ORG_REGISTERING,
} from 'redux/actions/organization';

const initialState = {
  organizations: [],
  organization: {
    name: null,
    identifier: null,
    backgroundImage: null,
    contact: {
      name: null,
      email: null,
      phone: null,
    },
    description: RichTextEditor.createEmptyValue(),
    thingsToNote: RichTextEditor.createEmptyValue(),
    questions: RichTextEditor.createEmptyValue(),
    marketingAuthorization: RichTextEditor.createEmptyValue(),
    website: null,
    address: {
      street: null,
      postalCode: null,
      city: null,
      country: null,
    },
    businessCode: null,
    bankAccount: null,
    _id: '',
    privacyStatement: null,
    travelConditions: null,
    registerEnabled: true,
  },
  customerOrg: null,
  error: null,
};

const updateOrganizationsArray = (orgs, updated) => {
  const filtered = orgs.filter((org) => org._id !== updated._id);
  filtered.push(updated);
  return filtered;
};

const addToOrganizationsArray = (orgs, updated) => {
  const filtered = orgs.filter((org) => org.name !== updated.name);
  filtered.push(updated);
  return filtered;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ORG_RESPONSE:
      return {
        ...state,
        organizations: addToOrganizationsArray(
          state.organizations,
          action.payload
        ),
      };

    case SAVE_ORG_ERROR:
      return { ...state, error: action.payload };

    case UPDATE_ORG_RESPONSE:
      return {
        ...state,
        organizations: updateOrganizationsArray(
          state.organizations,
          action.payload
        ),
      };

    case UPDATE_ORG_ERROR:
      return { ...state, error: action.payload };

    case DELETE_ORG_RESPONSE:
      return {
        ...state,
        organizations: state.organizations.filter(
          (org) => org._id !== action.payload._id
        ),
        organization: initialState.organization,
      };

    case DELETE_ORG_ERROR:
      return { ...state, error: action.payload };

    case FETCH_CUSTOMER_ORG_RESPONSE:
      return {
        ...state,
        customerOrg: action.payload,
      };

    case FETCH_CUSTOMER_ORG_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ORGS_RESPONSE:
      return { ...state, organizations: action.payload };

    case GET_ORGS_ERROR:
      return { ...state, error: action.payload };

    case SELECT_EXISTING_ORG:
      return { ...state, organization: action.payload };

    case SELECT_NEW_ORG:
      return { ...state, organization: initialState.organization };

    case EDIT_ORG_NAME:
      return {
        ...state,
        organization: { ...state.organization, name: action.payload },
      };

    case EDIT_ORG_IDENTIFIER:
      return {
        ...state,
        organization: { ...state.organization, identifier: action.payload },
      };

    case EDIT_ORG_CONTACT_NAME:
      return {
        ...state,
        organization: {
          ...state.organization,
          contact: { ...state.organization.contact, name: action.payload },
        },
      };

    case EDIT_ORG_CONTACT_EMAIL:
      return {
        ...state,
        organization: {
          ...state.organization,
          contact: { ...state.organization.contact, email: action.payload },
        },
      };

    case EDIT_ORG_CONTACT_PHONE:
      return {
        ...state,
        organization: {
          ...state.organization,
          contact: { ...state.organization.contact, phone: action.payload },
        },
      };

    case EDIT_ORG_DESCRIPTION:
      return {
        ...state,
        organization: { ...state.organization, description: action.payload },
      };

    case EDIT_ORG_THINGS_TO_NOTE:
      return {
        ...state,
        organization: { ...state.organization, thingsToNote: action.payload },
      };

    case EDIT_ORG_QUESTIONS:
      return {
        ...state,
        organization: { ...state.organization, questions: action.payload },
      };

    case EDIT_ORG_WEBSITE:
      return {
        ...state,
        organization: { ...state.organization, website: action.payload },
      };

    case EDIT_ORG_BACKGROUND_IMAGE:
      return {
        ...state,
        organization: {
          ...state.organization,
          backgroundImage: action.payload,
        },
      };

    case EDIT_ORG_STREET:
      return {
        ...state,
        organization: {
          ...state.organization,
          address: { ...state.organization.address, street: action.payload },
        },
      };

    case EDIT_ORG_POSTAL_CODE:
      return {
        ...state,
        organization: {
          ...state.organization,
          address: {
            ...state.organization.address,
            postalCode: action.payload,
          },
        },
      };

    case EDIT_ORG_CITY:
      return {
        ...state,
        organization: {
          ...state.organization,
          address: { ...state.organization.address, city: action.payload },
        },
      };

    case EDIT_ORG_COUNTRY:
      return {
        ...state,
        organization: {
          ...state.organization,
          address: { ...state.organization.addresss, country: action.payload },
        },
      };

    case EDIT_ORG_BUSINESS_CODE:
      return {
        ...state,
        organization: { ...state.organization, businessCode: action.payload },
      };

    case EDIT_ORG_BANK_ACCOUNT:
      return {
        ...state,
        organization: { ...state.organization, bankAccount: action.payload },
      };

    case EDIT_ORG_TRAVEL_CONDITIONS:
      return {
        ...state,
        organization: {
          ...state.organization,
          travelConditions: action.payload,
        },
      };

    case EDIT_ORG_PRIVACY_STATEMENT:
      return {
        ...state,
        organization: {
          ...state.organization,
          privacyStatement: action.payload,
        },
      };

    case EDIT_ORG_MARKETING_AUTHORIZATION:
      return {
        ...state,
        organization: {
          ...state.organization,
          marketingAuthorization: action.payload,
        },
      };

    case UPDATE_ORG_REGISTERING:
      return {
        ...state,
        organization: {
          ...state.organization,
          registerEnabled: action.payload,
        },
      };

    case UPDATE_ORG_REQUESTED:
    case DELETE_ORG_REQUESTED:
    case GET_ORGS_REQUESTED:
    case SAVE_ORG_REQUESTED:
    case FETCH_CUSTOMER_ORG_REQUESTED:
    default:
      return state;
  }
}
