import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminRoute = ({ component: Component, roles, ...rest }) => {
  const { isAuthenticated, loading, role } = useSelector((state) => state.auth);

  /* If in loading state, return loading message while waiting for 
  loadAuth() to complete */
  if (loading) {
    return <div></div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <Redirect to="/login" />
        ) : roles && roles.indexOf(role) === -1 ? (
          <Redirect to="/login" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default AdminRoute;
