import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlertMessage from 'components/layouts/AlertMessage';
import DeleteConfirm from 'components/layouts/DeleteConfirm';

import {
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';

import { ADMINROLES } from 'constants/roles';

import {
  updateAdminEmail,
  updateAdminName,
  updateAdminOrganization,
  updateAdminPassword,
  updateAdminRole,
  toggleAdminPassword,
  getAdmins,
  selectExistingAdmin,
  selectNewAdmin,
  updateAdmin,
  saveAdmin,
  deleteAdmin,
} from 'redux/actions/admin';

import { getOrganizations } from 'redux/actions/organization';
import { getOrganizationsFromState } from 'redux/selectors/organization';
import { getRoleAndOrganization } from 'redux/selectors/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  alertBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

const RootRoles = () => {
  return ADMINROLES.map((role, index) => (
    <MenuItem key={index} value={role}>
      {role}
    </MenuItem>
  ));
};

const OwnerRoles = () => {
  return ADMINROLES.filter((role) => role !== 'root').map((role, index) => (
    <MenuItem key={index} value={role}>
      {role}
    </MenuItem>
  ));
};

const OrganizationsOptions = (organizations) => {
  return organizations.map((organization) => (
    <MenuItem key={organization._id} value={organization._id}>
      {organization.name}
    </MenuItem>
  ));
};

const objectDoesNotContainNull = (obj) => {
  const notMandatory = [];
  if (obj.role === 'root') {
    notMandatory.push('organization');
  }
  const keys = Object.keys(obj);
  const values = keys
    .filter((key) => !notMandatory.includes(key))
    .map((key) => obj[key]);
  return !values.includes(null);
};

const getMenuItems = (admins) =>
  admins.map((admin) => (
    <MenuItem key={admin.email} value={admin.email}>
      {admin.email}
    </MenuItem>
  ));

const Admin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const { role, organization } = useSelector((state) =>
    getRoleAndOrganization(state.auth)
  );

  useEffect(() => {
    if (role === 'root') {
      dispatch(getOrganizations());
    }
  }, [role, dispatch]);

  useEffect(() => {
    dispatch(getAdmins());
  }, [dispatch]);

  const { admins, admin, showPassword } = useSelector((state) => state.admin);
  const { organizations } = useSelector((state) =>
    getOrganizationsFromState(state.organization)
  );

  useEffect(() => {
    if (organization) {
      dispatch(updateAdminOrganization(organization._id));
    }
  }, [organization, dispatch]);

  const getAdminEmailToObject = (email) => {
    let admin = admins.find((admin) => admin.email === email);
    if (typeof admin.role !== 'string') {
      if (admin.role) admin.role = admin.role.name;
    }
    return admin;
  };

  const handleAgree = () => dispatch(deleteAdmin(admin));
  const closeDialog = () => setConfirmDelete(false);

  return (
    <div>
      <h2> Admin </h2>
      {admins && organizations && (
        <FormControl
          className={classes.formControl}
          key={`amountOfSelections_${admins.length}`}
        >
          <InputLabel id="adminSelectionLabel">Select</InputLabel>
          <Select
            labelId="adminSelectionLabel"
            id="adminSelection"
            defaultValue=""
            onChange={({ target: { value } }) =>
              value !== 'new'
                ? dispatch(selectExistingAdmin(getAdminEmailToObject(value)))
                : // If owener role the organization will be defined.
                  dispatch(selectNewAdmin(organization))
            }
          >
            <MenuItem key="new" value={'new'}>
              New
            </MenuItem>
            {getMenuItems(admins)}
          </Select>
        </FormControl>
      )}

      <form
        key={admin._id}
        className={classes.root}
        noValidate
        autoComplete="off"
      >
        <div className={classes.alertBox}>
          <AlertMessage />
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <TextField
              id="email"
              defaultValue={admin.email}
              label="Admin email"
              required
              onChange={(event) =>
                dispatch(updateAdminEmail(event.target.value))
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="password"
              label="Admin password"
              required
              type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
              onChange={(event) =>
                dispatch(updateAdminPassword(event.target.value))
              }
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(event) => dispatch(toggleAdminPassword())}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <TextField
              id="name"
              defaultValue={admin.name}
              label="Admin name"
              required
              onChange={(event) =>
                dispatch(updateAdminName(event.target.value))
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label="Admin role"
              id="role"
              required
              select
              value={admin.role ? admin.role : ''}
              onChange={(event) => {
                dispatch(updateAdminRole(event.target.value));
              }}
            >
              {role === 'root' ? RootRoles() : OwnerRoles()}
            </TextField>
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            {role === 'owner' ? (
              <TextField
                label="Admin organization"
                id="organization"
                disabled
                value={organization._id}
                select
                InputProps={{
                  readOnly: true,
                }}
              >
                <MenuItem key={organization._id} value={organization._id}>
                  {organization.name}
                </MenuItem>
              </TextField>
            ) : (admin.role === 'owner' || admin.role === 'admin') &&
              role === 'root' ? (
              <TextField
                label="Admin organization"
                id="organization"
                select
                required
                value={admin.organization ? admin.organization : ''}
                onChange={(event) => {
                  dispatch(updateAdminOrganization(event.target.value));
                }}
              >
                {OrganizationsOptions(organizations)}
              </TextField>
            ) : (
              <></>
            )}
          </FormControl>
        </div>
      </form>
      <div className={classes.buttonContainer}>
        {objectDoesNotContainNull(admin) && (
          <Button
            className={classes.button}
            aria-label="save"
            color="primary"
            variant="contained"
            onClick={() =>
              dispatch(admin._id ? updateAdmin(admin) : saveAdmin(admin))
            }
          >
            Save
          </Button>
        )}
        {admin._id && (
          <Button
            className={classes.button}
            aria-label="delete"
            color="secondary"
            variant="contained"
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
        )}
      </div>
      <DeleteConfirm
        visible={confirmDelete}
        handleAgree={handleAgree}
        closeDialog={closeDialog}
      ></DeleteConfirm>
    </div>
  );
};

export default Admin;
