import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import { sendPaymentSuccess } from 'redux/actions/payment';

export const PaymentSuccess = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const paymentId = urlParams.get('checkout-reference');
  const transactionId = urlParams.get('checkout-transaction-id');
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    dispatch(sendPaymentSuccess(paymentId, transactionId));
    let timer1 = setTimeout(() => setRedirect(true), 1500);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timer1);
    };
  }, [dispatch, paymentId, transactionId]);

  return redirect ? (
    <Redirect to="/user-dashboard"></Redirect>
  ) : (
    <Container maxWidth="xl">
      <CircularProgress style={{ top: '50%', left: '50%' }} color="secondary" />
    </Container>
  );
};

export default PaymentSuccess;
