import React, { Fragment } from 'react';

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index}>
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
};

export default TabPanel;
