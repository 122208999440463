import API from 'services/api';
import { setAlert } from 'redux/actions/alert';
import { loginAfterRegister } from 'redux/actions/auth';

export const FETCH_GROUPS_REQUESTED = 'FETCH_GROUPS_REQUESTED';
export const FETCH_GROUPS_RESPONSE = 'FETCH_GROUPS_RESPONSE';
export const FETCH_GROUPS_ERROR = 'FETCH_GROUPS_ERROR';

export const SAVE_USER_REQUESTED = 'SAVE_USER_REQUESTED';
export const SAVE_USER_RESPONSE = 'SAVE_USER_RESPONSE';
export const SAVE_USER_ERROR = 'SAVE_USER_ERROR';

export const TOGGLE_PASSWORD = 'TOGGLE_PASSWORD';
export const TOGGLE_MARKETING_VIEW = 'TOGGLE_MARKETING_VIEW';

export const UPDATE_REGISTERING_USER_FIRST_NAME =
  'UPDATE_REGISTERING_USER_FIRST_NAME';
export const UPDATE_REGISTERING_USER_LAST_NAME =
  'UPDATE_REGISTERING_USER_LAST_NAME';
export const UPDATE_REGISTERING_USER_EMAIL = 'UPDATE_REGISTERING_USER_EMAIL';
export const UPDATE_REGISTERING_USER_PHONE = 'UPDATE_REGISTERING_USER_PHONE';
export const UPDATE_REGISTERING_USER_COUNTRY =
  'UPDATE_REGISTERING_USER_COUNTRY';
export const UPDATE_REGISTERING_USER_GENDER = 'UPDATE_REGISTERING_USER_GENDER';
export const UPDATE_REGISTERING_USER_ADDRESS_STREET =
  'UPDATE_REGISTERING_USER_ADDRESS_STREET';
export const UPDATE_REGISTERING_USER_ADDRESS_POSTCODE =
  'UPDATE_REGISTERING_USER_ADDRESS_POSTCODE';
export const UPDATE_REGISTERING_USER_ADDRESS_CITY =
  'UPDATE_REGISTERING_USER_ADDRESS_CITY';
export const UPDATE_REGISTERING_USER_PASSWORD =
  'UPDATE_REGISTERING_USER_PASSWORD';
export const UPDATE_REGISTERING_USER_BIRTHDAY =
  'UPDATE_REGISTERING_USER_BIRTHDAY';
export const UPDATE_REGISTERING_USER_MARKETING =
  'UPDATE_REGISTERING_USER_MARKETING';
export const UPDATE_REGISTERING_USER_GROUP = 'UPDATE_REGISTERING_USER_GROUP';

export const fetchGroups = (identifier) => async (dispatch) => {
  dispatch({ type: FETCH_GROUPS_REQUESTED });
  try {
    const res = await API.get(`/api/groups/${identifier}`);
    dispatch({ type: FETCH_GROUPS_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch({
      type: FETCH_GROUPS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const saveUser = (user, marketing) => async (dispatch) => {
  dispatch({ type: SAVE_USER_REQUESTED });
  try {
    user.marketing = marketing;
    const res = await API.post('/api/users', user);
    dispatch(loginAfterRegister(res.data));
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: SAVE_USER_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const togglePassword = () => ({
  type: TOGGLE_PASSWORD,
});

export const toggleMarketingView = () => ({
  type: TOGGLE_MARKETING_VIEW,
});

export const updateUserFirstName = (payload) => ({
  type: UPDATE_REGISTERING_USER_FIRST_NAME,
  payload,
});

export const updateUserLastName = (payload) => ({
  type: UPDATE_REGISTERING_USER_LAST_NAME,
  payload,
});

export const updateUserEmail = (payload) => ({
  type: UPDATE_REGISTERING_USER_EMAIL,
  payload,
});

export const updateUserPhone = (payload) => ({
  type: UPDATE_REGISTERING_USER_PHONE,
  payload,
});

export const updateUserPassword = (payload) => ({
  type: UPDATE_REGISTERING_USER_PASSWORD,
  payload,
});

export const updateUserBirthday = (payload) => ({
  type: UPDATE_REGISTERING_USER_BIRTHDAY,
  payload,
});

export const updateUserMarketing = (payload) => async (dispatch) => ({
  type: UPDATE_REGISTERING_USER_MARKETING,
  payload,
});

export const updateUserGroup = (payload) => ({
  type: UPDATE_REGISTERING_USER_GROUP,
  payload,
});

export const updateUserGender = (payload) => ({
  type: UPDATE_REGISTERING_USER_GENDER,
  payload,
});

export const updateUserAddressStreet = (payload) => ({
  type: UPDATE_REGISTERING_USER_ADDRESS_STREET,
  payload,
});

export const updateUserAddressCity = (payload) => ({
  type: UPDATE_REGISTERING_USER_ADDRESS_CITY,
  payload,
});

export const updateUserAddressPostCode = (payload) => ({
  type: UPDATE_REGISTERING_USER_ADDRESS_POSTCODE,
  payload,
});

export const updateUserCountry = (payload) => ({
  type: UPDATE_REGISTERING_USER_COUNTRY,
  payload,
});
