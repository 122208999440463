import React from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { useDispatch } from 'react-redux';
import { fetchEventPayments } from '../../../../redux/actions/payment';
import API from '../../../../services/api';

export const RemoveDiscountCodeButton = ({ id }) => {
  const dispatch = useDispatch();

  const handleDelete = async (id) => {
    try {
      await API.delete(`/api/discountCodes/${id}`);
      dispatch(fetchEventPayments());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IconButton onClick={() => handleDelete(id)}>
      <DeleteIcon color="secondary" />
    </IconButton>
  );
};
